<mwl-calendar-week-view *ngIf="weekendDays.length !== 7"
                        [viewDate]="viewDate"
                        [locale]="lang"
                        [events]="events"
                        [refresh]="refresh"
                        [weekStartsOn]="weekStartOn"
                        [weekendDays]="weekendDays"
                        [excludeDays]="weekendDays"
                        [dayStartHour]="dayStartOn?.getHours()"
                        [dayStartMinute]="dayStartOn?.getMinutes()"
                        [dayEndHour]="dayEndOn?.getHours()"
                        [dayEndMinute]="dayEndOn?.getMinutes()"
                        (eventClicked)="onEventClicked($event)"
                        (eventTimesChanged)="onEventTimesChanged($event)">
</mwl-calendar-week-view>
<div *ngIf="weekendDays.length === 7"> No existen dias laborables</div>
