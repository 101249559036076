import { Location } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Params, Route, Router, UrlTree } from '@angular/router';
import { catchError, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, defer, EMPTY, forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { AppConfigurationService } from '../../app.configuration.service';
import { DecoupledModalBridgeService } from '../../shared/decoupled-modal/decoupled-modal-bridge.service';
import {
  asIterableObject,
  backendTypeMatch,
  getInSafe,
  isArray,
  isNullOrUndefined,
  isNullOrWhitespace,
  nameof,
  trimCharsEnd,
  UtilsTypescript
} from '../../shared/utils/typescript.utils';
import { CommandService } from '../commands/command.service';
import { IResultCollector } from '../commands/resultcollector.interface';
import { CommunicationLocalService } from '../communication/communication-local.service';
import { ControllerInfo } from './models/controller-info.interface';
import { MenuItemCompiledFrontend } from './models/MenuItemCompiledFrontend.class';
import { NavigationRequest } from './models/NavigationRequest.class';
import { ReplacedControllerInfo } from './models/redirect-info.interface';
import { NavigationTabLevel } from './navigation-tab-level.class';
import { NavigationActionEventData } from './navigation.onaction.event';
import { Navutils } from './navutils';
import { BackNavigateOptions } from './object-navigation';
import urlJoin from 'proper-url-join';
import { HttpErrorResponse } from '@angular/common/http';
import * as $ from 'jquery';
import { DisposeReason, finalizeWithReason } from '../../utils/rxJsFinalizeWithReason';
import {
  CoreMenuFrontendRoute,
  CoreMenuFrontendRouteLocation,
  CoreMenuNavigateDirectionCommand,
  CoreMenuNavigateDirectionEnum,
  CoreMenuNavigateToAppendedController,
  CoreMenuNavigateToControllerCommand,
  CoreMenuNavigateToIdentifierCommand,
  CoreMenuNavigateToUrl,
  CoreMenuNavigateWaitCommand,
  CoreMenuNavigationRefreshCommand,
  CoreMenuNavigationRefreshPartialCommand,
  ILayout,
  MenuItemCompiled,
  MenuPathInfo,
  MenuQueryResponse,
  MenuType,
  NavigationTreeResponse,
  ThemeDefinitionCompiled,
  WebServiceResponseTyped
} from '../models/ETG_SABENTISpro_Application_Core_models';
import { MenuService } from '../services/ETG_SABENTISpro_Application_Core_menu.service';
import { ClientThemeService } from '../theme-manager/theme.service';
import { AuthService } from '../authentication/auth.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class NavigationService {

  protected layout: ILayout;

  /**
   * Evento que se lanza el pinchar en un elemento de navegación, de manera centralizada.
   */
  protected onActionValue$: EventEmitter<NavigationActionEventData> = new EventEmitter<NavigationActionEventData>();

  /**
   * Evento que se emite cada vez que se resuelve una NavigationRequest, sea o no esta solicitada
   * por navegación de usuario.
   */
  public navigationRequestResolved: EventEmitter<NavigationRequest> = new EventEmitter<NavigationRequest>();

  /**
   * Parecido a lastResolvedNavigationRequest, pero NO emite valores si el usuario ha iniciado
   * un proceso de navegación y además no emite si mientras se está cargando la navegación,
   * el usuario vuelve a navegar. Debe utilizarse únicamente en componentes que toman decisiones sobre
   * algún elemento del nodo de navegación actual.
   */
  protected _currentNavigationRequest: BehaviorSubject<NavigationRequest> = new BehaviorSubject<NavigationRequest>(null);

  /**
   * Contiene exáctamente la petición de navegación actual, haya sido O NO resuelta!
   */
  protected _activeNavigationRequest: BehaviorSubject<NavigationRequest> = new BehaviorSubject<NavigationRequest>(null);

  /**
   * La última navegación resuelta, si el usuario navega, emite el valor previo
   * hasta que se obtenga la nueva navegación.
   */
  protected _lastResolvedNavigationRequest: ReplaySubject<NavigationRequest> = new ReplaySubject<NavigationRequest>(1);

  /**
   * Versión síncrona que guarda el valor..
   */
  protected _lastResolvedNavigationRequestSync: BehaviorSubject<NavigationRequest> = new BehaviorSubject<NavigationRequest>(null);

  /**
   * El mapeo de rutas de angular a controladores de backend, necesario para que este servicio funcione. Se define en frontend
   * en un ficheor .JSON intratable ;(
   */
  protected routes: { [id: string]: string } = null;

  /**
   * El mapa completo de rutas de backend hacia los controladores.
   */
  protected menuPathInfo: { [id: string]: MenuPathInfo } = null;

  /**
   * El mapa completo de rutas de backend hacia los controladores.
   */
  protected unmodifiedMenuPathInfo: { [id: string]: MenuPathInfo } = null;

  /**
   * El mapeo inverso a menuPathInfo, a partir del controlador, obtener la ruta de backend.
   *
   * Se carga al arrancar el servicio (es necesario para que funcione).
   *
   * La clave es el controlador, y el valor es el path de backend.
   *
   * Para los ítems tipo ANCHOR (que comparten controlador con otros elementos de menú) la clave
   * es el controlador más los argumentos en el siguiente formato:
   *
   * controlador|argumento1:valor1,argumento2:valor2
   *
   */
  protected controllerMap: { [id: string]: ControllerInfo } = null;

  /***
   * Mapeo de identificadores de items de menú
   *
   * @protected
   */
  protected identifierMap: { [id: string]: ControllerInfo } = null;

  /**
   * Indica si routes, menuPathInfo y controllerMap ya están disponibles
   */
  protected routesLoaded: ReplaySubject<void> = new ReplaySubject<void>(1)

  /**
   * Usamos esto para cancelar la navegación en curso, por si el usuario navega
   * antes de que la petición actual de navegación se haya resuelto. Podría evitarse
   * con un switchMap o similar?
   */
  protected cancelCurrentNavigationEvent: EventEmitter<void> = new EventEmitter<void>();

  /**
   *
   * @protected
   */
  protected cancelAllGetMenuRemote: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Caché de árboles de navegación y solicitudes, la clave del diccionario
   * es el expanded path (path con argumentos reemplazados) de la ruta "ficticia" de backend.
   */
  protected trees: { [id: string]: NavigationRequest } = {};

  /**
   * @deprecated Use onMenuItemClicked$
   *
   * Evento que se lanza el pinchar en un elemento de navegación, de manera centralizada.
   *
   */
  public get onAction$(): Observable<MenuItemCompiledFrontend> {
    return this.onActionValue$
        .pipe(
            map((i) => i.menuItem)
        );
  }

  /**
   * Evento que se lanza el pinchar en un elemento de navegación, de manera centralizada.
   */
    public get onMenuItemClicked$(): Observable<NavigationActionEventData> {
    return this.onActionValue$;
  }

  /**
   * Observable que emite la última navegación resuelta, pero también emite
   * un nulo en el periodo de tiempo entre que se emite la solicitud de navegación
   * y se obtiene el resultado de backend. Incluye un debounce interno de 500ms
   * para evitar que cambios bruscos en las navegación sean propagadas.
   */
  public get currentNavigationRequest(): BehaviorSubject<NavigationRequest> {
    return this._currentNavigationRequest;
  }

  /**
   * Observable que devuelve la última navegación resuelta, o cualquier cambio
   * de navegación. Si se quiere acceder de manera síncrona al valor actual,
   * se puede usar lastResolvedNavigationRequestSync
   */
  public get lastResolvedNavigationRequest(): Observable<NavigationRequest> {
    return this._lastResolvedNavigationRequest;
  }

  /**
   * Versión síncrona de lastResolvedNavigationRequest. Emite nulo cuando todavía
   * no se ha lanzado la primera navegación.
   */
  public get lastResolvedNavigationRequestSync(): BehaviorSubject<NavigationRequest> {
    return this._lastResolvedNavigationRequestSync;
  }

  /**
   * Creates a new instance of NavigationService. On the creation point obtains
   * the navigation tree based on the current node.
   */
  constructor(
      private menuService: MenuService,
      private appConfigurationService: AppConfigurationService,
      private communicationLocalService: CommunicationLocalService,
      private location: Location,
      private router: Router,
      private layoutManager: ClientThemeService,
      private dmbs: DecoupledModalBridgeService,
      private commandService: CommandService,
      private authService: AuthService
  ) {
    // En la carga inicial obtenemos dos cosas imprescindibles para que el servicio funcione:
    // [1] El mapa de controladores de backend
    // [2] El mapa de enlace de esos controladores con las rutas de frontend
    this.appConfigurationService
        .isAppBootstrapped$()
        .pipe(
            take(1),
            switchMap(() => {
              const mappingFiles: string[] = [];
              // Este es el legacy
              // mappingFiles.push('assets/configuration/controler-route-mapping.json');
              // Cada módulo (activo) puede aportar sus rutas, algunas de estas rutas no existirán, pero no pasa nada
              for (const module of this.appConfigurationService.getActiveModulesConfig()) {
                mappingFiles.push('assets_modules/' + module.nombre.toLowerCase() + '/controler-route-mapping.json');
              }
              const observables: Observable<any>[] = [];
              observables.push(this.menuService.getNavigationtree());
              for (const mappingFile of mappingFiles) {
                observables.push(this.communicationLocalService.get(
                    mappingFile,
                    (e: HttpErrorResponse) => {
                      if (!(e instanceof HttpErrorResponse)) {
                        return false;
                      }
                      // Solo ignoramos el error si es un 404 no encontrado, porque
                      // puede haber módulos que no declaren este fichero
                      return e.status === 404;
                    }));
              }
              return forkJoin(observables);
            }),
        )
        .subscribe((data: Array<any>) => {
              // Data contiene el resultado de los observables, el primero es el arbol de rutas, de ahí en adelante
              // son los intentos de cargar los mapas de rutas de cada módulo
              const bootstrapNavigationTree: NavigationTreeResponse = (data.shift() as WebServiceResponseTyped<NavigationTreeResponse>).result;

              // Ensamblamos el mapeo de rutas con la info de todos los módulos
              this.routes = {};
              for (const routeMapping of data) {
                if (!routeMapping) {
                  continue;
                }
                for (const route of Object.keys(routeMapping)) {
                  if (Object.keys(this.routes).find((i) => i === route)) {
                    throw new Error('Route already exists: ' + route);
                  }
                  this.routes[route] = routeMapping[route];
                }
              }

              const dynamicPages: CoreMenuFrontendRoute[] = this.appConfigurationService.getBootstrapData('dynamicroutes');

              if (dynamicPages) {
                for (const frontendRoute of dynamicPages) {
                  // La ruta es por defecto desde el home, el prefijo del layout lo toma de la jerarquía
                  const route: Route = {
                    path: frontendRoute.Path
                  };

                  // Reemplazo al vuelo de los ficheros de controlador <-> ruta de frontend
                  if (Object.keys(this.routes).find((i) => i === frontendRoute.Controller)) {
                    throw new Error('Route already exists: ' + route?.path);
                  }

                  // Hay dos tipos de rutas, las que están dentro de la navegación, menús, etc.
                  switch (frontendRoute.Location) {
                    case CoreMenuFrontendRouteLocation.Root:
                      this.routes[frontendRoute.Controller] = '/' + frontendRoute.Path;
                      break;
                    case CoreMenuFrontendRouteLocation.Home:
                      this.routes[frontendRoute.Controller] = '/home/' + frontendRoute.Path;
                      break;
                  }
                }
              }

              this.unmodifiedMenuPathInfo = Object.assign({}, bootstrapNavigationTree.NavigationTree);
              this.populateControllerMap(Object.assign({}, bootstrapNavigationTree.NavigationTree), bootstrapNavigationTree.NavigationTreeAlterations);

              // Aplicamos la misma lógica que hay cuando se realiza el bootstrap de la aplicación en el primera petición,
              // que es hacer un NavigationRequest vacío y cacheamos el resultado. El resto de peticiones que se lancen inmediatamente después
              // tomarán el valor de la caché
              if (!isNullOrUndefined(bootstrapNavigationTree.FirstGetMenuResponse)) {
                const navigationRequest: NavigationRequest = this.postProcessRemoteNavigationRequest(bootstrapNavigationTree.FirstGetMenuResponse, new NavigationRequest(), null);
                navigationRequest.requestLastHit = new Date();
                this.trees[navigationRequest.requestBackendExpandedPath] = navigationRequest;
              }

              // Informamos que el bootstrap está completo
              // En el caso que estemos logados en la plataforma la primera solicitud del menú ya está hecha,
              // de lo contrario no se realizará hasta que el usuario acceda a la plataforma
              this.routesLoaded.next();
            }
        );

    layoutManager.currentTheme
        .pipe(map((theme: ThemeDefinitionCompiled) => {
          this.layout = theme.Layout;
        }));

    // Mapeamos los cambios de navegación al observable "estable"
    this.currentNavigationRequest.pipe(
        filter((request) => request != null),
        map((request) => this._lastResolvedNavigationRequest.next(request))
    )
        .subscribe();

    // Mandamos la última navegación resuelata al BehaviourSubject, para que esté disponible en procesos síncronos.
    this._lastResolvedNavigationRequest.pipe(
        map((request) => this._lastResolvedNavigationRequestSync.next(request))
    )
        .subscribe();

    // CoreMenuNavigateWaitCommand
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigateWaitCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigateWaitCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
              next.AddResult(
                  // Usamos un defer para garantizar que nos colgamos del currentNavigationRequest en el punto exacto del flujo
                  // de ejecución de comandos, y no al momento de registrar el observable ya que currentNavigationRequest es un observable
                  // que se va reemplazando de manera iterativa.
                  defer(() => {
                        return this.currentNavigationRequest
                            .pipe(
                                filter((i) => i !== null),
                                take(1),
                                map((nav: NavigationRequest) => true)
                            );
                      }
                  ));
            }
        );

    // CoreMenuNavigateWaitCommand
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigateToUrl.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigateToUrl, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          next.AddResult(
              () => {
                console.debug(`Navigate to ${next.Argument.IsExternal ? 'external' : 'internal'} URL: ${next.Argument.Url}`)
                if (!next.Argument.IsExternal) {
                  this.authService.$isAuthenticated.subscribe(x => {
                    return this.navigateByUrl(next.Argument.Url);
                  })
                  return Promise.resolve(true);
                }
                window.open(next.Argument.Url, '_blank');

                return Promise.resolve(true);
              });
        });

    // Refresh tree new command
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigationRefreshCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigationRefreshCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          const fullRefresh: boolean = next.Argument.Full === true;
          next.AddResult(this.refreshCurrentNavigation(fullRefresh)
              .pipe(
                  map((nav: NavigationRequest) => true)
              ));
        });

    // Deletes tree until level provided
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigationRefreshPartialCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigationRefreshPartialCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          this.invalidateCurrentNavigationPartial(next.Argument.Levels);
          next.AddResult(of(true));
        });

    // Navigate to controller command
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigateToControllerCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigateToControllerCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          next.AddResult(
              this.routesLoaded
                  .pipe(
                      switchMap(() => {
                        if (next.Argument.ReturnToCurrentUri) {
                          return fromPromise(this.navigateUrlByControllerWithReturnToUri(next.Argument.Controller, next.Argument.Arguments, this.router.url, next.Argument.QueryString));
                        }
                        return fromPromise(this.navigateUrlByController(next.Argument.Controller, next.Argument.Arguments, next.Argument.QueryString));
                      })));
        });

    // Navigate to identifier command
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigateToIdentifierCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigateToIdentifierCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          next.AddResult(
              () => {
                if (next.Argument.ReturnToCurrentUri) {
                  return this.navigateUrlByIdentifierWithReturnToUri(next.Argument.Identifier, this.router.url);
                } else {
                  return this.navigateUrlByIdentifier(next.Argument.Identifier);
                }
              });
        });

    // CoreMenuNavigateDirectionCommand command
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigateDirectionCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigateDirectionCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          next.AddResult(defer(() => {
                switch (next.Argument.Direction) {
                  case CoreMenuNavigateDirectionEnum.Left:
                    const destination: MenuItemCompiledFrontend = this.getHorizontalNavigationNode(this.currentNavigationRequest.value, false);
                    return this.navigateToMenuItem(destination);
                    break;
                  case CoreMenuNavigateDirectionEnum.Right:
                    const destination2: MenuItemCompiledFrontend = this.getHorizontalNavigationNode(this.currentNavigationRequest.value, true);
                    return this.navigateToMenuItem(destination2);
                    break;
                  case CoreMenuNavigateDirectionEnum.Up:
                    return fromPromise(this.backNew(next.Argument.SkipParametricNodeCount));
                    break;
                  default:
                    throw new Error('Not supported.');
                }
              })
                  .pipe(
                      map((i) => {
                        if (i === true || i === false) {
                          return i;
                        }
                        return true;
                      })
                  )
          );
        });

    // CoreMenuNavigateToAppend command
    this
        .commandService
        .CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreMenuNavigateToAppendedController.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMenuNavigateToAppendedController, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          next.AddResult(defer(() => {
                return fromPromise(this.navigateUrlBySubController(next.Argument.ToAppend, next.Argument.Arguments, null, this.currentNavigationRequest.value));
              })
                  .pipe(
                      map((i) => {
                        if (i === true || i === false) {
                          return i;
                        }
                        return true;
                      })
                  )
          )
        });
  }

  /**
   * Populates the controllerMap attribute with the all nodes in the app.
   * This nodes can be altered in Pre/Post backend MenuItem Callbacks.
   * When the application starts the controllerMap will be setted taken
   * the raw MenuPathInfo and doing all the alterations in every controller.
   *
   * When a controller is replaced, the previous controller will be setted inactive
   * @param newMenuPathInfo
   * @param alterations
   */
  private populateControllerMap(
      newMenuPathInfo: { [id: string]: MenuPathInfo },
      alterations: { [id: string]: MenuPathInfo }): void {
    this.controllerMap = {};
    this.identifierMap = {};
    for (const backendPath of Object.keys(newMenuPathInfo)) {
      const info: MenuPathInfo = newMenuPathInfo[backendPath];
      const alteration: MenuPathInfo = alterations ? alterations[backendPath] : null;

      // Guardamos todos los que tengan identificador...
      this.identifierMap[info.Id] = {
        FrontendPath: '',
        Controller: info.Controller,
        BackendPath: backendPath,
        Status: 'ACTIVE',
        MenuType: info.MenuType,
        Id: info.Id
      };

      // Elementos sin controlador no nos interesan ya que no se pueden mapear a frontend
      if (UtilsTypescript.isNullOrUndefined(info.Controller)) {
        continue;
      }

      switch (info.MenuType) {
        case MenuType.Normal:
          if (!this.controllerMap[info.Controller]) {
            this.controllerMap[info.Controller] = {
              FrontendPath: '',
              Controller: info.Controller,
              BackendPath: backendPath,
              Status: 'ACTIVE',
              MenuType: info.MenuType,
              Id: info.Id
            };
          }
          if (alteration && info.Controller !== alteration.Controller && !isNullOrUndefined(alteration.Controller)) {
            // Si el nodo está alterado
            this.controllerMap[alteration.Controller] = {
              FrontendPath: '',
              Controller: alteration.Controller,
              BackendPath: backendPath,
              Status: 'ACTIVE',
              MenuType: alteration.MenuType,
              Id: info.Id
            };
            // Si el nodo está alterado
            this.controllerMap[info.Controller] = {
              FrontendPath: '',
              Controller: info.Controller,
              BackendPath: backendPath,
              Status: 'INACTIVE',
              MenuType: info.MenuType,
              ReplacedByController: alteration.Controller,
              Id: info.Id
            };
          }
          break;
        case MenuType.WizardContainer:
        case MenuType.TabContainer:
        case MenuType.Menu:
        case MenuType.Undefined:
        case MenuType.Ghost:
        case MenuType.LocalAction:
          if (!this.controllerMap[info.Controller]) {
            this.controllerMap[info.Controller] = {
              FrontendPath: '',
              Controller: info.Controller,
              BackendPath: backendPath,
              Status: 'ACTIVE',
              MenuType: info.MenuType,
              Id: info.Id
            };
          }
          break;
        case MenuType.Anchor:
          // Se comenta todo esto de los ANCHOR porque tras análisis final del sistema
          // de navegación no se estima necesario puesto que las navegaciones reales SIEMPRE
          // se hacen a través de un nodo normal.
          // En el caso de los anchor, como comparten el enlace, usaremos un controlador ficiticio
          // donde están involucrados los arguments del anchor
          // const controllerKey: string = this.buildControllerMapKeyFromControllerAndArguments(info.Controller, info.AnchorDefaultArguments);
          // this.controllerMap[controllerKey] = backendPath;
          break;
        default:
          throw new Error('Not supported menu type: ' + info.MenuType);
      }
      this.menuPathInfo = newMenuPathInfo;
    }
  }

  /**
   * Get a navigation request from an activated route, this is an observable because it requires
   * the ROUTE mappings to be loaded.
   *
   * @param ars
   */
  public navigationRequestFromActivatedRoute(ars: ActivatedRouteSnapshot): Observable<NavigationRequest> {
    return this.routesLoaded
        .pipe(
            map(() => {
              // Lo primero es preparar el navigation request
              const controllerObject: {
                params?: { [id: string]: string };
                controller: string;
                url: string;
                parameterizedUrl: string
              } =
                  this.resolveControllerAndRouteFromRouteSnapshot(ars);
              const navigationRequest: NavigationRequest = new NavigationRequest();
              navigationRequest.requestArguments = controllerObject.params;
              navigationRequest.requestController = controllerObject.controller;
              navigationRequest.requestExpandedPath = controllerObject.url;
              navigationRequest.requestPath = this.removeLayoutPrefix(controllerObject.parameterizedUrl);

              // Para el caso de los ítems tipo ANCHOR, la clave del controllerMap es un poco diferente y necesita los argumentos, pero como todavía
              // no sabemos si la petición actual es de un ítem tipo ANCHOR o NO, buscamos primero como si fuera anchor, y luego como si no.
              // TODO: Queda pendiente adaptar este algoritmo para casos en que los argumentos del anchor sean parciales, esto es, una parte viene de front, y la otra de back.
              // const controllerMapKeyAnchor: string = this.buildControllerMapKeyFromControllerAndArguments(navigationRequest.requestController, navigationRequest.requestArguments);

              if (UtilsTypescript.isNullOrWhitespace(navigationRequest.requestController)) {
                throw new Error('Cannot find controller for frontend path: [' + navigationRequest.requestPath + ']. The frontend URL might not exist: ' + controllerObject.url);
              }

              const controllerInfo: ControllerInfo = this.controllerMap[navigationRequest.requestController];

              if (isNullOrUndefined(controllerInfo)) {
                throw new Error('Cannot find backend path for requested controller [' + navigationRequest.requestController + ']. Make sure this controller is declared in backend navigation.');
              }

              navigationRequest.requestBackendPath = controllerInfo.BackendPath;
              navigationRequest.requestBackendPathDepth = navigationRequest.requestBackendPath.split('/').length;
              navigationRequest.requestBackendExpandedPath = Navutils.replaceBackendArguments(navigationRequest.requestBackendPath, navigationRequest.requestArguments);
              navigationRequest.requestQueryParams = ars.queryParams;

              return navigationRequest;
            })
        );
  }

  /**
   * Redirect a invalid node to the allowed node.
   * The redirect handler was saved in the RedirectController property of the wrong NavigationRequest.
   * You must remember that this property is set every time this.controllerMap is managed
   * (after bootstrap application startup or any menuService.getMenu operation).
   *
   * Esta condición de redirección está condicionada a que se compartan los mismos argumentos
   * o bien los argumentos tambien sean alterados
   *
   * @param controllerInfo
   * @param invalidNavigationRequest
   */
  redirectInactiveNodeToAllowedNode(controllerInfo: ControllerInfo, invalidNavigationRequest: NavigationRequest): ReplacedControllerInfo {
    if (!isNullOrUndefined(controllerInfo.ReplacedByController)) {
      // Calculamos los argumentos mediante los nuevos argumentos y los cacheados
      // Primero todos los posibles
      const requestArguments: { [id: string]: string } = invalidNavigationRequest.requestArguments;
      const cachedInvalidNavigationRequest: NavigationRequest = this.trees[invalidNavigationRequest.requestBackendExpandedPath];
      const url: string = this.routes[controllerInfo.ReplacedByController];
      if (cachedInvalidNavigationRequest && cachedInvalidNavigationRequest.requestArguments) {
        const cachedRequestArguments: { [id: string]: string } = cachedInvalidNavigationRequest.requestArguments;
        Object.keys(cachedRequestArguments).forEach(key => {
          requestArguments[key] = cachedRequestArguments[key];
        });
      }

      // Ahora quitamos los que no se van a usar
      Object.keys(requestArguments).forEach(key => {
        if (url.indexOf('/' + key + '/') === -1 && url.indexOf('/' + key, url.lastIndexOf('/')) === -1) {
          delete requestArguments[key];
        }
      });

      // Creamos el request de redirección
      const redirectNavigationRequest: NavigationRequest = new NavigationRequest();
      redirectNavigationRequest.requestController = controllerInfo.ReplacedByController;
      redirectNavigationRequest.requestArguments = requestArguments;
      redirectNavigationRequest.requestPath = this.removeLayoutPrefix(url);
      redirectNavigationRequest.requestExpandedPath = this.removeLayoutPrefix(this.getUrlByController(controllerInfo.ReplacedByController, requestArguments));
      redirectNavigationRequest.requestBackendPath = this.controllerMap[controllerInfo.ReplacedByController].BackendPath;
      redirectNavigationRequest.requestBackendPathDepth = redirectNavigationRequest.requestBackendPath.split('/').length;
      redirectNavigationRequest.requestBackendExpandedPath = Navutils.replaceBackendArguments(
          redirectNavigationRequest.requestBackendPath,
          redirectNavigationRequest.requestArguments);
      return {
        originalNavigationRequest: invalidNavigationRequest,
        redirectedNavigationRequest: redirectNavigationRequest
      } as ReplacedControllerInfo;
    }
    throw new Error('Not supported exception. Unable to navigate to a inactive path: ' + controllerInfo.BackendPath + ' for the controller ' + invalidNavigationRequest.requestController);
  }

  /**
   * Permite obtener la batería de tabs de navegación de una navigationRequest, devuelve un array
   * con los diferentes niveles de navegación de tabs, y dentro de cada nivel sus tabs.
   *
   * @param navigationRequest
   */
  resolveTabLevelsTabsFromMenuPathRequest(navigationRequest: NavigationRequest): NavigationTabLevel[] {

    const result: NavigationTabLevel[] = [];
    const menuPath: MenuItemCompiledFrontend[] = navigationRequest.responseMenuPath;

    for (const menuItem of menuPath) {

      if (menuItem.menuType === MenuType.TabContainer) {
        const options: MenuItemCompiledFrontend[] = menuItem.children.filter((i) => i.Hidden !== true);

        // Do not add tabs row if there are not at least 2 tabs
        const forceToShowTab: boolean = menuItem.Metadata && menuItem.Metadata['force-show-tab'];
        if (!forceToShowTab && options.length < 2) {
          continue;
        }

        const level: NavigationTabLevel = new NavigationTabLevel();
        level.Tabs = options;
        level.Title = menuItem.title;
        level.ParentItem = menuItem;
        result.push(level);

        const stickParentTab: boolean = menuItem.Metadata && menuItem.Metadata['stick-parent-tab'];
        if (!stickParentTab) {
          break;
        }
      }

      // Limitar la profunidad de tabs a solo dos niveles
      if (result.length === 2) {
        break;
      }
    }

    return result;
  }

  /**
   * Prepares a route from the controller string.
   * @param {string} controller
   * @param {{ [paramKey: string]: any}} args
   */
  prepareRouteFromController(controller: string, args: { [paramKey: string]: any }): string {
    let frontendPath: string = this.resolveControllerToFrontendPath(controller);

    if (isNullOrUndefined(frontendPath)) {
      return null;
    }

    Object.keys(args).map(k => {
      frontendPath = frontendPath.replace(`/%${k}/`, `/${args[k]}/`);
    });

    return this.addLayoutPrefixIfMissing(frontendPath);
  }

  /**
   *  Refrescamos la navegación actual sea cual sea el nodo en el que estemos, interpretando
   * que debemos borrar las cachés de navegación.
   *
   * @param fullRefresh
   *   Si el refresco debe ser completo o parcial
   *
   */
  refreshCurrentNavigation(fullRefresh: boolean): Observable<NavigationRequest> {
    // Usamos el _activeNavigationRequest, ya que puede ser que se haga una navegación
    // justo antes de llamar a este método todavía no esté resuelta (no estará disponible en currentNavigationRequest)
    return this._activeNavigationRequest
        .pipe(
            filter((navigationRequest: NavigationRequest) => {
              return navigationRequest !== null;
            }),
            take(1),
            switchMap((navigationRequest: NavigationRequest) => {
              const copiedNavigationRequest: NavigationRequest = this.cloneNavigationRequest(navigationRequest);
              copiedNavigationRequest.requestCompleted = new ReplaySubject<NavigationRequest>(1);
              copiedNavigationRequest.responseRemoteMenu = null;
              copiedNavigationRequest.responseNodeForTitle = null;
              copiedNavigationRequest.responseProcessedTree = null;
              this.clearNavigationCaches(fullRefresh);
              return this.executeNavigationFromNavigationRequest(copiedNavigationRequest);
            })
        );
  }

  /**
   *  Borramos las cachés de navegación hasta el nivel dado. Si el nivel dado es superior al nivel máximo
   *  Booramos toda la caché.
   *
   * @param levels
   *   Nivel hasta el que se borra la caché de navegación
   *
   */
  invalidateCurrentNavigationPartial(levels: number): void {
    const currentNavigationRequest: NavigationRequest = this._activeNavigationRequest.getValue();

    const parts: string[] = currentNavigationRequest.requestBackendPath.split('/');
    const partsExpanded: string[] = currentNavigationRequest.requestBackendExpandedPath.split('/');

    let deleteFrom: string = null;
    let levelsTaken: number = 0;

    for (let x: number = parts.length - 1; x >= 0; x--) {
      if (parts[x].startsWith('%')) {
        levelsTaken++;
      }
      deleteFrom = [...partsExpanded].splice(0, x + 1).join('/');
      if (levelsTaken === levels) {
        break;
      }
    }

    if (deleteFrom === '') {
      this.trees = {};
      return;
    }

    for (const key of Object.keys(this.trees)) {
      if (key.startsWith(deleteFrom)) {
        delete (this.trees[key]);
      }
    }
  }

  /**
   *
   * @param original
   */
  cloneNavigationRequest(original: NavigationRequest): NavigationRequest {
    // Copiamos el navigation request y borramos lo que pertenezca a la resolución efectiva
    const copiedNavigationRequest: NavigationRequest = UtilsTypescript.jsonClone(original, (name, val) => {
      if (name === nameof<NavigationRequest>('requestCompleted')) {
        return undefined;
      }
      return val;
    });
    return copiedNavigationRequest;
  }

  getUriWithReturnToUrl(uri: string, returnToUri: string, queryParams: { [key: string]: string } = null): UrlTree {

    if (returnToUri === 'self') {
      returnToUri = this.router.url;
    }

    if (!isNullOrWhitespace(returnToUri)) {
      returnToUri = this.removeLayoutPrefix(returnToUri);
      // Intentamos usar el controller, sí y solo sí la ruta original NO TIENE ARGUMENTOS. Esto
      // ayuda a tener rutas más cortas en los argumentos
      for (const key of Object.keys(this.routes)) {
        if (this.routes[key] === returnToUri) {
          returnToUri = key;
          break;
        }
      }
    }

    queryParams = queryParams || {};

    if (!isNullOrWhitespace(returnToUri)) {
      queryParams['backToUri'] = returnToUri;
    }

    const urlTree: UrlTree = this.router.createUrlTree(
        [uri], {queryParams: queryParams}
    );

    return urlTree;
  }

  /**
   * Navera a una URL, haciendo que la navegación de vuelta de esa URl sea la indicada
   * en returnToUri.
   *
   * @param uri
   *   Uri de destino
   * @param returnToUri
   *   Uri a la que debe volver. Puede ser un controlador o una URI completa.
   */
  async navigateWithReturnToUrl(uri: string, returnToUri: string, queryParams: {
    [key: string]: string
  } = null): Promise<boolean> {
    const urlTree: UrlTree = this.getUriWithReturnToUrl(uri, returnToUri, queryParams);
    return this.router.navigateByUrl(urlTree);
  }

  /**
   * Borrar la caché de árboles de navegación
   *
   * @param full
   *   Borra todos los árboles, incluido el arbol "base" (el menú lateral). Sino borra todos menos el menú lateral.
   */
  public clearNavigationCaches(full: boolean): void {
    const treeCopy: { [id: string]: NavigationRequest } = this.trees;
    this.trees = {};

    // Si había alguna petición remota de menús, la cancelamos
    this.cancelAllGetMenuRemote.emit();

    // También la petición actual
    this.cancelCurrentNavigationEvent.emit();

    if (full !== true) {
      this.trees[''] = treeCopy[''];
    }
  }

  /**
   * Returns the active MenuItemCompiled from the requested snapshot
   */
  getMenuItemCompiled(ars: ActivatedRouteSnapshot): Observable<MenuItemCompiledFrontend> {
    return this.navigationRequestFromActivatedRoute(ars)
        .pipe(
            switchMap((navigationRequest: NavigationRequest) => {
                  return this.resolveTreeFromNavigationRequest(navigationRequest)
                      .pipe(
                          map((i) => {
                            if (i instanceof NavigationRequest) {
                              return i.responseMenuPath[0]
                            }
                          })
                      );
                }
            )
        );
  }

  /**
   * Navigate to the given URL, does not navigate if the node has an AccessBlockingMessage
   *
   * @param item
   */
  navigateToMenuItem(item: MenuItemCompiledFrontend, extras?: NavigationExtras): Observable<boolean> {

    // Primero controlamos el bloqueo del acceso
    if (item.accessBlocked()) {
      this.commandService.executeCommandChain(asIterableObject(item.AccessBlockedCommands));
      return of(false);
    }

    // Ejecutamos los comandos del item si los hubiera
    if (item.OnClickCommands && Object.keys(item.OnClickCommands)) {
      this.commandService.executeCommandChain(asIterableObject(item.OnClickCommands));
    }

    // Lanzamos el evento para que quien quiera haga cosas...
    const eventData: NavigationActionEventData = new NavigationActionEventData();
    eventData.menuItem = item;
    this.onActionValue$.next(eventData);

    // Si no hay path o se ha pedido que no se navegue
    if (!item.getFrontendPath() || eventData.stopNavigation === true) {
      return of(false);
    }

    return fromPromise(this.router.navigateByUrl(item.getFrontendPath(), extras));
  }

  /**
   * Algunas rutas no tienen navegación asociada, y el sistema de navegación
   * no debe calcular navegaciones en esas rutas...
   *
   * @param ars
   */
  shouldSkipNavigation(ars: ActivatedRouteSnapshot): boolean {
    // Algunas rutas no tienen la navegación todavía disponbile, nos las saltamos..
    const lowestLevelRoute: ActivatedRouteSnapshot = Navutils.findLastActivatedRouteSnapshotFromRoute(ars);
    const url: string = Navutils.findExpandedUrlFromRoute(lowestLevelRoute);
    const skipUrl: boolean =
        url === '/maintenance'
        || url === '/out-of-service'
        || url === '/error'
        || Navutils.pathStartsWith(url.split('/'), this.getUnsuscribeUrl().split('/'))
        || Navutils.pathStartsWith(url.split('/'), this.getLoginURL().split('/'))
        || Navutils.pathStartsWith(url.split('/'), this.getSelectPersonURL().split('/'))
        || Navutils.pathStartsWith(url.split('/'), this.getSsoURL().split('/'))
        || Navutils.pathStartsWith(url.split('/'), this.getQrCode().split('/'))
        || Navutils.pathStartsWith(url.split('/'), this.getPrivacyPolicyUrl().split('/'))
        || url === this.addLayoutPrefixIfMissing('/');
    return skipUrl;
  }

  /**
   * A partir de una ruta de angular (ActivatedRouteSnapshot) obtiene el árbol de navegación
   *
   * @param ars
   */
  executeNavigation(ars: ActivatedRouteSnapshot): Observable<boolean> {

    if (this.shouldSkipNavigation(ars) === true) {
      return of(true);
    }

    return this.navigationRequestFromActivatedRoute(ars)
        .pipe(
            switchMap(((navigationRequest: NavigationRequest) => {
                      return this.executeNavigationFromNavigationRequest(navigationRequest);
                    }
                )
            ),
            map((i) => true)
        );
  }

  public executeNullNavigation(): Promise<boolean> {
    this._currentNavigationRequest.next(null);
    return Promise.resolve(true);
  }

  /**
   * Ejecutar una navegación a partir de un navigation request
   *
   * @param navigationRequest
   */
  protected executeNavigationFromNavigationRequest(navigationRequest: NavigationRequest): Observable<NavigationRequest> {
    // Hay un flujo específico en que se lanzan varios navigation requests idénticos, y si primero cancelamos el actual,
    // pero en la llamada a resolveTreeFromNavigationRequest vincula el último al que ha sido cancelado... entonces
    // no se ejecuta ninguno de los dos. Para evitar eso, solo cancelamos si no hay una petición activa que fuera diferente
    // a la que nos están pasando
    if (this._activeNavigationRequest.getValue() && this._activeNavigationRequest.getValue().requestBackendExpandedPath !== navigationRequest.requestBackendExpandedPath) {
      this.cancelCurrentNavigationEvent.emit();
    }

    this._currentNavigationRequest.next(null);
    this._activeNavigationRequest.next(navigationRequest);

    return this.resolveTreeFromNavigationRequest(navigationRequest)
        .pipe(
            takeUntil(this.cancelCurrentNavigationEvent),
            map((resolvedNavigationRequest: NavigationRequest) => {
              resolvedNavigationRequest.requestLastHit = new Date();
              this._currentNavigationRequest.next(resolvedNavigationRequest);

              // Añadimos una clase al elemento body de HTML para identificar el controlador de la ruta actual
              $('body').removeClass(function (index: number, className: string): string {
                return (className.match(/(^|\s)nav-controller-\S+/g) || []).join(' ');
              });
              $('body').addClass('nav-controller-' + resolvedNavigationRequest.requestController.replace(/[^a-zA-Z0-9]/g, '_'));

              return resolvedNavigationRequest;
            })
        );
  }

  /**
   *
   * @param ars
   */
  resolveTreeFromRoute(ars: ActivatedRouteSnapshot): Observable<NavigationRequest | ReplacedControllerInfo> {
    // Emitimos el evento de cancelación, por si ya se está ejecutando una navegación
    return this.navigationRequestFromActivatedRoute(ars)
        .pipe(
            switchMap((navigationRequest: NavigationRequest) => {
              return this.resolveTreeFromNavigationRequest(navigationRequest);
            })
        );
  }

  resolveTreeFromNavigationRequest(navigationRequest: NavigationRequest): Observable<NavigationRequest | ReplacedControllerInfo> {
    // Cualquier llamada a resolución de árboles, necesita SÍ o Sí tener cargadas las rutas...
    return this.routesLoaded
        .pipe(
            switchMap(() => {
              return this.internalResolveTreeFromNavigationRequest(navigationRequest);
            })
        );
  }

  /**
   * Siempre que pidamos la navegación, asegurarnos de que la resolución base ('') ya está realizada
   * @param navigationRequest
   */
  private internalResolveTreeFromNavigationRequest(navigationRequest: NavigationRequest): Observable<NavigationRequest | ReplacedControllerInfo> {
    // Importante, asegurarnos de que siempre tenemos cargada la raíz. Por ejemplo, si refrescamos el navegador en una ruta
    // concreta, primero cargaremos la raíz y luego esa ruta, al final es menos costoso que hacerlo al revés ya que en la
    // segunda petición se hace una petición parcial y no completa.
    return this.doInternalResolveTreeFromNavigationRequest(new NavigationRequest())
        .pipe(
            switchMap(() => {
              const controllerInfo: ControllerInfo = this.controllerMap[navigationRequest.requestController];
              if (!isNullOrUndefined(controllerInfo) && controllerInfo.Status === 'INACTIVE') {
                // No todos los sitios donde se gestiona la redirección tienen en el pipe la comprobación de este evento.
                // Por ejemplo cuando se introduce la ruta directamente en el navegador
                this.cancelCurrentNavigationEvent.emit();
                // Este es el punto más problemático, ya que se debería abortar este navigationRequest,
                // se capturara en el guard para que canActivate sea false y en ese momento se redireccionara.
                // De momento, para ver que el "planteamiento" es el correcto ejecutamos aquí mismo la redirección
                // y devolvemos el navigationRequest "fcancelCurrentNavigationEventuturo", el cual se cargará
                return of(this.redirectInactiveNodeToAllowedNode(controllerInfo, navigationRequest));
              }
              return this.doInternalResolveTreeFromNavigationRequest(navigationRequest);
            })
        );
  }

  /**
   * Revuelve un árbol a partir de una petición de navegación (NavigationReques)
   *
   * @param navigationRequest
   *
   * @param clearCurrentNavigation
   *   Si se pasa true, se emitirá NULL en this.CurrentNavigationRequest a fin de indicar
   *   que se está cargando una nueva estructura de navegación solo si es necesario
   *   ir a backend a buscar el nuevo árbol.
   */
  private doInternalResolveTreeFromNavigationRequest(navigationRequest: NavigationRequest): Observable<NavigationRequest> {
    // Si ya se ha hecho este navigation request, lo reaprovechamos
    const existingRequest: NavigationRequest = this.trees[navigationRequest.requestBackendExpandedPath];

    // Si la petición ya existe o está en caché, loopeamos con el Subject interno
    if (existingRequest) {
      existingRequest.requestLastHit = new Date();
      return existingRequest.requestCompleted
          .pipe(map((i) => {
            // Clonamos el objeto navigation-request, y le manipulamos los query arguments, ya que esos NO deberían
            // estar en la caché!
            // TODO: En realidad no debería formar parte del propio navigation request?
            const clonedRequest: NavigationRequest = this.cloneNavigationRequest(i);
            clonedRequest.requestQueryParams = navigationRequest.requestQueryParams;
            return i;
          }));
    }

    // Guardamos la copia de la request
    this.trees[navigationRequest.requestBackendExpandedPath] = navigationRequest;

    // Aquí viene la optimización guapa... buscamos en la caché la resolución previa
    // que mejor se adapte a lo que necesitamos, cualquier nodo más específico
    // nos da la resolución completa, cualquier nodo por encima nos permite hacer
    // una resolución parcial.
    const bestPartialMatch: { navigationRequest: NavigationRequest; extraPath: string } = Object.keys(this.trees)
        .filter((i) => this.trees[i] !== navigationRequest && !isNullOrWhitespace(navigationRequest.requestController))
        .map((i) => {
          return {
            navigationRequest: this.trees[i],
            extraPath: UtilsTypescript.getStringMatchingPathParts(navigationRequest.requestBackendExpandedPath, i),
          }
        })
        .sort((i, j) => j.extraPath.length - i.extraPath.length)
        .find(() => true);

    if (!isNullOrUndefined(bestPartialMatch)) {
      return bestPartialMatch
          .navigationRequest
          .requestCompleted
          .pipe(
              switchMap((bestPartialMatchNavigationRequest: NavigationRequest) => {
                // Vemos hasta donde la navegación obtenida "llega"
                // Buscar en todos los menús (si hubiera más de uno)
                let menuItem: MenuItemCompiledFrontend = null;
                let matchingMenuRoot: MenuItemCompiledFrontend = null;
                for (const menu of bestPartialMatchNavigationRequest.responseProcessedTree) {
                  menuItem = menu;
                  while (true) {
                    // El que encaje, y que tenga la ruta más larga
                    const newMenuItem: MenuItemCompiledFrontend = menuItem.children
                        .filter((i) => i.menuType !== MenuType.Anchor)
                        .filter((i) => Navutils.pathStartsWith(navigationRequest.requestBackendExpandedPath.split('/'), i.expandedBackendPathExploded))
                        .sort((i, j) => j.expandedBackendPath.length - i.expandedBackendPath.length)
                        .find(() => true);

                    if (isNullOrUndefined(newMenuItem)) {
                      break;
                    }
                    menuItem = newMenuItem;
                  }
                  // Si el ítem no coincide con el menú (raíz), es que hemos encontrado algo...
                  if (menuItem !== menu) {
                    matchingMenuRoot = menu;
                    break;
                  }
                }
                // Llegados aquí hay dos opciones, que tengamos una porción suficiente del árbol como para no tener que pedirle nada a backend...
                // o que tengamos que pedir la parte que falta
                if (menuItem.controller === navigationRequest.requestController) {
                  // Primer caso, tenemos lo que necesitamos en frontend, así que fakeamos la petición, no hay nada que pedir a backend ya que tenemos
                  // suficiente para reconstruir el árbol hasta el nodo solicitado
                  return of(matchingMenuRoot)
                      .pipe(
                          map(
                              (i) => {
                                const fakeRemoteResponse: MenuQueryResponse = new MenuQueryResponse();
                                fakeRemoteResponse.Menus = [];
                                fakeRemoteResponse.Menus.push(UtilsTypescript.jsonClone(i));
                                return this.postProcessRemoteNavigationRequest(fakeRemoteResponse, navigationRequest);
                              }
                          )
                      );
                } else {
                  // En este segundo caso, tenemos parte del árbol, pero todavía nos falta resolverlo parcialmente, así que hay que pedirle
                  // a backend la parte que falta
                  return this.getMenuRemote(navigationRequest, menuItem.path, matchingMenuRoot);
                }
              }),
              catchError((err, caught) => {
                console.error(err);
                return throwError(err);
              })
          );
    }

    // En este caso, lo pedimos completo a backend
    // esta petición, la menú completo, sí que tendrá spinner ya que
    // es habitualmente la más tardada.
    return this.getMenuRemote(navigationRequest);
  }

  /**
   * @param navigationRequest
   * @param beginAtBackendPath
   * @param menuRoot
   */
  protected getMenuRemote(navigationRequest: NavigationRequest, beginAtBackendPath: string = null, menuRoot: MenuItemCompiledFrontend = null): Observable<NavigationRequest> {

    const obs: EventEmitter<NavigationRequest> = new EventEmitter<NavigationRequest>();

    // Separamos la llamada de red del pipe, para evitar que las desuscripciones interrumpan la carga
    // de los menús. Los menús tardan MUCHO en cargar, y hay unas cachés internas que se apalancan
    // en el hecho de que si alguien lo ha pedido antes, yo no vuelvo a pedirlo y me cuelgo
    // del requestCompleted del navigationRequest.
    this
        .menuService
        .getMenu(null, navigationRequest.requestController, navigationRequest.requestArguments, beginAtBackendPath, {showSpinner: true})
        .pipe(
            map(
                (next: WebServiceResponseTyped<MenuQueryResponse>) => {
                  return this.postProcessRemoteNavigationRequest(next.result, navigationRequest, menuRoot);
                }),
            // https://github.com/ReactiveX/rxjs/issues/6026
            finalizeWithReason((reason: DisposeReason) => {
              // Al final no ha hecho falta, pero lo dejo como referencia, esta es la manera
              // de capturar cuando se deja de ejecutar un pipe por una desuscripción.
              /*if (reason === DisposeReason.Unsubscribe) {
                this.trees[navigationRequest.requestBackendExpandedPath].requestCompleted.error(new Error('Petición de navegación cancelada'));
                delete this.trees[navigationRequest.requestBackendExpandedPath];
              }*/
            }),
            catchError((err, caught) => {
              obs.error(err);
              this.trees[navigationRequest.requestBackendExpandedPath].requestCompleted.error(err);
              delete this.trees[navigationRequest.requestBackendExpandedPath];
              // Este mini-pipe que obtiene la petición de menú remota ya propaga el error
              // a los listeners a través de la llamada a obs.error(), por lo que aquí no hacemos nada.
              return EMPTY;
            }),
            takeUntil(this.cancelAllGetMenuRemote)
        )
        .subscribe((a) => {
          obs.next(a);
        });

    return obs;
  }

  /**
   * Finds all the parameters from a given route.
   *
   * @param {ActivatedRouteSnapshot} ars
   * @param parameterName
   * @returns {Object[]}
   */
  findParmsForRouteByName(ars: ActivatedRouteSnapshot, parameterName: string): string {
    const params: object[] = this.findParamsFromRoute(ars);
    const paramContainer: object = params.find(x =>
        x.hasOwnProperty(parameterName)
    );
    if (isNullOrUndefined(paramContainer)) {
      return null;
    }
    return paramContainer[parameterName];
  }

  /**
   * Construye un array de parámetros a partir de una ruta activada de angular
   *
   * @param {ActivatedRouteSnapshot} ars
   * @returns {Object[]}
   */
  findParamsFromRoute(ars: ActivatedRouteSnapshot): Object[] {
    const params: any = [];
    if (ars.params && Object.keys(ars.params).length > 0) {
      params.push(ars.params);
    }
    if (ars.parent) {
      const aux: object[] = this.findParamsFromRoute(ars.parent);
      if (aux.length > 0) {
        params.push(...aux);
      }
    }
    return params;
  }

  /**
   * Obtiene el título de la página que debe mostrarse para el path actual.
   *
   * El título es el primer elemento en la jerarquía inversa de navegación que tiene un título
   */
  private getNodeForTitle(path: MenuItemCompiledFrontend[]): MenuItemCompiledFrontend {
    if (isNullOrUndefined(path)) {
      return null;
    }

    const rootArgumentCount: number = path[0].arguments.length;

    for (let x: number = 0; x < path.length; x++) {
      const menuItem: MenuItemCompiledFrontend = path[x];
      // Si hay un cambio de argumentos, desestimamos la herencia
      if (getInSafe(menuItem, (i) => i.arguments.length, 0) < rootArgumentCount) {
        break;
      }
      if (!UtilsTypescript.isNullOrWhitespace(menuItem.PageTitle)) {
        return menuItem;
      }
    }
  }

  /**
   * A partir de un "controller" obtiene el path de frontend, usando el fichero
   * de mapeo de rutas que hay en: controler-route-mapping.json, el proceso inverso
   * está en resolveFrontendPathToController: achse06 -> /home/achse06
   *
   * "achse06": "/home/achse06",
   * "achse06.templates": "/home/achse06/templates",
   * "achse06.templates.add": "/home/achse06/templates/add",
   * "achse06.templates.template": "/home/achse06/templates/%template",
   * "achse06.templates.template.details": "/home/achse06/templates/%template/details",
   * "achse06.templates.template.details.delete": "/home/achse06/templates/%template/details/delete",
   *
   * @param controller
   */
  private resolveControllerToFrontendPath(controller: string): string {
    return this.routes[controller];
  }

  /**
   * A partir de un "path" obtiene el path de frontend, usando el fichero
   * de mapeo de rutas que hay en: controler-route-mapping.json, el proceso inverso
   * está en resolveFrontendPathToController: /home/achse06 -> achse06
   *
   * "achse06": "/home/achse06",
   * "achse06.templates": "/home/achse06/templates",
   * "achse06.templates.add": "/home/achse06/templates/add",
   * "achse06.templates.template": "/home/achse06/templates/%template",
   * "achse06.templates.template.details": "/home/achse06/templates/%template/details",
   * "achse06.templates.template.details.delete": "/home/achse06/templates/%template/details/delete",
   *
   * @param frontendPath
   */
  private resolveFrontendPathToController(frontendPath: string): string {
    const result: string = Object.keys(this.routes).find((key: string) => this.routes[key] === frontendPath);
    if (isNullOrUndefined(result)) {
      return null;
    }
    return result;
  }

  /**
   * Get the setps of the wizard, if the current navigation item is a wizard container
   */
  getWizardSteps(): Observable<{ navigationRequest: NavigationRequest, wizardSteps: MenuItemCompiledFrontend[] }> {
    return this.lastResolvedNavigationRequest
        .pipe(
            map((navigationRequest: NavigationRequest) => {
              const children: MenuItemCompiledFrontend[] = getInSafe(navigationRequest.responseMenuPath, (i) => i.find(p => p.menuType === MenuType.WizardContainer).children, []);
              return {
                navigationRequest: navigationRequest,
                wizardSteps: children.filter((x: MenuItemCompiled) => !x.Hidden)
              };
            })
        );
  }

  /**
   * Get current page's Local Actions
   */
  getLocalActions(): Observable<MenuItemCompiledFrontend[]> {
    return this
        .currentNavigationRequest
        .pipe(
            map((navigationRequest: NavigationRequest) => {
              if (navigationRequest === null) {
                return [];
              }
              let localActions: MenuItemCompiledFrontend[] = [];
              if (navigationRequest.responseMenuPath && navigationRequest.responseMenuPath.length > 0) {
                const originalNode: MenuItemCompiledFrontend = Navutils.findOriginalNode(navigationRequest.responseMenuPath[0], navigationRequest.requestController);
                const currentPath: MenuItemCompiledFrontend = navigationRequest.responseMenuPath.find(p => p.controller === navigationRequest.requestController);
                if (currentPath && currentPath.children && currentPath.children.length > 0) {
                  localActions = currentPath.children.filter(p => p.menuType === MenuType.LocalAction);
                }
                if (currentPath && currentPath.menuType === MenuType.Anchor && originalNode && originalNode.children) {
                  localActions = [...localActions, ...originalNode.children.filter(p => p.menuType === MenuType.LocalAction)]
                }
              }
              return localActions;
            })
        );
  }

  /**
   * Procesar una resolución de menú remota
   *
   * @param remoteResponse
   * @param navigationRequest
   */
  private postProcessRemoteNavigationRequest(remoteResponse: MenuQueryResponse, navigationRequest: NavigationRequest, mergeOnTopOf: MenuItemCompiled = null): NavigationRequest {
    navigationRequest.responseRemoteMenu = remoteResponse;

    // Esto se usa para cuando se solicitan árboles parciales, hay que mergear la parte remota con la que se ha proporcionado localmente
    if (!UtilsTypescript.isNullOrWhitespace(remoteResponse.BeginsAtPath)) {

      if (remoteResponse.Menus.length > 1) {
        throw new Error('Cannot merge on top menu query that returns more than one menu');
      }

      if (isNullOrUndefined(mergeOnTopOf)) {
        throw new Error('When a partial menu is being assembled, a base menu implementation must be provided.');
      }

      const remoteMenu: MenuItemCompiled = remoteResponse.Menus[0];

      // Solo hay que añadir al ítem base, la respuesta de servidor como children, en la posición que indique el backend
      const mergeOnTopOfClone: MenuItemCompiled = UtilsTypescript.jsonClone(mergeOnTopOf);
      let localMenu: MenuItemCompiled = mergeOnTopOfClone;

      const parts: string[] = remoteResponse.BeginsAtPath.split('/');
      for (let x: number = 1; x < parts.length + 1; x++) {
        const currentPath: string = parts.slice(0, x).join('/');
        localMenu = localMenu.children.find((i) => i.path === currentPath);
      }

      // Una vez encontrado, reemplazar a los hijos
      localMenu.children = remoteMenu.children;

      // Aquí está la trampa, donde reemplazamos el árbol parcial por el que llega
      navigationRequest.responseRemoteMenu.Menus[0] = mergeOnTopOfClone;
    }

    this.processMenuResponse(navigationRequest);

    // Este emit de aquí es un pequeño truco para evitar colas de peticiones y tener un observer
    // centralizado en la caché
    navigationRequest.requestCompleted.next(navigationRequest);
    this.navigationRequestResolved.next(navigationRequest);
    return navigationRequest;
  }

  /**
   * Finds the mapping for the current component route node recursively.
   * @param {ActivatedRouteSnapshot} ar
   * @returns {string | null}
   */
  private resolveControllerAndRouteFromRouteSnapshot(ar: ActivatedRouteSnapshot): {
    params?: { [id: string]: string };
    controller: string;
    url: string;
    parameterizedUrl: string
  } {

    const lowestLevelRoute: ActivatedRouteSnapshot = Navutils.findLastActivatedRouteSnapshotFromRoute(ar);

    const result: any = {controller: '', params: {}, url: '', parameterizedUrl: ''};

    result.url = Navutils.findExpandedUrlFromRoute(lowestLevelRoute);
    result.parameterizedUrl = this.removeLayoutPrefix(Navutils.findParameterizedUrlFromRoute(lowestLevelRoute));
    result.controller = this.resolveFrontendPathToController(result.parameterizedUrl);

    // Esta línea obtiene el controlador a partir de lo que está en el propio router, el problema
    // es que esto genera duplicidad con lo que está definido en el fichero principal de mapa
    // de rutas. Mejor recuperar esto cuando se quite ese fichero, y hacer que de momento
    // lea de un solo sitio centralizado.
    // o.controller = ar.data && ar.data.mapping ? ar.data.mapping : null;

    // Al haber tenido dos sistemas duplicados en paralelo, ahora hay una buena cantidad
    // de rutas que o no coinciden, o no están definidas en ambos sistemas.
    // TODO: Deduda técnica: revisar todas las rutas, y lanzar excepción cuando tengamos
    // que recurrir a ar.data para conseguir el controller...
    if (UtilsTypescript.isNullOrWhitespace(result.controller)) {
      result.controller = ar.data && ar.data.mapping ? ar.data.mapping : null;
      if (!UtilsTypescript.isNullOrWhitespace(result.controller)) {
        console.debug('Información de ruta inconsistente: la ruta ' + result.parameterizedUrl + ' no tiene asociado un controlador en el fichero de mapeos pero si lo tiene en el mapping del router.');
      }
    }

    result.params = Navutils.findParamsFromRouteNew(lowestLevelRoute);
    return result;
  }

  /**
   * Obtiene la URL de frontend a partir del ID del nodo. Mucho cuidado
   * ya que devuelve la última versión "vista" de ese nodo.
   *
   * @param identifier
   */
  getUrlByIdentifier(identifier: string): string {
    const itemFromIdentifier: ControllerInfo = this.identifierMap[identifier];
    return itemFromIdentifier.FrontendPath;
  }

  /**
   * Construye una URL de frontend a partir de un controlador y sus argumentos
   * @param controller
   * @param defaultArguments
   */
  getUrlByController(controller: string, defaultArguments: { [key: string]: string }): string {

    if (this.routes[controller]) {
      controller = this.routes[controller];
    }

    controller = this.removeLayoutPrefix(controller);

    if (defaultArguments && Object.keys(defaultArguments).length > 0) {
      for (const argument of Object.keys(defaultArguments)) {
        let x: string = argument;
        // Normalizar, ya que en angular todos los argumentos deben empezar con %
        if (!x.startsWith('%')) {
          x = '%' + x;
        }
        if (!Navutils.pathContains(controller, x)) {
          throw new Error(`Path '${controller}' does not have provided argument ${x}`);
        }
        if (UtilsTypescript.isNullOrWhitespace(defaultArguments[argument])) {
          throw new Error(`Empty argument value '${argument}' for path '${controller}'`);
        }
        controller = controller.replace(x, defaultArguments[argument]);
      }
    }

    controller = this.addLayoutPrefixIfMissing(controller);
    return controller;
  }

  /**
   * Navegar a una URL usando su controlador
   *
   * @param controller
   * @param defaultArguments
   */
  navigateUrlByIdentifier(identifier: string): Promise<boolean> {
    return this.router.navigate([this.getUrlByIdentifier(identifier)]);
  }

  /**
   *
   * @param controller
   * @param defaultArguments
   */
  navigateUrlByController(controller: string, defaultArguments: { [key: string]: string }, queryParams: {
    [key: string]: string
  } = null): Promise<boolean> {

    const destionationUrl: string = this.getUrlByController(controller, defaultArguments);

    queryParams = queryParams || {};

    const urlTree: UrlTree = this.router.createUrlTree(
        [destionationUrl], {queryParams: queryParams}
    );

    return this.router.navigateByUrl(urlTree);
  }

  /**
   *
   * @param controller
   * @param defaultArguments
   */
  navigateUrlBySubController(controller: string, defaultArguments: { [key: string]: string }, queryParams: {
    [key: string]: string
  } = null, navigationRequest: NavigationRequest): Promise<boolean> {
    let currentController: string = navigationRequest.requestController;
    if (!controller.startsWith('.')) {
      currentController = currentController.concat('.')
    }
    const realController: string = currentController.concat(controller)

    let realArguments: { [p: string]: string } = defaultArguments || {};
    realArguments = {...realArguments, ...navigationRequest.requestArguments};

    console.log(realController);
    console.log(realArguments);
    const destionationUrl: string = this.getUrlByController(realController, realArguments);

    queryParams = queryParams || {};
    queryParams = {...queryParams, ...navigationRequest.requestQueryParams};

    const urlTree: UrlTree = this.router.createUrlTree(
        [destionationUrl], {queryParams: queryParams}
    );

    return this.router.navigateByUrl(urlTree);
  }

  /**
   * Navegar a una URL usando su controlador con retorno
   *
   * @param controller
   * @param defaultArguments
   */
  navigateUrlByControllerWithReturnToUri(sourcePath: string, defaultArguments: {
    [key: string]: string
  }, returnToUri: string, queryParams: { [key: string]: string } = null): Promise<boolean> {
    const destionationUrl: string = this.getUrlByController(sourcePath, defaultArguments);
    return this.navigateWithReturnToUrl(destionationUrl, returnToUri, queryParams);
  }

  /**
   *
   * @param identifier
   * @param defaultArguments
   * @param returnToUri
   */
  navigateUrlByIdentifierWithReturnToUri(identifier: string, returnToUri: string, queryParams: {
    [key: string]: string
  } = null): Promise<boolean> {
    const destionationUrl: string = this.getUrlByIdentifier(identifier);
    return this.navigateWithReturnToUrl(destionationUrl, returnToUri, queryParams);
  }

  /**
   * Permite calcular si un nodo está activo en la ruta actual, pero teniendo en cuenta
   * la jerarquía de nodos de BACKEND, no la de nodos de angular.
   *
   * @param item
   */
  public isNodeActive(navigationRequest: NavigationRequest, item: MenuItemCompiledFrontend): boolean {
    // Usamos el backend expanded path que es el que nos da la relación real del árbol de rutas
    const currentExpandedBackendPath: string[] = navigationRequest.responseMenuPath[0].expandedBackendPathExploded;
    let result: boolean;
    if (item.menuType === MenuType.Anchor) {
      result = Navutils.pathStartsWith(currentExpandedBackendPath, item.expandedBackendPathExploded);
    } else {
      result = Navutils.pathStartsWith(currentExpandedBackendPath, item.expandedBackendPathExploded);
    }
    return result;
  }

  /**
   * Processar el response del servidor para que se pueda usar en frontend
   *
   * @param navigationRequest
   */
  private processMenuResponse(navigationRequest: NavigationRequest): NavigationRequest {

    // Hay que preparar los DTO's de front
    navigationRequest.responseProcessedTree = [];
    for (const m of navigationRequest.responseRemoteMenu.Menus) {
      navigationRequest.responseProcessedTree.push(this.prepareTree(m, navigationRequest.requestArguments));
    }

    // Si es controlador vacío, es petición de navegación raíz (todos los menús)
    if (UtilsTypescript.isNullOrWhitespace(navigationRequest.requestController)) {
      return navigationRequest;
    }

    // This returns the tree for the current controller object.
    const currentTree: MenuItemCompiledFrontend = navigationRequest.responseProcessedTree[0];

    // This returns a path for the current controller object.
    navigationRequest.responseMenuPath = Navutils.findPathToNode(currentTree, navigationRequest.requestBackendExpandedPath, navigationRequest.requestArguments);

    navigationRequest.responseNodeForTitle = this.getNodeForTitle(navigationRequest.responseMenuPath);

    // We try to parse the route with the active controller object.
    let routePath: any = this.resolveControllerToFrontendPath(navigationRequest.requestController);

    if (isNullOrUndefined(routePath)) {
      // TODO ? THROW AN ERROR ?
      return;
    }

    // if routePath is differen to null or undefined
    // the routePath is armed replace the params of the controller with the keys in the path,
    // for more details view the method parseTree.
    Object.keys(navigationRequest.requestArguments).map(key => {
      if (routePath.indexOf(key) !== -1) {
        routePath = routePath.replace(key, navigationRequest.requestArguments[key]);
      }
    });

    return navigationRequest;
  }

  /**
   * This method parses a tree updating controllers array (used for searches optimization) and tree paths.
   * @param {MenuItemCompiled} tree
   * @param args
   */
  private prepareTree(tree: MenuItemCompiled, args: { [id: string]: string } = {}): MenuItemCompiledFrontend {

    // Clonamos los argumentos que nos envían, ya que podrían ser modificados durante este procesado
    args = UtilsTypescript.jsonClone(args);

    // Initialize with the remote information
    const result: MenuItemCompiledFrontend = Object.assign(new MenuItemCompiledFrontend(), tree);

    if (tree.argumentValues) {
      Object.assign(args, tree.argumentValues);
    }

    if (tree.DefaultArguments) {
      Object.assign(args, tree.DefaultArguments);
    }

    if (this.routes.hasOwnProperty(tree.controller)) {
      result.frontendCanNavigate = true;
    }

    if (!UtilsTypescript.isNullOrWhitespace(tree.controller)) {
      if (tree.ControllerReplaced && tree.menuType !== MenuType.Anchor) {
        // Alimentamos controllerMap con los nuevos controladores o modificamos el estado para aquellos que ya existen
        if (!this.controllerMap[tree.controller]) {
          this.controllerMap[tree.controller] = {
            FrontendPath: '',
            Controller: tree.controller,
            BackendPath: tree.path,
            Status: 'ACTIVE',
            ReplacedByController: null,
            MenuType: tree.menuType,
            Id: tree.Id
          };
        }
        if (this.controllerMap[tree.controller].BackendPath !== tree.path) {
          console.debug(`The backend path for the controller ${tree.controller} has been changed.
           Original: ${this.controllerMap[tree.controller].BackendPath}. Replace: ${tree.path}`);
        }
        this.controllerMap[tree.controller].Status = 'ACTIVE';
        this.controllerMap[tree.controller].ReplacedByController = null;
        this.controllerMap[tree.controller].BackendPath = tree.path;
        tree.OldControllers.forEach((x: string) => {
          if (!this.controllerMap[x]) {
            this.controllerMap[x] = {
              FrontendPath: '',
              Controller: tree.controller,
              BackendPath: tree.path,
              Status: 'INACTIVE',
              ReplacedByController: null,
              MenuType: tree.menuType,
              Id: tree.Id
            };
          }
          this.controllerMap[x].Status = 'INACTIVE';
          this.controllerMap[x].ReplacedByController = tree.controller;
        });
      }
      result.frontendPath = this.prepareFrontendPath(tree.controller, args);
      if (tree.RedirectController) {
        result.redirectedFrontendPath = this.prepareFrontendPath(tree.RedirectController, tree.RedirectControllerArguments);
      }
    }

    if (!isArray(tree.CssClasses)) {
      result.CssClasses = [];
    }

    result.children = [];
    if (tree.children && tree.children.length > 0) {
      for (let i: number = 0; i < tree.children.length; ++i) {
        const childItem: MenuItemCompiledFrontend = this.prepareTree(tree.children[i], args);
        result.children.push(childItem);
      }
    }

    // Este caso es para cuando tenemos un ítem de navegación "normal", que no enlaza con ningún componente
    // el comportamiento esperado es que automáticamente navegemos al primer hijo disponible (por ejemplo, si pintáramos un tab
    // container este primer hijo sería la primera pestaña).
    if (isNullOrUndefined(result.frontendPath)) {
      // Intentamos calcular un path de destino dentro de los posibles hijos
      result.frontendPath = Navutils.findFirstAvailableNavigationPath(result);
    }

    let canonicalBackendPath: string = null;
    if (result.menuType === MenuType.Anchor) {
      if (this.controllerMap[result.controller]) {
        // El ExpandedBackendPath en el caso de anchor debe ser el del nodo original
        canonicalBackendPath = this.controllerMap[result.controller].BackendPath;
      }
    } else {
      canonicalBackendPath = result.path;
    }

    if (result.menuType === MenuType.Anchor && isNullOrWhitespace(canonicalBackendPath)) {
      throw new Error(`No se ha podido encontrar la ruta canónica de backend para el item tipo anchor con el controlador '${result.controller}'. Asegúrese de que existe un menú item de tipo normal con ese controlador.`);
    }

    result.expandedBackendPath = Navutils.replaceBackendArguments(canonicalBackendPath, args);

    // Actualizamos la información del identificador, por si hubiera cambiado...
    this.identifierMap[tree.Id] = {
      FrontendPath: result.frontendPath,
      Controller: tree.controller,
      BackendPath: tree.path,
      Status: 'ACTIVE',
      MenuType: tree.menuType,
      Id: tree.Id
    };

    return result;
  }

  prepareFrontendPath(controller: string, args: { [id: string]: string } = {}): string {
    let url: string = this.routes[controller];
    if (isNullOrUndefined(url)) {
      console.error('Requested controller ' + controller + ' is NOT declared in the routes configuration file and frontend path cannot be assembled.');
      return null;
    }
    for (const key in args) {
      if (!isNullOrUndefined(url) && url.indexOf(key) !== -1) {
        url = url.replace(key, args[key]);
      }
    }
    return this.addLayoutPrefixIfMissing(url);
  }


  /**
   * @deprecated, favor user backNew()
   */
  back(options: BackNavigateOptions = {skipMapping: false, skipParametricNode: false}): void {

    // If the user defined that skipMapping should be done. Then do a location back immediately.
    if (options.skipMapping) {
      this.location.back();
      return;
    }

    // If a relative node is passed in params, then route to it.
    if (!isNullOrUndefined(options.relativePath)) {
      return this.navigateToRelativePath(options);
    }

    const skipParametricNodeCount: number = options.skipParametricNode ? 1 : null;

    this.backNew(skipParametricNodeCount).then(() => {
    });
  }

  /**
   * Obtiene un nodo lateral para navegar (si lo hay)
   *
   * @param navigationRequest
   *   La navigationRequest sobre la que se quiere calcular la navegación.
   *
   * @param leftToRight
   *   Por defecto busca el elemento a la izda (navegación "Anterior"). User toMyRight para buscar el nodo a la derecha (navegación "Siguiente").
   */
  getHorizontalNavigationNode(navigationRequest: NavigationRequest, leftToRight: boolean = false): MenuItemCompiledFrontend {

    const parentItem: MenuItemCompiledFrontend = navigationRequest.responseMenuPath.slice(1)[0];
    const currentMenuItem: MenuItemCompiledFrontend = navigationRequest.responseMenuPath[0];

    const supportedMenuParentTypes: MenuType[] = [MenuType.TabContainer, MenuType.WizardContainer];
    if (!supportedMenuParentTypes.includes(parentItem.menuType)) {
      console.warn('La navegación lateral solo está soportada si el elemento padre es del tipo contenedor.');
    }

    const siblings: MenuItemCompiledFrontend[] = parentItem.children.filter((i) => i.Hidden !== true);
    let itemPos: number = -1;
    for (let x: number = 0; x < siblings.length; x++) {
      if (currentMenuItem.expandedBackendPath === siblings[x].expandedBackendPath) {
        itemPos = x;
        break;
      }
    }

    if (itemPos === -1) {
      throw new Error('No se ha podido encontrar el nodo horizontal de navegación activo.');
    }

    // Ver si un nodo es navegable.
    const nodeIsNavigable: (item: MenuItemCompiledFrontend) => boolean = (item: MenuItemCompiledFrontend) => {
      if (item.Hidden) {
        return false;
      }
      if (!item.frontendCanNavigate) {
        return false;
      }
      return true;
    };

    if (!leftToRight) {
      // Navegar hacia la izda.
      for (let j: number = itemPos - 1; j >= 0; j--) {
        const sibling: MenuItemCompiledFrontend = siblings[j];
        if (nodeIsNavigable(sibling)) {
          return sibling;
        }
      }
    } else {
      // Navegar hacia la derecha.
      for (let j: number = itemPos + 1; j < siblings.length; j++) {
        const sibling: MenuItemCompiledFrontend = siblings[j];
        if (nodeIsNavigable(sibling)) {
          return sibling;
        }
      }
    }

    // Si estamos en modo derecha/izda (como un volver) y no quedan nodos a la izda.,
    // lo que hago es subir
    if (!leftToRight) {
      for (let x: number = 1; x < navigationRequest.responseMenuPath.length; x++) {
        if (nodeIsNavigable(navigationRequest.responseMenuPath[x])) {
          return navigationRequest.responseMenuPath[x];
        }
      }
    }

    // No hay a donde navegar lateralmente, así que hacemos default hacia arriba
    return null;
  }

  /**
   * Implementación genérica para el comportamiento "Volver" de la navegación.
   *
   * @param skipParametricNodeCount
   *   Al recorrer el árbol de manera inversa, saltará el número de nodos paramétricos indicado por este parámetro.
   * @param useLocationBack
   *   Si debe usar el location.back() del navegador, y obviar los cálculos del árbol
   * @param lateralNavigation
   *   Si debe usar navegación lateral en lugar de vertial
   */
  async backNew(skipParametricNodeCount?: number, useLocationBack: boolean = false, lateralNavigation: boolean = false): Promise<void> {

    // If the user defined that skipMapping should be done. Then do a location back immediately.
    if (useLocationBack) {
      return new Promise((resolve) => {
        this.location.back();
        resolve();
      });
    }

    await this.lastResolvedNavigationRequest
        .pipe(
            take(1)
        )
        .subscribe(async (navigationRequest) => {

          if (skipParametricNodeCount && lateralNavigation) {
            throw new Error('No se pueden saltar nodos paramétricos cuando se ejecuta una navegación lateral.');
          }

          const params: Params = navigationRequest.requestQueryParams;

          // If there is a query route, then route to it.
          if (params['backToUri']) {
            let sourcePath: string = params['backToUri'];
            if (this.routes[sourcePath]) {
              sourcePath = this.routes[sourcePath];
            }
            sourcePath = this.addLayoutPrefixIfMissing(sourcePath);
            return this.router.navigateByUrl(sourcePath);
          }

          // Validate if skipMapping is false or skipParametricNode is true
          const currentMenuItem: MenuItemCompiledFrontend = navigationRequest.responseMenuPath[0];

          if (lateralNavigation) {
            const targetNode: MenuItemCompiledFrontend = this.getHorizontalNavigationNode(navigationRequest);
            if (targetNode) {
              await this.router.navigate([targetNode.getFrontendPath()]);
              return Promise.resolve();
            }
          }

          for (let x: number = 1; x < navigationRequest.responseMenuPath.length; x++) {

            const menuItemParent: MenuItemCompiledFrontend = (x + 1) < navigationRequest.responseMenuPath.length ? navigationRequest.responseMenuPath[x + 1] : null;
            const menuItem: MenuItemCompiledFrontend = navigationRequest.responseMenuPath[x];

            // Validate if the path is same that initial path
            if (menuItem.redirectedFrontendPath && menuItem.redirectedFrontendPath === currentMenuItem.redirectedFrontendPath) {
              continue;
            }

            // Validate if the path is same that initial path
            if (menuItem.frontendPath === currentMenuItem.frontendPath) {
              continue;
            }

            // Validar que la página sea navegable...
            if (!menuItem.frontendCanNavigate) {
              continue;
            }

            if (skipParametricNodeCount && (currentMenuItem.arguments.length - menuItem.arguments.length) < skipParametricNodeCount) {
              // Seguimos subiendo hasta perder un parámetro
              continue;
            }

            // Este caso es para cuando tengo varios niveles de tabs consecutivos (N niveles de tabs) en realidad los de los niveles
            // superiores no cuentan como navegación
            if (menuItem.menuType === MenuType.TabContainer && menuItemParent.menuType === MenuType.TabContainer) {
              continue;
            }

            await this.router.navigate([menuItem.getFrontendPath()]);
            return Promise.resolve();
          }
        });

    return Promise.resolve();
  }

  /**
   * Routes to a relative path.
   * @param {BackNavigateOptions} options
   */
  navigateToRelativePath(options: BackNavigateOptions): void {
    const pathParams: any = !isNullOrUndefined(options.activatedRoute) ? {relativeTo: options.activatedRoute} : {};
    this.router.navigate(options.relativePath, pathParams);
    return;
  }

  /**
   * Get the Main Home Page
   */
  getHomeURL(): string {
    return this.addLayoutPrefixIfMissing('/home');
  }

  /**
   * Navigate to Main Home Page
   */
  navigateFromHome(relativePath: string, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate([(this.getHomeURL() + relativePath).replace('//', '/')], extras)
  }

  navigateFromHomeWithCommands(relativePath: string, commands: any[], extras?: NavigationExtras):
      Promise<boolean> {
    const route: string = (this.getHomeURL() + relativePath).replace('//', '/');
    const finalCommands: any[] = [];
    finalCommands.push(route);
    commands.forEach(o => finalCommands.push(o));
    return this.router.navigate(finalCommands, extras)
  }

  /**
   * Navigate based on the provided URL, which must be absolute.
   */
  navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  /**
   * Navigate based on the provided array of commands and a starting point.
   * If no starting route is provided, the navigation is absolute.
   */
  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  /**
   * Navigate to Main Home Page
   */
  goToHome(): Promise<boolean> {
    if (this.router.url === this.getHomeURL()) {
      return Promise.resolve(true);
    }
    return this.router.navigate([this.getHomeURL()]);
  }

  /**
   * Navigate to Not Found page
   */
  goToNotFoundPage(): Promise<boolean> {
    if (this.router.url === this.getUrlByController('404', null)) {
      return Promise.resolve(true);
    }
    return this.router.navigate([this.getUrlByController('404', null)]);
  }

  /**
   * Ir a la página de acceso bloqueado (si la hay)
   * @param originalPath
   * @param accessBlockedNodes
   */
  goToAccessBlocked(originalPath: string, accessBlockedNodes: MenuItemCompiledFrontend[]): Promise<boolean> {
    // TODO: Al finalizar el desarrollo quitar todo esto...
    // Ya no tiene sentido, el bloqueo se hace mediante comandos
    /*const errorNodes: string[] = accessBlockedNodes.map(o => o.AccessBlockingMessage);
    const urlTree: UrlTree = this.router.createUrlTree([this.getAccessBlocked()], {
        queryParams: {
          originalControler: originalPath,
          accessBlockedNodes: JSON.stringify(errorNodes)
        }
      }
    );
    return this.navigateWithReturnToUrl(urlTree.toString(), originalPath);*/
    return Promise.resolve(true);
  }

  /**
   * Get the Login Page
   */
  getLoginURL(): string {
    return this.addLayoutPrefixIfMissing('/login');
  }

  /**
   * Navigate to Login Page
   */
  goToLogin(): Promise<boolean> {
    console.debug('Navigation: goToLogin');
    this.clearNavigationCaches(true);
    return this.router.navigate([this.getLoginURL()]).then((result) => {
          // En el caso que nos desloguemos de la aplicación tenemos que dejar el controllerMap
          // como si nada hubiera pasado. Para ello tomamos como referencia que hayamos llegado a la
          // página de login
          if (result) {
            this.createVirginControllerMap();
          }
          return result;
        }
    );
  }

  /**
   * Get the Login Page
   */
  getSelectPersonURL(): string {
    return this.addLayoutPrefixIfMissing('/selectPerson');
  }

  /**
   * Navigate to SelectPerson Page
   */
  goToSelectPerson(): Promise<boolean> {
    return this.router.navigate([this.getSelectPersonURL()])
  }

  /**
   * Get the sso url
   */
  getSsoURL(): string {
    return '/sso';
  }

  /**
   * Get the sso url
   */
  getQrCode(): string {
    return this.addLayoutPrefixIfMissing('/qr-code/form');
  }

  getUnsuscribeUrl(): string {
    return this.addLayoutPrefixIfMissing('/unsubscribe');
  }

  /**
   * Get the sso url
   */
  getPrivacyPolicyUrl(): string {
    return this.addLayoutPrefixIfMissing('/privacy-policy');
  }

  /**
   * Se asegura de que una ruta tiene el prefijo del layout
   * @param path
   */
  addLayoutPrefixIfMissing(path: string): string {
    const layout: ILayout = this.layoutManager.currentThemeBehavior.getValue()?.Layout ?? this.layout;
    if (!layout) {
      return path;
    }

    if (Navutils.pathStartsWith(path.split('/'), layout.Root.split('/'))) {
      return path;
    }
    const prefix: string = trimCharsEnd(layout.Root, '/');

    return urlJoin(prefix, path);
  }

  /**
   * Remove layout prefix from path if any
   *
   * @param path
   */
  protected removeLayoutPrefix(path: string): string {
    const layout: ILayout = this.layoutManager.currentThemeBehavior.getValue()?.Layout ?? this.layout;
    if (!layout) {
      return path;
    }

    if (path.indexOf(layout.Root) === 0) {
      path = '/' + path.replace(layout.Root, '');
    }

    return path;
  }

  /**
   * Clears all caches and sets the controllerMap
   * with the Unmodified MenuPathInfo which was retrieved in the MenuTreeResponse
   */
  protected createVirginControllerMap(): void {
    this.clearNavigationCaches(true);
    this.populateControllerMap(Object.assign({}, this.unmodifiedMenuPathInfo), null);
  }
}
