import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ViewHeaderDirective } from './view-header.directive';
import { ViewMenuBarModule } from './view-menu-bar/view-menu-bar.module';
import { ViewSmallBarModule } from './view-small-bar/view-small-bar.module';
import { ViewMaterialBarModule } from './view-material-bar/view-material-bar.module';


@NgModule({
  declarations: [
    ViewHeaderDirective
  ],
  exports: [
    ViewHeaderDirective
  ],
  imports: [
    CommonModule,
    ViewMenuBarModule,
    ViewSmallBarModule,
    ViewMaterialBarModule
  ]
})
export class ViewHeadersModule {
}
