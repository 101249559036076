import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartConfiguration, ChartData, ChartDataSets } from 'chart.js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { parseKeyItemToArray } from '../../../../../../../../../shared/utils/prime.utils';
import { BaseFuseChartTypeInterface } from '../../base-fuse-chart-type.class';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import {
  ISerie,
  RadarChart,
  RadarChartDisplayOptions,
  SerieValue
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-basic-radar-chart',
  templateUrl: './basic-radar-chart.component.html',
  styleUrls: ['./basic-radar-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class BasicRadarChartComponent extends BaseFuseChartTypeInterface<RadarChart, RadarChartDisplayOptions> implements OnInit, OnDestroy {

  /**
   * Canvas element.
   */
  @ViewChild('canvasItem', {static: true}) canvas: ElementRef;

  /**
   * Canvas context.
   */
  ctx: CanvasRenderingContext2D;

  /**
   * Chart object.
   */
  chart: Chart;

  /**
   * This triggers an event when the component has been succesfully initializated.
   */
  private initializedTrigger: BehaviorSubject<boolean>
      = new BehaviorSubject<boolean>(false);

  private subscription: Subscription;

  /**
   * `BasicRadarChartComponent` class constructor.
   */
  constructor(protected cdReference: ChangedetectorReference) {
    super(cdReference);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.initializedTrigger.next(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Chart configuration initializer method.
   */
  initializeChart(): void {
    const labelSerie: ISerie = this.getDataSeries().Series[this.currentChart.LabelSeriesId];
    const labels: SerieValue[] = parseKeyItemToArray<SerieValue>(labelSerie.Values);

    const dataSeries: ISerie[] = parseKeyItemToArray<ISerie>(this.getDataSeries().Series)
        .filter((v: ISerie) => v['key'] !== this.currentChart.LabelSeriesId);
    const data: ChartData = this.getChartData(labels, dataSeries);

    this.subscription = this.initializedTrigger
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter(v => v),
            take(1))
        .subscribe(() => {
          this.chart = new Chart(this.ctx, <ChartConfiguration>{
            type: 'radar',
            data: data,
            options: this.getChartOptions(data.datasets),
          });
          this.cdReference.changeDetector.detectChanges();
        });
  }

  /**
   * This method return a `ChartData` object from the input series.
   * @param {SerieValue[]} labels
   * @param {ISerie[]} series
   */
  private getChartData(labels: SerieValue[], series: ISerie[]): Chart.ChartData {
    return {
      labels: this.getLabels(labels),
      datasets: this.getDataset(labels, series)
    }
  }

  /**
   * Chart configuration builder.
   * @param {ChartDataSets[]} data
   */
  private getChartOptions(data: ChartDataSets[]): object {
    return {
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 10
        }
      },
      legend: {
        position: 'top',
      },
      scale: {
        ticks: {
          beginAtZero: true,
          suggestedMax: 100
        }
      },
      tooltips: {
        custom: function (tooltip: any): void {
          if (!tooltip) {
            return;
          }
        },
        callbacks: {
          // use label callback to return the desired label
          label: function (tooltipItem: any): string {
            return tooltipItem.yLabel.toString().replace('.', ',');
          }
        }
      }
    };
  }

  /**
   * Returns an array of `ChartDataSets` with the data for the chart.
   *
   * @param {SerieValue[]} labels
   * @param {ISerie[]} series
   * @returns {ChartDataSets[]}
   */
  private getDataset(labels: SerieValue[], series: ISerie[]): ChartDataSets[] {
    const colorSchemeLength: number = this.displayOptions.ColorScheme.length;

    return series.map((s: ISerie, index: number) => {
      const values: SerieValue[] = parseKeyItemToArray<SerieValue>(s.Values);
      const data: number[] = labels.map((label: SerieValue) => (values[label.Id].Value));
      const color: string = this.colorConvert(this.displayOptions.ColorScheme[index % colorSchemeLength]);

      return this.buildDataset(s.Name, data, color);
    });
  }

  /**
   * Builds a `ChartDataSets` object.
   *
   * @param {string} title
   * @param {number[]} data
   * @param {string} color
   * @param {string} hoverBorderColor
   * @returns {ChartDataSets}
   */
  private buildDataset(title: string, data: number[], color: string, hoverBorderColor?: string): ChartDataSets {
    return {
      backgroundColor: color,
      borderColor: color,
      hoverBorderColor: hoverBorderColor || color,
      data: data,
      label: title,
    };
  }

  /**
   * Returns an array of string for the chart labels.
   *
   * @param {SerieValue[]} labels
   * @returns {string[]}
   */
  private getLabels(labels: SerieValue[]): Array<string> {
    return labels.map(l => l.Value);
  }

  /**
   * Covert Hex to Rgb
   * @param color
   * @private
   */
  private colorConvert(color: string): string {
    const r: number = parseInt(color.substring(1, 3), 16);
    const g: number = parseInt(color.substring(3, 5), 16);
    const b: number = parseInt(color.substring(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  }
}
