import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { skip, takeUntil } from 'rxjs/operators';
import { GenericListPageSimpleArguments } from './GenericListPageSimpleArguments';
import { AppContextService } from '../../context/context.service';
import { SessionstateService } from '../../../core/sessionstate/sessionstate.service';
import { GenericPageComponent } from '../generic-page.class';

@Component({
  selector: 'app-generic-list-page-simple',
  templateUrl: './generic-list-page-simple.component.html',
  styleUrls: ['./generic-list-page-simple.component.scss']
})
export class GenericListPageSimpleComponent extends GenericPageComponent implements OnInit {

  /**
   * The form identifier
   */
  listId: string;

  /**
   *
   */
  public configurationArguments: GenericListPageSimpleArguments;

  /**
   * El formulario
   */
  @ViewChild('list', {static: true}) List2Component;

  /**
   *
   * @param activatedRoute
   * @param navigationService
   */
  constructor(protected activatedRoute: ActivatedRoute,
              protected navigationService: NavigationService,
              protected contextService: AppContextService,
              protected route: ActivatedRoute,
              protected sessionStateService: SessionstateService) {
    super(navigationService, activatedRoute, route, sessionStateService, contextService);

    this.activatedRoute
      .data
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data) => {
        if (!data || !(data['componentConfig'] instanceof GenericListPageSimpleArguments)) {
          throw new Error('');
        }

        this.configurationArguments = data['componentConfig'] as GenericListPageSimpleArguments;
      });

    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data) => {
        this.updateParams();
      });

    this.contextService
      .contextChanged
      .pipe(
        takeUntil(this.componentDestroyed$),
        // Como es un replay subject, no nos interesa el primer emit.
        skip(1)
      )
      .subscribe(x => this.updateParams());
  }

  ngOnInit(): void {
    this.updateListId();
    this.updateParams();
  }

  /**
   * Agregar todos los argumentos que vamos a necesitar
   *
   * @protected
   */
  protected captureRequiredArguments(): string[] {
    const result: { [name: string]: boolean } = {};
    if (this.configurationArguments.listArgumentMapping) {
      for (const p of Object.values(this.configurationArguments.listArgumentMapping)) {
        result[p] = true;
      }
    }

    return Object.keys(result);
  }

  protected updateListId(): void {
    const formId: string = this.configurationArguments?.listId;

    if (formId.includes('@session:')) {
      if (!this.sessionData) {
        this.getSessionData(() => this.updateListId());
        return;
      }
      this.listId = this.getSessionDataValue(formId);
      return;
    } else if (formId.includes('@nav-argument:')) {
      this.listId = this.getNavArgumentValue(formId, '@nav-argument:');
      return;
    } else if (formId.includes('@query-param:')) {
      this.listId = this.getQueryParamValue(formId, '@query-param:');
      return;
    } else if (formId.includes('@resolver-param:')) {
      this.listId = this.getQueryParamValue(formId, '@resolver-param:');
      return;
    }

    this.listId = formId;
  }


  get paramsList(): any {
    return this.mapParamsToComponentParams(this.configurationArguments.listArgumentMapping);
  }
}
