import { Directive, Input } from '@angular/core';
import { ChangedetectorReference } from '../core/changedetector/changedetectoreference';
import { ChangedetectorService } from '../core/changedetector/changedetector.service';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appNgxSelectChangedetectionFixerDirective]',
  providers: [
    ChangedetectorReference
  ]
})
export class NgxSelectChangedetectionFixerDirective extends DestroyableObjectTrait {

  private initialized: boolean;
  private _bsDropDown: BsDropdownDirective;

  @Input()
  public set bsDropDown(value: BsDropdownDirective) {
    this._bsDropDown = value;
    this.initializeComponent(value);
    this.initialized = true;
  }

  public get bsDropDown(): BsDropdownDirective {
    return this._bsDropDown;
  }

  constructor(private cdReference: ChangedetectorReference,
              private cdService: ChangedetectorService) {
    super();
  }

  initializeComponent(dropdown: BsDropdownDirective): void {

    const originalIsDisabled: boolean = dropdown.isDisabled;

    dropdown.isDisabled = false;
    dropdown.show();
    dropdown.hide();
    dropdown.isDisabled = originalIsDisabled;

    dropdown
        .onShown
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(this.onShow.bind(this));

    dropdown
        .onHidden
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(this.onDisplayChanged.bind(this));
  };


  onShow(): void {
    if (!this.initialized) {
      this.initialized = true;
    }
    this.onDisplayChanged();
  }

  /**
   * Re-render the application if needed
   */
  onDisplayChanged(): void {
    this.cdReference.changeDetector.detectChanges();
    this.cdService.runApplicationChangeDetection();
  }
}
