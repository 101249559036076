import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { takeUntil } from 'rxjs/operators';
import { makeSafeForCSS } from '../../utils/typescript.utils';

@Component({
  selector: 'app-local-actions',
  templateUrl: './local-actions.component.html',
  styleUrls: ['./local-actions.component.scss']
})
export class LocalActionsComponent extends DestroyableObjectTrait implements OnInit, OnDestroy {

  localActions: MenuItemCompiledFrontend[] = [];

  constructor(
    private navigationService: NavigationService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigationService
      .getLocalActions()
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(newPath => {
        this.localActions = newPath;
      });
  }

  /**
   * Al pinchar en un local action
   * @param action
   */
  handleLocalAction(action: MenuItemCompiledFrontend): void {
    this.navigationService.navigateToMenuItem(action);
  }

  /**
   * Clases para el botón
   *
   * @param i
   */
  getClass(i: any): string[] {
    // Ponemos el controller como clase css para poder identificar con concreción un elemento
    let cssClasses: string[] = ['nav-localaction', makeSafeForCSS(this.localActions[i].controller)];
    if (this.localActions[i].CssClasses) {
      cssClasses = cssClasses.concat(this.localActions[i].CssClasses);
    } else {
      // TODO: Comportamiento legacy debería quitarse!
      cssClasses.push('o-btn__primary');
    }
    return cssClasses;
  }
}
