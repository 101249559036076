import { Injectable, Optional, SkipSelf } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { CommandService } from '../../core/commands/command.service';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { CoreOpenFormInModalCommand, ICommand } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../decoupled-modal/models/decoupled-modal-bridge.interface';
import { backendTypeMatch, UtilsTypescript } from '../utils/typescript.utils';
import { EventFormSucceededInterface } from './event-form-succeeded.interface';
import { Observable } from 'rxjs';
import { defer } from 'rxjs';

/**
 * Servicios globales de la API de formularios (i.e. listener para los comandos)
 */
@Injectable({
  providedIn: 'root',
})
export class FormGlobalServicesClass {

  /**
   * FormGlobalServicesClass class constructor.
   */
  constructor(
    protected dmbs: DecoupledModalBridgeService,
    protected commandService: CommandService,
    @Optional() @SkipSelf() parentModule?: FormGlobalServicesClass
  ) {
    if (parentModule) {
      throw new Error(
        'FormGlobalServicesClass is already loaded. Import it in the AppModule only');
    }

    this.commandService.CommandObservable
      .pipe(
        filter((obj: any) => backendTypeMatch(CoreOpenFormInModalCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreOpenFormInModalCommand, (() => Promise<boolean>) | Observable<boolean>>),
      ).subscribe((next) => {
      next.AddResult(defer(() => {
        return this.OpenModal(next.Argument)
      }));
    });
  }

  /**
   *
   */
  public OpenModal(command: CoreOpenFormInModalCommand): Observable<boolean> {
    const clonedCommand: CoreOpenFormInModalCommand = UtilsTypescript.jsonClone(command);

    // El motor de formularios modifica la configuración colocandole un parámetro Title en el comando
    // ya que el comando hereda de DtoFrontendModal. Clonamos el DTO para que no sea afectado el original
    const instanceRef: ModalReference<EventFormSucceededInterface> = this.dmbs.showForm(
      clonedCommand.FormId,
      clonedCommand,
      clonedCommand.Arguments,
      null
    );

    /**
     * TODO: Esto no tiene un takeUntil porque deberíamos tener acceso a un take until de la propia modal...
     */
    return instanceRef.close$
      .pipe(
        switchMap((result: any) => {
          if (!clonedCommand.OnCloseCommands) {
            return Promise.resolve(result);
          }
          // Hasta que no terminen los comandos de cierre de modal no hago nada
          return this.commandService.executeCommandChain(Object.values(clonedCommand.OnCloseCommands))
            .then((i) => result);
        }),
        map((result: any) => {
            return result;
          }
        )
      );
  }
}
