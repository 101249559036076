import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeCa from '@angular/common/locales/ca';
import localeGa from '@angular/common/locales/ga';
import localeGl from '@angular/common/locales/gl';
import localeEu from '@angular/common/locales/eu';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService, TranslateStore } from '@ngx-translate/core';
import * as moment from 'moment';
import { FuseModule } from '../@fuse/fuse.module';
import { LetsTalkModule } from './achs-lets-talk/achs-lets-talk.module';
import { appBootstrapFactory, BootstrapService } from './app-bootstrap.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigurationService } from './app.configuration.service';
import { AuthService } from './core/authentication/auth.service';
import { ChangedetectorService } from './core/changedetector/changedetector.service';
import { CommunicationService } from './core/communication/communication.service';
import { CoreModule } from './core/core.module';
import { ErrorService } from './core/error-handling/error.service';
import { AppVersionInterceptor } from './core/interceptors/app-version.interceptor';
import { CommandsInterceptor } from './core/interceptors/commands.interceptor';
import { ContextInterceptor } from './core/interceptors/context.interceptor';
import { ErrorCatcherInterceptor } from './core/interceptors/error-catcher.interceptor';
import { SessionStateRefreshInterceptor } from './core/interceptors/sessionstate-refresh.interceptor';
import { LayoutManagerService } from './core/layout-manager/layout-manager.service';
import { LockerModule } from './core/locker/locker.module';
import { MaintenanceModule } from './core/maintenance/maintenance.module';
import { MessageToastModule } from './core/message-toast/message-toast.module';
import { NavigationService } from './core/navigation/navigation.service';
import { CoalescingComponentFactoryResolver } from './core/coalescing-factory-resolver.service';
import { RestTranslatorModule } from './core/translator/rest-translator.module';
import { EditProfileModule } from './editProfile/editProfile.module';
import { GoogleAnalyticsModule } from './googleanalytics/googleanalytics.module';
import { AuthGuard } from './guards/auth-guard.service';
import { CheckAccessblockingmessageGuard } from './guards/check-accessblockingmessage-guard';
import { IsLoggedOutGuard } from './guards/is-logged-out.guard';
import { LayoutsModule } from './layouts/layouts.module';
import { primeFuseConfig } from './layouts/prime-fuse/config';
import { LoginService } from './login/services/login.service';
import { PageNotFoundComponent } from './not-found.component';
import { OrganizationalStructureModule } from './organizational-structure/organizational-structure.module';
import { ParametricsModule } from './parametrics/parametrics.module';
import { AppModalService } from './shared/decoupled-modal/app-modal.service';
import { AppMappingService } from './shared/mapping/mapping.service';
import { BreadCrumb_Service } from './shared/navigation-components/bread-crumb/bread-crumb.service';
import { MenuService } from './shared/navigation-components/menu/menu.service';
import { SharedModule } from './shared/shared.module';
import { UtilsModule } from './shared/utils/utils.module';
import { SingleSignOnComponent } from './singlesignon/singlesignon.component';
import { AppbootstrapInterceptor } from './core/interceptors/appbootstrap.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BROWSER_GLOBALS_PROVIDERS } from './shared/utils/browser-globals';
import { TranslatorService } from './core/translator/services/rest-translator.service';
import { ContextService } from './core/services/ETG_SABENTISpro_Application_Core_context.service';
import { BaseSystemService } from './core/services/ETG_SABENTISpro_Application_Core_basesystem.service';
import { MaintenanceModeService } from './core/services/ETG_SABENTISpro_Application_Core_maintenancemode.service';
import { LogService } from './core/services/ETG_SABENTISpro_Application_Core_log.service';
import { HeaderCookieInterceptor } from './core/interceptors/header-cookie.interceptor';
import { SessionstateService } from './core/sessionstate/sessionstate.service';
import { CacheHeadersInterceptor } from './core/cache-headers.interceptor';
import { RxNgZoneScheduler } from './utils/rx-ng-zone-scheduler';
import { KeepUrlBeforeLoginGuard } from './guards/keep-url-before-login-guard.service';
import { MaintenanceControlService } from './core/maintenance/maintenance-control.service';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeCa, 'ca');
registerLocaleData(localeGa, 'ga');
registerLocaleData(localeGl, 'gl');
registerLocaleData(localeEu, 'eu');

@NgModule({
  imports: [
    FuseModule.forRoot(primeFuseConfig),
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    CoreModule.forRoot(),
    LockerModule.forRoot(),
    SharedModule.forRoot(),
    ReactiveFormsModule,
    RestTranslatorModule.forRoot(),
    MaintenanceModule,
    AppRoutingModule,
    OrganizationalStructureModule,
    ParametricsModule,
    EditProfileModule,
    MessageToastModule.forRoot(),
    GoogleAnalyticsModule.forRoot(),
    LetsTalkModule.forRoot(),
    UtilsModule,
    LayoutsModule,
    ServiceWorkerModule.register(
        'ngsw-worker.js',
        {
          enabled: environment.pwa,
          registrationStrategy: 'registerImmediately'
        })
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    SingleSignOnComponent
  ],
  providers: [
    ChangedetectorService,
    AuthService,
    AppMappingService,
    LoginService,
    KeepUrlBeforeLoginGuard,
    AuthGuard,
    IsLoggedOutGuard,
    CommunicationService,
    ContextService,
    CheckAccessblockingmessageGuard,
    AppConfigurationService,
    BaseSystemService,
    TranslatorService,
    TranslateService,
    TranslateStore,
    RxNgZoneScheduler,
    // https://stackoverflow.com/questions/50104843/how-to-hide-base-href-in-angular-4-website-hosted-on-s3
    [{provide: APP_BASE_HREF, useValue: '/'}],
    {
      provide: APP_INITIALIZER,
      useFactory: appBootstrapFactory,
      deps: [BootstrapService],
      multi: true
    },
    /**
     * interceptors order is important. the order executed in the interceptors is the following:
     * first, when the package is sent the interceptors are executed in the same order that are declared, that
     * means that the fist interceptor declared is the first executed. However, the order when the package returns
     * is the inverse order, it means that the first interceptor that catches or maps the response is the last
     * declared.
     */
    {provide: HTTP_INTERCEPTORS, useClass: CacheHeadersInterceptor, multi: true},
    // Refresco de token por si hubiera caducado
    {provide: HTTP_INTERCEPTORS, useClass: HeaderCookieInterceptor, multi: true},
    // Refresco de token por si hubiera caducado
    {provide: HTTP_INTERCEPTORS, useClass: SessionStateRefreshInterceptor, multi: true},
    // Contexto
    {provide: HTTP_INTERCEPTORS, useClass: ContextInterceptor, multi: true},
    // Después el procesado general de errores
    {provide: HTTP_INTERCEPTORS, useClass: ErrorCatcherInterceptor, multi: true},
    // Este intercetpro solo añade una cabecera a los request con la versión del cliente
    {provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptor, multi: true},
    // El bootstrap interceptor después, que controla los errores 0 (sin conexión) y 503 (indisponibilidad temporal)
    {provide: HTTP_INTERCEPTORS, useClass: AppbootstrapInterceptor, multi: true},
    // Primero en procesar respuestas es siempre el sistema de comandos, por ejemplo para un 503
    // puede haber un comando de ir a modo mantenimiento...
    {provide: HTTP_INTERCEPTORS, useClass: CommandsInterceptor, multi: true},
    {provide: ErrorHandler, useClass: ErrorService},
    {provide: LOCALE_ID, useValue: 'es'},
    BROWSER_GLOBALS_PROVIDERS,
    LogService,
    MaintenanceModeService,
    LayoutManagerService,
    NavigationService,
    BreadCrumb_Service,
    MenuService,
    CoalescingComponentFactoryResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  /**
   * Constructor de la clase AppModule.
   */
  constructor(
      maintenanceControlService: MaintenanceControlService,
      ams: AppModalService,
      coalescingResolver: CoalescingComponentFactoryResolver,
      authService: AuthService,
      sessionStateService: SessionstateService,
      loginService: LoginService
  ) {
    this.overrideDate();

    ams.register();

    // Registramos el factory resolver coalescente. Esto en tanto se actualiza
    // a la version 9 de angular. En teoria la version 9 resuelve el problema
    // de visibilidad de componentes fuera del modulo (lazy-loaded) que lo define.
    coalescingResolver.register();
  }

  /**
   * Angular y javascript en general tienen mal resuelta la compunicación de las fechas hacia APIs.
   * En el momento de serializar a JSON una fecha JSON.stringify(date), internamente se invoca el método
   * Date.toISOString() que convierte la fecha aplicando el cambio de hora del navegador del cliente.
   * Mediante este hack, modificamos la serialización de los atributos tipo Date para que viaje la fecha y hora.
   */
  overrideDate(): void {
    // Esto lo que hace es evitar que el serializador de JSON incluya timezone al serializar un objeto
    // de fecha, se utilizó en cierto momento para evitar que las fechas que se serializan estuvieran
    // influidas por la zona horaria del navegador, y fuera la fecha tal-cual la introduce el usuario.
    // Lo dejamos porque realmente esto evita confusiones...
    // eslint-disable-next-line
    Date.prototype.toJSON = function (key: any) {
      // This code return me as string like "2020/04/09 12:48:52"
      const value: string = moment(this).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS');
      return value;
    }
  }
}
