import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploaderService } from '../../../../file-uploader/file-uploader.service';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import { MessageToastService } from '../../../../../core/message-toast/share/message-toast.service';
import { FileWrapper } from '../../../../file-uploader/models/file-wrapper';
import { FileUtils } from '../../../../utils/file.utils';
import { FormatFileSize } from '../../../../utils/typescript.utils';

@Component({
  selector: 'app-file-dropable',
  templateUrl: './file-dropable.component.html',
  styleUrls: ['./file-dropable.component.scss']
})
export class FileDropableComponent extends DestroyableObjectTrait {

  /**
   * Unique input id
   */
  @Input() uniqueId: string;

  /**
   * Declare if there are multiple files
   */
  @Input() multiple: boolean;

  /**
   * Enable or not the Drag And Drop feature
   */
  @Input() enableDragAndDrop: boolean;

  /**
   * The valid file extensions
   */
  @Input() validExtensions: string[];

  /**
   * The valid file extensions
   */
  @Input() validSize?: number;

  /**
   * Enable or not the Drag And Drop feature
   */
  @Input() disabled: boolean;

  /**
   * The text button
   */
  @Input() buttonLabel: string = 'Cargar fichero';

  @Input() maxFiles: number;

  @Input() currentFiles: number;

  /**
   * Event that indicates if ocurred an error while dropping a file on the drop zone.
   */
  @Output() onOverError: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Event that indicates if the mouse is dragging a file on the drop zone.
   */
  @Output() onOverControl: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Event that indicates if the mouse is dragging a file on the drop zone.
   */
  @Output() onFilesLoaded: EventEmitter<FileWrapper[]> = new EventEmitter<FileWrapper[]>();

  /**
   * Boolean that indicates if ocurred an error while dropping a file on the drop zone.
   *
   * @type {boolean}
   */
  private _overError = false;

  public get overError(): boolean {
    return this._overError;
  }

  public set overError(value: boolean) {
    this._overError = value;
    this.onOverError.emit(value);
  }

  /**
   * Boolean that indicates if the mouse is dragging a file on the drop zone.
   */
  private _overControl = false;

  public get overControl(): boolean {
    return this._overControl;
  }

  public set overControl(value: boolean) {
    this._overControl = value;
    this.onOverControl.emit(value);
  }

  constructor(protected cdRef: ChangeDetectorRef,
              protected uploaderService: FileUploaderService,
              protected messageService: MessageToastService) {
    super();
  }

  /**
   * Handle file over control (before drop)
   *
   * @param {DragEvent} e
   */
  handleFileOver(e: boolean): void {
    if (this.disabled) {
      return;
    }
    if (e) {
      if (this.currentFiles < (this.maxFiles || 0)) {
        this.overControl = true;
      } else {
        this.overError = true;
      }
      this.cdRef.detectChanges();
      return;
    }

    this.overControl = this.overError = false;
    this.cdRef.detectChanges();
  }

  /**
   * Handle a file drop
   *
   * @param {FileList} items
   */
  handleFileDrop(items: FileList): void {
    if (this.disabled) {
      return;
    }

    const wFiles: FileWrapper[] = Array.from(items).map(file => {
      return this.uploaderService.createFile(file);
    });

    if (this.dropFilesValid(wFiles)) {
      this.onFilesLoaded.emit(wFiles);
    }
  }

  /**
   * Validate a set of dropped files
   *
   * @param {FileWrapper[]} items
   * @returns {boolean}
   */
  dropFilesValid(items: FileWrapper[]): boolean {

    const validItems: FileWrapper[] =
      items.filter(f => {
        return FileUtils.IsValidSize(f, this.validSize) && FileUtils.IsValidExtension(f, this.validExtensions);
      });

    if (validItems.length !== items.length) {
      this.messageService.showError(
        `Algunos de los ficheros seleccionados no cumplen con las restricciones de
        tamaño (${this.validSize > 0 ? FormatFileSize(this.validSize) : FormatFileSize(FileUtils.FileSizeLimit)}) y/o extensión.`,
        'Error',
        false);
    }

    return validItems.length === items.length;
  }

  /**
   * List of valid extensions
   *
   * @returns {Array<string>}
   */
  getValidExtensions(): string {
    return [...(this.validExtensions || [])]
      .map(e => `.${e}`)
      .join(', ');
  }

}
