<div class="clearfix">
  <div *ngIf="option === 0 && params && formPlugin" class="invisible">
    <app-form-manager
        [formPlugin]="formPlugin"
        [params]="params"
        (formLoaded)="loaded($event)"
        (canceled)="cancel($event)"
        (saveSucceed)="saveSucceed($event)"
        #form
    >
    </app-form-manager>
  </div>
  <div *ngIf="option === 1 && task">
    <h4 class="text-center">Exportar plantilla</h4>
    <app-batch-item
        [initialTask]="task"
    ></app-batch-item>
    <button style="margin-left: 40%;" (click)="closeModal()" class="o-btn__secondary">Cerrar</button>
  </div>
</div>
