<div [ngSwitch]="currentStep">

  <div *ngSwitchCase="mappingSteps.SELECT_MAPPING">
    <app-form-manager
        formPlugin="core-bulk-load-select-form"
        [params]="bulkLoadSelectParams"
        (canceled)="closeModal($event)"
        (saveSucceed)="saveSucceedSelectForm($event)">
    </app-form-manager>
  </div>

  <div *ngSwitchCase="mappingSteps.EXECUTION_FORM">
    <app-form-manager
        [formPlugin]="selectedMappingMappingRequest.ExecutionFormId"
        [params]="executionFormArguments"
        (canceled)="closeModal($event)"
        (saveSucceed)="saveSucceedExecutionForm($event)"
        (formEventComplex)="executionFormEventComplexHandler($event)">
    </app-form-manager>
  </div>

  <div *ngSwitchCase="mappingSteps.TEMPLATE_BATCH">
    <div *ngIf="templateTask; else elseBlock">
      <h2 class="text-center">Exportar plantilla</h2>
      <app-batch-item
          [initialTask]="templateTask">
      </app-batch-item>
    </div>
    <ng-template #elseBlock>
      <div class="invisible">
        <app-form-manager
            [formPlugin]="templateGeneratePluginRequest.FormId"
            [params]="templateGeneratePluginRequest.Arguments"
            (formLoaded)="loaded($event)"
            (canceled)="cancelTemplateBatch($event)"
            (saveSucceed)="saveSucceedTemplateBatch($event)">
        </app-form-manager>
      </div>
    </ng-template>
  </div>

  <div *ngSwitchCase="mappingSteps.RESULT_BATCH">
    <div *ngIf="executionTask">
      <h2 class="text-center">Ejecución de la importación</h2>
      <app-batch-item
          [initialTask]="executionTask">
      </app-batch-item>
    </div>
  </div>
</div>
<div class="modal-footer l-btn-html-center">
  <div [ngSwitch]="currentStep">
    <div *ngSwitchCase="mappingSteps.RESULT_BATCH">
      <button (click)="closeModal($event)" class="o-btn__secondary">Cerrar</button>
    </div>
    <div *ngSwitchCase="mappingSteps.TEMPLATE_BATCH">
      <button (click)="cancelTemplateBatch($event)" class="o-btn__secondary">Volver</button>
    </div>
  </div>
</div>
