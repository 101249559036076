import { ActivatedRoute } from '@angular/router';
import * as jsonpath from 'jsonpath';
import { DtoFrontendModalSize, KeyValuePair } from '../../core/models/ETG_SABENTISpro_Application_Core_models';

export class UtilsTypescript {

  /**
   * Use json to clone an object/data deeply
   * @param data
   */
  public static jsonClone(data: any, replacer?: (this: any, key: string, value: any) => any): any {
    if (isNullOrUndefined(data)) {
      return data;
    }
    return JsonClone(data, replacer);
  }

  /**
   * Key value pairs are actually sent down by JSON serializer
   * as objects, so we make an interable version here...
   *
   * @constructor
   */
  public static toKeyValuePair<TKeyType, TValueType>(source: KeyValuePair<TKeyType, TValueType>[]): KeyValuePair<TKeyType, TValueType>[] {
    if (isNullOrUndefined(source)) {
      return [];
    }
    const result: KeyValuePair<TKeyType, TValueType>[] = [];
    for (const key of Object.keys(source)) {
      const kvp: KeyValuePair<TKeyType, TValueType> = new KeyValuePair<TKeyType, TValueType>();
      const tmp: any = key;
      kvp.Key = tmp as TKeyType;
      kvp.Value = source[key];
      result.push(kvp);
    }
    return result;
  }

  /**
   * Key value pairs are actually sent down by JSON serializer
   * as objects, so we make an interable version here...
   *
   * @constructor
   */
  public static toKeyValuePair2<TKeyType, TValueType>(source: {
    [key: number]: TValueType
  }): KeyValuePair<TKeyType, TValueType>[] {
    if (isNullOrUndefined(source)) {
      return [];
    }
    const result: KeyValuePair<TKeyType, TValueType>[] = [];
    for (const key of Object.keys(source)) {
      const kvp: KeyValuePair<TKeyType, TValueType> = new KeyValuePair<TKeyType, TValueType>();
      const tmp: any = key;
      kvp.Key = tmp as TKeyType;
      kvp.Value = source[key];
      result.push(kvp);
    }
    return result;
  }

  /**
   * Make sure we can interate on null or undefined values.
   *
   * @param {TSourceType[]} source
   * @returns {TSourceType[]}
   */
  public static asIterable<TSourceType>(source: TSourceType[]): TSourceType[] {
    if (isNullOrUndefined(source)) {
      return new Array<TSourceType>();
    }
    return source;
  }

  /**
   * Is null or undefined
   *
   * @param source
   * @returns {boolean}
   */
  public static isNullOrUndefined(source: any): boolean {
    return isNullOrUndefined(source);
  }

  /**
   * Check if this or any of it's children has a class
   *
   * @param element
   * @param className
   */
  public static hasClass(element: HTMLElement, className: string): boolean {
    const regex: RegExp = new RegExp('\\b' + className + '\\b');
    do {
      if (regex.exec(element.className)) {
        return true;
      }
      element = element.parentElement;
    } while (element);
    return false;
  }

  /**
   * Check if a string is null or empty
   *
   * @param {string} source
   * @returns {boolean}
   */
  public static isNullOrWhitespace(source: string): boolean {
    return isNullOrWhitespace(source);
  }

  /**
   * Alternative to Object.values()
   *
   * @param obj
   * @constructor
   */
  public static ObjectValues<T>(obj: { [key: string]: T }): T[] {
    return Object.keys(obj).map(e => obj[e]);
  }

  /**
   * Check if a given ANY is an object
   *
   * @param obj
   */
  public static isObject(obj: any): boolean {
    return obj === new Object(obj);
  }

  /**
   * When collections are sent by Newtonsoft with types, these are converted
   * to objects with a $type attribute in order to supporte deserialization.
   *
   * @param {object} source
   */
  public static getNewtonSoftRealValue(source: object): any {
    return getNewtonSoftRealValue(source);
  }

  /**
   * Copy and Object to another
   * @param {Object} src
   * @param {Object} dst
   */
  public static copyObject(src: object, dst: object): void {
    for (const key in src) {
      if (src.hasOwnProperty(key)) {
        dst[key] = src[key];
      }
    }
  }

  /**
   * Rellenar un valor en un objeto jerárquico usando un path/selector
   *
   * @param selector
   * @param container
   */
  public static setValueInObjectPath(selector: string, container: any, value: any): void {

    const selectorParts: string[] = selector.split('.');

    if (selectorParts.length === 1) {
      container[selector] = value;
      return;
    }

    if (isNullOrUndefined(container[selectorParts[0]])) {
      container[selectorParts[0]] = {};
    }

    this.setValueInObjectPath(selectorParts.slice(1, selectorParts.length).join('.'), container[selectorParts[0]], value);
  }


  /**
   * Dados dos strings, devuelve la parte inicial que coincida en ambos literales
   * Si a = home/test/do/it y b = home/test2/bing devuelve home/test
   * @param a
   * @param b
   */
  public static getStringMatchingPathParts(a: string, b: string): string {
    const pathPartsA: string[] = a.split('/');
    const pathPartsB: string[] = b.split('/');
    const maxLength: number = pathPartsA.length > pathPartsB.length ? pathPartsB.length : pathPartsA.length;
    const matchingParts: string[] = [];
    for (let x: number = 0; x < maxLength; x++) {
      if (pathPartsA[x] !== pathPartsB[x]) {
        break;
      }
      matchingParts.push(pathPartsA[x]);
    }
    return matchingParts.join('/');
  }

  /**
   *
   * @returns {string}
   */
  public static guid(): string {
    function s4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
}

/**
 * Permite comprobar si un tipo de .Net coincide con el que envía el serializador. Cuidado porque en ciertos casos
 * límite puede dar falsos positivos.
 *
 * Esto es así porque a veces el serializador simplifica los nombres de tipo para reducir el payload,
 * eliminando el namespace completo.
 *
 * @param type
 *   El tipo completo, tal y como está en los objetos del dumper.
 *
 * @param serializerType
 *   El tipo que viene del serializador
 */
export function backendTypeMatch(type: string, remoteObject: object): boolean {
  if (!remoteObject || !remoteObject.hasOwnProperty('$type')) {
    return false;
  }
  const remoteType: string = remoteObject['$type'];
  if (remoteType === type) {
    return true;
  }
  if (remoteType === type.split('.').pop()) {
    return true;
  }
  return false;
}

export function floatSafeRemainder(val: number, step: number): number {
  const valDecCount: number = (val.toString().split('.')[1] || '').length;
  const stepDecCount: number = (step.toString().split('.')[1] || '').length;
  const decCount: number = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt: number = parseInt(val.toFixed(decCount).replace('.', ''), 0);
  const stepInt: number = parseInt(step.toFixed(decCount).replace('.', ''), 0);
  return (valInt % stepInt) / Math.pow(10, decCount);
}

/**
 * Use JSONE to clone (stringify/parse) an object.
 *
 * This "clones" an object deeply.
 *
 * @param data
 */
export function JsonClone(data: any, replacer?: (this: any, key: string, value: any) => any): any {
  return JSON.parse(JSON.stringify(data, replacer));
}

/**
 * Format a file size in bytes to a human-readable representation
 *
 * @param {number} bytes
 * @returns {string}
 * @constructor
 */
export function FormatFileSize(bytes: number): string {
  // eslint-disable-next-line no-bitwise
  const exp: number = Math.log(bytes) / Math.log(1024) | 0;
  const result: string = (bytes / Math.pow(1024, exp)).toFixed(2);
  return result + ' ' + (exp === 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
}

/**
 * Type-safe access of deep property of an object
 *
 * https://stackoverflow.com/questions/15260732/does-typescript-support-the-operator-and-whats-it-called
 *
 * @param obj                   Object to get deep property
 * @param unsafeDataOperation   Function that returns the deep property
 * @param defaultValue           Value to return in case if there is no such property
 */
export function getInSafe<O, T>(obj: O, unsafeDataOperation: (x: O) => T, defaultValue?: any): T {
  try {
    const result: T = unsafeDataOperation(obj);
    return isNullOrUndefined(result) ? defaultValue : result;
  } catch (error) {
    return defaultValue;
  }
}

/**
 * Check if the string is null or whitespace
 * @param source
 */
export function isNullOrWhitespace(source: string): boolean {
  if (isNullOrUndefined(source)) {
    return true;
  }
  if (String(source).trim() === '') {
    return true;
  }
  return false;
}

/**
 * TODO: Mover a utilidades
 */
export function truncateAtWord(text: string,
                               maxCharacters: number,
                               trailingStringIfTextCut: string = '...',
                               punctuationCharacters: string = '. /\\\\:-¿{[*=+'): string {

  if (isNullOrWhitespace(text)) {
    return text;
  }

  let cut: boolean = false;

  const textLength: number = text.length;

  if (textLength > maxCharacters) {
    const cutLength: number = trailingStringIfTextCut.length;
    text = text.substr(0, maxCharacters - cutLength);
    const space: number = text.lastIndexOf(' ');

    if (space !== -1) {
      text = text.substr(0, space);
    }

    cut = true;
  }

  // text = text.trimRight(punctuationCharacters);

  if (cut) {
    text += trailingStringIfTextCut;
  }

  return text;
}

/**
 * Make sure an array is an array
 * @param obj
 */
export function asIterable<O>(obj: Array<O>): Array<O> {
  if (!isArray(obj)) {
    return [];
  }
  return obj;
}

export function asIterableObject<T>(obj: { [key: string]: T }): Array<T> {
  if (isNullOrUndefined(obj)) {
    return [];
  }
  const result: Array<T> = new Array<T>();
  for (const key of Object.keys(obj)) {
    // Este type es el que pone el serializador de JSON y no lo queremos cuando iteramos!
    if (key === '$type') {
      continue;
    }
    result.push(obj[key]);
  }
  return result;
}

/**
 * Comprobar si una fecha es válida
 * @param d
 */
export function isValidDate(d: Date): boolean {
  return isFinite(d.getTime());
}

/**
 * Reemplaza en la cadena cualquier carácter no válido (alfanumérico) por un guión.
 *
 * @param identifier
 */
export function cleanIdentifier(identifier: string): string {
  return identifier.replace(/[^a-z0-9+]+/gi, '-').toLowerCase();
}

/**
 * Map an object
 *
 * @param obj
 * @param unsafeDataOperation
 * @param defaultValue
 */
export function mapDictionary<T>(obj: { [key: string]: T }): Array<T> {
  const result: T[] = new Array<T>();
  for (const key of Object.keys(obj)) {
    result.push(obj[key]);
  }
  return result;
}

/**
 * Make a breadth-first search on the tree to flatten its children
 * @param tree
 * @param childrenKey
 * @param collection
 */
export function bfs(tree: any, childrenKey: any, collection: any): void {
  if (!tree[childrenKey] || tree[childrenKey].length === 0) {
    return;
  }
  for (const k in tree[childrenKey]) {
    if (tree[childrenKey].hasOwnProperty(k)) {
      const child: any = tree[childrenKey][k];
      if (child.hasOwnProperty('DefaultValue')) {
        collection[k] = child;
      }
      this.bfs(child, childrenKey, collection);
    }
  }
  return;
}

/**
 * Remove an element from the DOM.
 * @param {Element} element
 */
export function removeElement(element: Element): Element {
  return element.parentElement.removeChild(element);
}

/**
 * Returns a boolean indicating if an object is an empty string.
 * @param {Object} value Object to test.
 */
export function isEmpty(value: any): boolean {
  return typeof value === 'string' && value === '';
}

export function isNullOrUndefined(value: any): boolean {
  return value === undefined || value === null;
}

export function isUndefined(value: any): boolean {
  return value === undefined;
}

export function isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}

export function isArray(value: any): boolean {
  return Array.isArray(value);
}

export function isString(obj: any): boolean {
  return typeof (obj) === 'string';
}

export function jsonEqual(value: any, value2: any): boolean {
  if (value === value2) {
    return true;
  }
  if (isNullOrUndefined(value) && isNullOrUndefined(value2)) {
    return true;
  }
  if (JSON.stringify(value) === JSON.stringify(value2)) {
    return true;
  }
  return false;
}

/**
 * Get the last inner `ActivatedRoute` from a parent `ActivatedRoute`.
 * @param {ActivatedRoute} activatedRoute
 */
export function getLastInnerActivatedChild(
    activatedRoute: ActivatedRoute): ActivatedRoute {
  if (isNullOrUndefined(activatedRoute.firstChild)) {
    return activatedRoute;
  }

  return getLastInnerActivatedChild(activatedRoute.firstChild);
}

/**
 * When collections are sent by Newtonsoft with types, these are converted
 * to objects with a $type attribute in order to supporte deserialization.
 *
 * @param {object} source
 */
export function getNewtonSoftRealValue(source: object): any {
  if (isNullOrUndefined(source)) {
    return source;
  }
  if (source.hasOwnProperty('$type') && source.hasOwnProperty('$values')) {
    return source['$values'];
  }
  return source;
}

export function cleanNewtonsoftTypeProperty(source: object): any {
  if (isNullOrUndefined(source)) {
    return source;
  }
  if (source.hasOwnProperty('$type')) {
    const result: object = {};
    Object.keys(source).forEach((key) => {
      if (key === '$type') {
        return;
      }
      result[key] = source[key];
    });
    return result;
  }
  return source;
}

/**
 * Basic nameof implementation
 *
 * @param propertyName
 */
export function nameof<T>(key: keyof T, instance?: T): keyof T {
  return key;
}

/**
 * Evaluar JSON path en un objeto
 *
 * @constructor
 */
export function JsonPathEvaluate<T>(jsonPath: string, object: any): T[] {
  if (!object) {
    return [];
  }
  const result: any[] = jsonpath.query(object, jsonPath);
  if (result && result.length) {
    return result as T[];
  }
  return [];
}

export function JsonPathTryEvaluate<T>(jsonPath: string, object: any): T[] {
  if (!object) {
    return [];
  }

  try {
    jsonpath.parse(jsonPath)
  } catch (e) {
    return [];
  }

  const result: any[] = jsonpath.query(object, jsonPath);
  if (result && result.length) {
    return result as T[];
  }
  return [];
}

/**
 * Evalua un PATH usando JPATH.
 * @param jsonPath
 * @param object
 * @param success
 *   Callback al que se llama si la evaluación es existosa. La evaluación no será existosa si el objeto
 *   no contiene un valor para el JPATH indicado, o si el propio JPATH está malformado o se ha producido
 *   cualquier tipo de error.
 * @constructor
 */
export function JsonPathTryEvaluateNew<T>(
    jsonPath: string,
    object: any,
    success: (value: T) => void,
    failure: (e: Error) => void): void {

  // Verifica si jsonPath es nulo o no es un string
  // Solo admitimos JPATH bien formados, es decir, que empiezan con la raíz $
  if (typeof jsonPath !== 'string' || !jsonPath.startsWith('$')) {
    failure(new Error('Invalid JPATH selector [' + jsonPath + '].'));
    return;
  }

  if (!object) {
    failure(new Error('Input objet is null'));
    return;
  }

  try {
    jsonpath.parse(jsonPath)
  } catch (e) {
    failure(e);
    return;
  }

  const result: any = jsonpath.query(object, jsonPath);
  success(result);
}

/**
 * Evaluar JSON path en un objeto
 *
 * @constructor
 */
export function JsonPathSet<T>(jsonPath: string, object: any, value: any): T[] {
  if (!object) {
    return [];
  }
  const result: any[] = jsonpath.apply(object, jsonPath, (x: any) => value);
  if (result && result.length) {
    return result as T[];
  }
  return [];
}

/**
 * Dada un path de Json, sustituye el formato del
 * path para ser evaluado por JsonPath sustituyendo la separación de los atributos por puntos a []
 *
 * @constructor
 */
export function JsonPathSanitizer(source: string): string {
  let result: string = '$["' + source;

  while (result.indexOf('.') !== -1) {
    result = result.replace('.', '"]["');
  }

  result += '"]';

  return result;
}

/**
 * Mapear los tamaÃ±os d emodales a clases.
 *
 * @param modalSize
 * @constructor
 */
export function ModalSizeToModalClass(modalSize: DtoFrontendModalSize): 'modal-lg' | 'modal-md' | 'modal-sm' | 'modal-xl' {
  switch (modalSize) {
    case DtoFrontendModalSize.Small:
      return 'modal-sm';
    case DtoFrontendModalSize.Large:
      return 'modal-lg';
    case DtoFrontendModalSize.Medium:
      return 'modal-md';
    case DtoFrontendModalSize.ExtraLage:
      return 'modal-xl';
    default:
      return 'modal-md';
  }
}

export function trimChars(source: string, c: string): string {
  const re: RegExp = new RegExp('^[' + c + ']+|[' + c + ']+$', 'g');
  return source.replace(re, '');
}

export function trimCharsEnd(source: string, c: string): string {
  const re: RegExp = new RegExp('[' + c + ']+$', 'g');
  return source.replace(re, '');
}

/**
 * Make a string safe for it's usage in CSS
 * @param name
 */
export function makeSafeForCSS(name: string): string {
  if (!name) {
    return '';
  }
  return name.replace(/[^a-z0-9]/g, function (s: string): string {
    const c: number = s.charCodeAt(0);
    if (c === 32 || c === 92 || c === 47 || c === 46 || c === 95) {
      return '-'
    }
    if (c >= 65 && c <= 90) {
      return '_' + s.toLowerCase()
    }
    return '__' + ('000' + c.toString(16)).slice(-4);
  });
}

/**
 * Return if n is a valid number
 * @param n
 */
export function isNumber(n: any): boolean {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function removeFromArray<T>(source: T[], key: T): void {
  const index: number = source.indexOf(key, 0);
  if (index > -1) {
    source.splice(index, 1);
  }
}
