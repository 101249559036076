<div class="l-multiselector-inline">
  <div *ngFor="let item of renderedItems; let i = index;"
       class="c-multiselector__container" [id]="'multiselector-container-' + getId()"
       [ngStyle]="item.Styles"
       [ngClass]="getCssClasses(item, 'row-' + i)">
    <div class="c-multiselector__items" [id]="'multiselector-items-' + getId()">
      <div class="c-multiselector__icon--remove" [id]="'multiselector-icon-remove-' + getId()" *ngIf="!isDisabled">
        <i class="fas fa-minus t-remove" (click)="removeElement(item.Key)"></i>
      </div>
      <div class="u-base-regular-text">{{ item.Name }}</div>
      <div class="u-base-bold-text">{{ item.Description }}</div>
      <ng-container *ngIf="item.hasOwnProperty('ExtraInformation')">
        <div *ngFor="let extra of item['ExtraInformation']">{{ extra }}</div>
      </ng-container>
      <ng-container *ngIf="item.hasOwnProperty('Html')">
        <div [innerHTML]="item['Html']"></div>
      </ng-container>
    </div>
  </div>
</div>

<div class="c-multiselector__icon--add t-add"
     [id]="'multiselector-icon-add-' + getId()"
     (click)="openModal()"
     *ngIf="showAddIcon">
  <i class="fas fa-plus"></i>
</div>
