<div style="display: flex; padding: 5px 0px;" *ngIf="anyFilters">
  <span class="filter-bullet" *ngFor="let filter of filters | keys">
    {{ getFilterName(filters[filter]) }}
    {{ getFilterNegate(filters[filter]) }}
    {{ getFilterOperator(filters[filter]) }}
    {{ getFilterValue(filters[filter], 1) }}
    <span *ngIf="getFilterOperatorId(filters[filter]) === 'InRange'"> y {{ getFilterValue(filters[filter], 2)}}</span>
    <span class="close" (click)="removeFilter(filter)">&times;</span>
  </span>
</div>
