import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { BackNavigateOptions } from '../../../core/navigation/object-navigation';

/**
 * MappingExecutionComponent: component that controls the mapping execution
 *
 * @export
 * @class MappingExecutionDetailsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-mapping-execution-details',
  templateUrl: './mapping-execution-details.component.html'
})

/**
 * This view controls the visualization of information for users and asociated groups
 */
export class MappingExecutionDetailsComponent implements OnInit {

  params = {};

  /**
   * Creates an instance of MappingExecutionComponent
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   */
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private navigationService: NavigationService) {
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    const routeParams: object[] = this.navigationService.findParamsFromRoute(this.activatedRoute.snapshot);
    const params: object = routeParams.find(x => x.hasOwnProperty('mapping'));
    if (!isNullOrUndefined(params)) {
      this.params[ 'mappingexecutionId' ] = params[ 'mappingexecution' ];
    }
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    // redirect to list
    this.navigationService.back({skipMapping: true} as BackNavigateOptions);  }

  /**
   * Handler for form succeed event.
   * @param e
   */
  saveSucceed(e: any): void {
  }
}
