<div class="exposed-filters-container"
     *ngIf="exposedFiltersFormState !== null && exposedFiltersFormState !== undefined">
  <ng-container #exposedFilterContainer appFormViewGenerator (changedValue)="changeFormValue($event)"
                *ngIf="exposedFiltersFormState !== null && exposedFiltersFormState !== undefined"
                [formState]="this.exposedFiltersFormState"></ng-container>
  <ng-container *ngIf="!updateViewConfigurationOnChangeSelection">
    <div class="button-container">
      <button class="c-btn-refresh" (click)="applyQuickFilters()">
        <i class="fas fa-filter af af-filter"></i>
      </button>
    </div>
  </ng-container>
</div>
