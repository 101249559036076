import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonDataService } from './common-data/common-data.service';
import { CommunicationLocalService } from './communication/communication-local.service';
import { ComponentCommunicationService } from './communication/component-communication.service';
import { ErrorPageComponent } from './error-handling/error-page/error-page.component';
import { LayoutManagerModule } from './layout-manager/layout-manager.module';
import { MessageBarModule } from './message-bar/message-bar.module';
import { ThemeManagerModule } from './theme-manager/theme-manager.module';
import { RestTranslatorModule } from './translator/rest-translator.module';
import { SabentisRouterLinkDirective } from './navigation/sabentis-router-link.directive';
import { SabentisRouterLinkActiveDirective } from './navigation/sabentis-router-link-active.directive';
import { SabentisRouterLinkWithoutHrefDirective } from './navigation/sabentis-router-link-without-href.directive';
import { NavigationContextRefreshService } from './navigation/navigation.context.refresh.service';
import { ClientCache } from './clientcache/ClientCacheClass';
import { CoreRenderTemplateSimpleMessageComponent } from './rendertemplate/core-render-template-simple-message/core-render-template-simple-message.component';
import { CoreRenderTemplateDirective } from './rendertemplate/core-render-template.directive';
import { CallbackService } from './callback/callback.service';
import { MenuService } from './services/ETG_SABENTISpro_Application_Core_menu.service';
import { ViewsService } from './services/ETG_SABENTISpro_Application_Core_views.service';
import { FileService } from './services/ETG_SABENTISpro_Application_Core_file.service';
import { AchsHiraService } from './services/ETG_SABENTISpro_Achs_achshira.service';
import { SabentisCompanyService } from './services/ETG_SABENTISpro_Application_Modules_sabentiscompany.service';
import { SsoService } from './services/ETG_SABENTISpro_Application_Modules_sso.service';
import { GeocodingService } from './services/ETG_SABENTISpro_Application_Core_geocoding.service';
import { BatchService } from './services/ETG_SABENTISpro_Application_Core_batch.service';
import { SessionService } from './services/ETG_SABENTISpro_Application_Core_session.service';
import { HiraService } from './services/ETG_SABENTISpro_Application_Modules_hira.service';
import { AchsIntegrationService } from './services/ETG_SABENTISpro_Achs_achsintegration.service';
import { PagetitleService } from './navigation/pagetitle.service';
import { ObserveElementDirective } from './observer-api/observe-element.directive';
import { ClientThemeService } from './theme-manager/theme.service';
import { DateTimeService } from './date-time/date-time.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RestTranslatorModule,
    RouterModule,
    ThemeManagerModule,
    MessageBarModule,
    LayoutManagerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    RestTranslatorModule,
    ThemeManagerModule,
    LayoutManagerModule,
    SabentisRouterLinkDirective,
    SabentisRouterLinkWithoutHrefDirective,
    SabentisRouterLinkActiveDirective,
    CoreRenderTemplateDirective,
    ObserveElementDirective
  ],
  declarations: [
    ErrorPageComponent,
    SabentisRouterLinkDirective,
    SabentisRouterLinkWithoutHrefDirective,
    SabentisRouterLinkActiveDirective,
    CoreRenderTemplateSimpleMessageComponent,
    CoreRenderTemplateDirective,
    ObserveElementDirective
  ]
})

export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        MenuService,
        AchsIntegrationService,
        CommunicationLocalService,
        ComponentCommunicationService,
        SessionService,
        BatchService,
        FileService,
        SabentisCompanyService,
        HiraService,
        SsoService,
        AchsHiraService,
        CommonDataService,
        GeocodingService,
        ViewsService,
        ClientCache,
      ]
    }
  }

  constructor(
    clientThemeService: ClientThemeService,
    dateTimeService: DateTimeService,
    navigationRefresh: NavigationContextRefreshService,
    callback: CallbackService,
    pageTitleService: PagetitleService
  ) {
  }
}
