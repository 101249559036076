import { ChangeDetectorRef, EventEmitter, Input, Output, Directive } from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import { CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { startOfDay } from 'date-fns';
import { ICalendarMode } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Directive()
export abstract class ViewModeCalendarComponentAbstract {
  protected _configuration: ICalendarMode;
  @Input() @Output() selectedDay: { date: Date };
  @Input() @Output() viewDate: Date;
  @Input() @Output() lang: string;
  @Input() @Output() refresh: Subject<any>;
  @Input() @Output() events: CalendarEvent[] = [];

  @Output() viewDateChange: EventEmitter<Date>;
  @Output() eventClicked: EventEmitter<{ event: CalendarEvent<any>; }>;
  @Output() eventTimesChanged: EventEmitter<CalendarEventTimesChangedEvent<any>>;

  protected constructor(protected cdr: ChangeDetectorRef) {
    this.selectedDay = {date: startOfDay(new Date())};
    this.viewDateChange = new EventEmitter();
    this.eventClicked = new EventEmitter();
    this.eventTimesChanged = new EventEmitter();
  }

  get configuration(): ICalendarMode {
    return this._configuration;
  }

  @Input()
  set configuration(value: ICalendarMode) {
    this._configuration = value;
    this.parseConfiguration();
  }

  protected abstract parseConfiguration(): void;
}
