import { Component, ElementRef, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { NGXGroupedSeriesChartComponent } from '../../ngx-grouped-series-chart.class';
import {
  BarChartDisplayOptions,
  GroupedVerticalBarChart
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-grouped-vertical-bar-chart',
  templateUrl: './grouped-vertical-bar-chart.component.html',
  styleUrls: ['./grouped-vertical-bar-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class GroupedVerticalBarChartComponent extends NGXGroupedSeriesChartComponent<GroupedVerticalBarChart, BarChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  GetViewValueOnInitializeChart(): [number, number] {
    return this.GetDefaultViewValueOnInitializeChart(this.elementRef);
  }

  GetViewValueOnResize(): [number, number] {
    return this.GetDefaultViewValueOnResize(this.elementRef);
  }
}
