<app-formelementinputlabel [config]="config" [group]="group">
</app-formelementinputlabel>
<app-formelementinputdescription [config]="config" [group]="group">
</app-formelementinputdescription>
<!-- El fieldset se ha puesto aquí exclusivamente para permitir habilitar/deshabilitar toda la tabla en bloque-->
<fieldset [disabled]="!config.editable">
  <table [formGroup]="group">
    <tr *ngFor="let row of getSorted(this.element.Rows)" [ngClass]="row.CssClasses">
      <td *ngFor="let cell of getSorted(row.Cells)" [attr.colspan]="cell.Colspan"
          [attr.rowspan]="cell.Rowspan"
          [ngClass]="cell.CssClasses">
        <ng-container
            appGenericInput
            [config]="getConfigFromCell(cell)"
            [group]="fieldsetGroup"></ng-container>
      </td>
    </tr>
  </table>
</fieldset>
