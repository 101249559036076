<input
    [id]="this.uniqueComponentId('input-')"
    type="text"
    [attr.inputmode]="getInputMode()"
    appNumbersOnly
    [(ngModel)]="inputValue"
    [ngModelOptions]="{ standalone: true }"
    [disabled]="isDisabled"
    [class.--disabled]="isDisabled"
    (blur)="blur()"
    autocomplete="off"
    [placeholder]="config?.placeholder"
    #inputElement/>
