/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { LocaleTarget } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class LocaleService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Permite obtener uno o varios literales traducidos para sincronizar con aplicaciones externas.  Devuelve un diccionario donde las claves son un hash acordado entre aplicaciones externas y backend SHA512(LITERAL + IDIOMA).
  *
  * @param {string} languagecode Idioma del que se quieren obtener las traducciones.
  * @param {string} contextPrefix Perefijo del contexto del que se quieren obtener los locales (i.e. UI:)
  * @param {number} changedSince Obtener solo las traducciones modificadas desde la fecha (UnixTimestampUTC)
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {LocaleTarget[]}
  */
  getLocalebycontext(languagecode: string, contextPrefix: string, changedSince: number = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<LocaleTarget[]>>
  {
    const params: any = {'languagecode' : languagecode, 'contextPrefix' : contextPrefix, 'changedSince' : JSON.stringify(changedSince)};
    return this.generalService.get(`core-locale/locale-by-context`, params, clientOpts) as Observable<WebServiceResponseTyped<LocaleTarget[]>>;
  }

  /**
  *
  * Este endpoint se usa para registrar literales cuando la aplicación está en modo recolección, todas las traducciones de frontend deben venir de un proceso de carga inicial de datos obtenidos desde locale-by-context. Permitimos el acceso anónimo porque puede haber partes del aplictivo frontend que requieran localización aún y estando como usuario anónimo, y este endpoint sirve para el registro.
  *
  * @param {string} source
  * @param {string} languageCode
  * @param {string} context
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {LocaleTarget}
  */
  postLocale(source: string, languageCode: string, context: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<LocaleTarget>>
  {
    const params: any = {'source' : source, 'languageCode' : languageCode, 'context' : context};
    return this.generalService.post(`core-locale/locale`, params, clientOpts) as Observable<WebServiceResponseTyped<LocaleTarget>>;
  }
}
