import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommunicationService } from '../../../../../core/communication/communication.service';
import { MessageToastService } from '../../../../../core/message-toast/share/message-toast.service';
import { FormElementFile } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../../../decoupled-modal/decoupled-modal-bridge.service';
import { FileUploaderService } from '../../../../file-uploader/file-uploader.service';
import { UploadStatus } from '../../../../file-uploader/models/file-wrapper';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FileComponentClass } from '../file-component.class';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

/**
 * Implements custom file selector.
 *
 * @see https://blog.angularindepth.com/never-again-be-confused-when-implementing-controlvalueaccessor-in-angular-forms-93b9eee9ee83
 */
@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss', './file-select-pretty.component.scss', './file-select-list.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileSelectComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => FileSelectComponent), multi: true}
  ]
})
export class FileSelectComponent extends FileComponentClass {

  /**
   * Boolean that indicates if ocurred an error while dropping a file on the drop zone.
   *
   * @type {boolean}
   */
  overError = false;

  /**
   * The upload status
   */
  public UploadStatus: any = UploadStatus;

  protected readonly open = open;

  /**
   * Get in instance of FileSelectComponent
   */
  constructor(
      protected uploaderService: FileUploaderService,
      protected communicationService: CommunicationService,
      protected messageService: MessageToastService,
      protected cdRef: ChangeDetectorRef,
      protected dmbs: DecoupledModalBridgeService,
      protected formManagerService: FormManagerService,
      protected localeService: TranslatorService) {
    super(uploaderService, communicationService, messageService, cdRef, dmbs, formManagerService, localeService);
  }

  /**
   * Obtener la configuración del elemento
   */
  get formElement(): FormElementFile {
    return this.config.FormElement as FormElementFile;
  }

  /**
   * onUploadFiles
   * @param files
   */
  onOverError(value: boolean): void {
    this.overError = value;
  }

  /**
   * If this suport multiple uploads
   *
   * @returns {boolean}
   */
  isMultiple(): boolean {
    return (this.config.maxFiles || 1) > 1;
  }
}
