<div class="c-field" [formGroup]="group">
  <app-formelementinputlabel [config]="config" [group]="group">
  </app-formelementinputlabel>
  <app-formelementinputdescription [config]="config" [group]="group">
  </app-formelementinputdescription>
  <app-formelementinput-validationmessages [config]="config" [group]="group">
  </app-formelementinput-validationmessages>
  <ng-container *ngIf="isTaskRunning() else documentContainer">
    <app-batch-item
        [initialTask]="task"
        [showCloseButton]="false"
        [showResultButtons]="false"
        (onTaskCompleted)="onTaskCompleted($event)">
    </app-batch-item>
  </ng-container>

  <ng-template #documentContainer>
    <ng-container *ngFor="let document of documents">
      <ng-container *ngIf="document?.type === 'application/pdf'">
        <object type="application/pdf"
                [title]="document.name"
                width="100%" height="450"
                [data]="getSafeResourceUrl(document)"
                *ngIf="document?.type === 'application/pdf'">
          <embed type="application/pdf"
                 [title]="document.name"
                 [src]="getSafeResourceUrl(document)"/>
        </object>
      </ng-container>
      <ng-container *ngIf="document?.type !== 'application/pdf'">
        <div>
        <div style="font-weight: 700;">
          <div>{{ 'Documento' | t }}</div>
        </div>
        <div class="data">
          <div class="flex-col-xs-12 flex-col-sm-6">
            <span>{{'Nombre del documento' | t }}: </span>
            <span>{{ document.name }}</span>
          </div>
        </div>
        <button id="downloadDocument" class="o-btn__primary" (click)="downloadResult(document)">{{'Descargar documento' | t }}</button>
        </div>
      </ng-container>
      <div style="margin-bottom: 2rem;"></div>
    </ng-container>
  </ng-template>
</div>
