import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import {
  ProgressBarChart,
  ProgressBarChartDisplayOptions
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Directive({
  selector: '[appProgressBarColor]'
})
export class ProgressBarColorDirective implements OnInit {

  static counter = 0;

  @Input() appProgressBarColor: ProgressBarChart;
  styleEl: HTMLStyleElement = document.createElement('style');

  constructor(private el: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.renderer.setAttribute(this.el.nativeElement, `app-progress-bar-color-${this.appProgressBarColor.Id}`, '');
    this.renderer.appendChild(this.el.nativeElement, this.styleEl)
    this.updateColor(`app-progress-bar-color-${this.appProgressBarColor.Id}`);
  }

  updateColor(progressBarAttr: string): void {
    // update dynamic style with the uniqueAttr
    this.styleEl.innerText =
      `[${progressBarAttr}] .mat-progress-bar-fill::after {background-color: ${
        (this.appProgressBarColor.ChartDisplayOptions as ProgressBarChartDisplayOptions).ProgressColor};}`;
    this.styleEl.innerText += `[${progressBarAttr}] .mat-progress-bar-buffer{background-color: ${
      (this.appProgressBarColor.ChartDisplayOptions as ProgressBarChartDisplayOptions).BackgroundColor};}`;
  }

}
