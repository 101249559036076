<div *ngIf="connectionError && !searching" class="c-simplemesage-container message-error">
  <i ngClass="fa fa-times-circle"></i>
  <span>Ha habido un problema con la conexión. Por favor, inténtelo de nuevo.</span>
</div>
<div class="c-multiple-autocomplete">
  <div class="c-tag-close">
    <div class="c-tag-close__item"
         *ngFor="let selectedOption of acValue; let i = index;"
         data-index="4">{{ selectedOption.Name }}
      <i (click)="unsetOption(i)" class="c-tag-close__btn"></i>
    </div>
  </div>
  <div class="u-separador"></div>
  <div class="c-autocomplete-multiple">
    <div class="o-input" #inputGroup>
      <input [id]="uniqueComponentId('input-')"
             type="text"
             class="o-input__field--search"
             [placeholder]="config.placeholder || 'Buscar...'"
             (keydown.enter)="keydownHandler($event)"
             (keyup.enter)="searchForResults()"
             (keyup.escape)="clearSearch()"
             (focusout)="inputTouched()"
             [(ngModel)]="searchValue"
             [ngModelOptions]="{standalone: true}"
             autocomplete="off"
             [disabled]="isDisabled">
      <button class="c-form-field__button"
              type="button"
              (click)="searchForResults()"
              [disabled]="isDisabled">
        <i class="m-fi  m-fi--search af af-magnifier"></i>
      </button>
    </div>
    <div [ngStyle]="{ width: optionsWidth+'px' }" style="position: absolute; z-index: 9999;" class="c-list-group"
         *ngIf="optionList?.length > 0 && searched === true"
         (appFormScroll)="scrolledToEndHandler()">
      <button *ngFor="let o of optionList; let i = index;" type="button" class="t-option c-list-group__item"
              [ngStyle]="o.Styles"
              [ngClass]="o.CssClasses"
              (click)="setOption(i)"> {{ o.Name }}
      </button>
    </div>
    <ul [ngStyle]="{ width: optionsWidth+'px' }" style="position: absolute; z-index: 9999;" class="c-list-group"
        *ngIf="optionList?.length === 0 && searched === true">
      <li class="list-group-item">{{ 'No se han encontrado resultados' | t }}</li>
    </ul>
  </div>
</div>
