import { LocationStrategy } from '@angular/common';
import { Injectable, isDevMode, Optional, SkipSelf } from '@angular/core';
import { get } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, Observer, of, ReplaySubject, retry, timer } from 'rxjs';
import { isArray, isNullOrUndefined, nameof } from 'app/shared/utils/typescript.utils';

import { environment } from '../../../environments/environment';
import { getInSafe } from '../../shared/utils/typescript.utils';
import { HttpClient } from '@angular/common/http';
import { IThemeInfo, KeyValuePair, ThemeDefinitionCompiled } from '../models/ETG_SABENTISpro_Application_Core_models';
import * as jquery from 'jquery';
import urlJoin from 'proper-url-join';
import { SessionstateService } from '../sessionstate/sessionstate.service';
import { catchError, filter, flatMap, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { BootstrapService } from '../../app-bootstrap.service';
import { ILayoutVariablesInterface } from '../layout-manager/interface/i-layout-variables.interface';
import { LayoutVariables } from '../layout-manager/constants/layout-variables.class';
import { AppConfigurationService } from '../../app.configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ClientThemeService extends DestroyableObjectTrait {

  public SiteIcon: string = './favicon.ico';
  public currentThemeBehavior: BehaviorSubject<ThemeDefinitionCompiled> = new BehaviorSubject<ThemeDefinitionCompiled>(null);
  public currentTheme: ReplaySubject<ThemeDefinitionCompiled> = new ReplaySubject<ThemeDefinitionCompiled>(1);
  public currentStyleSheetsChanged: ReplaySubject<KeyValuePair<string, string>[]> = new ReplaySubject<KeyValuePair<string, string>[]>(1);
  private defaultStyleSheets: KeyValuePair<string, string>[];

  /**
   * ClientThemeService class constructor.
   *
   * @param {HttpClient} http
   * @param {LocationStrategy} locationStrategy
   */
  constructor(
      private http: HttpClient,
      private locationStrategy: LocationStrategy,
      private sessionStateService: SessionstateService,
      private bootstrapService: BootstrapService,
      private configurationManager: AppConfigurationService,
      @Optional() @SkipSelf() parentModule?: ClientThemeService
  ) {
    super();

    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
          'ClientThemeService is already loaded. Import it in the AppModule only');
    }

    this.configurationManager.addBootstrapKey('current-theme');

    this.loadStyleSheets(this.getDefaultCss()).subscribe((loaded: HTMLLinkElement[]) => {
    });

    this.currentTheme
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(value =>
            this.currentThemeBehavior.next(value)
        );

    this.currentStyleSheetsChanged
        .pipe(
            takeUntil(this.componentDestroyed$),
            take(1)
        ).subscribe((layoutInfo) => {
      this.configurationManager.removeBootstrapKey('current-theme');
      this.configurationManager.triggerBootstrapDone(true);
    });

    // Este subject se ejecuta siempre en el constructor de sessionStateService al hacer el bootDataReady$.
    this.sessionStateService
        .$sessionDataPropertyChanged<IThemeInfo>([nameof<IThemeInfo>('CurrentThemeHash')])
        .pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((data: IThemeInfo) => {
              if (!isNullOrUndefined(data['CurrentThemeHash'])) {
                console.debug('Setting theme from Session');
                return of(data.Theme);
              }
              return this.bootstrapService.bootDataReady$()
                  .pipe(take(1),
                      map((responseData: any) => {
                        console.debug('Setting theme from boot data');
                        return responseData.result['current-theme'] as ThemeDefinitionCompiled;
                      }))
            }),
        )
        .subscribe((data) => {
          this.setCurrentTheme(data);
        });
  }

  setCurrentTheme(config: ThemeDefinitionCompiled): void {
    this.currentTheme.next(config);
    this.getAvailableCssThemes()
        .subscribe(availableCssThemes => {
          const match: KeyValuePair<string, string> = availableCssThemes
              .find(available => available.Key.indexOf(config.CssTheme) !== -1);
          if (!match) {
            throw new Error('The requested theme ' + config.CssTheme + ' is not available in the compiled themes :' + availableCssThemes.join(','));
          }
          const assemblyStyles: string[] = getInSafe(config, x => x.AssemblyStyles, []);
          const customStyles: string[] = getInSafe(config, x => x.UserStyles, []);
          const stylesheets: KeyValuePair<string, string>[] = this.getDefaultCss();
          stylesheets.push(match);

          assemblyStyles.forEach(x => {
            const assemblyStylesheet: KeyValuePair<string, string> = new KeyValuePair<string, string>();
            assemblyStylesheet.Key = `assembly_${x}`;
            assemblyStylesheet.Value = this.configurationManager.get('domain') + 'core-theme/assembly-style?style=' + encodeURIComponent(x);
            stylesheets.push(assemblyStylesheet);
          });

          customStyles.forEach(x => {
            const customStyleSheet: KeyValuePair<string, string> = new KeyValuePair<string, string>();
            customStyleSheet.Key = `custom_${x}`;
            customStyleSheet.Value = this.configurationManager.get('domain') + 'core-theme/user-style?style=' + encodeURIComponent(x);
            stylesheets.push(customStyleSheet)
          });

          this.loadStyleSheets(stylesheets).subscribe((loaded: HTMLLinkElement[]) => {
            this.currentStyleSheetsChanged.next(stylesheets);
            let stylesheetsToDisable: HTMLLinkElement[] = this.getAllHTMLLinkElement();

            stylesheetsToDisable = stylesheetsToDisable.filter(value => !loaded.includes(value));

            stylesheetsToDisable.map(x => {
              x.disabled = true;
            })
          });
        });
  }

  getCurrentLayoutVariables(): Observable<ILayoutVariablesInterface> {
    return this.currentTheme
        .pipe(
            takeUntil(this.componentDestroyed$),
            map(currentTheme => {
              return LayoutVariables[currentTheme.Layout.Id];
            }));
  }

  /**
   * Returns an array containing all the avalable themes css file paths.
   */
  getAvailableCssThemes(): Observable<KeyValuePair<string, string>[]> {
    let paths: KeyValuePair<string, string>[] = [];
    return new Observable((obs: Observer<KeyValuePair<string, string>[]>) => {
      if (isDevMode() && environment['angularConfig']) {
        paths = paths.concat(this.getDevPaths());
        obs.next(paths);
        obs.complete();
      } else {
        this.getProdPaths().subscribe(prodPaths => {
          paths = paths.concat(prodPaths);
          obs.next(paths);
          obs.complete();
        });
      }
    });
  }

  /**
   * @param url
   * @private
   */
  private prepareExternalUrl(url: string): string {
    // El baseHREF registrado en el router de angular NO nos sirve, porque el servicio
    // está trampeado. Necesitamos el HREF exclusivo de los recursos estáticos.
    // const baseHref: string = this.locationStrategy.getBaseHref()
    const baseHref: string = jquery('head base').attr('href');
    return urlJoin(baseHref, this.locationStrategy.prepareExternalUrl(url));
  }

  /**
   * Returns available themes paths for production environment.
   */
  private getProdPaths(): Observable<KeyValuePair<string, string>[]> {
    const path: string = this.prepareExternalUrl('themes.json');

    return this.http.get(path)
        .pipe(
            retry(2),
            catchError((error) => []),
            flatMap((response: Response) => {
              return new Observable((obs: Observer<KeyValuePair<string, string>[]>) => {
                const themes: any = [];
                const items: any = response;

                Object.getOwnPropertyNames(items).map(asset => {
                  themes.push({
                    Key: asset,
                    Value: this.prepareExternalUrl(isArray(items[asset]) ? items[asset][0] : items[asset])
                  } as KeyValuePair<string, string>);
                });

                obs.next(themes);
                obs.complete();
              });
            }));
  }

  /**
   * Returns available themes paths for development environment.
   */
  private getDevPaths(): KeyValuePair<string, string>[] {
    const themes: KeyValuePair<string, string>[] = [];
    // console.log(JSON.stringify(environment));
    const globalStyles: any[] = get(environment, 'angularConfig.projects.sabentisapp.architect.build.options.styles', []);
    const lazyStyles: void[] = globalStyles
        .filter(style => (typeof style === 'object') && style['input'])
        .map(style => {
          let src: any = style.input;
          src = src.substr(src.lastIndexOf('/') + 1).replace('scss', 'css');
          themes.push({
            Key: src,
            Value: this.prepareExternalUrl(src)
          } as KeyValuePair<string, string>);
        });


    return themes;
  }

  removeLinkStyleSheetsFromHead(): void {
    const links: HTMLLinkElement[] = Array.from(document.head.getElementsByTagName('link'))
        .map(x => x as HTMLLinkElement)
        .filter(x => x.rel === 'stylesheet' && x.type === 'text/css');

    links.forEach(l => document.head.removeChild(l));
  }

  /**
   * Given themes configuration objects, web them into the dom
   */
  loadStyleSheets(themes: KeyValuePair<string, string>[]): Observable<HTMLLinkElement[]> {
    return forkJoin(
        themes.map(theme => this.loadStyleSheet(theme))
    );
  }

  loadStyleSheet(theme: KeyValuePair<string, string>): Observable<HTMLLinkElement> {
    return new Observable((obs: Observer<HTMLLinkElement>) => {
      const head: HTMLHeadElement = document.head;

      // Check if a link element with the same href already exists in the document head
      const existingLink: HTMLLinkElement = this.getHTMLLinkElementByHref(theme.Value);
      if (existingLink) {
        if (existingLink.disabled) {
          existingLink.disabled = false;
        }

        console.debug(`CSS ${theme.Key} is already loaded.`);
        obs.next(existingLink);
        obs.complete();
        return;
      }

      const link: HTMLLinkElement = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = theme.Value;
      link.onerror = (event: ErrorEvent): void => {
        console.debug('Error while loading CSS: ' + theme.Key);
        obs.next(link);
        obs.complete();
      }
      link.onload = (): void => {
        console.debug(`CSS ${theme.Key} loaded.`);
        obs.next(link);
        obs.complete();
      }
      head.appendChild(link);
    });
  }

  private getHTMLLinkElementByHref(value: string): HTMLLinkElement {
    return document.head.querySelector(`link[rel="stylesheet"][href="${value}"]`);
  }

  private getAllHTMLLinkElement(): HTMLLinkElement[] {
    const linkElements: NodeListOf<HTMLLinkElement> = document.head.querySelectorAll('link[rel="stylesheet"]');
    const result: HTMLLinkElement[] = [];
    linkElements.forEach(x => result.push(x));
    return result;
  }

  /**
   * This handler works as a functional onload handler to replace `link.onload`.
   *
   * Note: this method **IS NOT** used as not all browsers supports
   * CSSStyleSheet objects. But it's safer than `object.onload` method as
   * validates that new css rules has been loaded.
   *
   * @param {Observer<void>} obs
   */
  private linkLoadListener(obs: Observer<void>, theme: KeyValuePair<string, string>): void {
    let styleSheet: CSSStyleSheet = null;

    // Poll every ten milliseconds to validate if the new stylesheet was added.
    timer(10, 10)
        .pipe(filter(i => {
              const documentSheets: StyleSheetList = document.styleSheets;
              const sheets: StyleSheet[] = [];

              for (let sheet: number = 0; sheet < documentSheets.length; sheet++) {
                sheets.push(documentSheets[sheet] as StyleSheet);
              }

              styleSheet = sheets.find(s => !isNullOrUndefined(s.href) && s.href.includes(theme.Value)) as CSSStyleSheet;

              return !isNullOrUndefined(styleSheet);
            }),
            take(1),
            flatMap(() => {
              return timer(10, 10);
            }),
            filter(() => {
              const rules: number = getInSafe(styleSheet, s => s.cssRules.length, null);
              return !isNullOrUndefined(rules) && rules > 0;
            }),
            take(1))
        .subscribe(() => {
          obs.next(null);
          obs.complete();
        });
  }

  private getDefaultCss(): KeyValuePair<string, string>[] {
    if (this.defaultStyleSheets) {
      return [...this.defaultStyleSheets];
    }

    const stylesheets: KeyValuePair<string, string>[] = [];

    stylesheets.push({
      Key: 'materialicons',
      Value: 'https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined'
    } as KeyValuePair<string, string>);
    stylesheets.push({
      Key: 'robotofonts',
      Value: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500'
    } as KeyValuePair<string, string>);
    stylesheets.push({
      Key: 'styles',
      Value: 'styles.css'
    } as KeyValuePair<string, string>);

    this.defaultStyleSheets = stylesheets;

    return [...this.defaultStyleSheets];
  }
}
