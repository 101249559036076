<div [@dialog] *ngIf="visible" class="dialog">
  <div class="dialog__inner">
    <button type="button" class="close" aria-label="Close" *ngIf="closable" (click)="close()" >
      <span aria-hidden="true">&times;</span>
    </button>
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="visible && dialogInfo" class="overlay" (click)="close()"></div>
<div *ngIf="visible && !dialogInfo" class="overlay" ></div>

