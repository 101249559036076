import { Directive, EventEmitter, HostListener, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../core/changedetector/changedetectoreference';
import { asIterableObject, getInSafe } from '../../../../../../../shared/utils/typescript.utils';
import { parseKeyItemToArray } from '../../../../../../../shared/utils/prime.utils';
import { NGXChartComponent } from './ngx-chart.class';
import {
  AreaChart,
  BubbleChart,
  ILegendChartDisplayOption,
  ISerie,
  LineChart,
  NGXChartDisplayOptions,
  SerieValue
} from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class NGXTrendSeriesChartComponent<TChart extends LineChart | AreaChart | BubbleChart, TDisplayOptions extends NGXChartDisplayOptions>
    extends NGXChartComponent<TChart, TDisplayOptions> implements OnInit {

  /**
   * Data to display.
   */
  data: { name: string, series?: { name: string, x: any, y: any, value: any }[] }[];

  /**
   * Color Scheme
   */
  colorScheme: { domain: string[] }

  detectChangesDebouncer: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('mousemove')
  onMouseEnter(): void {
    this.detectChangesDebouncer.emit();
  }

  /**
   * VerticalBarChartComponent class constructor.
   */
  protected constructor(protected cdReference: ChangedetectorReference) {
    super(cdReference);
    this.data = [];
  }

  /**
   * Component initialization lifecycle method.
   */
  ngOnInit(): void {
    this.chart.update();
  }

  getTooltipText(item: any): string {
    if (this.displayOptions && this.displayOptions.TooltipText && item) {
      return this.displayOptions.TooltipText.replace('{value}', item.value);
    }
    return item.map(o => `${o.name}: ${o.value}`).join('; ');
  }

  /**
   * Initialization method.
   */
  public initializeChart(): void {
    this.colorScheme = getInSafe(this.displayOptions, x => {
      return {domain: x.ColorScheme}
    }, {domain: []});

    const labelSerie: ISerie = (this.getDataSeries()).Series[this.currentChart.LabelSeriesId];
    const labels: SerieValue[] = parseKeyItemToArray<SerieValue>(labelSerie.Values);

    const valueSeries: ISerie[] = parseKeyItemToArray<ISerie>(this.getDataSeries().Series)
        .filter((v: ISerie) => v['key'] !== this.currentChart.LabelSeriesId);

    this.data = [];

    // labelSerie => Colombia, España, ...
    // Valores 2020: => 10, 20
    // Valores 2021: => 40, 50

    asIterableObject(labelSerie.Values).forEach(label => {
      this.data.push({
        name: label.Value, // Colombia, España,
        series: valueSeries.map((value: ISerie) => ({
          name: value.Name,
          x: value.Values[label.Id].Value,
          y: value.Values[label.Id].ValueY,
          value: value.Values[label.Id].Value
        }))
      })
    });
    this.numSeries = valueSeries.length;
    this.view = this.GetViewValueOnInitializeChart();
    this.chart.update();
    this.cdReference.changeDetector.detectChanges();

    this.detectChangesDebouncer
        .pipe(
            debounceTime(10),
            takeUntil(this.componentDestroyed$),
        )
        .subscribe((i) => this.cdReference.changeDetector.detectChanges());
  }

  onNgxChartResized(event: any): void {
    this.view = this.GetViewValueOnResize();
    this.cdReference.changeDetectorParent.detectChanges();
  }

  onNgxChartResizedWithDirective(): void {
    this.view = this.GetViewValueOnResize();
    this.cdReference.changeDetectorParent.detectChanges();
  }

  /**
   * Custom responsive management
   */
  public getLegendDisplayOptions(): ILegendChartDisplayOption {
    if (!this.displayOptions) {
      return null;
    }
    const value: NGXChartDisplayOptions = Object.assign(new NGXChartDisplayOptions(), this.displayOptions);
    if (window.innerWidth < 600) {
      value.LegendPosition = 'bottom';
    }
    return value;
  }

  public abstract GetViewValueOnInitializeChart(): [number, number];

  public abstract GetViewValueOnResize(): [number, number];
}
