import { ChangeDetectorRef, EventEmitter, Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, timer } from 'rxjs';
import { filter, map, shareReplay, take, takeUntil, takeWhile } from 'rxjs/operators';
import { CommandService } from '../../core/commands/command.service';
import { EventsService } from '../../core/events/events.service';
import {
  CoreBatchActionTaskCommand,
  CoreBatchScheduleTaskCommand,
  CoreFileDownload,
  CoreMenuNavigateToAppendedController,
  CoreMenuNavigateToControllerCommand,
  CoreOpenFormInModalCommand,
  ICommand,
  IVboOperation,
  IViewField,
  IViewFilterManager,
  IViewMode,
  IViewModeColumnBased,
  IViewModeField,
  IViewModeUserConfigurationColumn,
  IViewModeUserConfigurationColumnsBased,
  IViewProcessor,
  IViewsUserConfiguration,
  QueryExecutableCountResultType,
  SingleItemOperationTypeEnum,
  UserConfigurationOrigin,
  ViewConfiguration,
  ViewExecutableCountResult,
  ViewFieldTypeEnum,
  ViewFilterInstanceSimple,
  ViewFilterManagerSimple,
  ViewModeColumnBasedFieldSort,
  ViewModeTypeEnum,
  ViewModeUserConfigurationColumnBased,
  ViewRefreshInterval,
  ViewResult,
  ViewResultRow,
  ViewsExecutionRequestOptions,
  ViewsExecutionResponse,
  ViewsFieldVbo,
  ViewsFieldVboSelectionMode,
  ViewSortDirection,
  ViewsPagerUserConfigurationSimple,
  ViewsPluginRequest,
  ViewsPredefinedFilter,
  ViewsPredefinedFilterSet,
  ViewsPredefinedFilterSetUserConfig,
  ViewsSingleItemOperationForm,
  ViewsVboDefaultStartupParameter,
  ViewsVboSelectedItem,
  ViewsVboUserConfiguration,
  ViewUserConfiguration,
  WebServiceResponse,
  WebServiceResponseTyped
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { LIST_EVENT_TYPE } from '../../googleanalytics/googleanalytics.module';
import { DecoupledModalBridgeService } from '../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../decoupled-modal/models/decoupled-modal-bridge.interface';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { PrimeUtils } from '../utils/prime.utils';
import {
  asIterableObject,
  backendTypeMatch,
  getInSafe,
  isNullOrUndefined,
  isNullOrWhitespace,
  isString,
  JsonClone,
  jsonEqual,
  JsonPathEvaluate,
  JsonPathTryEvaluate,
  UtilsTypescript
} from '../utils/typescript.utils';
import { LocalActionEventData2 } from './events/localaction.eventdata';
import { SingleItemOperationEventData2 } from './events/singleitemoperation.eventdata';
import { SioFormOpenedEventData } from './events/sioformopened.eventdata';
import { VboOperations, VboToggleEvent } from './events/vboitemtoogle.eventdata';
import { ViewsinitializedEventdata } from './events/viewsinitialized.eventdata';
import { ViewModeUtils } from './grid/viewmode.utils';
import { ViewsuserconfigchangedAction, ViewsuserconfigchangedEventdata } from './viewsuserconfigchanged.eventdata';
import { BrowserTabService } from '../../core/browser-tab/browser-tab.service';
import { Guid } from 'guid-typescript';
import { ViewsService } from '../../core/services/ETG_SABENTISpro_Application_Core_views.service';
import { CORE_QUEUE } from '../../core/models/ETG_SABENTISpro_Models_models';
import { ActiveUserConfigurationClass } from './activeUserConfigurationClass';

/**
 * Procesor identifier keys
 */
export enum ViewProcessorKeys {
  ViewsPredefinedFilterSet = 'view_predefined_filterset'
}

/**
 * El componente de listados es lo bastante complejo como para necesitar un
 * orquestador interno (en este caso el ListComponentService) ya que esto nos
 * permite desacoplar elementos y funciones dentro del propio componente
 * de listados.
 */
@Injectable()
export class ListComponent2Service
    extends DestroyableObjectTrait implements OnDestroy {

  /**
   * Usamos esto cuando queremos cambiar la configuración de usuar, e indicar
   */
  static userConfigurationChangeNotAffectingCountTag: string = 'user-config-no-count-changed';

  /**
   * The plugin ID for this instance
   */
  currentPluginRequest: ViewsPluginRequest;

  /**
   * View execution result
   */
  data: ViewResult;

  /**
   * Emit and event when rows selection change
   */
  vboSelectedRowsChanged = new EventEmitter<{ [id: string]: ViewsVboSelectedItem }>();

  /**
   * The vbo field, if any
   */
  vboField: ViewsFieldVbo;

  /**
   * Toogle vbo items select/remove
   */
  onVboItemToggle: EventEmitter<VboToggleEvent> = new EventEmitter<VboToggleEvent>();

  /**
   * Sio form opened
   */
  onSioFormOpened: EventEmitter<SioFormOpenedEventData> = new EventEmitter<SioFormOpenedEventData>();

  /**
   * This event is triggered when a local action has been called by the user.
   * Local actions are completely unrelated actions to the list itself,
   * and should be moved, when navigation supports it, to the navigation itself.
   */
  onLocalAction: EventEmitter<LocalActionEventData2> =
      new EventEmitter<LocalActionEventData2>();

  /**
   * Triggered when a Single Item Operation happens
   */
  onSingleItemOperation: EventEmitter<SingleItemOperationEventData2> =
      new EventEmitter<SingleItemOperationEventData2>();

  /**
   * Triggered when a Single Item Operation happens
   */
  onVboOperationCompleted: EventEmitter<{ operation: IVboOperation, result: any }> =
      new EventEmitter<{
        operation: IVboOperation,
        result: { id: string, message: any[], responseData: any } | CORE_QUEUE
      }>();

  /**
   * Temporalmente mientras se recomponentiza... lanzamos este evento en el
   * manager interno para avisar al componente de listado de nivel superior
   * que debe refrescar el listado...
   */
  userConfigurationChanged: EventEmitter<ViewsuserconfigchangedEventdata> = new EventEmitter<ViewsuserconfigchangedEventdata>();

  /**
   * Emits the active user configuration. La configuración de usuario activa
   * se emite cuando ya se ha resuelto la petición a backend y guardados los
   * datos resultado de un cambio de configuración. Emits null during a
   * component reload.
   */
  activeUserConfiguration: ReplaySubject<ActiveUserConfigurationClass> = new ReplaySubject<ActiveUserConfigurationClass>(1);

  /**
   * Triggered when the View is initialized because the ViewConfiguration
   * and ViewUserConfiguration are setted the first time.
   */
  viewIntialized: ReplaySubject<ViewsinitializedEventdata> = new ReplaySubject<ViewsinitializedEventdata>(1);

  /**
   * Datos de la vita cargados
   */
  viewDataLoaded: EventEmitter<ViewResult> = new EventEmitter<ViewResult>();

  /**
   * Como viewDataLoaded, pero emite siempre el último valor (replay=1)
   */
  viewDataLoadedWithReplay: Observable<ViewResult>;

  /**
   * This subject emits a true-ish value when the view is making a request to
   * backend.
   */
  requestInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * This subject emits an event when the component is being destroyed.
   * Reference to the main component's change detector
   */
  cdRef: ChangeDetectorRef;

  /**
   * The list configuration.
   */
  private configuration: ViewConfiguration;

  /**
   * The user configuration
   */
  private userConfiguration: ViewUserConfiguration;

  /**
   * VBO user configuration.
   *
   * TODO: Esto no debería ser público.
   */
  public vboUserConfiguration: ViewsVboUserConfiguration;

  /**
   * If we have an empty result-set
   */
  public emptyResultSetRender: boolean;

  /**
   * Identificador único para esta instancia de listado
   */
  public uniqueId: string;

  /**
   * Get an instance of ListComponentService
   */
  constructor(
      private viewsService: ViewsService,
      private eventService: EventsService,
      private dmbs: DecoupledModalBridgeService,
      private commandService: CommandService,
      private zone: NgZone,
      private browserTabService: BrowserTabService
  ) {
    super();

    this.uniqueId = Guid.create().toString();

    this.handleLocalActions();

    this.onSingleItemOperation
        .pipe(
            takeUntil(this.componentDestroyed$)
        )
        .subscribe(this.handlerSingleItemOperations.bind(this));

    this.viewDataLoadedWithReplay = new ReplaySubject<ViewResult>(1);
    this.viewDataLoaded.pipe(
        takeUntil(this.componentDestroyed$)
    )
        .subscribe(
            (i) => (this.viewDataLoadedWithReplay as ReplaySubject<ViewResult>).next(i)
        )
    ;
  }

  /**
   * Triggers a change detection cycle.
   *
   * In case that this service is used without an UI. Then this method will
   * skip the detection cycle.
   */
  detectChanges(): void {
    if (isNullOrUndefined(this.cdRef)) {
      console.error('Calling detect changes on Views when this.cdRef is NULL.');
      return;
    }

    this.cdRef.detectChanges();
  }

  /**
   * Handle local actions events to other services.
   */
  handleLocalActions(): void {
    this.onLocalAction
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((lai: LocalActionEventData2) => {
          if (!isNullOrUndefined(lai.data.OnClickCommands)) {
            const commands: Array<ICommand> = asIterableObject(lai.data.OnClickCommands);
            commands.forEach(command => {
              if (backendTypeMatch(CoreOpenFormInModalCommand.$type, command)) {
                const castedCommand: CoreOpenFormInModalCommand = command as CoreOpenFormInModalCommand;
                if (this.vboUserConfiguration) {
                  castedCommand.Arguments['UserConfiguration'] = this.userConfiguration;
                  castedCommand.Arguments['PluginRequest'] = this.currentPluginRequest;
                }
              }
            });
            this.commandService.executeCommandChain(commands)
            return;
          }

          this.eventService
              .emitEvent({
                action: LIST_EVENT_TYPE,
                params: {
                  event: 'la',
                  idlist: this.currentPluginRequest.Id,
                  operation: lai.action
                }
              });
        });
  }

  /**
   * Handler para los eventos de single item operation
   *
   * @param sio
   */
  handlerSingleItemOperations(sio: SingleItemOperationEventData2): void {
    switch (sio.operation.Type) {
      case SingleItemOperationTypeEnum.Form:
        const sioForm: ViewsSingleItemOperationForm = sio.operation as ViewsSingleItemOperationForm;
        const formArgs: { [id: string]: any } = sioForm.FormArguments || {};
        // Mapeamos la información de fila a argumentos de formulario
        if (sioForm.FormArgumentsFromViewColumns) {
          for (const key of Object.keys(sioForm.FormArgumentsFromViewColumns)) {
            const valueSelector: string = sioForm.FormArgumentsFromViewColumns[key];
            if (sio.row.Columns[valueSelector]) {
              formArgs[key] = sio.row.Columns[valueSelector].RawValue;
            } else {
              const queryResult: string[] = JsonPathEvaluate(valueSelector, sio.row.Columns);
              if (queryResult && queryResult.length) {
                formArgs[key] = queryResult[0];
              }
            }
          }
        }

        // Mapeamos los metadatos de fila a argumentos de formularios
        if (sioForm.FormArgumentsFromViewMetadata) {
          for (const key of Object.keys(sioForm.FormArgumentsFromViewMetadata)) {
            const valueSelector: string = sioForm.FormArgumentsFromViewMetadata[key];
            const queryResult: string[] = JsonPathEvaluate(valueSelector, sio.row.Metadata);
            if (queryResult && queryResult.length) {
              formArgs[key] = queryResult[0];
            }
          }
        }

        const instanceRef: ModalReference<unknown> = this.dmbs.showForm(
            sioForm.FormId,
            {...(sioForm.ModalSettings || {})},
            formArgs
        );

        instanceRef.close
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              // TODO: En todas la modales puede pasar dos cosas: que se ejecute un flujo, o que se descarte, deberíamos revisar si se descarta
              // para evitar el refresh, para lo que la modal debería devolvernos el SUBMIT ACTION
              this.refresh();
            });

        // Lanzamos un evento por si alguien desde el exterior quiere hacer algo (como colgarse del evento
        // de cierre de la modal)
        const sioFormOpenedEventData: SioFormOpenedEventData = new SioFormOpenedEventData();
        sioFormOpenedEventData.sio = sioForm;
        sioFormOpenedEventData.instanceRef = instanceRef;
        this.onSioFormOpened.next(sioFormOpenedEventData);

        break;
      default:
        if (!isNullOrUndefined(sio.operation.OnClickCommands)) {
          const commands: Array<ICommand> = asIterableObject(JsonClone(sio.operation.OnClickCommands));
          commands.forEach(command => {
            if (backendTypeMatch(CoreMenuNavigateToControllerCommand.$type, command)) {
              const castedCommand: CoreMenuNavigateToControllerCommand = command as CoreMenuNavigateToControllerCommand;
              castedCommand.Arguments = this.processArguments(castedCommand.Arguments, sio);
              castedCommand.QueryString = this.processArguments(castedCommand.QueryString, sio);
            } else if (backendTypeMatch(CoreMenuNavigateToAppendedController.$type, command)) {
              const castedCommand: CoreMenuNavigateToAppendedController = command as CoreMenuNavigateToAppendedController;
              castedCommand.Arguments = this.processArguments(castedCommand.Arguments, sio);
            } else if (backendTypeMatch(CoreFileDownload.$type, command)) {
              const castedCommand: CoreFileDownload = command as CoreFileDownload;
              const value: any = JsonPathTryEvaluate(castedCommand.Field, sio.row.Columns)[0];
              castedCommand.Url = !isNullOrUndefined(value) || value === [] ? value : castedCommand.Field;
            } else if (backendTypeMatch(CoreBatchActionTaskCommand.$type, command)) {
              const castedCommand: CoreBatchActionTaskCommand = command as CoreBatchActionTaskCommand;
              castedCommand.QueueId = JsonPathEvaluate<string>(castedCommand.QueueId, sio.row.Metadata)[0];
            } else if (backendTypeMatch(CoreBatchScheduleTaskCommand.$type, command)) {
              const castedCommand: CoreBatchScheduleTaskCommand = command as CoreBatchScheduleTaskCommand;
              castedCommand.PluginId = JsonPathTryEvaluate<string>(castedCommand.PluginId, sio.row.Metadata)[0] ?? castedCommand.PluginId;
              castedCommand.Arguments = this.processArguments(castedCommand.Arguments, sio);
            } else if (backendTypeMatch(CoreOpenFormInModalCommand.$type, command)) {
              const castedCommand: CoreOpenFormInModalCommand = command as CoreOpenFormInModalCommand;
              castedCommand.Arguments = this.processArguments(castedCommand.Arguments, sio);
            }
          });
          this.commandService.executeCommandChain(commands);
        }
        break;
    }

    /**
     * Emitimos el evento al event emiter central
     */
    this.eventService.emitEvent({
      action: LIST_EVENT_TYPE,
      params: {
        'event': 'sio',
        'idlist': this.currentPluginRequest.Id,
        'operation': sio.operation.Id
      }
    });
  }

  /***
   * Process the arguments of a command using JsonPathEvaluate if necessary
   * @param args
   * @param sio
   */
  processArguments(args: any, sio: SingleItemOperationEventData2): any {
    if (isNullOrUndefined(args)) {
      return null;
    }
    const result: any = {};
    Object.keys(args)
        .forEach(arg => {
          let value: any = null;
          try {
            value = JsonPathEvaluate(args[arg], sio.row.Metadata)[0];
          } catch (e) {
            value = null;
          }
          const valueIsValid: boolean = !isNullOrUndefined(value) || value === [];
          if (!valueIsValid && isString(args[arg]) && args[arg][0] === '$') {
            console.error('No se ha podido mapear el argumento dinámico de comando: ' + args[arg]);
            console.debug(JSON.stringify(sio.row.Metadata));
          }
          result[arg] = valueIsValid ? value : args[arg];
        });
    return result;
  }

  /**
   +   * Obtiene la configuración de filtros ViewFilterManagerSimple
   +   */
  getFilterManagerSimple(): ViewFilterManagerSimple {
    if (!this.configuration.FilterManagers) {
      return null;
    }
    const type: string = (new ViewFilterManagerSimple()).$type;
    for (const key of Object.keys(this.configuration.FilterManagers)) {
      const viewFilter: IViewFilterManager = this.configuration.FilterManagers[key];
      if (backendTypeMatch(type, viewFilter)) {
        return viewFilter as ViewFilterManagerSimple;
      }
    }
    return null;
  }

  /**
   * Requests a `ViewResult` object for a `ViewsPluginRequest` using the
   * `ViewsService`.
   *
   * @param {boolean} updateConfiguration Defines if the view configuration must
   *  be updated after the request.
   *
   * @param {ViewsuserconfigchangedEventdata} options Indicates to the service
   *  if custom options must be used.
   */
  loadList(
      pluginRequest: ViewsPluginRequest,
      updateConfiguration: boolean,
      options: ViewsuserconfigchangedEventdata = null): Observable<ViewResult> {

    this.requestInProgress$.next(true);

    // Triggers a change detection cycle.
    this.detectChanges();

    if (!jsonEqual(this.currentPluginRequest, pluginRequest) && pluginRequest?.Id) {
      this.eventService.emitEvent({
        action: LIST_EVENT_TYPE,
        params: {
          'event': 'load',
          'idlist': pluginRequest?.Id
        }
      });
    }

    // Updates the last plugin request object.
    this.currentPluginRequest = pluginRequest;

    // Finally request to the `ViewsService` for the view info.
    const requestoptions: ViewsExecutionRequestOptions = new ViewsExecutionRequestOptions();
    requestoptions.LoadViewConfiguration = updateConfiguration;

    // Separamos la configuración del servicio de la que consultamos ya que en
    // el caso de que borremos la configuración de usuario actual y falle, no perdamos nada.
    let userConfigurationRequest: ViewUserConfiguration = null;

    // If set, then a custom user configuration must be used.
    if (getInSafe(options, (i) => i.userConfiguration, null) !== null) {
      this.userConfiguration = options.userConfiguration;
    }
    if (options?.refreshAction === ViewsuserconfigchangedAction.DropCurrentUserAndLoad) {
      userConfigurationRequest = null;
    } else {
      userConfigurationRequest = this.userConfiguration;
    }

    return this.viewsService
        .postLoad(
            this.currentPluginRequest,
            requestoptions,
            this.currentPluginRequest.Id,
            userConfigurationRequest,
            {showSpinner: false})
        .pipe(
            takeUntil(this.componentDestroyed$),
            take(1),
            map((response) => {

              const executionResponse: ViewsExecutionResponse =
                  Object.assign(new ViewsExecutionResponse(), response.result);

              if (response.hasOwnProperty('error') && !isNullOrUndefined(response.error)) {
                this.requestInProgress$.next(false);
                throw new Error(JSON.stringify(response));
              }

              // If the configuration was set to be updated, then process it and
              // emit an update event.
              if (updateConfiguration) {
                const propagation: boolean = !isNullOrUndefined(this.configuration)
                this.setConfiguration(executionResponse.ViewConfiguration);

                // Le ponemos un ignore para que no vuelva a lanzar la carga...
                this.setUserConfiguration(getInSafe(executionResponse,
                    (i) => i.UserConfiguration, this.userConfiguration), propagation, ViewsuserconfigchangedAction.Ignore);

                this.viewIntialized.next(
                    new ViewsinitializedEventdata(
                        this.configuration, this.userConfiguration));
              } else if (executionResponse.ViewUserConfigurationOrigin === UserConfigurationOrigin.DEFAULT) {
                this.setUserConfiguration(getInSafe(executionResponse,
                    (i) => i.UserConfiguration, this.userConfiguration), false, ViewsuserconfigchangedAction.Ignore);
              }

              const previousData: ViewResult = this.data;

              // If set in the options, then the latest result must be appended, to
              // the list. Else, then the result must replace the latest data.
              if (getInSafe(options,
                  (i) => i.refreshAction, null) === ViewsuserconfigchangedAction.Append) {
                const newData: ViewResultRow[] = this.data
                    .Results
                    .concat((executionResponse.Result as ViewResult).Results);

                this.data = (executionResponse.Result as ViewResult);
                this.data.Results = newData;
              } else {
                this.data = (response.result as ViewsExecutionResponse).Result as ViewResult;
              }

              // Esto es una optimización para que si tenemos un count en el data anterior,
              // y el cambio de configuración de usuario no afecta
              // al count total (como un cambio de página), tomamos el count que ya hubieramos ejecutado anteriormente
              if (
                  (!this.data.ResultCount ||
                      this.data.ResultCount.CountType === QueryExecutableCountResultType.None) &&
                  previousData &&
                  previousData.ResultCount &&
                  previousData.ResultCount.CountType === QueryExecutableCountResultType.Exact &&
                  options &&
                  options.tags &&
                  options.tags.includes(ListComponent2Service.userConfigurationChangeNotAffectingCountTag)) {
                this.data.ResultCount = previousData.ResultCount;
              }

              const vboData: ViewsVboUserConfiguration = (this.userConfiguration.UserConfigurations.vbo as ViewsVboUserConfiguration);

              if (!isNullOrUndefined(vboData) &&
                  options &&
                  options.tags &&
                  !options.tags.includes(ListComponent2Service.userConfigurationChangeNotAffectingCountTag)) {
                const event: VboToggleEvent = new VboToggleEvent()
                event.operation = VboOperations.UNSELECT_ALL;
                this.vboToggleItemHandlerWithoutRowsChangedEvent(event);
                this.onVboItemToggle.next(event);
              }

              // Esto es para calcular el renderizado de consulta con universo de datos vacío
              if (!UtilsTypescript.isNullOrUndefined(this.data.ResultCount.EmptyResultSet) && this.data.ResultCount.EmptyResultSet) {
                this.emptyResultSetRender = !!this.getConfiguration().OnEmptyResultSetRender;
                this.commandService.executeCommandChain(asIterableObject((this.getConfiguration().OnEmptyResultSetCommandsOnLoad))).then();
              } else {
                this.emptyResultSetRender = false;
              }

              this.requestInProgress$.next(false);

              this.viewDataLoaded.next(this.data);

              // Establecemos la configuración de usuario activa, sabienod que ya hay datos para soportarla
              this.activeUserConfiguration.next(new ActiveUserConfigurationClass(this.userConfiguration, executionResponse.ViewStorageIsAllowed, executionResponse.ViewUserConfigurationOrigin));

              return this.data;
            })
        );
  }

  /**
   * Obtiene la cuenta total de resultados del listado actual, ya sea de manera síncrona o asíncrona,
   * según la disponibilidad de la información.
   *
   * En caso de obtenerla de manera síncrona, la almacena en la cuenta total de resultados
   * para que se pueda obtener siempre que no se actualicen los resultados actuales.
   *
   */
  getTotalItemCount(): Observable<ViewExecutableCountResult> {
    if (this.data && this.data.ResultCount && !isNullOrUndefined(this.data.ResultCount.Count)) {
      return of(this.data.ResultCount);
    }
    const totalResultCountObservableKey: string = 'totalResultCountObservable';
    // Esto es un truco para evitar hacer múltiples llamadas a backend para obtener el count
    if (this.data[totalResultCountObservableKey]) {
      return this.data[totalResultCountObservableKey] as Observable<ViewExecutableCountResult>;
    }
    // Guardamos una referencia al this.data, para poder almacenar el resultado de manera persistente, se es manera
    // nos aseguramos de que si se refrescan los resultados, la cuenta total deja de ser válida ya que se reemplaza
    // el objeto this.data entero
    const currentData: ViewResult = this.data;
    const result: Observable<ViewExecutableCountResult> = this.viewsService
        .postCount(this.currentPluginRequest, this.currentPluginRequest.Id, this.userConfiguration, {showSpinner: false})
        .pipe(
            take(1),
            takeUntil(this.componentDestroyed$),
            map((response: WebServiceResponseTyped<ViewExecutableCountResult>) => {
              currentData.ResultCount = response.result;
              return response.result;
            }),
            shareReplay(1)
        );
    this.data[totalResultCountObservableKey] = result;
    return result;
  }

  /**
   * Adds a new filter and emits a configuration changed event.
   *
   * @param {ViewFilterInstanceSimple} f Filter to be added.
   */
  addFilter(f: ViewFilterInstanceSimple): void {
    if (isNullOrUndefined(this.userConfiguration.Filters)) {
      this.userConfiguration.Filters = {};
    }
    this.userConfiguration.Filters[f.Id] = f;
    this.setPaginatorFirstPage();
    this.userConfigurationChanged.emit(
        new ViewsuserconfigchangedEventdata(this.userConfiguration));
  }

  /**
   * Returns the view configuration setted in the service.
   *
   * @returns {ViewConfiguration}
   */
  getConfiguration(): ViewConfiguration {
    return this.configuration;
  }

  /**
   * Obtener el viewmode actual
   */
  getCurrentViewMode(): IViewMode {
    if (!this.userConfiguration) {
      console.error('Trying to retrieve current view mode when userConfiguration is not yet loaded');
    }
    const viewModeKey: string = (this.userConfiguration && this.userConfiguration.CurrentViewMode && this.userConfiguration.CurrentViewMode.Id) ? this.userConfiguration.CurrentViewMode.Id : this.configuration.DefaultViewMode;
    const result: IViewMode = this.configuration.AvailableViewModes[viewModeKey];
    if (!result) {
      console.debug('Could not find view mode configuration for view mode: ' + viewModeKey);
    }
    return result;
  }

  /**
   * Obtiene la configuración de la columna a partir de la columna de la user configuration
   *
   * @param viewModeUserConfigurationColumn
   */
  getViewModeColumnFromViewModeUserColumn(viewModeUserConfigurationColumn: IViewModeUserConfigurationColumn): IViewModeField {
    const currentViewMode: IViewModeColumnBased = this.getCurrentViewMode() as IViewModeColumnBased;
    return currentViewMode.Columns[viewModeUserConfigurationColumn.Field];
  }

  /**
   * Get the current sort configuration.
   *
   * @constructor
   */
  getCurrentSort(field: string): ViewSortDirection {
    if (isNullOrUndefined(this.userConfiguration) || isNullOrUndefined(this.userConfiguration.CurrentViewMode)) {
      return ViewSortDirection.None;
    }

    const sfs: ViewModeColumnBasedFieldSort = (this.userConfiguration.CurrentViewMode as ViewModeUserConfigurationColumnBased).SingleFieldSort;
    if (isNullOrUndefined(sfs)) {
      return ViewSortDirection.None;
    }

    return (sfs.Field === field) ? sfs.Direction : ViewSortDirection.None;
  }

  /**
   * Given a base sort direction/method. This method returns the `string`
   * corresponding to the next sorting direction/method.
   *
   * @param {string} direction
   * @returns {string}
   */
  nextDirection(direction: ViewSortDirection): ViewSortDirection {
    let resultDirection: ViewSortDirection;

    switch (direction) {
      case ViewSortDirection.Ascending:
        resultDirection = ViewSortDirection.Descending;
        break;

      default:
        resultDirection = ViewSortDirection.Ascending;
        break;
    }

    return resultDirection;
  }

  /**
   * Removes an existing filter from the `userConfiguration` property.
   *
   * After deletion this method emits a userConfigurationChanged event to trigger
   * secuential updates.
   *
   * @param {string} filterKey
   */
  removeFilter(filterKey: string): void {
    delete this.userConfiguration.Filters[filterKey];

    this.userConfigurationChanged
        .emit(new ViewsuserconfigchangedEventdata(this.userConfiguration));
  }

  /**
   * Set the current `ViewConfiguration`.
   *
   * @param {ViewConfiguration} configuration
   */
  setConfiguration(configuration: ViewConfiguration): void {
    this.configuration = configuration;
    const intervalProcessor: ViewRefreshInterval = this.getViewProcessor<ViewRefreshInterval>(ViewRefreshInterval);
    if (intervalProcessor) {
      this.zone.runOutsideAngular(() => {
        timer(intervalProcessor.Interval, intervalProcessor.Interval)
            .pipe(
                takeUntil(this.componentDestroyed$),
                takeWhile(x => !isNullOrUndefined(getInSafe(this.configuration, config => intervalProcessor.Interval, null))),
                filter(x =>
                    // Solo pedimos info de las notificaciones si la pestaña está activa.
                    // Aunque es una condición sincrona, no afecta a la coprobación y debe estar aquí ya que es una condición del timer.
                    this.browserTabService.getCurrentIsVisible())
            )
            .subscribe(() => this.zone.run(() => {
              if (this.requestInProgress$.getValue() === false) {
                this.refresh();
              }
            }));
      });
    }
  }

  /**
   * Sets a sorting order/method to order the view results.
   *
   * @param {ViewModeColumnBasedFieldSort} sort
   */
  setSort(sort: ViewModeColumnBasedFieldSort): void {
    (this.userConfiguration.CurrentViewMode as IViewModeUserConfigurationColumnsBased)
        .SingleFieldSort = sort;

    this.userConfigurationChanged
        .emit(new ViewsuserconfigchangedEventdata(this.userConfiguration));
  }

  /**
   * Reset the page number
   */
  setPaginatorFirstPage(): void {
    if (this.userConfiguration.Pagination) {
      (this.userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage = 0;
    }
  }

  /**
   * Sets the current `ViewUserConfiguration`.
   *
   * @param {ViewUserConfiguration} value Configuration to set.
   *
   * @param {bool} propagation If true, then an event will be triggered after
   *  the configuration is set.
   *
   * @param {ViewsuserconfigchangedAction} refreshAction Array of actions to
   *  process once the `propagation` option trigger the event.
   */
  setUserConfiguration(
      value: ViewUserConfiguration,
      propagation: boolean = true,
      refreshAction: ViewsuserconfigchangedAction = ViewsuserconfigchangedAction.Refresh,
      eventTags: string[] = []): void {

    this.userConfiguration = value;
    this.vboUserConfiguration = new ViewsVboUserConfiguration();


    if (this.configuration && this.configuration.Fields) {

      const field: IViewField = Object.keys(this.configuration.Fields)
          .map(fid => this.configuration.Fields[fid])
          .filter(f => f.Type === ViewFieldTypeEnum.BulkOperations)
          .shift();

      if (!isNullOrUndefined(field)) {

        this.vboField = field as ViewsFieldVbo;
        this.vboUserConfiguration = this.getUserConfigurationSet(field.Id, ViewsVboUserConfiguration);

        if (isNullOrUndefined(this.vboUserConfiguration.SelectedItems)) {
          this.vboUserConfiguration.SelectedItems = {};
        }
      }
    }

    if (propagation) {
      this.userConfigurationChanged
          .emit(new ViewsuserconfigchangedEventdata(
              this.userConfiguration,
              refreshAction,
              eventTags));
    }
  }

  /**
   * If any items has been selected in the VBO
   */
  hasAnyVboSelected(): boolean {
    return this.hasAnyVboSelectedFromConfig(this.userConfiguration);
  }

  /**
   * If there are any search results available for the current user configuration. Esto debería venir de backend,
   * ver propuesta EmptyActiveResultSet  en ACHSPRIME-5559. De momento hacemos lo que podemos
   * con la info que viene de backend...
   */
  resultsAvailableForCurrentUserConfiguration(): boolean {
    if (this.data && this.data.ResultCount && this.data.ResultCount.EmptyResultSet === true) {
      return false;
    }
    // En realidad este criterio depende de la paginación...
    if (isNullOrUndefined(this.data) || this.data.Results.length === 0) {
      return false;
    }
    return true;
  }

  /**
   * Tell if we have VBO field available
   */
  hasAnyVboFieldAvailable(): boolean {

    if (isNullOrUndefined(this.vboField)) {
      return false;
    }
    return true;
  }

  /**
   * Tell if we have any selected VBO items from a user configuration
   */
  hasAnyVboSelectedFromConfig(userConfig: ViewUserConfiguration): boolean {
    if (isNullOrUndefined(userConfig)) {
      return false;
    }

    const selectedItems: { [key: string]: ViewsVboSelectedItem } = getInSafe(userConfig,
        v => (v.UserConfigurations.vbo as ViewsVboUserConfiguration).SelectedItems, {});

    const allItemsInAllPages: boolean = getInSafe(userConfig,
        v => (v.UserConfigurations.vbo as ViewsVboUserConfiguration).AllItemsInAllPages, false);

    const resultsAvailable: boolean = this.resultsAvailableForCurrentUserConfiguration();

    if (allItemsInAllPages) {
      return resultsAvailable;
    }

    return Object.keys(selectedItems).length > 0;
  }

  vboToggleItemHandlerBase(event: VboToggleEvent): void {
    if (event.row && event.row.Disabled === true && event.row.Selected === true) {
      return;
    }

    switch (event.operation) {
      case VboOperations.SELECT:
        const field: ViewsFieldVbo = this.getFieldFromViewConfiguration(event.fieldName);
        if (field.ItemSelectionMode !== ViewsFieldVboSelectionMode.Multiple) {
          this.vboUserConfiguration.SelectedItems = {};
        }
        this.vboUserConfiguration.SelectedItems[event.row.Id] = event.row;
        break;
      case VboOperations.UNSELECT:
        delete (this.vboUserConfiguration.SelectedItems[event.row.Id]);
        break;
      case VboOperations.SELECT_ALL:
        this.vboUserConfiguration.AllItemsInAllPages = true;
        break;
      case VboOperations.UNSELECT_ALL:
        this.vboUserConfiguration.AllItemsInAllPages = false;
        break;
      case VboOperations.CLEAR:
        this.vboUserConfiguration.AllItemsInAllPages = false;
        this.vboUserConfiguration.SelectedItems = {};
        break;
    }

    this.onVboItemToggle.emit(event);
  }

  /**
   * Internal propagation of a new selected item
   */
  vboToggleItemHandlerWithoutRowsChangedEvent(event: VboToggleEvent): void {

    this.vboToggleItemHandlerBase(event);

    this.userConfigurationChanged
        .emit(new ViewsuserconfigchangedEventdata(
            this.userConfiguration, ViewsuserconfigchangedAction.Ignore));
    this.detectChanges();
  }

  /**
   * Internal propagation of a new selected item
   */
  vboToggleItemHandler(event: VboToggleEvent): void {

    this.vboToggleItemHandlerBase(event);

    this.vboSelectedRowsChanged.emit(this.vboUserConfiguration.SelectedItems);
    this.userConfigurationChanged
        .emit(new ViewsuserconfigchangedEventdata(
            this.userConfiguration, ViewsuserconfigchangedAction.Ignore));
    this.detectChanges();
  }

  /**
   * Toggle all items.
   */
  vboToggleAll(): void {
    if (!this.vboUserConfiguration.AllItemsInAllPages) {
      this.vboToggleItemHandler({
        operation: VboOperations.SELECT_ALL,
        row: null,
        fieldName: 'vbo'
      });
      return;
    }

    this.vboToggleItemHandler({
      operation: VboOperations.UNSELECT_ALL,
      row: null,
      fieldName: 'vbo'
    });
  }

  /**
   * Returns a boolean indicating if a item is selected.
   *
   * @param {ViewsVboSelectedItem} item Item to query.
   */
  isVboSelected(item: ViewsVboSelectedItem): boolean {
    if (isNullOrUndefined(item)) {
      return false;
    }

    if (item.Selected === true && item.Disabled === true) {
      return true;
    }
    return Object.keys(this.vboUserConfiguration.SelectedItems).includes(item.Id);
  }

  /**
   * Returns a integer indicating if the current list has selected VBO items.
   *
   * @returns {number}
   */
  countVboItems(): number {
    if (this.vboUserConfiguration && this.vboUserConfiguration.SelectedItems) {
      return Object.keys(this.vboUserConfiguration.SelectedItems).length;
    }
    return 0;
  }

  /**
   * Returns a boolean indicating if all rows in the view are vbo selected.
   *
   * @returns {boolean}
   */
  isVboAllSelected(): boolean {
    return isNullOrUndefined(this.vboUserConfiguration)
        ? false : this.vboUserConfiguration.AllItemsInAllPages;
  }

  /**
   * Materializa en memoria todos los elementos seleccionados
   */
  materializeVboSelectionInMemory(): Observable<ViewsVboSelectedItem[]> {

    const userConfiguration: ViewUserConfiguration = this.getUserConfiguration();

    return this.viewsService
        .postVbomaterializeinmemory(this.currentPluginRequest, userConfiguration, this.currentPluginRequest.Id)
        .pipe(
            takeUntil(this.componentDestroyed$),
            map((response: WebServiceResponse) => {
              return UtilsTypescript.getNewtonSoftRealValue(response.result) as ViewsVboSelectedItem[];
            })
        );
  }

  /**
   * Materialize the items of a ViewsBulkOperation in a Bulk Operation. Returns the ID of the CORE_BO.
   *
   * @returns {string}
   */
  materializeVboOperation(): Observable<string> {
    const userConfiguration: ViewUserConfiguration = this.getUserConfiguration();
    return this.viewsService.postVbomaterializeoperation(this.currentPluginRequest, userConfiguration, this.currentPluginRequest.Id)
        .pipe(
            takeUntil(this.componentDestroyed$),
            take(1),
            map(
                (i: WebServiceResponseTyped<string>) => i.result
            )
        );
  }

  /**
   * Returns the predefined filter set for the current view.
   *
   * @returns {ViewsPredefinedFilterSet}
   */
  getPredefinedFiltersConfiguration(): ViewsPredefinedFilterSet {
    return this.getConfigurationSet(
        ViewProcessorKeys.ViewsPredefinedFilterSet, ViewsPredefinedFilterSet);
  }

  /**
   * Sets as active a predefined filter.
   *
   * @param {ViewsPredefinedFilter} filter Filter to be activated.
   */
  setPredefinedFilter(viewFilter: ViewsPredefinedFilter): void {
    const predefinedFilterConfig: ViewsPredefinedFilterSet =
        this.getPredefinedFiltersConfiguration();

    if (isNullOrUndefined(predefinedFilterConfig.Filters[viewFilter.Id])) {
      return;
    }

    const config: ViewsPredefinedFilterSetUserConfig =
        this.getUserConfigurationSet(
            ViewProcessorKeys.ViewsPredefinedFilterSet,
            ViewsPredefinedFilterSetUserConfig);

    config.SelectedFilters = isNullOrUndefined(config.SelectedFilters) ? [] : config.SelectedFilters;

    if (config.SelectedFilters.includes(viewFilter.Id)) {
      return;
    }

    if (predefinedFilterConfig.AllowMultipleSelection) {
      config.SelectedFilters.push(viewFilter.Id);
    } else {
      config.SelectedFilters = [viewFilter.Id];
    }

    this.userConfigurationChanged
        .emit(new ViewsuserconfigchangedEventdata(this.userConfiguration));
  }

  /**
   * Removes as active a predefined filter.
   *
   * @param {ViewsPredefinedFilter} viewFilter Filter to be removed.
   */
  removePredefinedFilter(viewFilter: ViewsPredefinedFilter): void {
    const config: ViewsPredefinedFilterSetUserConfig =
        this.getUserConfigurationSet(
            ViewProcessorKeys.ViewsPredefinedFilterSet,
            ViewsPredefinedFilterSetUserConfig);

    config.SelectedFilters = isNullOrUndefined(config.SelectedFilters)
        ? [] : config.SelectedFilters;

    if (!config.SelectedFilters.includes(viewFilter.Id)) {
      return;
    }

    config.SelectedFilters = config.SelectedFilters
        .filter(f => (f !== viewFilter.Id));

    this.userConfigurationChanged
        .emit(new ViewsuserconfigchangedEventdata(this.userConfiguration));
  }

  /**
   * Toggles the activation of a predefined filter.
   *
   * @param {ViewsPredefinedFilter} viewFilter Filter to be removed.
   */
  togglePredefinedFilter(viewFilter: ViewsPredefinedFilter): void {
    const config: ViewsPredefinedFilterSetUserConfig =
        this.getUserConfigurationSet(
            ViewProcessorKeys.ViewsPredefinedFilterSet,
            ViewsPredefinedFilterSetUserConfig);

    config.SelectedFilters = isNullOrUndefined(config.SelectedFilters)
        ? [] : config.SelectedFilters;

    if (config.SelectedFilters.includes(viewFilter.Id)) {
      this.removePredefinedFilter(viewFilter);
      return;
    }

    this.setPredefinedFilter(viewFilter);
  }

  /**
   * Returns a boolean indicating if a predefined filter is activated.
   *
   * @param {ViewsPredefinedFilter} viewFilter Filter to be queried.
   * @returns {boolean}
   */
  isPredefinedFilterActivated(viewFilter: ViewsPredefinedFilter): boolean {
    const config: ViewsPredefinedFilterSetUserConfig =
        this.getUserConfigurationSet(
            ViewProcessorKeys.ViewsPredefinedFilterSet,
            ViewsPredefinedFilterSetUserConfig);

    config.SelectedFilters = isNullOrUndefined(config.SelectedFilters)
        ? [] : config.SelectedFilters;

    return config.SelectedFilters.includes(viewFilter.Id);
  }

  /**
   *  Returns the user confiuration for the current view.
   *
   * @returns {ViewUserConfiguration}
   */
  getUserConfiguration(): ViewUserConfiguration {
    return this.userConfiguration;
  }

  /**
   * Refresh the View Configuration updating the local actions, columns, etc and reload the data
   */
  refreshConfigurationAndLoadData(
      args: any[],
      options: ViewsuserconfigchangedEventdata = null): Observable<ViewResult> {

    const pluginRequest: ViewsPluginRequest = new ViewsPluginRequest();
    pluginRequest.Id = this.configuration.ViewId;
    pluginRequest.Arguments = args;

    this.configuration = null;
    this.userConfiguration = null;
    this.activeUserConfiguration.next(null);

    return this.loadList(pluginRequest, true, options);
  }

  /**
   * Changes the view configuration CurrentViewMode property to the defined value.
   *
   * @param {ViewModeTypeEnum} value
   */
  changeViewMode(value: ViewModeTypeEnum): void {
    if (this.userConfiguration.CurrentViewModeType !== value) {
      const newUserConfiguration: ViewUserConfiguration =
          {...this.userConfiguration};

      newUserConfiguration.CurrentViewModeType = value;
      newUserConfiguration.CurrentViewMode =
          ViewModeUtils.GetCurrentViewModeUserConfiguration(newUserConfiguration);

      this.setUserConfiguration(
          newUserConfiguration,
          true,
          ViewsuserconfigchangedAction.ViewModeChange);
    }
  }

  /**
   * Returns a view configuration processor data by identifier and datatype.
   *
   * If data is not set, then a generic object is returned using a generic factory.
   * getConfigurationSet(ViewProcessorKeys.ViewsPredefinedFilterSet, ViewsPredefinedFilterSet)
   *
   * Generic Factory: https://www.typescriptlang.org/docs/handbook/generics.html#using-class-types-in-generics
   * @param {string} identifier Identifier for the configuration.
   * @param {T} dataType Generic type
   *
   * @returns {T} Generic type intance.
   */
  private getConfigurationSet<T extends IViewProcessor>(
      identifier: string,
      dataType: new () => T): T {
    const conf: ViewConfiguration = this.getConfiguration();

    if (isNullOrUndefined(conf.Processors)) {
      conf.Processors = {};
    }

    if (isNullOrUndefined(conf.Processors[identifier])) {
      conf.Processors[identifier] = new dataType();
    }

    return conf.Processors[identifier] as T;
  }

  /**
   * Busca un ViewProcessor del tipo indicado.
   *
   * @param id
   *   Opcional, si se especifica busca por tipo e identificador.
   *
   * @param dataType
   */
  public getViewProcessor<T extends IViewProcessor>(dataType: new () => T,
                                                    id: string = null): T {

    const candidates: T[] = this.findViewProcessorsOfType<T>(dataType);

    if (candidates.length === 0) {
      return null;
    }

    if (candidates.length === 1) {
      return candidates[0];
    }

    if (!isNullOrWhitespace(id)) {
      return candidates.find((i) => i.Id === id);
    }

    return null;
  }

  /**
   *
   * @param id
   *   Id pude ser nulo cuando sabemos que solo habrá un procesor de ese tipo
   * @param dataType
   * @private
   */
  public findViewProcessorsOfType<T extends IViewProcessor>(dataType: new () => T): T[] {

    const conf: ViewConfiguration = this.getConfiguration();

    // Si nos fijamos en backend, los procesors originalmente estaban "desperdigados" en varios
    // atributos, finalmente se centralizaron en config.processors
    let availableProcessors: IViewProcessor[] = [];

    availableProcessors = [...(conf.Processors ? Object.values(conf.Processors) : []), ...availableProcessors];
    availableProcessors = [...(conf.Fields ? Object.values(conf.Fields) : []), ...availableProcessors];
    availableProcessors = [...(conf.FilterManagers ? Object.values(conf.FilterManagers) : []), ...availableProcessors];
    availableProcessors = [...(conf.Sorts ? Object.values(conf.Sorts) : []), ...availableProcessors];
    availableProcessors = [...(conf.LocalActions ? Object.values(conf.LocalActions) : []), ...availableProcessors];
    availableProcessors = [...(conf.AvailableViewModes ? Object.values(conf.AvailableViewModes) : []), ...availableProcessors];

    availableProcessors = (!!conf.Pager) ? [conf.Pager, ...availableProcessors] : availableProcessors;
    availableProcessors = (!!conf.GroupBy) ? [conf.GroupBy, ...availableProcessors] : availableProcessors;
    availableProcessors = (!!conf.QuickSearch) ? [conf.QuickSearch, ...availableProcessors] : availableProcessors;

    const candidates: T[] = availableProcessors
        .filter((i) => backendTypeMatch(new dataType().$type, i))
        .map((i) => i as T);

    return candidates;
  }

  /**
   * Returns a user configuration set by identifier and dataType.
   *
   * If data is not set, then a generic object is returned using a generic factory.
   * getUserConfigurationSet(field.Id, ViewsVboUserConfiguration)
   *
   * Generic Factory: https://www.typescriptlang.org/docs/handbook/generics.html#using-class-types-in-generics
   * @param {string} identifier Identifier for the configuration.
   * @param {T} dataType Generic type
   *
   * @returns {T} Generic type intance.
   */
  public getUserConfigurationSet<T extends IViewsUserConfiguration>(
      identifier: string,
      dataType: new () => T): T {
    return this.getUserConfigurationSetFromConfig(identifier, this.getUserConfiguration(), dataType);
  }

  public getUserConfigurationSetFromConfig<T extends IViewsUserConfiguration>(
      identifier: string,
      userConf: ViewUserConfiguration,
      dataType: new () => T): T {

    let userconfigurations: { [key: string]: IViewsUserConfiguration } =
        getInSafe(userConf, x => x.UserConfigurations, {});

    if (isNullOrUndefined(userconfigurations)) {
      userconfigurations = {};
    }

    if (isNullOrUndefined(userconfigurations[identifier])) {
      userconfigurations[identifier] = new dataType();
    }

    return userconfigurations[identifier] as T;
  }

  /**
   * Get a ViewField from it's name and type
   * @param fieldName
   */
  protected getFieldFromViewConfiguration<T extends IViewField>(fieldName: string): T {
    const configuration: ViewConfiguration = this.getConfiguration();
    if (configuration.Fields && configuration.Fields[fieldName]) {
      return configuration.Fields[fieldName] as T;
    }
    return null;
  }

  /**
   * Obtiene el modo de selección del VBO
   */
  vboItemSelectionMode(fieldName: string): ViewsFieldVboSelectionMode {

    if (isNullOrUndefined(this.getConfiguration())) {
      return null;
    }

    let selectionMode: ViewsFieldVboSelectionMode;

    selectionMode = getInSafe(this.currentPluginRequest,
        (i) => (i.Parameters[fieldName] as ViewsVboDefaultStartupParameter).SelectionMode, null);

    if (!isNullOrUndefined(selectionMode)) {
      return selectionMode;
    }

    selectionMode = getInSafe(this.currentPluginRequest,
        (i) => (i.Parameters['vbo*'] as ViewsVboDefaultStartupParameter).SelectionMode, null);

    if (!isNullOrUndefined(selectionMode)) {
      return selectionMode;
    }

    selectionMode = getInSafe(this.getFieldFromViewConfiguration<ViewsFieldVbo>(fieldName), (i) => i.ItemSelectionMode, null);

    if (!isNullOrUndefined(selectionMode)) {
      return selectionMode;
    }
  }

  /**
   * Refresh to the views.
   */
  dropStoredUserConfigurationAndRefresh(): void {
    const config: ViewConfiguration = this.getConfiguration();
    this.viewsService.deleteDeletestoredconfiguration(config.ViewStorageKey)
        .pipe(
            takeUntil(this.componentDestroyed$)
        )
        .subscribe(x => {
          if (x.result) {
            // Set the new configuration.
            this.setUserConfiguration(this.getUserConfiguration(), true, ViewsuserconfigchangedAction.DropCurrentUserAndLoad);
          }
        })
  }

  /**
   * Refresh to the views.
   */
  refresh(): void {
    // Get the latest configuration.
    const configuration: ViewUserConfiguration = this.getUserConfiguration();
    // Set the new configuration.
    this.setUserConfiguration(configuration);
  }

  emitOnVboOperationCompleted(result: {
    operation: IVboOperation,
    result: { id: string, message: any[], responseData: any } | CORE_QUEUE
  }): void {
    if (!result.operation.SkipRefreshViewOnFinish) {
      PrimeUtils.ParseKeyItemToArray(this.userConfiguration.UserConfigurations)
          .filter((x: IViewsUserConfiguration) => x['$type'] === 'ViewsVboUserConfiguration')
          .map((x: ViewsVboUserConfiguration) => {
            x.AllItemsInAllPages = false
            x.SelectedItems = null
          });
      this.refresh();
    }
    this.onVboOperationCompleted.emit(result);
  }
}
