import { Injectable } from '@angular/core';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { UtilsTypescript } from '../../utils/typescript.utils';
import { NavigationRequest } from '../../../core/navigation/models/NavigationRequest.class';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';

@Injectable()
export class BreadCrumb_Service {

  // Elementos que deben aparecer en el breadcrumb
  breadcrumb: MenuItemCompiledFrontend[];

  /**
   * creates a new instance of BreadCrumb_Service and uses navigation service to subscribe to its path changes to
   * update the breadcrumb.
   * @param {NavigationService} navigationService
   */
  constructor(private navigationService: NavigationService) {
    this.navigationService
      .lastResolvedNavigationRequest
      .subscribe(
        (request: NavigationRequest) => {
          const newPath: MenuItemCompiledFrontend[] = request.responseMenuPath;
          this.breadcrumb = newPath.filter((x) => {
            // Quitamos los que no tienen título (aparecerían en blanco)
            return !UtilsTypescript.isNullOrWhitespace(x.title)
              // Quitamos del breadcrumb el elemento "Home" ya que la home en realidad como tal no existe en el árbol de navegación...
              && this.navigationService.getHomeURL() !== x.getFrontendPath()
              // Este es un caso especial, por lo general si el elemento está hidden no aparecerá en el breadcrumb,
              // pero si el elemento coincide con aquél en que está el usuario actualmente [0] sí lo mostramos
              // ya que de otro modo es confuso. Sirve para algunos casos raros donde la navegación que ve el usuario
              // está fuertemente apalancada en anchors
              && (x.controller === request.responseMenuPath[0].controller || x.Hidden !== true)
              // Al usar esta propiedad, forzamos a mostrar el breadcrum sin mas, usado cuando se tiene una ruta
              // parametrizada oculta, pero se desea mostrar el breadcrum de cualquier forma
              || (x.ForceToShowBreadcrum)
          }).reverse();
        }
      );
  }
}

