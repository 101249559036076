import { ChangeDetectorRef, Component, ElementRef, forwardRef, HostListener, Input, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import {
  FormElementAutocompleteCallbackResult,
  FormElementOption,
  FormSubmitData,
  IFormElementOption
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { getInSafe } from '../../../../utils/typescript.utils';
import { catchError, finalize, take, takeUntil } from 'rxjs/operators';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { EMPTY, throwError } from 'rxjs';

@Component({
  selector: 'app-autocomplete-multiple',
  templateUrl: './autocomplete-multiple.component.html',
  styles: [
    `
      .scrollable {
        height: auto;
        max-height: 166px;
        overflow-x: hidden;
      }
    `
  ],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutocompleteMultipleComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => AutocompleteMultipleComponent), multi: true}
  ]
})
export class AutocompleteMultipleComponent extends FrontendFormElementInput {

  @Input('acValue') _acValue: FormElementOption[];

  @ViewChild('inputGroup', {static: true}) inputGroup;

  optionList: IFormElementOption[] = [];

  private currentPage = 0;
  private currentSearch = '';
  private isPaged = false;

  optionsWidth: number;
  searched = false;
  searching = false;
  connectionError = false;
  searchValue: string;

  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected _eref: ElementRef,
              protected localeService: TranslatorService) {
    super(formManagerService, cdRef, localeService);
  }

  /**
   * Getter for the _acValue
   * @returns {any}
   */
  get acValue(): any {
    return this._acValue;
  }

  /**
   * Setter for the _acValue. On the setter the value is propagated
   * to let angular Forms know about this change
   * @param val: is the setter value
   */
  set acValue(val: any) {
    this._acValue = val;
    this.propagateChange(val);
  }

  /**
   * This handler is called when the document click event
   * is fired
   *
   * ACHSPRIME-2168 Revisado funciona OK
   *
   * @param e
   */
  @HostListener('document:click', ['$event'])
  clickedOutside(event: Event): void {
    if (!this._eref.nativeElement.contains(event.target) && this.searched) {
      this.clearSearch();
    }
  }

  /**
   * Clear search
   */
  clearSearch(): void {
    this.optionList = [];
    this.searched = false;
    this.forceDetectChanges();
  }

  /**
   * This function is used to communicate with the server and get
   * the options list
   * @param {string} search: search string. Is used in backend to
   * return the values that contains this string
   * @param page
   */
  private getOptionList(search: string, page: number): void {

    // Evitar solapar búsquedas
    if (this.searching === true) {
      return;
    }

    const formSubmitData: FormSubmitData = new FormSubmitData();
    formSubmitData.formInput = this.formManagerService.getFormComponentValue('');
    formSubmitData.submitElement = this.config.name;

    this.searching = true;
    this.connectionError = false;

    if (isNullOrUndefined(search)) {
      search = '';
    }

    this.formManagerService.getFieldautocompletecallback(
        formSubmitData,
        this.config.name,
        search,
        page
    )
        .pipe(
            finalize(() => {
              this.searching = false;
            }),
            catchError((err) => {
              if (err.status === 0) {
                this.connectionError = true;
                this.searching = false;
                this.forceDetectChanges();
                return EMPTY;
              }
              return throwError(err);
            }),
        )
        .pipe(takeUntil(this.componentDestroyed$), take(1))
        .subscribe(
            (result: FormElementAutocompleteCallbackResult) => {
              this.searched = true;
              const resultOptions: IFormElementOption[] = getInSafe(result, (i) => i.Options, []);

              if (page === 0) {
                this.isPaged = result.IsPaged;
                this.optionsWidth = this.inputGroup.nativeElement.offsetWidth;
              }

              this.optionList = [...this.optionList, ...resultOptions];

              this.forceDetectChanges();
            }
        );
  }

  /**
   * This function is called when input autocomplete element
   * get focus
   */
  inputTouched(): void {
    this.propagateTouch();
  }

  /**
   * Add a handler for events keydown.enter on components acomplete.
   *
   * Keydown is used because the forms trigger the submit in keydown.enter
   * events, the custom form components do not stop the propagation of
   * keydown.enter by default as do other native elements such as textareas,
   * empty input fields and others.
   * @see https://stackoverflow.com/questions/40909585/angular-2-how-to-prevent-a-form-from-submitting-on-keypress-enter
   * @see https://stackoverflow.com/questions/37362488/how-can-i-listen-for-keypress-event-on-the-whole-page
   * @param {KeyboardEvent} e Keyboard event
   */
  keydownHandler(e: KeyboardEvent): void {
    e.preventDefault();
  }

  /**
   * This method ask for new items if calback is paged
   */
  scrolledToEndHandler(): void {
    if (this.isPaged !== true || this.searching === true) {
      return;
    }
    this.currentPage++;
    this.getOptionList(this.currentSearch, this.currentPage);
  }

  /**
   * This function is used to trigger an options
   * search. The keyup.enter Event is used to know the current
   * value of the input
   * @param e: keyup.enter Event.
   */
  searchForResults(): void {
    this.optionList = [];
    this.currentPage = 0;
    this.currentSearch = this.searchValue;
    this.getOptionList(this.currentSearch, 0);
  }

  /**
   * This function is used to set the selected Option
   * @param {number} optionIndex
   */
  setOption(optionIndex: number): void {
    if (this.isDisabled) {
      return;
    }

    let aux: IFormElementOption[] = [];
    if (!isNullOrUndefined(this.acValue)) {
      aux = [...this.acValue];
    }

    if (aux.some((i) => i.Key === this.optionList[optionIndex].Key) === false) {
      aux.push(this.optionList[optionIndex]);
    }

    this.acValue = aux;
    this.optionList = [];
    this.forceDetectChanges();
  }

  /**
   * This function is used to set the selected option to null
   */
  unsetOption(i: number): void {
    if (this.isDisabled) {
      return;
    }

    const aux: FormElementOption[] = [...this.acValue];
    aux.splice(i, 1);
    this.acValue = aux;
    this.propagateTouch();
    this.forceDetectChanges();
  }

  /**
   * @inheritDoc
   */
  writeValue(value: any): void {
    this._acValue = value;
  }

  /**
   * @inheritDoc
   */
  equalValues(valueA: any, valueB: any): boolean {
    const valueA2: string = getInSafe(valueA as FormElementOption, (i) => i.Key, valueA);
    const valueB2: string = getInSafe(valueB as FormElementOption, (i) => i.Key, valueB);
    return valueA2 === valueB2;
  }
}
