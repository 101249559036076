import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RestTranslatorModule } from '../../../core/translator/rest-translator.module';
import { UtilsModule } from '../../utils/utils.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ViewModeDirective } from './view-mode.directive';
import { ViewModeTableModule } from './ViewModeTable/view-mode-table.module';
import { ViewGridComponent } from './ViewModeTable/components/grid.component';
import { ViewCardBoardsComponent } from './ViewModeCardBoards/components/card-boards.component';
import { ViewModeCardBoardsModule } from './ViewModeCardBoards/view-mode-card-boards.module';
import { ViewModeSelectorComponent } from './view-mode-selector/view-mode-selector.component';
import { ViewModeCalendarModule } from './ViewModeCalendar/view-mode-calendar.module';
import { ViewModeCalendarComponent } from './ViewModeCalendar/components/view-mode-calendar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    RestTranslatorModule,
    ViewModeCardBoardsModule,
    ViewModeTableModule,
    ViewModeCalendarModule,
    TooltipModule.forRoot()
  ],
  declarations: [
    ViewModeDirective,
    ViewModeSelectorComponent,
  ],
  exports: [
    ViewModeDirective,
    ViewGridComponent,
    ViewCardBoardsComponent,
    ViewModeCalendarComponent,
    ViewModeSelectorComponent
  ]
})
export class GridModule {
}
