<div class="c-field" [formGroup]="group">
  <ng-container *ngIf="config">
    <app-fuse-chart
        [@animate]="{ value: '*', params: { y: '100%' }}"
        [args]="this.ChartEmbeddedValue?.ChartsArguments"
        [chartId]="this.ChartEmbeddedValue?.ChartId"
        [skipCaches]="this.ChartEmbeddedValue?.SkipCache"
        [forceSyncExecution]="!this.ChartEmbeddedValue?.DeferredExecution">
    </app-fuse-chart>
  </ng-container>
</div>
