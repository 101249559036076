import { AbstractControl } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { map, pairwise, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { isArray } from 'app/shared/utils/typescript.utils';

import {
  ActionsApiAction,
  ActionsApiTrigger,
  DtoFrontendModal,
  DtoFrontendModalSize,
  DtoFrontendModalType,
  FormElement,
  FormElementActionsApiAction,
  FormElementActionsApiTrigger,
  FormElementState,
  FormElementTrigger,
  FormElementType,
  FormInputChangeAlert,
  FormSubmitType,
  IStateApiCondition,
  StateApiConditionType,
  StateApiGroupCondition,
  StateApiTriggerCondition
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { ConfirmDialogLabels } from '../../decoupled-modal/models/modal-params.interface';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { getInSafe, isNullOrUndefined, UtilsTypescript } from '../../utils/typescript.utils';
import { FrontendFormElementInput } from '../form-components/formelementinput.class';
import { FrontendFormElementWrapper } from '../form-components/formelementwrapper.class';
import { FormManagerService } from '../form-manager/form-manager.service';
import { FieldConfig } from '../interfaces/field-config.interface';
import { stateApiTriggerSourceData } from './stateApiTriggerSourceData';
import { FieldsetComponent } from '../form-components/fieldset/fieldset.component';

export class StateApiService extends DestroyableObjectTrait {

  /**
   * Es un mapeo de los diferentes estados (Visible, Invisible, Etc.) a los atributos
   * y valores que deben modificarse en los FieldConfig que hay bindeados a los controles.
   */
  statesMapping: { [key: string]: { config: string, value: boolean } } = {};

  /**
   * Dependencias entre elementos para la API de estados (no acciones)
   */
  stateApiElementDependency: { [key: string]: { [key: number]: IStateApiCondition } } = {};

  /**
   * Elementso que tienen triggers
   */
  actionApiElementsWithTriggers: { [key: string]: FormElement } = {};

  /**
   * Source elements. Esto es una relación entre selectores (key) y componentes (string[]) cuyo estado
   * está afectado por ese selector.
   *
   * De esa manera, cuando cambia un valor de un componente, re-calculamos únicamente el estado
   * de los componentes a los que afecta en lugar de calcular el estado de todos los componentes.
   */
  sourceElements: { [key: string]: string[] } = {};

  /**
   * The state API emits a string/stateApiTriggerSourceData when an element change
   * event has been evaluated by the `elementChanged` method.
   */
  elementChangeEvaluated$: Subject<{ controlKey: string, triggerElementData: stateApiTriggerSourceData }>
      = new Subject<{ controlKey: string, triggerElementData: stateApiTriggerSourceData }>();

  /**
   * Returns opposite state of the given state
   * @param state
   * @returns {any}
   */
  static getOppositeState(state: string): string {
    const dic: any = {};
    dic[FormElementState[FormElementState.Visible]] = FormElementState[FormElementState.Invisible];
    dic[FormElementState[FormElementState.Invisible]] = FormElementState[FormElementState.Visible];
    dic[FormElementState[FormElementState.Checked]] = FormElementState[FormElementState.Unchecked];
    dic[FormElementState[FormElementState.Unchecked]] = FormElementState[FormElementState.Checked];
    dic[FormElementState[FormElementState.Optional]] = FormElementState[FormElementState.Required];
    dic[FormElementState[FormElementState.Required]] = FormElementState[FormElementState.Optional];
    dic[FormElementState[FormElementState.Expanded]] = FormElementState[FormElementState.Collapsed];
    dic[FormElementState[FormElementState.Collapsed]] = FormElementState[FormElementState.Expanded];
    dic[FormElementState[FormElementState.Enabled]] = FormElementState[FormElementState.Disabled];
    dic[FormElementState[FormElementState.Disabled]] = FormElementState[FormElementState.Enabled];
    return dic[state];
  }

  /**
   * Creates a new instance of StateApiService
   */
  constructor(
      protected formManagerService: FormManagerService,
      protected dmbs: DecoupledModalBridgeService
  ) {
    super();
    this.statesMapping[FormElementState[FormElementState.Visible]] = {config: 'visible', value: true};
    this.statesMapping[FormElementState[FormElementState.Invisible]] = {config: 'visible', value: false};
    this.statesMapping[FormElementState[FormElementState.Checked]] = {config: 'checked', value: true};
    this.statesMapping[FormElementState[FormElementState.Unchecked]] = {config: 'checked', value: false};
    this.statesMapping[FormElementState[FormElementState.Required]] = {config: 'required', value: true};
    this.statesMapping[FormElementState[FormElementState.Optional]] = {config: 'required', value: false};
    this.statesMapping[FormElementState[FormElementState.Expanded]] = {config: 'expanded', value: true};
    this.statesMapping[FormElementState[FormElementState.Collapsed]] = {config: 'expanded', value: false};
    this.statesMapping[FormElementState[FormElementState.Enabled]] = {config: 'editable', value: true};
    this.statesMapping[FormElementState[FormElementState.Disabled]] = {config: 'editable', value: false};
  }

  /**
   * Add an element state to the dictionary
   * @param {string} key
   * @param {Object} elementState
   */
  registerElementInStateAndActionApi(element: FormElement): void {
    if (element.Type === FormElementType.Secret) {
      return;
    }

    if (element.ElementStates && Object.keys(element.ElementStates).length > 0) {
      this.stateApiElementDependency[element.ClientPath] = element.ElementStates;
    }

    if (isArray(element.ElementActions)) {
      this.actionApiElementsWithTriggers[element.ClientPath] = element;
    }
  }

  /**
   * Executa an action
   *
   * @param selector
   * @param action
   * @param triggerElementData
   */
  executeAction(action: ActionsApiAction, triggerElementData: stateApiTriggerSourceData): void {
    switch (action.Action) {
      case FormElementActionsApiAction.ClearValues:
        this.formManagerService.resetFormComponent(action.TargetElement, true);
        break;
      case FormElementActionsApiAction.SetValue:
        this.formManagerService.setFormComponentValue(action.TargetElement, action.Data, true);
        break;
      case FormElementActionsApiAction.Reset:
        // Ponemos un propagate=true en el reset, porque si no lo hacemos, las API de estados
        // no se calcularían!
        this.formManagerService.resetFormComponent(action.TargetElement, true);
        break;
      case FormElementActionsApiAction.Rebuild:
        this.formManagerService.submitForm(triggerElementData.path, FormSubmitType.Rebuild);
        break;
      case FormElementActionsApiAction.RebuildValues:
        this.formManagerService.submitForm(triggerElementData.path, FormSubmitType.RebuildValues);
        break;
      case FormElementActionsApiAction.RunCustomCallback:
        this.formManagerService.runCustomCallback(triggerElementData.path, action, triggerElementData);
        break;
      case FormElementActionsApiAction.Collapse:
        const formControl: FieldsetComponent = this.formManagerService.getFormComponentInstance(action.TargetElement).instance as FieldsetComponent;
        formControl.collapseSet(action.Data);
        break;
      default:
        console.error('Action API action not supported: ' + action.Action);
    }
  }

  /**
   * Acciones de la API de acciones destinadas a la comprobación antes del cambio de valor de un componente de formulario
   *
   * @param action
   * @param triggerElementData
   */
  executeActionApiCanChangeValueForComponent(action: ActionsApiAction, triggerElementData: stateApiTriggerSourceData): Observable<boolean> {
    switch (action.Action) {
      case FormElementActionsApiAction.ConfirmMessage:

        const message: FormInputChangeAlert = action.Data as FormInputChangeAlert

        const ref: ModalReference<any> = this.dmbs.showConfirm(
            {
              messages: message.Messages,
              NoLabel: message.NoLabel,
              YesLabel: message.YesLabel,
              notVisibleNo: message.HideNoButton
            } as ConfirmDialogLabels,
            {
              Title: message.Title,
              HideHeader: message.HideHeader,
              CssClasses: message.CssClasses,
              ModalSize: DtoFrontendModalSize.Medium,
              ModalType: message.ModalType
            } as DtoFrontendModal
        );

        return ref.close
            .pipe(map((data) => {
              if (!data) {
                return false;
              }
              return true;
            }));
        break;
      default:
        console.error('Action API action not supported: ' + action.Action);
        return of(true);
    }
  }

  /**
   * Applies state to a component
   * @param {string} selector
   * @param {string} state
   */
  applyState(selector: string, state: string, triggerElementData: stateApiTriggerSourceData): void {

    const mappedState: { config: string, value: boolean } = this.statesMapping[state];

    if (isNullOrUndefined(mappedState)) {
      return;
    }

    // updates config
    const config: FieldConfig = this.formManagerService.getFieldConfigFromSelector(selector);
    const formElement: FormElement = this.formManagerService.getConfigFromSelector(selector);

    // Si no ha habido un cambio real de estado, no hago nada ni lanzo el evento, para optimizar
    // ciclos.
    if (config[mappedState.config] === mappedState.value) {
      return;
    }

    config[mappedState.config] = mappedState.value;

    // update angular control
    const control: AbstractControl = this.formManagerService.form.get(selector);
    switch (state) {
      case FormElementState[FormElementState.Visible]:
        this.restoreEnabledStateInControl(formElement);
        break;
      case FormElementState[FormElementState.Invisible]:
        if (control.enabled) {
          control.disable({emitEvent: false});
        }
        break;
      case FormElementState[FormElementState.Enabled]:
        this.restoreEnabledStateInControl(formElement);
        break;
      case FormElementState[FormElementState.Disabled]:
        if (control.enabled) {
          // Idealmente, tendrian que emitir evento todos los componentes.
          // Pero por seguridad y necesidades de desarrollo, de momento solo van a emitir los checkboxes
          control.disable({emitEvent: true});
        }
        break;
      case FormElementState[FormElementState.Required]:
        control.updateValueAndValidity({emitEvent: false});
        break;
      case FormElementState[FormElementState.Optional]:
        control.updateValueAndValidity({emitEvent: false});
        break;
      case FormElementState[FormElementState.Checked]:
        control.setValue(true, {emitEvent: false});
        break;
      case FormElementState[FormElementState.Unchecked]:
        control.setValue(false, {emitEvent: false});
        break;
    }

    // Avisamos para que se actualice la vista del campo
    this.formManagerService.elementConfigChanged.emit(formElement.ClientPath);
  }

  /**
   * Restore the element's hierarchy state of "editable" attribute.
   *
   * Lo que pasa es que al hacer disable() en un FormGroup todos sus hijos
   * de forma jerarquica pasan a estar deshabilitados, y hay que restaurar
   * su estado.
   *
   * @param element
   */
  restoreEnabledStateInControl(element: FormElement): void {
    const config: FieldConfig = this.formManagerService.getFieldConfigFromSelector(element.ClientPath);
    const control: AbstractControl = this.formManagerService.form.get(element.ClientPath);
    const isDisabled: boolean = this.formManagerService.calculateDisabledFromConfigHierarchy(config);
    if (isDisabled) {
      control.disable({emitEvent: false});
      return;
    } else {
      control.enable({emitEvent: true});
      if ([FormElementType.FieldSet, FormElementType.Form].includes(element.Type)) {
        for (const key of Object.keys(element.Children)) {
          const child: FormElement = element.Children[key];
          if (child.Type === FormElementType.Secret) {
            continue;
          }
          this.restoreEnabledStateInControl(child);
        }
      }
    }
  }

  /**
   * When an element has changed its' value or state
   *
   * @param controlKey
   * @param triggerElementData
   */
  elementChanged(controlKey: string, triggerElementData: stateApiTriggerSourceData): void {
    this.evaluateStateApiForComponent(controlKey, triggerElementData);
    this.evaluateActionApiForComponent(controlKey, triggerElementData);
    this.elementChangeEvaluated$.next({controlKey, triggerElementData});
    this.formManagerService.stateApiCalculateCount++;
    this.formManagerService.formChangeDetector.detectChanges();
  }

  /**
   * Evaluar la API de estados para un componente cuyo valor ha cambiado
   *
   * Evaluar significa recalcular los estados de aquellos componentes que están afectados
   * por el componente que ha cambiado.
   *
   * @param controlKey
   * @param triggerElementData
   */
  protected evaluateStateApiForComponent(controlKey: string, triggerElementData: stateApiTriggerSourceData): void {
    if (!this.sourceElements.hasOwnProperty(controlKey)) {
      return;
    }
    for (let i: number = 0; i < this.sourceElements[controlKey].length; ++i) {
      this.checkState(this.sourceElements[controlKey][i], triggerElementData);
    }
  }

  /**
   * Evaluar la API de acciones para un componente
   *
   * @param controlKey
   * @param triggerElementData
   */
  protected evaluateActionApiForComponent(controlKey: string, triggerElementData: stateApiTriggerSourceData): void {
    // La API de acciones es mucho más sencilla que la de estados!
    const formElement: FormElement = this.formManagerService.getConfigFromSelector(controlKey);

    for (const trigger of UtilsTypescript.asIterable(formElement.ElementActions)) {
      if (!isArray(trigger.Actions)) {
        continue;
      }
      // Si no se cumple el trigger continuamos
      if (!this.evaluateActionApiTrigger(trigger, triggerElementData)) {
        continue;
      }
      for (const action of trigger.Actions) {
        this.executeAction(action, triggerElementData);
      }
    }
  }

  /**
   *
   * @param contorlKey
   * @protected
   */
  protected componentHasBuildOrRebuildAction(controlKey: string): boolean {
    const formElement: FormElement = this.formManagerService.getConfigFromSelector(controlKey);
    for (const trigger of UtilsTypescript.asIterable(formElement.ElementActions)) {
      if (!isArray(trigger.Actions)) {
        continue;
      }
      for (const action of trigger.Actions) {
        if ([FormElementActionsApiAction.Rebuild, FormElementActionsApiAction.RebuildValues, FormElementActionsApiAction].includes(action.Action)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Algunas acciones de la API de acciones sirven para colgarse de la confirmación de cambio de valor de la API de formularios.
   *
   * @param controlKey
   * @param triggerElementData
   */
  protected evaluateActionApiCanChangeValueForComponent(controlKey: string, triggerElementData: stateApiTriggerSourceData): Observable<boolean> {
    // La API de acciones es mucho más sencilla que la de estados!
    const formElement: FormElement = this.formManagerService.getConfigFromSelector(controlKey);

    let actionsChain: Observable<boolean> = of(true);

    for (const trigger of UtilsTypescript.asIterable(formElement.ElementActions)) {
      if (!isArray(trigger.Actions)) {
        continue;
      }
      // Si no se cumple el trigger continuamos
      if (!this.evaluateActionApiTrigger(trigger, triggerElementData)) {
        continue;
      }
      for (const action of trigger.Actions) {
        const a: Observable<boolean> = this.executeActionApiCanChangeValueForComponent(action, triggerElementData);
        if (a) {
          actionsChain = actionsChain.pipe(switchMap((i) => a));
        }
      }
    }

    return actionsChain;
  }

  /**
   *
   * @param condition
   * @param triggerElementData
   */
  evaluateStateApiCondition(condition: IStateApiCondition, triggerElementData: stateApiTriggerSourceData): boolean {
    const result: boolean = this.doEvaluateStateApiCondition(condition, triggerElementData);
    return condition.Negate === true ? !result : result;
  }

  /**
   * Checks state conditions
   *
   * @param condition
   * @param triggerElementData
   */
  protected doEvaluateStateApiCondition(condition: IStateApiCondition, triggerElementData: stateApiTriggerSourceData): boolean {
    switch (condition.Type) {
      case StateApiConditionType.Trigger:
        const triggerCondition: StateApiTriggerCondition = Object.assign(new StateApiTriggerCondition(), condition);
        return this.evaluateTrigger(triggerCondition);
      case StateApiConditionType.GroupAnd:
        const conditionGroupAnd: StateApiGroupCondition = Object.assign(new StateApiGroupCondition(), condition);
        if (isNullOrUndefined(conditionGroupAnd.Conditions)) {
          return true;
        }
        for (const c of conditionGroupAnd.Conditions) {
          const evaluateStateResult: boolean = this.evaluateStateApiCondition(c, triggerElementData);
          if (isNullOrUndefined(evaluateStateResult) || evaluateStateResult === false) {
            return false;
          }
        }
        return true;
      case StateApiConditionType.GroupOr:
        const conditionGroupOr: StateApiGroupCondition = Object.assign(new StateApiGroupCondition(), condition);
        if (isNullOrUndefined(conditionGroupOr.Conditions)) {
          return true;
        }
        for (const c of conditionGroupOr.Conditions) {
          if (this.evaluateStateApiCondition(c, triggerElementData) === true) {
            return true;
          }
        }
        return false;
      case StateApiConditionType.GroupXor:
        const conditionGroupXor: StateApiGroupCondition = Object.assign(new StateApiGroupCondition(), condition);
        if (isNullOrUndefined(conditionGroupXor.Conditions)) {
          return true;
        }
        let trueCount: number = 0;
        for (const c of conditionGroupXor.Conditions) {
          if (this.evaluateStateApiCondition(c, triggerElementData) === true) {
            trueCount++;
          }
        }
        return trueCount < 0 && trueCount < conditionGroupXor.Conditions.length;
      default:
        throw new Error('Not supported condition type: ' + condition.Type);
    }
  }

  /**
   * Check current condition
   * @param trigger
   * @param triggerElementData
   */
  evaluateActionApiTrigger(trigger: ActionsApiTrigger, triggerElementData: stateApiTriggerSourceData): boolean {
    switch (trigger.TriggerType) {
      case FormElementActionsApiTrigger.ValueChanged:
        return true;
      case FormElementActionsApiTrigger.ValueChangedTo:
        return this.areValueEqual(triggerElementData.path, trigger.TriggerData, triggerElementData.newValue);
      case FormElementActionsApiTrigger.ValueChangedFrom:
        return this.areValueEqual(triggerElementData.path, trigger.TriggerData, triggerElementData.prevValue);
      case FormElementActionsApiTrigger.ValueChangedFromTo:
        return this.areValueEqual(triggerElementData.path, trigger.TriggerData, triggerElementData.prevValue)
            && this.areValueEqual(triggerElementData.path, trigger.TriggerData2, triggerElementData.newValue);
      case FormElementActionsApiTrigger.ValueFilled:
        return this.isEmptyFormValue(triggerElementData.path, triggerElementData.prevValue) && !this.isEmptyFormValue(triggerElementData.path, triggerElementData.newValue);
      case FormElementActionsApiTrigger.ValueEmptied:
        return !this.isEmptyFormValue(triggerElementData.path, triggerElementData.prevValue) && this.isEmptyFormValue(triggerElementData.path, triggerElementData.newValue);
      default:
        throw new Error('Trigger action not supported: ' + trigger.TriggerType);
    }
  }

  /**
   * Check if a component's value is empty
   *
   * @param selector
   * @param value
   */
  protected areValueEqual(selector: string, valueA: any, valueB: any): boolean {
    // Al final quien nos va a decir si el valor es vacío
    // será el propio componente, ya que algunos tiene valores estructurados
    // complejos
    const wrapper: FrontendFormElementWrapper = (this.formManagerService.getFormComponentInstance(selector).instance) as FrontendFormElementWrapper;
    return wrapper.formElementInstance().equalValues(valueA, valueB);
  }

  /**
   * Check if a component's value is empty
   *
   * @param selector
   * @param value
   */
  protected isEmptyFormValue(selector: string, value: any): boolean {
    // Al final quien nos va a decir si el valor es vacío
    // será el propio componente, ya que algunos tiene valores estructurados
    // complejos
    const formControl: FrontendFormElementInput = this.formManagerService.getFormComponentInputInstance(selector);
    return formControl.emptyValue(value);
  }

  protected compareFormValue(selector: string, valueA: any, valueB: any): number {
    // Al final quien nos va a decir si el valor es vacío
    // será el propio componente, ya que algunos tiene valores estructurados
    // complejos
    const formControl: FrontendFormElementInput = this.formManagerService.getFormComponentInputInstance(selector);
    return formControl.compareValues(valueA, valueB);
  }

  /**
   * Check current condition
   * @param trigger
   */
  evaluateTrigger(trigger: StateApiTriggerCondition): boolean {
    const formElement: AbstractControl = this.formManagerService.form.get(trigger.Selector);
    const formControl: FrontendFormElementInput = this.formManagerService.getFormComponentInputInstance(trigger.Selector);
    if (isNullOrUndefined(formElement)) {
      console.debug('Could not find form control with path: ' + trigger.Selector);
    }
    const inputValue: any = UtilsTypescript.getNewtonSoftRealValue(formElement.value);
    const value: any = UtilsTypescript.getNewtonSoftRealValue(trigger.Value);
    const value2: any = UtilsTypescript.getNewtonSoftRealValue(trigger.Value2);
    switch (trigger.Trigger) {
      case FormElementTrigger.Checked:
        const isChecked: boolean = inputValue;
        return trigger.Value === false ? !isChecked : isChecked;
      case FormElementTrigger.Value:
        return this.evaluateValueTrigger(inputValue, value, formControl);
      case FormElementTrigger.Empty:
        const isEmpty: boolean = this.isEmptyFormValue(trigger.Selector, inputValue);
        return trigger.Value === false ? !isEmpty : isEmpty;
      case FormElementTrigger.ValueGreaterThan:
        return this.compareFormValue(trigger.Selector, inputValue, value) > 0;
      case FormElementTrigger.ValueInRange:
        // Primero miramos que no tenga ningun valor por dos motivos
        // 1. El compareFormValue no nos sirve para saber si el valo esta vacio o no
        // 2. En caso de que este vacio podemos devolver falso directamente
        return !this.isEmptyFormValue(trigger.Selector, inputValue) && this.compareFormValue(trigger.Selector, inputValue, value) >= 0 && this.compareFormValue(trigger.Selector, inputValue, value2) <= 0;
      case FormElementTrigger.ValueSmallerThan:
        return this.compareFormValue(trigger.Selector, inputValue, value) < 0;
      case FormElementTrigger.AnyValue:
        return this.evaluateAnyValueTrigger(inputValue, value, formControl);
      case FormElementTrigger.HasOptions:
        return formControl.hasOptions() === (!!value);
      case FormElementTrigger.Modified:
        const defaultValue: any = formControl.config.defaultValue;
        const isModified: boolean = !this.evaluateValueTrigger(inputValue, defaultValue, formControl);
        return trigger.Value === false ? !isModified : isModified;
      default:
        return inputValue === value;
    }
  }

  /**
   * Evaluar el operador "Value"
   * @param inputValue
   * @param constraintValue
   */
  protected evaluateValueTrigger(inputValue: any, constraintValue: any, formControl: FrontendFormElementInput): boolean {
    const inputValueArray: string[] = Array.isArray(inputValue) ? inputValue.map((i) => i) : [inputValue];
    const constraintValueArray: string[] = Array.isArray(constraintValue) ? constraintValue.map((i) => i) : [constraintValue];
    if (inputValueArray.length !== constraintValueArray.length) {
      return false;
    }
    return constraintValueArray.every((i) => inputValueArray.filter((x) => formControl.equalValues(x, i)).length > 0);
  }

  /**
   * Evaluar el operador "AnyValue"
   * @param inputValue
   * @param constraintValue
   */
  protected evaluateAnyValueTrigger(inputValue: any, constraintValue: any, formControl: FrontendFormElementInput): boolean {
    const inputValueArray: string[] = Array.isArray(inputValue) ? inputValue.map((i) => i) : [inputValue];
    const constraintValueArray: string[] = Array.isArray(constraintValue) ? constraintValue.map((i) => i) : [constraintValue];
    return constraintValueArray.some((i) => inputValueArray.filter((x) => formControl.equalValues(x, i)).length > 0);
  }

  /**
   * Evaluar los estados de un componente
   *
   * @param key
   * @param triggerElementData
   */
  checkState(key: string, triggerElementData: stateApiTriggerSourceData): void {
    const states: { [key: string]: IStateApiCondition } = getInSafe(this.stateApiElementDependency, te => te[key], {});

    // Hay situaciones donde el componente puede tener configuraciones
    // de estados opuestos en backend (por ejemplo una configurción para visible y otra para invisible)
    // lo que da lugar a comportamientos
    // no esperados en frontend. Utilizamos esto para llevar trazabilidad
    // de los estados que se han aplicado solo para depurar y diagnosticar.
    const appliedStates: { [key: string]: boolean } = {};

    // Evaluar los estados de los componentes que dependen del componente que ha cambiado
    Object.keys(states)
        .map(state => {
          let stateToApply: string = state;
          const evaluationResult: boolean = this.evaluateStateApiCondition(states[state], triggerElementData);
          if (!evaluationResult) {
            stateToApply = StateApiService.getOppositeState(state);
          }
          // Antes de aplicar el estado definitivo, miramos que no haya
          // habido otra regla previa que haya aplicado el estado inverso
          // lo que es confuso, no vamos a lanzar excepción, pero sí
          // enviar aviso a consola
          const invertedState: string = StateApiService.getOppositeState(stateToApply);
          if (appliedStates[invertedState] === true) {
            console.error(`Comportamiento contradictorio para componente '${key}': Aplicando el estado '${stateToApply}' pero ya se ha aplicado antes un estado inverso '${invertedState}' para el componente. No debe configurar dos estados opuestos en el mismo componente de formulario.`);
          }
          appliedStates[stateToApply] = true;
          this.applyState(key, stateToApply, triggerElementData);
        });
  }

  /**
   * Optimize dictionary to make calculations on an easier way
   */
  optimize(): void {
    for (const key in this.stateApiElementDependency) {
      if (this.stateApiElementDependency.hasOwnProperty(key)) {
        for (const state in this.stateApiElementDependency[key]) {
          if (this.stateApiElementDependency[key].hasOwnProperty(state)) {
            this.registerSelectorFromConditions(this.stateApiElementDependency[key][state] as IStateApiCondition, key);
          }
        }
      }
    }
  }

  /**
   * Optimizes and subscribe to form components
   */
  optimizeAndSubscribe(): void {

    this.optimize();

    if (isNullOrUndefined(this.formManagerService.form)) {
      return;
    }

    const elementsToTrack: object = {};

    // Los de la API de estados
    for (const k of Object.keys(this.sourceElements)) {
      elementsToTrack[k] = true;
    }

    // Los de la API de acciones
    for (const k of Object.keys(this.actionApiElementsWithTriggers)) {
      elementsToTrack[k] = true;
    }

    // Nos colgamos del cambios de valor de todos los componentes involucrados
    for (const k of Object.keys(elementsToTrack)) {
      // Verificamos que el control esté materializado
      const fc: AbstractControl = this.formManagerService.form.get(k);
      if (isNullOrUndefined(fc)) {
        continue;
      }
      const inputControl: FrontendFormElementInput = this.formManagerService.getFormComponentInputInstance(k);

      const canChangeValuePrevious: (data: stateApiTriggerSourceData) => Observable<boolean> = inputControl.canChangeValue;
      inputControl.canChangeValue = (data: stateApiTriggerSourceData) => {
        return canChangeValuePrevious(data)
            .pipe(
                switchMap((i) => {
                  if (i === false) {
                    return of(false);
                  }
                  // Si alguna de las acciones del componente es Rebuild o RebuildValues, y el formulario está bussy, no dejo cambiar
                  // el valor ya que esto provocaría que se perdieran los valores de los campos
                  // que están "bussy"
                  // TODO: Verificar que tiene un action del tipo rebuild o rebuildvalues
                  if (this.componentHasBuildOrRebuildAction(k) && this.formManagerService.formIsBussy()) {
                    this.dmbs.showConfirm(
                        {
                          messages: ['Actualmente el formulario se encuentra ocupado realizando una operación y no se puede modificar el valor de este campo.'],
                          NoLabel: null,
                          YesLabel: 'Aceptar',
                          notVisibleNo: true
                        } as ConfirmDialogLabels,
                        {
                          Title: 'Formulario ocupado',
                          HideHeader: false,
                          CssClasses: [],
                          ModalSize: DtoFrontendModalSize.Small,
                          ModalType: DtoFrontendModalType.Modal
                        } as DtoFrontendModal);
                    return of(false);
                  }
                  return this.evaluateActionApiCanChangeValueForComponent(k, data) as Observable<boolean>;
                })
            );
      };

      // El valueChanges() tambien se lanza cuando se deshabilita (.disable)
      // un control ya que angular asume que un control con disable=true
      // no tiene input de usuario

      fc.valueChanges
          .pipe(
              takeUntil(this.componentDestroyed$),
              startWith(this.formManagerService.getFormComponentValueRaw(k)),
              pairwise(),
              map(([prev, next]: [any, any]) => {
                // Como estamos escuchando al evento a nivel de componente
                // el valor todavia no esta propagado al formulario, y podemos
                // obtener el valor original.
                const previousValue: any = prev;
                const bothNull: boolean = isNullOrUndefined(next) && isNullOrUndefined(previousValue);
                // Solo calculamos la API de estados si ha habido un cambio de valor del control,
                // así evitamos cálculos innecesarios.
                if (!bothNull && (JSON.stringify(next) !== JSON.stringify(previousValue))) {
                  this.triggerElementChanged(k, next, previousValue);
                }

                return [prev, next];
              })
          )
          .subscribe(([prev, next]: [any, any]) => {
          });
    }
  }

  /**
   * Permite lanzar la detección del cálculo de estados para un elemento
   *
   * @param elementPath
   * @param currentValue
   * @param previousValue
   */
  triggerElementChanged(elementPath: string, currentValue: any, previousValue: any): void {
    const triggerElementData: stateApiTriggerSourceData = new stateApiTriggerSourceData();
    triggerElementData.prevValue = previousValue;
    triggerElementData.newValue = currentValue;
    triggerElementData.path = elementPath;
    this.elementChanged(elementPath, triggerElementData);
  }

  /**
   * Register which targets got each trigger from its conditions
   * @param {StateApiTriggerCondition[]} conditions. triger conditions
   * @param {string} key. target key
   */
  registerSelectorFromConditions(condition: IStateApiCondition, key: string): void {
    switch (condition.Type) {
      case StateApiConditionType.Trigger:
        const triggerCondition: StateApiTriggerCondition = condition as StateApiTriggerCondition;
        if (isNullOrUndefined(this.sourceElements[triggerCondition.Selector])) {
          this.sourceElements[triggerCondition.Selector] = [];
        }
        if (!!!this.sourceElements[triggerCondition.Selector].find((i) => i === key)) {
          this.sourceElements[triggerCondition.Selector].push(key);
        }
        break;
      case StateApiConditionType.GroupAnd:
      case StateApiConditionType.GroupXor:
      case StateApiConditionType.GroupOr:
        const triggerGroup: StateApiGroupCondition = condition as StateApiGroupCondition;
        if (!isNullOrUndefined(triggerGroup.Conditions)) {
          for (const c of triggerGroup.Conditions) {
            this.registerSelectorFromConditions(c, key);
          }
        }
        break;
      default:
        throw new Error('Condition type not supported: ' + condition.Type);
    }
  }
}
