<!--Aviso importante: no se utiliza el atributo nativo DISABLED del botón ya que esto impide que funcionen los ToolTips de NGX-->
<!-- [isDisabled]="isDisabled" -->
<button
    type="button"
    [id]="uniqueComponentId()"
    [ngClass]="getButtonClasses()"
    (click)="clicked()"
    [title]="toolTip ?? ''"
    placement="auto"
    [attr.data-gtmLabel]="gtmTag"
    #button>{{ config.label }}</button>

