import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { ICustomComponentInterface } from './i-custom-component.interface';
import { isNullOrUndefined } from '../utils/typescript.utils';

export const TYPE_MAP: any = {};

@Injectable()
export class CustomComponentFactoryService {

  typeMap = TYPE_MAP;

  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {
  }

  /***
   * Create the component
   * @param componentId
   * @param container
   * @param currentInjector
   * @param params
   */
  createComponent<T>(componentId: string, currentContainer: ViewContainerRef, currentInjector: Injector = null, params: {}): ComponentRef<ICustomComponentInterface> {
    if (!this.typeMap[componentId]) {
      throw new Error(`No class exists with the decorator @TypeManagerDecorator('${componentId}')`);
    }

    let componentReference: ComponentRef<ICustomComponentInterface>;
    if (!isNullOrUndefined(currentInjector)) {
      componentReference = currentContainer.createComponent(this.typeMap[componentId]);
      componentReference.instance.initializeDynamicComponent(params);
      return componentReference;
    }

    componentReference = currentContainer.createComponent(this.typeMap[componentId], null, currentInjector);
    componentReference.instance.initializeDynamicComponent(params);
    return componentReference;
  }
}
