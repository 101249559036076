import { Injectable, ViewContainerRef } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { AppConfigurationService } from '../app.configuration.service';
import { AuthService } from '../core/authentication/auth.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { switchMap } from 'rxjs/operators';
import { AuthenticationStatusEnums } from '../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Guard que se pone en partes de la aplicación que requieren que el usuario esté autenticado
 */
@Injectable()
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild, CanDeactivate<any> {

  /**
   * This attribute is used to specify the container where error notifications
   * will be shown. This variable is set on app bootstrap.
   */
  public container: ViewContainerRef;

  /**
   * AuthGuard class constructor.
   *
   * @param {Router} router
   * @param {AuthService} auth
   * @param {ThemeCustomizerService} themeCustomizerService
   */
  constructor(
      private router: Router,
      private auth: AuthService,
      private appConfig: AppConfigurationService,
      private navigationService: NavigationService
  ) {
  }

  /**
   * Interface that a class can implement to be a guard deciding if a children
   * can be loaded.
   *
   * @param {Route} route
   */
  canLoad(route: Route): Observable<boolean> {
    const url: string = `/${route.path}`;
    return this.appConfig
        .isAppBootstrapped$()
        .pipe(
            switchMap(() => this.checkLogIn(url))
        );
  }

  /**
   * Interface that a class can implement to be a guard deciding if a route can
   * be activated.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.appConfig
        .isAppBootstrapped$()
        .pipe(
            switchMap(() => this.checkLogIn(state.url))
        );
  }

  /**
   * Interface that a class can implement to be a guard deciding if a child
   * route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  /**
   * Interface that a class can implement to be a guard deciding if a route can
   * be deactivated.
   *
   * @param {any} component
   * @param {ActivatedRouteSnapshot} currentRoute
   * @param {RouterStateSnapshot} currentState
   * @param {RouterStateSnapshot} nextState
   */
  canDeactivate(
      component: any,
      currentRoute: ActivatedRouteSnapshot,
      currentState: RouterStateSnapshot,
      nextState: RouterStateSnapshot
  ): Observable<boolean> {
    // No hay motivos para no dejar desactivar un componente, podría?? ser un sitio
    // para implementar esa función de que si hay cambios en la pantalla no dejar salir? Pero
    // no está pensado ni implementado
    return of(true);
  }

  /**
   * Retorna el estado de autenticación, y redirige internamente al login
   * o al selector de persona si el usuario no está autenticado
   * @param url
   * @protected
   */
  protected checkLogIn(url: string): Observable<boolean> {
    return this.auth
        .$isAuthenticated
        .pipe(
            switchMap((i) => {
              switch (i) {
                case AuthenticationStatusEnums.Authenticated:
                  return of(true);
                  break;
                case AuthenticationStatusEnums.AuthenticatedWithoutPerson:
                  // En este caso enviar al selector de persona, excepto si ya estoy allí...
                  if (url.endsWith('selectPerson')) {
                    return of(true);
                  }
                  console.debug('Auth guard: goToSelectPerson');
                  return this.navigationService.goToSelectPerson().then((j) => false);
                  break;
                default:
                  // Enviar al login
                  console.debug('Auth guard: goToLogin');
                  return this.navigationService.goToLogin().then((j) => false);
              }
            })
        );
  }
}
