import { ChangeDetectorRef, Component, HostBinding, OnInit, SkipSelf } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { FormElementFromContentsProgress } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from '../../../utils/typescript.utils';
import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';

/**
 * Progress in elements form component.
 *
 * Este componente no necesita el Control Value Accessor por que es
 * únicamente de salida.
 */
@Component({
  selector: 'app-formelement-progress',
  templateUrl: './formelementprogress.component.html'
})
export class FormElementFromContentsProgressComponent
  extends FrontendFormElementWrapper implements OnInit {

  /**
   * @inheritdoc
   */
  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  /**
   * FormElementProgressComponent class constructor.
   *
   * @param {FormManagerService} formManager
   * @param {ChangeDetectorRef} cdRef
   * @param {ChangeDetectorRef} cdRefParent
   */
  constructor(
    protected formManager: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    @SkipSelf()
    protected cdRefParent: ChangeDetectorRef) {
    super(formManager, cdRef, cdRefParent);
  }

  /**
   * Returns the field configuration as a FormElementFromContentsProgress object.
   */
  get fieldConfig(): FormElementFromContentsProgress {
    return this.config as FormElementFromContentsProgress;
  }

  /**
   * Component init handler.
   */
  ngOnInit(): void {
    super.ngOnInit();

    setTimeout(() => this.cdRef.detectChanges());

    if (this.fieldConfig.ProgressUpdateOnChange) {
      this.group
        .valueChanges
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => setTimeout(() => this.cdRef.detectChanges()));
    }
  }

  /**
   * Get the progress value.
   */
  getProgress(): number {
    const values: any[] = this.fieldConfig.ProgressElements
      .map((k: string) => {
        return this.formManagerService.getFormComponentInputInstanceSafe(k);
      })
      .filter(k => !isNullOrUndefined(k))
      .map(k => {
        const component: AbstractControl = this.formManagerService.getFormComponent(k.config.ClientPath);
        return !k.emptyValue(component.value || null);
      });

    let value: number = (values.filter(v => v).length / this.fieldConfig.ProgressElements.length) * 100;
    value = Math.floor(value);

    if (this.fieldConfig.ProgressUseSteps) {
      value = round(value, this.fieldConfig.ProgressStepValue);
    }

    return value;
  }

  /**
   * Binding of component classes.
   */
  @HostBinding('class')
  get getClasses(): string {
    return super.getComponentClassesRendered();
  }
}

/**
 * Rounds a value to its nearest increment value.
 *
 * @param {number} value
 * @param {number} increment
 * @param {number} offset
 */
function round(value: number, increment: number, offset: number = 0): number {
  return Math.floor((value - offset) / increment) * increment + offset;
}

