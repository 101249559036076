import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SingleItemOperationEventData2 } from '../../../shared/list_v2/events/singleitemoperation.eventdata';
import { ViewFieldMetadata } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Component ViewConfiguration
 */
@Component({
  selector: 'app-view-configuration',
  templateUrl: './view-configuration.component.html'
})
export class ViewConfigurationComponent {

  /**
   * Constructor the view.
   * @param router Router
   * @param activateRoute ActivatedRoute
   */
  constructor(
      private router: Router,
      private activateRoute: ActivatedRoute
  ) {
  }

  /**
   * Handler on SingleItemOperation
   * @param event SingleItemOperationEventData2
   */
  public onSingleItemOperation(event: SingleItemOperationEventData2): void {
    const machineName: any = (event.row.Metadata.field_meta_conf_machineName as ViewFieldMetadata).RawValue;
    const configurationType: any = (event.row.Metadata.field_meta_conf_configurationType as ViewFieldMetadata).RawValue;
    this.router.navigate([machineName, configurationType, 'details'], {relativeTo: this.activateRoute});
  }
}
