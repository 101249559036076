import { Component, ElementRef } from '@angular/core';

import { ListComponent2Service } from '../../../list.service';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import { IViewHeaderComponent } from '../../view-header-component.interface';

const titleTooltip: string = 'Refrescar';

/**
 * Parent filter component
 * contains filter component and configuration component
 * emits and retrieves configuration changes
 */
@Component({
  selector: 'app-view-menu-bar',
  templateUrl: './view-menu-bar.component.html'
})
export class ViewMenuBarComponent extends DestroyableObjectTrait implements IViewHeaderComponent {


  constructor(public listComponentService: ListComponent2Service, protected _elementRef: ElementRef) {
    super();
  }

  getElementRef(): ElementRef {
    return this._elementRef
  }

  /**
   * Refresh the view without modify searching and page
   */
  refreshView(): void {
    this.listComponentService.refresh();
  }

  /**
   * Returns a string with the content for the tooltip.
   */
  get tooltip(): string {
    return titleTooltip;
  }
}
