import { Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { ChangedetectorService } from '../core/changedetector/changedetector.service';
import { ChangedetectorReference } from '../core/changedetector/changedetectoreference';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import * as $ from 'jquery';
import { Guid } from 'guid-typescript';
import { interval } from 'rxjs';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';

/**
 * Esta directiva se encarga de:
 *
 * -- Implementar un fix para un bug de posicionamiento en el componente de calendarios al estar incrustado
 * en la API de formularios que tiene el ChangeDetection desactivado por defecto
 * -- Impedir que el calendario se visualice cuando no cabe en la pantalla (Responsive)
 */
@Directive({
  selector: '[appNgxDaterangepickerChangedetectionFixerDirective]',
  providers: [
    ChangedetectorReference
  ]
})
export class NgxDaterangepickerChangedetectionFixerDirective extends DestroyableObjectTrait implements OnDestroy {

  _bsDaterangepicker: BsDaterangepickerDirective;

  /**
   * Para indicar si el componente ha sido inicializado. Ocurre que durante la inicilización
   * del componente se hace un show/hide lo que lanza los eventos de touch/blur y esto
   * no es correcto porque el usuario realmente no ha tocado los componentes.
   */
  @Output() initialized: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Id del contenedor que se creará en el DOM para alojar el calendario flotante.
   *
   * Hacemos esto porque el componente no permite acceder al calendario, y esta
   * es la única manera de conocer donde estará y poder interactuar con él.
   *
   * @private
   */
  private containerId: string;

  set bsDaterangepicker(value: BsDaterangepickerDirective) {
    this._bsDaterangepicker = value;
    // Creamos un elemento en la DOM para conenter al calendario flotante, y lo asignamos
    // como container al bsDatepicker
    $('body').prepend('<div id="' + this.containerId + '"></div>');
    this.bsDaterangepicker.container = '#' + this.containerId;
    this.hideCalendar();
    this.initializeComponent(value);
  }

  get bsDaterangepicker(): BsDaterangepickerDirective {
    return this._bsDaterangepicker;
  }

  /**
   * NgxDatepickerChangedetectionFixerDirective's class constructor.
   *
   * @param {ElementRef} elementRef
   * @param {ChangedetectorReference} cdReference
   * @param {ChangedetectorService} cdService
   */
  constructor(
      private elementRef: ElementRef,
      private cdReference: ChangedetectorReference,
      private cdService: ChangedetectorService,
  ) {
    super();
    this.containerId = 'datepicker__' + Guid.create().toString();
  }

  /**
   *
   */
  ngOnDestroy(): void {
    $('#' + this.containerId).remove();
    super.ngOnDestroy();
  }

  /**
   * Tener calendarios flotantes si hago SCROLL es muy molesto, si hago scroll QUITO el calendario.
   *
   * @param event
   */
  @HostListener('window:scroll', ['$event'])
  scrolled(event: Event): void {
    if (this.bsDaterangepicker.isOpen) {
      this.bsDaterangepicker.hide();
    }
  }

  /**
   * Al hacer resize, también lo quitamos
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  resized(event: Event): void {
    if (this.bsDaterangepicker.isOpen) {
      this.bsDaterangepicker.hide();
    }
  }

  /**
   * El calendario siempre está oculto vía CSS, solo lo pintamos si cabe...
   */
  showIfCalendarFits(): void {

    // Este es el objeto calendario ya pintado/renderizado
    const calendarObject: JQuery<HTMLElement> = $('#' + this.containerId).find('.bs-datepicker').first();

    const calendarPosition: DOMRect = calendarObject[0].getBoundingClientRect() as DOMRect;

    const topClearance: number = calendarPosition.top;
    const bottomClearance: number = window.innerHeight - calendarPosition.bottom;

    // Quiero que no se corte, ni por arriba ni por abajo, con una distancia mínima de 3px
    const fitsVertically: boolean = (topClearance > 3) && (bottomClearance > 3);

    if (fitsVertically) {
      this.showCalendar();
    } else if (calendarPosition.top - calendarPosition.height > 3) { // Si cabe en la parte superior del selector, lo movemos
      this._bsDaterangepicker.placement = 'top';

      // Reseteamos el componente para emplazarlo en la nueva posición
      this._bsDaterangepicker.hide();
      this._bsDaterangepicker.show();
      this.showCalendar();
    } else {
      this.hideCalendar();
    }
  }

  showCalendar(): void {
    // IMPORTANTE: Usamos opacity porque:
    // si usamos display:none, no se renderiza, por lo que no podemos saber si cabe o no
    // si usamos visibility:hidden, aparecen trozos del calendario, ya que este atributo
    // puede ser sobreescrito por los hijos de manera individual
    $('#' + this.containerId).css('opacity', '1');
  }

  hideCalendar(): void {
    // IMPORTANTE: Usamos opacity porque:
    // si usamos display:none, no se renderiza, por lo que no podemos saber si cabe o no
    // si usamos visibility:hidden, aparecen trozos del calendario, ya que este atributo
    // puede ser sobreescrito por los hijos de manera individual
    $('#' + this.containerId).css('opacity', '0');
  }

  /**
   * Initializes the datepicket configuraton.
   *
   * @param {BsDaterangepickerDirective} datepicker
   */
  initializeComponent(datepicker: BsDaterangepickerDirective): void {
    datepicker
        .onShown
        .pipe(
            takeUntil(this.componentDestroyed$),
            tap(() => {
              this.onDisplayChanged();
            })
        )
        .subscribe(() => {

          interval(75)
              .pipe(
                  takeUntil(datepicker.onHidden),
                  filter(() => {
                    const empty: boolean = $('#' + this.containerId).find('.bs-datepicker').length === 0;
                    return !empty;
                  }),
                  take(1),
                  tap(() => {
                    this.showIfCalendarFits();
                  })).subscribe();
        });

    datepicker
        .onHidden
        .pipe(
            takeUntil(this.componentDestroyed$),
            tap(() => {
              this.hideCalendar();
            })
        )
        .subscribe(this.onDisplayChanged.bind(this));

    // Avisar de que ya estamos inicializados
    this.initialized.emit(true);
  };

  /**
   * Re-render the application if needed
   */
  onDisplayChanged(): void {
    this.cdReference.changeDetector.detectChanges();
    this.cdService.runApplicationChangeDetection();
  }
}
