import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

@Component({
  selector: 'app-autocomplete-wrapper',
  templateUrl: './autocomplete-wrapper.component.html'
})
export class AutocompleteWrapperComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput')
  formElementInstanceValue: FrontendFormElementInput;

  @ViewChild('formInputMultiple')
  formElementInstanceValueMultiple: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.config.isMultiselect === true ? this.formElementInstanceValueMultiple : this.formElementInstanceValue;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
