import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

/**
 * Componente para embeber un listado en un formulario como input.
 *
 * Para poder preservar el comportamiento del resto de componentes de
 * formulario, que conservan su estado después de un rebuild, el VALUE de
 * este control es el ViewUserConfiguration completo.
 */
@Component({
  selector: 'app-views-embed',
  templateUrl: './views-embed.component.html'
})
export class ViewsEmbedComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', {static: true})
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get getClasses(): string {
    return super.getComponentClassesRendered();
  }
}
