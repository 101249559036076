<form #filterForm="ngForm">
  <div class="form-group">
    <label for="field">{{'Columna' | t}}</label>
    <select
        *ngIf="fields"
        id="field"
        (change)="fieldSelectionChanged(filterForm)"
        class="form-control"
        ngModel
        name="field"
        required
    >
      <option *ngFor="let f of fields | keys" [value]="fields[f].Id">{{ fields[f].Label }}</option>
    </select>
  </div>
  <div class="form-group">
    <label for="operator">{{'Contenido del filtro' | t}}</label>
    <select id="operator" class="form-control" ngModel name="operator" required [disabled]="isDisabled(filterForm)">
      <option value="" disabled selected>{{'Seleccione una opción' | t}}</option>
      <option *ngFor="let op of avaliableOperators" [value]="op.Type">{{ op.Label }}</option>
    </select>
  </div>
  <div class="form-group" *ngIf="(isText(filterForm) || isNumber(filterForm)) && isNotEmpty(filterForm)">
    <label for="value">{{'Palabra clave' | t}}</label>
    <input
        id="value"
        (change)="logForm(filterForm)"
        autocomplete="off"
        [type]="(isNumber(filterForm) && !isContainedInCsv(filterForm))  ? 'number' : 'text'"
        class="form-control"
        ngModel
        name="fieldValue"
        required
        [disabled]="isDisabled(filterForm)"
    />
  </div>
  <div class="form-group" [class.hidden]="!(isDate(filterForm) && !hasSecondValue(filterForm) && isNotEmpty(filterForm))">
    <label for="valueD">Fecha</label>
    <input
        bsDatepicker
        appNgxDatepickerChangedetectionFixerDirective
        id="valueD"
        autocomplete="off"
        class="form-control"
        ngModel
        name="fieldValue"
        [required]="(isDate(filterForm) && !hasSecondValue(filterForm) && isNotEmpty(filterForm))"
        [placeholder]="bsConfigDatepicker?.dateInputFormat"
        [disabled]="isDisabled(filterForm)"
        [bsConfig]="bsConfigDatepicker"
    />
  </div>
  <div class="form-group" [class.hidden]="!(isDate(filterForm) && hasSecondValue(filterForm) && isNotEmpty(filterForm))">
    <label for="valueDR">{{'Fecha' | t}}</label>
    <input
        id="valueDR"
        bsDaterangepicker
        appNgxDaterangepickerChangedetectionFixerDirective
        autocomplete="off"
        class="form-control"
        ngModel
        name="fieldValue"
        [required]="(isDate(filterForm) && hasSecondValue(filterForm) && isNotEmpty(filterForm))"
        [placeholder]="bsConfigDaterangepicker.rangeInputFormat"
        [disabled]="isDisabled(filterForm)"
        [bsConfig]="bsConfigDaterangepicker"
    />
  </div>
  <div class="form-group" *ngIf="(isBoolean(filterForm) || isEnum(filterForm)) && isNotEmpty(filterForm)">
    <label for="valueO">{{'Palabra clave' | t}}</label>
    <select id="valueO" ngModel name="fieldValue" required>
      <option *ngFor="let i of getOptions(filterForm)" [value]="i.Id">{{ i.DisplayName }}</option>
    </select>
  </div>
  <div class="form-group" *ngIf="hasSecondValue(filterForm) && !isDate(filterForm)">
    <label for="value2">{{'Palabra clave' | t}}</label>
    <input id="value2" autocomplete="off" class="form-control" ngModel name="fieldValue2" required/>
  </div>
  <div class="form-group elemtype-checkboxesmodal default">
    <input type="checkbox"
           *ngIf="fields"
           class="form-control"
           ngModel
           name="negate"
           id="negate"/>
    <label for="negate">{{'Aplicar el opuesto de este filtro' | t}}</label>
  </div>
  <div class="filter_accept">
    <button type="button" (click)="onHidden()" class="o-btn__danger">{{'Cancelar' | t}}</button>
    <button (click)="saveAndHideModal(filterForm)" class="o-btn__primary"
            [disabled]="!filterForm.valid">{{'Filtrar' | t}}
    </button>
  </div>
</form>
