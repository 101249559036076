import { Injectable } from '@angular/core';
import { CanDeactivateComponentInterface } from './can-deactivate-component.interface';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateComponentService {

  private components: CanDeactivateComponentInterface[];

  constructor() {
    this.components = [];
  }

  public addComponent(component: CanDeactivateComponentInterface): void {
    this.components.push(component);
  }

  public removeComponent(component: CanDeactivateComponentInterface): void {
    const index: number = this.components.findIndex(x => x === component);
    if (index !== -1) {
      this.components.splice(index);
    }
  }

  public checkAllComponentsCanBeDeactivated(): boolean {
    const componentsCannotBeDeactivated: CanDeactivateComponentInterface[] = this.components.filter(x => !x.canDeactivateComponent());
    console.log('checkAllComponentsCanBeDeactivated executed with result ' + componentsCannotBeDeactivated.length + ' of ' + this.components.length);
    return componentsCannotBeDeactivated.length === 0;
  }
}
