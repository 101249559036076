/**
 * Event thrown when a local action occurs.
 */
import { IViewsLocalAction } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

export class LocalActionEventData2 {

  /**
   * The name of the local action.
   */
  public action: string;

  /**
   * The actual instance of the local action.
   */
  public data: IViewsLocalAction;

}
