import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { RestTranslatorModule } from '../core/translator/rest-translator.module';
import { SFormModule } from '../shared/form/form.module';
import { SharedModule } from '../shared/shared.module';
import { OrganizationalStructureRoutingModule } from './organizational-structure-routing.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SFormModule,
    SharedModule,
    OrganizationalStructureRoutingModule,
    RestTranslatorModule,
    MatProgressBarModule,
    FlexModule
  ],
  exports: []
})
export class OrganizationalStructureModule { }
