<div class="c-side-nav-child" [class.open]="open">
  <div *ngFor="let item of tree; let i = index;">
    <a [appSabentisRouterLink]="item"
       appSabentisRouterLinkActive="active"
       [ngClass]="makeSafeForCssWithPrefix(item.controller)"
       *ngIf="!hasValidChildren(item)"><span>{{ item.title }}</span></a>

    <div class="c-side-nav-child__link"
         *ngIf="hasValidChildren(item)"
         [class.open]="i === openedChildren"
         (click)="changeOpenedChildren(i, item)">

      <div class="c-side-nav-child__title" [ngClass]="makeSafeForCssWithPrefix(item.controller)">{{ item.title }}</div>
      <i class="fa fa-chevron-down"></i>

    </div>

    <app-menu-child *ngIf="hasValidChildren(item)" [open]="(i === openedChildren) && open"
                    [tree]="item.children" [depth]="depth+1"></app-menu-child>

  </div>
</div>




