import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { CommandService } from '../../core/commands/command.service';
import { Observable, Subject } from 'rxjs';
import {
  AuthenticationStatusEnums,
  CoreMessageCommand,
  CoreSessionImpersonateCommand,
  CoreSessionLogoutCommand,
  ISessionInfo,
  MessageCommandData,
  WebServiceResponse
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { delay, filter, map, pairwise, switchMap, take, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../core/navigation/navigation.service';
import { AuthService } from '../../core/authentication/auth.service';
import { backendTypeMatch, isNullOrUndefined, nameof } from '../../shared/utils/typescript.utils';
import { PrimeUtils } from '../../shared/utils/prime.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../core/services/ETG_SABENTISpro_Application_Core_session.service';
import { TranslatorService } from '../../core/translator/services/rest-translator.service';
import { DecoupledModalBridgeService } from '../../shared/decoupled-modal/decoupled-modal-bridge.service';
import { ToastrService } from 'ngx-toastr';
import { SessionstateService } from '../../core/sessionstate/sessionstate.service';

/**
 * Servicio que se encarga de
 * - Gestionar las redirecciones a login/selección de persona/home en función
 * del estado de autenticación del usuario.
 * - Gestionar la suplantación
 */
@Injectable({
  providedIn: 'root'
})
export class LoginService extends DestroyableObjectTrait {

  public logoutCompleted$: Subject<boolean> = new Subject<boolean>();

  // Banderilla para ver si el usuario ha solicitado el cierre de sesión
  // explícito, para evitar mostrar el mensaje de su sesión ha caducado
  private logoutRequested: boolean = false;

  /**
   * Class constructor for `LoginService`
   * @param {Router} router
   * @param {SessionService} sessionService
   * @param {AuthService} authService
   */
  constructor(
      protected sessionService: SessionService,
      protected sessionStateService: SessionstateService,
      protected authService: AuthService,
      protected commandService: CommandService,
      protected navigationService: NavigationService,
      protected tranlsationService: TranslatorService,
      protected decoupledModalBridgeService: DecoupledModalBridgeService,
      protected toastService: ToastrService,
      protected router: Router,
      protected route: ActivatedRoute,
      @Optional() @SkipSelf() parentModule?: LoginService,
  ) {
    super();

    if (parentModule) {
      throw new Error('Login service is already loaded. Import it in the AppModule only.');
    }

    // Gestionar cambios en el estado de autenticación del usuario
    authService.$isAuthenticated
        .pipe(
            takeUntil(this.componentDestroyed$),
            pairwise()
        )
        .subscribe(([previous, next]) => {
          this.authenticationStatusChangedHandler(previous, next);
        });

    // Implementación del comando de backend que solicita un logout
    this.commandService
        .CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreSessionLogoutCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreSessionLogoutCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
              next.AddResult(() => this.doLogOut()
                  .pipe(map(() => true))
                  .toPromise())
            }
        );

    this.commandService
        .CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreSessionImpersonateCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreSessionImpersonateCommand, (() => Promise<boolean>) | Observable<boolean>>)
        )
        .subscribe((next) => {
          next.AddResult(() => {
            this.automaticUserImpersonation(next.Argument.UserId);
            return Promise.resolve(true);
          });
        });

    // Esto es para recordarle al usuario, si tiene una sesión abierta, que es una sesión de suplantación.
    this.sessionStateService.$sessionDataPropertyChanged<ISessionInfo>([nameof<ISessionInfo>('IsImpersonatedSession')])
        .pipe(
            take(1),
            filter((i) => i.IsImpersonatedSession === true)
        )
        .subscribe((i) => {
          this.toastService.info('Esta sesión es una sesión suplantada.');
        });
  }

  /**
   *
   * @param previous
   * @param next
   * @private
   */
  private authenticationStatusChangedHandler(previous: AuthenticationStatusEnums, next: AuthenticationStatusEnums): Observable<any> {
    // No hacer nada
    if (previous === next) {
      return;
    }
    console.debug(`Authentication state changed from: '${previous}' to '${next}'`)
    switch (next) {
      case AuthenticationStatusEnums.Authenticated:
        console.debug(`Authentication state: Authenticated`);
        if (this.route.snapshot.url.length === 0 || this.route.snapshot.url.findIndex(x => x.path === 'home') !== -1) {
          console.debug(`Authentication state: Authenticated - Go to home`);
          this.navigationService.goToHome().then();
        }
        break;
      case AuthenticationStatusEnums.AuthenticatedWithoutPerson:
        console.debug(`Authentication state: AuthenticatedWithoutPerson`);
        this.navigationService.goToSelectPerson().then();
        break;
      default:
        console.debug(`Authentication state: NONE`);
        if (localStorage.getItem('sso-redirect-uri')) {
          window.location.href = localStorage.getItem('sso-redirect-uri');
        }
        if (previous !== AuthenticationStatusEnums.NoAuthenticated && this.logoutRequested !== true) {
          const message: CoreMessageCommand = new CoreMessageCommand();
          message.Message = new MessageCommandData();
          message.Message.Message = this.tranlsationService.get('Su sesión ha caducado o ya no es válida.');
          this.commandService.executeCommandChain([message]).then();
        }
        this.logoutRequested = false;
        // Si hay modales abiertas, las cerramos
        this.decoupledModalBridgeService.closeAllModals().then();
        // Borramos localstorage y session storage
        localStorage.clear();
        sessionStorage.clear();
        // Completamos el logout
        this.logoutCompleted$.next(true);
        this.navigationService.goToLogin().then();
    }
  }

  /**
   * Starts an impersonated session.
   * @param {string} userid
   */
  doImpersonate(userid: string): Observable<boolean> {
    return this.sessionService
        .postImpersonate(userid, PrimeUtils.GetDevice())
        .pipe(
            map((response: WebServiceResponse) => {
              if (response.error) {
                return false;
              }
              /**
               * IMPORTANT: additional logic should not be implemented here. This method
               * only works as an observable mapper to apply the `logInCompleted`
               * and other response processing methods.
               */
              // this.setTokens(response.result);
              // No hacemos nada, al pedir la impersonación, se producen cambios internos
              // en la sesión que ya se propagan por otros sitios
              return true;
            })
        );
  }

  /**public string Name { get; } = "Dashboard PDP - Medidas Correctoras";
   * Ends an impersonated session.
   */
  doEndImpersonation(): Observable<boolean> {
    return this.sessionService.postEndimpersonate(PrimeUtils.GetDevice())
        .pipe(
            map((response: WebServiceResponse) => {
              if (response.error) {
                return false;
              }
              /**
               * IMPORTANT: additional logic should not be implemented here. This method
               * only works as an observable mapper to apply the `logInCompleted`
               * and other response processing methods.
               */
              // this.setTokens(response.result);
              // No hacemos nada, al pedir la impersonación, se producen cambios internos
              // en la sesión que ya se propagan por otros sitios
              return true;
            })
        );
  }

  /**
   * Execute the logout operation
   */
  doLogOut(): Observable<void> {
    return this.sessionService
        .postLogout(false)
        .pipe(
            map((i) => this.logoutRequested = true),
            // Para que este observable tenga sentido, debe hacer el retorno
            // cuando el logout esté completado
            switchMap((i) => this.logoutCompleted$.asObservable().pipe(map(() => null)))
        );
  }

  /**
   * Change the person on the system. It will change the Access && Refresh tokens
   * @param {string} personId
   * @returns {Observable<any>}
   */
  changePerson(personId: string): Observable<any> {
    if (isNullOrUndefined(personId)) {
      throw new Error('personId is necessary');
    }
    return this.sessionService.postChangeperson(personId);
  }

  /***
   * impersonate user
   */
  automaticUserImpersonation(user: string): void {
    this.doImpersonate(user)
        .pipe(
            takeUntil(this.componentDestroyed$),
            take(1),
            delay(4000),
            filter((i) => i === true)
        )
        .subscribe((data) => {
        });
  }
}
