import { ChangeDetectorRef, Component, HostListener, Injector, OnInit, ViewContainerRef } from '@angular/core';
import { MessageToastService } from './core/message-toast/share/message-toast.service';
import { SpinnerService } from './shared/spinner/spinner.service';
import { Router } from '@angular/router';
import { AppContainerService } from './app-container.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  @HostListener('document:click', ['$event', '$event.target'])
  onDocumentClicked(event: MouseEvent, targetElement: HTMLElement): void {
    this.appContainerService.documentClicked.next(null);
  }


  /**
   * Get an instance of AppComponent
   *
   * @param {ViewContainerRef} vcr
   * @param {MessageToastService} messageToastervice
   */
  constructor(
      public vcr: ViewContainerRef,
      private messageToastervice: MessageToastService,
      private spinnerService: SpinnerService,
      private cdRef: ChangeDetectorRef,
      private injector: Injector,
      private router: Router,
      private appContainerService: AppContainerService) {
  }

  /**
   * updates page title on component initialitation
   */
  ngOnInit(): void {
    this.spinnerService.setRootViewContainer(this.vcr, this.cdRef);
  }
}
