import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UtilsUrl } from '../utils/utils-url';
import { ClientThemeService } from '../core/theme-manager/theme.service';

/**
 * Este guard se encarga de que la ruta que pedimos esté siempre dentro de layout
 * que esté activo actualmente en la aplicación.
 *
 * * Si la ruta no tiene prefijo de layout, se la pone.
 * * Si la ruta tiene un prefijo de layout diferente al activo, se la cambia.
 *
 * Esto nos permite trabajar con rutas SIN LAYOUT (en los tests, enlaces externos, etc..)
 */
@Injectable()
export class LayoutGuard implements CanActivate {
  constructor(
      private themeService: ClientThemeService,
      private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const layoutPrefixes: string[] = ['main', 'fuse'];
    return this.themeService.currentTheme
        .pipe(
            switchMap(theme => {
              const activeLayoutPrefix: string = theme.Layout.Root;

              const currentLayoutUrl: string = layoutPrefixes.find((i) => i === route.url[0]?.path);
              const currentUrlPathName: string = new URL(UtilsUrl.JoinPath('http://localhost/', state.url)).pathname;

              // Si no tengo un prefijo de layout, se lo pongo
              if (!currentLayoutUrl) {
                const newRoute: string = UtilsUrl.JoinPath(activeLayoutPrefix, currentUrlPathName);
                return this.router.navigate([newRoute], {queryParams: route.queryParams, replaceUrl: true})
                    .then((value) => Promise.resolve(false));
              }

              const currentUrlNoLayout: string = currentUrlPathName.split('/').slice(2).join('/');

              // Si el prefijo está mal, lo cambio
              if ('/' + currentLayoutUrl + '/' !== activeLayoutPrefix) {
                const newRoute: string = UtilsUrl.JoinPath(activeLayoutPrefix, currentUrlNoLayout);
                return this.router.navigate([newRoute], {queryParams: route.queryParams, replaceUrl: true})
                    .then((value) => Promise.resolve(false));
              }

              // Todo está bien
              return of(true);
            })
        );
  }
}
