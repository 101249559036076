<ng-container
    *ngIf="listComponentService.getConfiguration() && listComponentService.getUserConfiguration()">
  <app-exposed-filters></app-exposed-filters>
  <div class="view-top">
    <div class="view-top__left">
      <app-view-searcher-new></app-view-searcher-new>
      <app-view-filter-trigger></app-view-filter-trigger>
    </div>
    <div class="view-top__right">
      <span tooltip="Actualizar" placement="bottom">
        <button class="c-btn-refresh" (click)="refreshView()">
          <i class="fas fa-sync-alt"></i>
        </button>
      </span>
      <app-view-configuration></app-view-configuration>
    </div>
  </div>
  <app-view-user-configuration-material></app-view-user-configuration-material>
  <app-view-filter-container></app-view-filter-container>
  <app-view-filter-tabs></app-view-filter-tabs>
</ng-container>
