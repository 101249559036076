import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaintenancePageComponent } from './components/maintenance-page.component';
import { OutOfServiceComponent } from './components/out-of-service.component';
import { MaintenanceRoutingModule } from './maintenance-routing.module';

@NgModule({
  imports: [
    CommonModule,
    MaintenanceRoutingModule
  ],
  declarations: [
    MaintenancePageComponent,
    OutOfServiceComponent
  ],
  exports: [
    MaintenancePageComponent,
    OutOfServiceComponent
  ]
})
export class MaintenanceModule { }
