/**
 * Created by JORGE on 12-Jul-17.
 */
import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { SpinnerComponent } from './spinner.component';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { SpinnerInstance } from './spinnerinstance.class';
import { Guid } from 'guid-typescript';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class SpinnerService {

  protected spinnerInstances: Array<SpinnerInstance> = new Array<SpinnerInstance>();

  protected ref: ComponentRef<SpinnerComponent>;

  protected viewContainerRef: ViewContainerRef;

  protected changeDetectorRef: ChangeDetectorRef;

  protected refreshSpinnerVisibilityDebouncer: Subject<void> = new Subject<void>();

  /**
   * Get an instance of SpinnerService
   *
   * @param componentFactoryResolver
   */
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {

    this.refreshSpinnerVisibilityDebouncer
      .pipe(debounceTime(350))
      .subscribe(() => {
        this.refreshSpinnerVisibility();
      });
  }

  /**
   * Done only on initialization, set the root view container ref.
   *
   * @param viewContainerRef
   */
  setRootViewContainer(viewContainerRef: ViewContainerRef, changeDetectorRef: ChangeDetectorRef): void {
    this.viewContainerRef = viewContainerRef;
    this.changeDetectorRef = changeDetectorRef;
  }

  /**
   * Show a spinner, returns a unique ID to identify the spinner.
   *
   * @param message
   *
   * @param safetyTimeoutMiliseconds
   *  If the spinner has not been freed after this period of time, it will automatically disappear.
   *
   * @param forceShowNow
   *  Set to true to avoid initial showing delay
   *
   * @param weight
   */
  showSpinner(message: string = 'Cargando...',
              safetyTimeout: number = 900000,
              weight: number = 0,
              forceShowNow: boolean = false,
              cssClasses: string[] = []): Guid {

    const spinnerInstance: SpinnerInstance = new SpinnerInstance();
    spinnerInstance.message = message;
    spinnerInstance.id = Guid.create();
    spinnerInstance.safetyTimeoutMiliseconds = safetyTimeout;
    spinnerInstance.clearOnExpireTimeout = window.setTimeout(() => this.spinnerInstanceExpirationTimeoutCallback(spinnerInstance), safetyTimeout);
    spinnerInstance.weight = weight;
    spinnerInstance.cssClasses = cssClasses;

    this.spinnerInstances.push(spinnerInstance);

    // Sort by weight
    this.spinnerInstances.sort((n1, n2) => n1.weight - n2.weight);

    if (forceShowNow === true) {
      this.refreshSpinnerVisibility();
    } else {
      this.refreshSpinnerVisibilityDebouncer.next();
    }

    return spinnerInstance.id;
  }

  updateSpinnerText(id: Guid, message: string): void {
    const spinnerInstance: SpinnerInstance = this.spinnerInstances.find((spi: SpinnerInstance) => spi.id.equals(id));
    if (isNullOrUndefined(spinnerInstance)) {
      return;
    }
    spinnerInstance.message = message;
    window.clearTimeout(spinnerInstance.clearOnExpireTimeout);
    spinnerInstance.clearOnExpireTimeout = window.setTimeout(() => this.spinnerInstanceExpirationTimeoutCallback(spinnerInstance), spinnerInstance.safetyTimeoutMiliseconds);
    this.refreshSpinnerVisibility();
  }

  /**
   * Remove a spinner instance
   */
  removeSpinner(id: Guid): void {
    if (!id) {
      return;
    }
    const spinnerInstance: SpinnerInstance = this.spinnerInstances.find((spi: SpinnerInstance) => spi.id.equals(id));
    if (isNullOrUndefined(spinnerInstance)) {
      console.error('Could not find spinner with id ' + id);
      return;
    }
    clearTimeout(spinnerInstance.clearOnExpireTimeout);
    this.spinnerInstances = this.spinnerInstances.filter((spi: SpinnerInstance) => {
      return !spi.id.equals(id);
    });
    this.refreshSpinnerVisibilityDebouncer.next();
  }

  /**
   * Verifica la existencia de spinners caducados/huerfanos.
   *
   * Este callback solo se llama si el spinner no se ha removido todavía
   */
  protected spinnerInstanceExpirationTimeoutCallback(spinnerInstance: SpinnerInstance): void {
    console.debug('SpinnerService: some spinner instances have expired and have been automatically cleared.');
    this.removeSpinner(spinnerInstance.id);
  }

  /**
   * Show or hide the spinner
   */
  protected refreshSpinnerVisibility(): void {
    if (this.spinnerInstances.length === 0) {
      this.doRemoveSpinner();
    } else {
      this.doShowSpinner();
    }
  }

  /**
   * Show a spinner, and return a unique ID for that spinner
   *
   * @param viewContainerRef
   */
  protected doShowSpinner(): string {
    // No container
    if (isNullOrUndefined(this.viewContainerRef)) {
      console.debug('SpinnerService: missing root view container reference.');
      return;
    }
    // Spinner already showing
    if (!isNullOrUndefined(this.ref)) {
      // Update the message with the top-most in the queue
      this.ref.instance.message = this.spinnerInstances[this.spinnerInstances.length - 1].message;
      this.ref.instance.cssClasses = this.spinnerInstances[this.spinnerInstances.length - 1].cssClasses;
      this.ref.instance.detectChanges();
      this.changeDetectorRef.detectChanges();
      return;
    }
    this.ref = this.viewContainerRef.createComponent(SpinnerComponent);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Remove a spinner instance
   */
  protected doRemoveSpinner(): void {
    if (isNullOrUndefined(this.ref)) {
      return;
    }
    this.ref.destroy();
    this.ref = null;
    this.changeDetectorRef.detectChanges();
  }
}
