/**
 * Enum Utils
 */
export class EnumUtils {

  /**
   * Get the Enum Name from the value
   * @param value
   * @param en
   * @constructor
   */
  public static GetEnumNameFromValue(value: number, en: any): string {
    for (const enumMember in en) {
      if (parseInt(enumMember, 10) === value) {
        return en[enumMember];
      }
    }
    return null;
  }

  /**
   * Get the enum names of a enum
   * @param en
   * @constructor
   */
  public static GetEnumNames(en: any): string[] {
    const result: string[] = [];
    for (const enumMember in en) {
      if (typeof en[enumMember] === 'string') {
      result.push(en[enumMember]);
      }
    }
    return result;
  }
}
