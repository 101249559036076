import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { GenericPermissionService } from './generic-permission.service';
import { PermissionsDirective } from './permissions.directive';
import { PermissionsWraperComponent } from './permissions-wraper.component';

@NgModule({
    imports: [CommonModule, TooltipModule.forRoot()],
    declarations: [PermissionsDirective, PermissionsWraperComponent],
    providers: [GenericPermissionService],
    exports: [PermissionsDirective]
})
export class GenericPermissionModule { }
