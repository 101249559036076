import { NgModule } from '@angular/core';

// Imports for ngx-bootstrap components
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
    imports: [
        AccordionModule.forRoot(),
        ButtonsModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TabsModule.forRoot()
    ],
    declarations: [],
    exports: [
        AccordionModule,
        ButtonsModule,
        ModalModule,
        TooltipModule,
        PopoverModule,
        BsDatepickerModule,
        TabsModule
    ],
    providers: [
        BsModalService,
        ComponentLoaderFactory
    ]
})

export class NgxBootStrapModule {
}
