import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupedVerticalBarChartComponent } from './grouped-vertical-bar-chart.component';
import { FuseSharedModule } from '../../../../../../../../../../@fuse/shared.module';
import { BarChartModule, ChartCommonModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../../utils/utils.module';

@NgModule({
    declarations: [GroupedVerticalBarChartComponent],
    imports: [
        FuseSharedModule,
        CommonModule,
        BarChartModule,
        UtilsModule,
        ChartCommonModule
    ]
})
export class GroupedVerticalBarChartModule {
}
