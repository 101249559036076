import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommunicationService } from '../../core/communication/communication.service';
import { CoreModule } from '../../core/core.module';
import { FileDropDirective } from './directives/file-drop.directive';
import { FileSelectDirective } from './directives/file-select.directive';
import { FileUploaderService } from './file-uploader.service';

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ],
  declarations: [
    FileDropDirective,
    FileSelectDirective,
  ],
  exports: [
    FileDropDirective,
    FileSelectDirective,
  ],
  providers: [
    FileUploaderService,
    CommunicationService
  ]
})
export class FileUploaderModule { }
