import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import { MenuDirection } from '../enums/dropdown-menu-direction.enum';
import { MenuItemCompiled, MenuType } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { MenuItemCompiledFrontend } from '../../../../core/navigation/models/MenuItemCompiledFrontend.class';
import { isNullOrUndefined } from '../../../utils/typescript.utils';

@Component({
  selector: 'app-dropdown-menu-child',
  templateUrl: './dropdown-menu-child.component.html',
  styleUrls: ['./dropdown-menu-child.component.css']
})
export class DropdownMenuChildComponent implements AfterViewChecked {
  @Input() children: MenuItemCompiledFrontend[];
  @Input() open = false;

  @Input() direction: MenuDirection;
  @Input() isRoot = false;

  @Output() childEvents = new EventEmitter<string>();

  @ViewChild('list', {static: true}) list;

  /**
   * Creates a new instance of DropdownMenuChildComponent
   * @param {Renderer2} renderer2
   */
  constructor(
    private renderer2: Renderer2,
    protected cdRef: ChangeDetectorRef) {
  }

  /**
   * Updates styles with the renderer when menu is type left.
   */
  ngAfterViewChecked(): void {
    if ((this.direction === MenuDirection.left) && this.isRoot) {
      this.renderer2.setStyle(this.list.nativeElement, 'left', ((this.list.nativeElement.offsetWidth * -1) + 34) + 'px');
    }
  }

  /**
   * Checks if an item can be shown. An item is displayed when is of type Normal
   * @param {MenuItemCompiled} child
   * @returns {boolean}
   */
  canShowItem(child: MenuItemCompiled): boolean {
    return child.menuType === MenuType.Normal || child.menuType === MenuType.TabContainer;
  }

  /**
   * Returns css array class for direction
   * @returns {string}
   */
  getDirection(): string[] {
    let result: string = 'left';
    switch (this.direction) {
      case MenuDirection.right:
        result = 'right';
        break;
      case MenuDirection.left:
        result = 'left';
        break;
      default:
        result = 'left';
        break;
    }
    return [result];
  }

  /**
   * Returns true if menu item has children of type menu
   * @returns {boolean}
   */
  hasMenuChildren(child: MenuItemCompiledFrontend): boolean {
    return !isNullOrUndefined(child.children) &&
      child.children.length > 0 &&
      child.children.filter(x => x.menuType === MenuType.Normal || x.menuType === MenuType.TabContainer).length > 0;
  }

  /**
   * Returns true if menu's direction is left
   * @returns {boolean}
   */
  isLeft(): boolean {
    return this.direction === MenuDirection.left;
  }

  /**
   * Menu item Handler. Emits a menu event.
   * @param {MenuItemCompiled} child
   */
  itemClicked(child: MenuItemCompiledFrontend): void {
    if (isNullOrUndefined(child.getFrontendPath())) {
      this.childEvents.emit(child.controller);
    } else {
      this.childEvents.emit(null);
    }
  }

  /**
   * Propagates menu events
   * @param e
   */
  propagateEvent(e: any): void {
    this.childEvents.emit(e);
  }
}
