import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FuseSharedModule } from '../../../../../../../../../@fuse/shared.module';
import { BubbleChartModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../utils/utils.module';
import { BasicBubbleChartComponent } from './components/basic-bubble-chart.component';

@NgModule({
    declarations: [BasicBubbleChartComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        BubbleChartModule,
        UtilsModule
    ]
})
export class FuseBubbleChartModule {
}
