import { ComponentFactoryResolver, ComponentRef, Directive, Input, Type, ViewContainerRef } from '@angular/core';

import { DestroyableObjectTrait } from '../../../../../../../shared/utils/destroyableobject.trait';
import { isNullOrUndefined } from '../../../../../../../shared/utils/typescript.utils';
import { fuseChartsComponentsConstants } from './fuse-chart-types';
import { IFuseChartTypeInterface } from './i-fuse-chart-type.interface';
import { FuseChartUtils } from '../../fuse-chart-utils.class';
import { FuseChartsService } from '../../fuse-charts.service';
import { takeUntil } from 'rxjs/operators';
import {
  ChartExecutionResponse,
  IChart
} from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Directive({
  selector: '[appFuseChart]'
})
export class FuseChartDirective extends DestroyableObjectTrait {

  protected currentChartResponse: ChartExecutionResponse;
  protected currentViewModeType: string = null;

  protected FuseChartsComponentsConstants: { [type: string]: Type<IFuseChartTypeInterface> } = {};

  component: ComponentRef<IFuseChartTypeInterface>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private chartService: FuseChartsService) {
    super();
    // Inicializar las constantes, de momento por aquí...
    this.FuseChartsComponentsConstants = fuseChartsComponentsConstants();
    this.chartService.currentChartUserConfiguration
      .pipe(
        takeUntil(this.componentDestroyed$),
      ).subscribe(x => {
      if (x) {
        this.CreateComponent(x.SelectedChart);
      }
    });
    this.chartService.lastChartExecutionResponseChanges
      .pipe(
        takeUntil(this.componentDestroyed$),
      ).subscribe(value => {
      if (value) {
        this.currentChartResponse = value;
        this.CreateComponent(this.currentChartResponse.UserConfiguration.SelectedChart);
      }
    });
  }

  get CurrentChartResponse(): ChartExecutionResponse {
    return this.currentChartResponse;
  }

  @Input() set CurrentChartResponse(value: ChartExecutionResponse) {
    this.currentChartResponse = value;
  }

  private CreateComponent(chartId: string): void {
    const chartDefinition: IChart = FuseChartUtils.GetChart(this.currentChartResponse.CompiledChart, chartId);
    if (this.currentChartResponse && this.currentViewModeType !== chartId) {
      const constant: Type<IFuseChartTypeInterface> = this.findTypeFromChart(chartDefinition);
      if (!isNullOrUndefined(this.component)) {
        this.container.clear();
        this.component = null;
      }
      this.component = this.container.createComponent(constant);
      if (!isNullOrUndefined(this.currentChartResponse)) {
        this.component.instance.setCurrentChart(this.currentChartResponse, chartDefinition);
      }
      this.currentViewModeType = chartId;
    } else {
      this.component.instance.setCurrentChart(this.currentChartResponse, chartDefinition);
    }
  }

  private findTypeFromChart(chart: IChart): Type<IFuseChartTypeInterface> {
    const chartType: string = chart['$type'];
    for (const key in this.FuseChartsComponentsConstants) {
      if (this.FuseChartsComponentsConstants.hasOwnProperty(key)) {
        if (key === chartType) {
          return this.FuseChartsComponentsConstants[key];
        }

        if (key.endsWith('.' + chartType)) {
          return this.FuseChartsComponentsConstants[key];
        }
      }
    }

    throw new Error('Could not find chart type: ' + chartType);
  }
}
