import { CommonModule } from '@angular/common';
import { isDevMode, NgModule } from '@angular/core';
import { RestTranslatorModule } from '../core/translator/rest-translator.module';
import { AboutComponent } from './components/about.component';
import { FeedbackDialogComponent } from './components/feedback-dialog.component';
import { FeedbackComponent } from './components/feedback.component';
import { HelpButtonComponent } from './components/help-button.component';
import { HelpconfigComponent } from './components/helpconfig.component';
import { HelpService } from './help.service';
import { SFormModule } from '../shared/form/form.module';
import { MailboxInfoComponent } from './components/mailbox-info.component';

/**
 * Import diferents Services to the help form.
 */
@NgModule({
    imports: [
        CommonModule,
        RestTranslatorModule,
        SFormModule,
    ],
    declarations: [
        AboutComponent,
        FeedbackComponent,
        HelpconfigComponent,
        FeedbackDialogComponent,
        HelpButtonComponent,
        MailboxInfoComponent
    ],
  exports: [
    FeedbackComponent,
    HelpconfigComponent,
    HelpButtonComponent,
    FeedbackDialogComponent,
    MailboxInfoComponent
  ],
    providers: [
    HelpService
    ]
})
export class HelpModule {
  constructor() {
    if (isDevMode()) {
      console.log('Help module started ☎');
    }
  }
}
