<div [formGroup]="group">
  <app-formelementinputlabel
    [config]="config"
    [group]="group">
  </app-formelementinputlabel>

  <app-inner-qr-code [formControlName]="config.name"
                     [id]="uniqueComponentId('qrcode-')"
                     [config]="config"
                    [group]="group">
  </app-inner-qr-code>

  <app-formelementinputdescription
    [config]="config"
    [group]="group">
  </app-formelementinputdescription>

</div>
