import { Component, OnInit } from '@angular/core';
import { ClientThemeService } from 'app/core/theme-manager/theme.service';
import { KeyValuePair } from '../../models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-theme-demo',
  templateUrl: './theme-demo.component.html'
})
export class ThemeDemoComponent implements OnInit {

  public selectedValue = '';
  private themes: KeyValuePair<string, string>[] = [];

  constructor(
    private themeService: ClientThemeService
  ) {
  }

  ngOnInit(): void {
    this.themeService.getAvailableCssThemes().subscribe(themes => {
      this.themes = themes.filter(theme => theme.Key.indexOf('achs') === -1)
    })
  }

  update(value: any): void {
    this.selectedValue = value;
    if (value) {
      this.themeService.setCurrentTheme(value);
    }
  }

  getOptions(): KeyValuePair<string, string>[] {
    return this.themes;
  }
}
