import { Guid } from 'guid-typescript';

export class SpinnerInstance {

  /**
   * The unique id for this spinner instance
   */
  public id: Guid;

  /**
   * Registered timeout to clear on expired
   */
  public clearOnExpireTimeout: number;

  /**
   * The spinner loading message
   */
  public message: string;

  /**
   * The weight
   */
  public weight = 0;

  /**
   * Css classes
   */
  public cssClasses: string[] = [];

  /**
   *
   */
  public safetyTimeoutMiliseconds: number;
}
