import { NgModule } from '@angular/core';

import { ViewModeTableModule } from '../ViewModeTable/view-mode-table.module';
import { RestTranslatorModule } from '../../../../core/translator/rest-translator.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilsModule } from '../../../utils/utils.module';
import { ViewCardBoardsComponent } from './components/card-boards.component';

@NgModule({
    imports: [
        ViewModeTableModule,
        RestTranslatorModule,
        CommonModule,
        FormsModule,
        UtilsModule,
    ],
    declarations: [
        ViewCardBoardsComponent,
    ],
    exports: [
        ViewCardBoardsComponent,
    ]
})
export class ViewModeCardBoardsModule {}
