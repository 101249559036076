import { Injectable, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MessageBarService } from '../message-bar/services/message-bar.service';
import { PrimeUtils } from '../../shared/utils/prime.utils';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CommandService } from '../commands/command.service';
import { backendTypeMatch } from '../../shared/utils/typescript.utils';
import { IResultCollector } from '../commands/resultcollector.interface';
import { CoreMaintenanceCommand } from '../models/ETG_SABENTISpro_Application_Core_models';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

/**
 * This service subscribes to CommandService and keeps the status
 * of the maintenance mode.
 */
@Injectable({
  providedIn: 'root'
})
export class MaintenanceControlService extends DestroyableObjectTrait implements OnDestroy {

  /**
   * Flag that indicates if the maintenance mode is active.
   */
  protected maintenanceMode = false;

  /**
   * MaintenanceControlService class constructor.
   */
  constructor(
      private commandService: CommandService,
      private mbService: MessageBarService,
      private router: Router,
      @Optional() @SkipSelf() parentModule?: MaintenanceControlService
  ) {
    super();

    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
          'MaintenanceControlService is already loaded. Import it in the AppModule only');
    }

    this.commandService
        .CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreMaintenanceCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreMaintenanceCommand, (() => Promise<boolean>) | Observable<boolean>>),
        )
        .subscribe((next) => {
          next.AddResult(() => {
            this.activateMaintenanceMode();
            return Promise.resolve(true);
          });
        });
  }

  /**
   * Activates the maintenance mode and navigates to the maintenance page.
   */
  goToMaintenancePage$(retry?: number): Observable<boolean> {
    this.activateMaintenanceMode();
    return fromPromise(PrimeUtils.NavigateToMaintenance(this.router, retry));
  }

  /**
   * Activates the maintenance mode and navigates to the maintenance page.
   */
  goToOutOfServicePage$(): Observable<boolean> {
    if (this.router.url.includes('out-of-service')) {
      return of(true);
    }

    return fromPromise(this.router.navigate(['/out-of-service']));
  }

  /**
   * Activates the maintenance mode.
   */
  activateMaintenanceMode(): void {
    if (!this.maintenanceModeIsActive()) {
      this.mbService.addMessage(
          `Estamos realizando tareas de mantenimiento.
        Algunas funciones pueden estar desactivadas.`,
          {closeable: false});
    }

    this.maintenanceMode = true;
  }

  /**
   * De-activates the maintenance mode.
   */
  deactivateMaintenanceMode(): void {
    this.maintenanceMode = false;
  }

  /**
   * Returns a boolean indicating if the maintenance mode is active.
   */
  maintenanceModeIsActive(): boolean {
    return this.maintenanceMode;
  }
}
