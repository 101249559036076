import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';

import { getInSafe } from '../../shared/utils/typescript.utils';
import { ICommand, WebServiceResponse } from '../models/ETG_SABENTISpro_Application_Core_models';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CommandService } from '../commands/command.service';

/**
 * This interceptor works as a `Command Bus` to handle actions triggered
 * from backend endpoints.
 */
@Injectable()
export class CommandsInterceptor implements HttpInterceptor {
  /**
   * Command Interceptor class constructor.
   * @param {CommandService} commandService
   */
  constructor(
    private commandService: CommandService
  ) {
  }

  /**
   * Intercept an outgoing `HttpRequest` and optionally transform it or the
   * response.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let commands: ICommand[];
    return next
      .handle(req)
      .pipe(
        // Después de ejecutar los subscribes, con un tap hacemos algo.
        tap(res => {
          commands = getInSafe(res as HttpResponse<WebServiceResponse>, (i) => i.body.commands, []);
          commands = this.handleSyncCommands(commands);
        }),
        // Si hay error, también pueden haber comandos
        catchError((error: HttpErrorResponse, obs: Observable<any>) => {
          commands = getInSafe(error, i => error.error['commands'], []);
          commands = this.handleSyncCommands(commands);
          return throwError(error);
        }),
        finalize(() => {
          if (commands && commands.length) {
            this.commandService.executeCommandChain(commands).then();
          }
        }));
  }

  /**
   * Este commando tiene un tratamiento especial ya que debe ejecutarse
   * antes del retry de la petición, lo que nos obliga a que sea síncrono...
   * TODO: No está bien que un comando se gestione así fuera del ciclo normal,
   * pero se mantiene porque es una migración de un comportamiento viejo
   */
  private handleSyncCommands(commands: ICommand[]): ICommand[] {
    const commandsSync: ICommand[] = commands.filter((i) => i.Sync === true);
    if (commandsSync && commandsSync.length) {
      commands = commands.filter((i) => !commandsSync.includes(i));
      this.commandService.executeCommandChain(commandsSync).then();
    }
    return commands;
  }
}
