import { Injectable } from '@angular/core';
import { Translation } from '../models/translation';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { RestTranslatorInterface } from '../component/rest-translator.interface';
import { TranslatorDatabase } from '../component/rest-translator-database.class';
import { TranslatorPassthrough } from '../component/rest-translator-passthrough.class';
import { map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TranslatorDatabaseService {

  private implementation: ReplaySubject<RestTranslatorInterface>
      = new ReplaySubject<RestTranslatorInterface>(1);

  constructor(private toastService: ToastrService) {
    this.indexDbAvailable()
        .pipe(
            map((available) => {
              if (available) {
                return new TranslatorDatabase();
              }
              this.toastService.warning(
                  'Esta aplicación necesita IndexedDB para funcionar correctamente, pero no está soportado por su navegador. Esto puede ocurrir, por ejemplo, en el modo incógnito de Firefox o Safari. Por favor, utilice un navegador compatible como Google Chrome o Microsoft Edge.',
                  'Error',
                  {disableTimeOut: true});
              return new TranslatorPassthrough();
            })
        ).subscribe((i) => this.implementation.next(i));
  }

  indexDbAvailable(): Observable<boolean> {
    if (window.indexedDB) {
      const subject: Subject<boolean> = new Subject<boolean>();
      const request: IDBOpenDBRequest = window.indexedDB.open('test-indexeddb', 2);
      request.onerror = (event) => {
        console.error('No se ha podido acceder a indexedDB: ' + JSON.stringify(event));
        subject.next(false);
      };
      request.onsuccess = (event) => {
        subject.next(true);
      };
      return subject;
    } else {
      return of(false);
    }
  }

  /**
   * Obtener todas las traducciones almacenads de un idioma determinado
   *
   * @param language
   */
  getTranslationsForLanguage(language: string): Observable<Translation[]> {
    return this.implementation
        .pipe(switchMap((i) => i.getTranslationsForLanguage(language)));
  }

  /**
   * Borra todas las traducciones para un idioma determinado, se usa
   * para resincronizar las traducciones de ese idioma.
   *
   * @param language
   */
  clearTranslationsForLanguage(language: string): Observable<number> {
    return this.implementation
        .pipe(switchMap((i) => i.clearTranslationsForLanguage(language)));
  }

  putTranslations(translations: Translation[]): Observable<number> {
    return this.implementation
        .pipe(switchMap((i) => i.putTranslations(translations)));
  }

  /**
   * Clear all the translations and remove all the localstorage locale keys
   */
  clear(): Observable<void> {
    return this.implementation
        .pipe(switchMap((i) => i.clear()));
  }
}
