import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FuseSharedModule } from '../../../../../../../../../@fuse/shared.module';
import { AreaChartModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../utils/utils.module';
import { AreaChartComponent } from './area-chart.component';

@NgModule({
    declarations: [AreaChartComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        UtilsModule,
        AreaChartModule
    ]
})
export class FuseAreaChartModule {
}
