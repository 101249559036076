import { Component } from '@angular/core';
import { AbstractDecoupledModalComponent } from '../../decoupled-modal/models/abstract-decoupled-modal.component';

import { BatchTaskInfo } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-modal-batch-item',
  templateUrl: './modal-batch-item.component.html',
  styleUrls: ['./modal-batch-item.component.scss']
})
export class ModalBatchItemComponent extends AbstractDecoupledModalComponent {

  params: { Job: BatchTaskInfo };

  constructor() {
    super();
  }
}
