<div class="c-primary-tabs">
  <div [ngClass]="['tab-level tab-level__' + i]" *ngFor="let tabLevel of tabs; let i = index">
    <ng-container *ngFor="let tab of tabLevel.Tabs">
      <div [tooltip]="tab.NavigationToolTip">
        <a
            [appSabentisRouterLink]="tab"
            appSabentisRouterLinkActive="is-current"
            placement="auto"
            [ngClass]="getTabClasses(tab, i)"
        >
          {{ tab.title }}
        </a>
      </div>
    </ng-container>
  </div>
</div>
