/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ChartPluginRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ChartUserConfiguration } from '../models/ETG_SABENTISpro_Application_Core_models';
import { IChartExportingResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ChartExecutionResponse } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class ChartService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Export a Chart as Excel
  *
  * @param {ChartPluginRequest} pluginRequest Information about the requested plugin
  * @param {ChartUserConfiguration} userConfiguration The user configuration
  * @param {boolean} deferredExecution
  * @param {string} chartId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {IChartExportingResult} General configuration of the list and the specific configuration of the user (if any)
  */
  postExportrequestexcel(pluginRequest: ChartPluginRequest, userConfiguration: ChartUserConfiguration, deferredExecution: boolean, chartId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<IChartExportingResult>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'userConfiguration' : JSON.stringify(userConfiguration), 'deferredExecution' : JSON.stringify(deferredExecution), 'chartId' : chartId};
    return this.generalService.post(`core-chart/export-request-excel/${chartId}`, params, clientOpts) as Observable<WebServiceResponseTyped<IChartExportingResult>>;
  }

  /**
  *
  * Exporta la gráfica en formato tabla en html
  *
  * @param {ChartPluginRequest} pluginRequest Information about the requested plugin
  * @param {ChartUserConfiguration} userConfiguration The user configuration
  * @param {string} chartId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {IChartExportingResult} General configuration of the list and the specific configuration of the user (if any)
  */
  postExportrequesthtmltable(pluginRequest: ChartPluginRequest, userConfiguration: ChartUserConfiguration, chartId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<IChartExportingResult>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'userConfiguration' : JSON.stringify(userConfiguration), 'chartId' : chartId};
    return this.generalService.post(`core-chart/export-request-htmlTable/${chartId}`, params, clientOpts) as Observable<WebServiceResponseTyped<IChartExportingResult>>;
  }

  /**
  *
  * Export a Chart
  *
  * @param {ChartPluginRequest} pluginRequest Information about the requested plugin
  * @param {ChartUserConfiguration} userConfiguration The user configuration
  * @param {boolean} deferredExecution
  * @param {string} chartId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {IChartExportingResult} General configuration of the list and the specific configuration of the user (if any)
  */
  postExportrequestimage(pluginRequest: ChartPluginRequest, userConfiguration: ChartUserConfiguration, deferredExecution: boolean, chartId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<IChartExportingResult>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'userConfiguration' : JSON.stringify(userConfiguration), 'deferredExecution' : JSON.stringify(deferredExecution), 'chartId' : chartId};
    return this.generalService.post(`core-chart/export-request-image/${chartId}`, params, clientOpts) as Observable<WebServiceResponseTyped<IChartExportingResult>>;
  }

  /**
  *
  * Get the configuration of a listing by your ID
  *
  * @param {ChartPluginRequest} pluginRequest Information about the requested plugin
  * @param {string} chartId
  * @param {ChartUserConfiguration} userConfiguration The user configuration
  * @param {boolean} skipCache
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {ChartExecutionResponse} General configuration of the list and the specific configuration of the user (if any)
  */
  postLoad(pluginRequest: ChartPluginRequest, chartId: string, userConfiguration: ChartUserConfiguration = null, skipCache: boolean = false, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<ChartExecutionResponse>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'chartId' : chartId, 'userConfiguration' : JSON.stringify(userConfiguration), 'skipCache' : JSON.stringify(skipCache)};
    return this.generalService.post(`core-chart/load/${chartId}`, params, clientOpts) as Observable<WebServiceResponseTyped<ChartExecutionResponse>>;
  }
}
