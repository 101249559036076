import { Component } from '@angular/core';

import { BreadCrumb_Service } from './bread-crumb.service';
import { MenuType } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html'
})
export class BreadCrumb_Component {

  constructor(public breadrumbService: BreadCrumb_Service,
              public navigationService: NavigationService) {
  }

  /**
   * Indica si el item de menú aparece o no context-app-selector.component.htmlen el breadcrumb
   * @param item
   */
  showInBreadcrumb(item: MenuItemCompiledFrontend): boolean {
    if ([MenuType.Ghost].includes(item.menuType)) {
      return false;
    }
    return true;
  }
}
