import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { asIterableObject, getInSafe } from '../../../../../../../../../shared/utils/typescript.utils';
import { FuseChartsService } from '../../../../fuse-charts.service';
import { BaseFuseChartTypeInterface } from '../../base-fuse-chart-type.class';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { FrontendGeocodingService } from '../../../../../../../../../shared/geocoding/frontend-geocoding.service';
import { takeUntil } from 'rxjs/operators';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import {
  GeoCoordinate,
  ISerieValue,
  MapLocationsChart,
  MapLocationsDisplayOptions,
  SerieValue
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-basic-map',
  templateUrl: './basic-map.component.html',
  styleUrls: ['./basic-map.component.scss'],
  providers: [ChangedetectorReference]
})
export class BasicMapComponent extends BaseFuseChartTypeInterface<MapLocationsChart, MapLocationsDisplayOptions> {

  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  /**
   * Markers array.
   */
  public markers: {
    position: google.maps.LatLngLiteral,
    title: string
  }[] = [];

  /**
   * Initial center
   */
  public initialCenter: google.maps.LatLngLiteral;

  /**
   * flag for view map
   *
   */
  public viewMap: boolean = false;

  /**
   * BasicMapComponent class constructor.
   *
   * @param {FuseChartsService} fuseChartsService
   * @param {ChangeDetectorRef} cdRef
   */
  constructor(
    public fuseChartsService: FuseChartsService,
    private cdRef: ChangeDetectorRef,
    protected cdReference: ChangedetectorReference,
    public frontendGeocondingService: FrontendGeocodingService) {
    super(cdReference);
    this.frontendGeocondingService
      .apiLoaded
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(x => {
          this.viewMap = x;
        }
      )
  }

  /**
   * Return the description for a chart.
   */
  get description(): string {
    return getInSafe(this.chartResponse, c => c.CompiledChart.Description, null);
  }

  /**
   * Initialize map.
   */
  initializeChart(): void {
    const lSerie: { [key: string]: ISerieValue } = getInSafe(this.getDataSeries(), r => r.Series[this.currentChart.LabelSeriesId].Values, {});
    const vSerie: { [key: string]: ISerieValue } = getInSafe(this.getDataSeries(), r => r.Series[this.currentChart.ValueSeriesId].Values, {});
    const markers: {
      position: google.maps.LatLngLiteral,
      title: string
    }[] = [];

    asIterableObject(lSerie).forEach((lItem: ISerieValue, index: number) => {
      const vItem: ISerieValue = vSerie[lItem.Id];

      markers.push(
        {
          position: {
            lat: Number((vItem.Value as GeoCoordinate).Latitud),
            lng: Number((vItem.Value as GeoCoordinate).Longitud),
          } as google.maps.LatLngLiteral,
          title: (lItem as SerieValue).Value
        }
      );
    });

    if (markers.length > 0) {
      this.initialCenter = markers[0].position;
    } else {
      this.initialCenter = {
        lat: this.frontendGeocondingService.defaultLatitude,
        lng: this.frontendGeocondingService.defaultLongitude
      } as google.maps.LatLngLiteral;
    }

    this.markers = markers;
    this.detectChanges();
  }

  openInfoWindow(marker: MapMarker): void {
    this.infoWindow.open(marker);
    this.detectChanges();
  }

  /**
   * Triggers a change detection cycle.
   */
  private detectChanges(): void {
    this.cdRef.detectChanges();
  }
}
