<ng-container *ngIf="viewConfigurationSelectorColumns?.length <= 0">
  <ng-container *ngTemplateOutlet="nocolumns"></ng-container>
</ng-container>

<ng-container *ngIf="viewConfigurationSelectorColumns?.length > 0">
  <ng-container *ngTemplateOutlet="columns"></ng-container>
</ng-container>

<ng-container *ngTemplateOutlet="actions"></ng-container>

<ng-template #nocolumns>
  <span class="u-font-size__xxs" style="padding-bottom: 15px">
    {{ 'No hay columnas disponibles.' | t }}
  </span>
</ng-template>

<ng-template #columns>
  <div style="display: flex; flex-direction: column">
      <span class="u-font-size__xxs" style="padding-bottom: 15px">
    {{ 'A continuación puede marcar las columnas que desea ver:' | t }}
  </span>
    <ng-container *ngFor="let item of viewConfigurationSelectorColumns; let i = index">
      <div [id]="'filter_' + i" [ngClass]="'c-radio-checkbox input_filter_' + i">
        <input type="checkbox"
               [id]="'input_filter_' + i"
               [(ngModel)]="item.Visible"
               [disabled]="item.Disabled"
               #checkElement/>
        <label [for]="'input_filter_' + i" class="o-checkradio__label t-check"
               [title]="item?.Name">{{ item?.Name }}
        </label>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #actions>
  <div class="filter_columns">
    <button *ngIf="viewConfigurationSelectorColumns?.length > 0" type="button" class="o-btn__link"
            (click)="removeAllItems()">{{ 'Quitar todas las columnas' | t }}
    </button>

    <button *ngIf="viewConfigurationSelectorColumns?.length > 0" type="button" class="o-btn__link"
            (click)="addAllItems()">{{ 'Añadir todas las columnas' | t }}
    </button>
  </div>
  <div class="filter_accept">
    <button type="button" class="o-btn__danger" (click)="closeHandler()">{{ 'Cancelar' | t }}</button>
    <button *ngIf="viewConfigurationSelectorColumns?.length > 0" type="button" class="o-btn__primary"
            [disabled]="noColumnsSelected"
            (click)="saveAndCloseHandler()">{{ 'Aceptar' | t }}
    </button>
  </div>
</ng-template>
