import { ChangeDetectorRef, Component } from '@angular/core';
import { of } from 'rxjs';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import { delay, takeUntil } from 'rxjs/operators';

/**
 * To do: Pending to change when modals service will be full operative.
 */
@Component({
  selector: 'app-view-configuration-modal',
  templateUrl: './view-configuration-modal.component.html',
  styles: [`
    .modal {
      background: rgba(0, 0, 0, 0.6);
    }
  `]
})
export class ViewConfigurationModalComponent extends DestroyableObjectTrait {

  visible = false;

  visibleAnimate = false;

  /**
   * Creates a new instance of ViewConfigurationModalComponent
   */
  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  /**
   * Hides modal.
   */
  hide(): void {
    this.visibleAnimate = false;

    of(null)
        .pipe(delay(300),
            takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.visible = false;
          this.cdRef.detectChanges();
        });
  }

  /**
   * Hides the modal when clicks outside the element.
   */
  onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal')) {
      this.hide();
    }
  }

  /**
   * Change modal visibility.
   */
  show(): void {
    this.visible = true;

    of(null)
        .pipe(
            delay(100),
            takeUntil(this.componentDestroyed$))
        .subscribe(() => {
          this.visibleAnimate = true;
          this.cdRef.detectChanges();
        });
  }

}
