import { Component, HostBinding, Input } from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import {
  IViewModeUserConfigurationColumn,
  ViewModeTableColumn,
  ViewResultCellType,
  ViewResultRow
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../list.service';
import { IGridRowMode } from '../shared/grid-row-mode-interface.component';
import { getInSafe } from '../../../../utils/typescript.utils';
import { ViewModeUtils } from '../../viewmode.utils';

/**
 * Components that extends `IGridRowMode` works as row iterators to
 * display the table data in a customized way for a grid.
 *
 * Usually `IGridRowMode` components selector use a tbody[app-attribute] to
 * contain the rows, making the component friendlier to CSS and simpler
 * to handle.
 */
@Component({
  // Include this component as and attribute selector component to simplofy the
  // css treatment.
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tbody[app-grid-row-default]',
  templateUrl: './grid-row-default.component.html'
})
export class GridRowDefaultComponent implements IGridRowMode {

  /**
   * Fields on headers.
   */
  @Input() headerFields: IViewModeUserConfigurationColumn[];

  /**
   * Import of `isNullOrUndefined`.
   */
  isNullOrUndefined = isNullOrUndefined;

  /**
   * Import of `ViewResultCellType`.
   *
   * @type {ViewResultCellType}
   */
  ViewResultCellType = ViewResultCellType;

  /**
   * If needed to trigger a custom row initialization logic,
   * then this is the place to put it.
   */
  initialize(): void {
  }

  /**
   * GridRowDefaultComponent class constructor.
   * @param {ListComponent2Service} listComponentService Component's list service.
   */
  constructor(protected listComponentService: ListComponent2Service) {
  }

  /**
   * Component class attr setter.
   */
  @HostBinding('class')
  get hostWrapperClasses(): string {
    return 'view-table-body views-row-group';
  }

  /**
   * Return the view data.
   */
  get data(): ViewResultRow[] {
    return getInSafe(this.listComponentService.data, (data) => data.Results, []);
  }

  getViewModeTableColumn(headerUserField: IViewModeUserConfigurationColumn): ViewModeTableColumn {
    return ViewModeUtils.getViewModeTableColumn(headerUserField, this.listComponentService);
  }

  /**
   * Track element unique keys for better performance
   *
   * @param {number} index
   * @param {ViewResultRow} element
   * @returns {number}
   */
  trackElement(index: number, element: ViewResultRow): number {
    return element ? element.Index : null;
  }

  /**
   * Resolve css classes for each cell.
   * @param fieldInclude
   * @param rowResult
   */
  resolveTableCellClasses(fieldInclude: IViewModeUserConfigurationColumn, rowResult: ViewResultRow): string[] {
    return ViewModeUtils.resolveTableCellClasses(fieldInclude, rowResult, this.listComponentService);
  }

  /**
   * Resolve css classes for each row.
   * @param {ViewResultRow} rowResult
   * @returns {any}
   */
  resolveRowClasses(rowResult: ViewResultRow): string[] {
    return ViewModeUtils.resolveRowClasses(rowResult);
  }

  /**
   * Check the type of a cell
   * @param fieldInclude
   */
  checkFieldType(row: ViewResultRow, fieldInclude: IViewModeUserConfigurationColumn): string {
    return ViewModeUtils.checkFieldType(row, fieldInclude, this.listComponentService);
  }


  /**
   * Check the type of a cell
   * @param fieldInclude
   */
  checkFieldTypeInTypes(row: ViewResultRow, fieldInclude: IViewModeUserConfigurationColumn, types: string[]): boolean {
    return types.findIndex(x => this.checkFieldType(row, fieldInclude) === x) !== -1;
  }
}
