/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { BatchTaskInfo } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class ReportService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Creates a new Report from a given plugin or entity
  *
  * @param {string} target entity or plugin from wich report is created
  * @param {any} targetArguments additional parameters for report creation
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {BatchTaskInfo}
  */
  postCreatereport(target: string, targetArguments: any = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<BatchTaskInfo>>
  {
    const params: any = {'target' : target, 'targetArguments' : JSON.stringify(targetArguments)};
    return this.generalService.post(`core-reports/create-report`, params, clientOpts) as Observable<WebServiceResponseTyped<BatchTaskInfo>>;
  }
}
