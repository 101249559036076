import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { AppMappingService } from '../../../shared/mapping/mapping.service';
import { FormManagerComponent } from '../../../shared/form/form-manager/form-manager.component';
import { BackNavigateOptions } from '../../../core/navigation/object-navigation';
import {
  MappingDefinition,
  MappingDefinitionNonGeneric
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * MappingDetailsComponent: component that controls the mapping details
 *
 * @export
 * @class MappingExecutionDetailsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-mapping-details',
  templateUrl: './mapping-details.component.html'
})

/**
 * This view controls the visualization of information for users and asociated groups
 */
export class MappingDetailsComponent implements OnInit {

  @ViewChild('form', {read: FormManagerComponent, static: true}) form;
  params = {};

  /**
   * Creates an instance of MappingDetailsComponent
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private navigationService: NavigationService, private appMappingService: AppMappingService) {
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    const routeParams: object[] = this.navigationService.findParamsFromRoute(this.activatedRoute.snapshot);
    const params: object = routeParams.find(x => x.hasOwnProperty('mapping'));
    if (!isNullOrUndefined(params)) {
      if (this.router.url.includes('copy')) {
        this.params['copyFromMappingId'] = params['mapping'];
      } else {
        this.params['mappingdefinitionId'] = params['mapping'];
      }
    }
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    if (this.router.url.includes('add')) {
      // redirect to list
      this.navigationService.back();
    } else {
      this.navigationService.back({skipMapping: true} as BackNavigateOptions);
    }
  }

  /**
   * Handler for form succeed event.
   * @param e
   */
  saveSucceed(e: any): void {
    if (this.router.url.includes('add')) {
      // redirect to list
      this.navigationService.back();
    } else {
      this.navigationService.back();
    }
  }

  /**
   * Handler for form succeed event.
   * @param e
   */
  formEventExecuted(e: any): void {
    switch (e) {
      case 'generateTemplate':
        const definition: MappingDefinitionNonGeneric = JSON.parse(this.form.value['configurationRaw']) as MappingDefinitionNonGeneric;
        this.appMappingService.GenerateTemplate(definition);
        break;
    }
  }
}
