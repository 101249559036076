/**
 * Una clase que sirve para cachear cosas de cliente
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClientCache {

  /**
   * Browser level cache
   *
   * @protected
   */
  protected memoryCache: { [key: string]: string } = {};

  constructor() {
  }

  /**
   * Retrieve an item from the cache
   *
   * @param key
   * @param defaultValue
   */
  public getItem<T>(key: string, defaultValue: T): T {

    if (this.memoryCache.hasOwnProperty(key)) {
      return JSON.parse(this.memoryCache[key]) as T;
    }

    const persistentItem: string = localStorage.getItem('client-cache-class::' + key);

    if (persistentItem) {
      return JSON.parse(persistentItem) as T;
    }

    return defaultValue;
  }

  /**
   * Guardar un item en caché
   *
   * @param key
   * @param item
   * @param scope
   *   Si es persistente a refrescos del navegador, o solo se aguanta durante la
   *   sesión actual de navegador
   */
  public setItem(key: string, item: any, scope: 'persistent' | 'browser'): void {
    switch (scope) {
      case 'persistent':
        localStorage.setItem('client-cache-class::' + key, JSON.stringify(item));
        break;
      case 'browser':
        this.memoryCache[key] = JSON.stringify(item);
        break;
      default:
        throw new Error('Invalid scope type');
    }
  }
}
