<div class="map-container" *ngIf="googleMapsApiLoaded">
  <div [hidden]="!loading" class="spinner-map"></div>
  <google-map *ngIf="!authError"
              height="300px"
              width="100%"
              [zoom]="zoom"
              [center]="position">
    <map-marker *ngIf="position"
                #markerComponent="mapMarker"
                [position]="position">
    </map-marker>
  </google-map>
</div>
