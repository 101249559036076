import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationService } from './navigation.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ClientThemeService } from '../theme-manager/theme.service';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { KeyValuePair } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({
  providedIn: 'root'
})
/**
 * Servicio para propagar los cambios en la navegación al título de página HTML. Se implementa porque
 * ayuda en la propagación de informacíon de ubicación a Google Analytics
 */
export class PagetitleService extends DestroyableObjectTrait {

  private defaulTitle: string = null;

  constructor(
    private titleService: Title,
    private navigationService: NavigationService,
    private themeService: ClientThemeService,
    @Optional() @SkipSelf() parentModule?: PagetitleService
  ) {
    super();

    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
        'PagetitleService is already loaded. Import it in the AppModule only');
    }

    this.defaulTitle = titleService.getTitle();

    this.themeService.currentTheme
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(theme => {
        if (titleService.getTitle() === this.defaulTitle) {
          this.titleService.setTitle(theme?.LoginDefinition?.Title);
        }

        const iconElement: HTMLLinkElement = Array.from(document.head.getElementsByTagName('link'))
          .map(x => x as HTMLLinkElement)
          .find(x => x.rel === 'icon' && x.type === 'image/x-icon');

        if (iconElement) {
          this.themeService.currentStyleSheetsChanged.pipe(takeUntil(this.componentDestroyed$),
            map(stylesheets => {
              const activeTheme: KeyValuePair<string, string> = stylesheets.find(t => t.Value.includes(theme.CssTheme))
              if (activeTheme && activeTheme.Value && iconElement && iconElement.hasAttribute('href')) {
                const path: string = activeTheme.Value.substr(0, activeTheme.Value.lastIndexOf('/'));
                return `${path}/${theme.CssTheme}.ico`;
              }
              return null;
            })
          ).subscribe(path => {
            if (path) {
              iconElement.href = path;
            }
          });
        }
      });

    this.navigationService.currentNavigationRequest
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((i) => i !== null),
        map((i) => {
          if (i && i.responseNodeForTitle && i.responseNodeForTitle.title) {
            this.titleService.setTitle(i.responseNodeForTitle?.title);
          } else {
            this.titleService.setTitle(this.defaulTitle);
          }
        })
      ).subscribe();
  }
}
