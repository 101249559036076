import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../core/authentication/auth.service';
import { NavigationService } from '../../core/navigation/navigation.service';
import { BackNavigateOptions } from '../../core/navigation/object-navigation';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import { SessionService } from '../../core/services/ETG_SABENTISpro_Application_Core_session.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html'
})
export class AccessComponent extends DestroyableObjectTrait implements OnInit {
  @ViewChild('form', {static: true}) form;
  public params: { editProfile: boolean };
  public currentUrl: string;

  /**
   * Creates an instance of AccessComponent
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   * @param {AuthService} auth
   * @param sessionService
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private auth: AuthService,
    private sessionService: SessionService
  ) {
    super();
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.params = {
      editProfile: true
    };
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    this.navigationService.back({relativePath: ['../..'], activatedRoute: this.activatedRoute} as BackNavigateOptions);
  }
}
