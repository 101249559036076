import { Directive, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../core/changedetector/changedetectoreference';
import { getInSafe } from '../../../../../../../shared/utils/typescript.utils';
import { parseKeyItemToArray } from '../../../../../../../shared/utils/prime.utils';
import { NGXChartComponent } from './ngx-chart.class';
import {
  GroupedHorizontalBarChart,
  GroupedVerticalBarChart,
  ILegendChartDisplayOption,
  ISerie,
  NGXChartDisplayOptions,
  SerieValue
} from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Directive()
export abstract class NGXGroupedSeriesChartComponent<TChart extends GroupedHorizontalBarChart | GroupedVerticalBarChart, TDisplayOptions extends NGXChartDisplayOptions>
  extends NGXChartComponent<TChart, TDisplayOptions> implements OnInit {

  /**
   * Data to display.
   */
  data: { name: string, series?: { name: string, value: number }[] }[];

  /**
   * Color Scheme
   */
  colorScheme: { domain: string[] }

  /**
   * VerticalBarChartComponent class constructor.
   */
  protected constructor(protected cdReference: ChangedetectorReference) {
    super(cdReference);
    this.data = [];
  }

  /**
   * Component initialization lifecycle method.
   */
  ngOnInit(): void {
    this.chart.update();
  }

  getTooltipText(item: any): string {
    if (this.displayOptions && this.displayOptions.TooltipText && item) {
      return this.displayOptions.TooltipText.replace('{value}', item.value);
    }
    return '';
  }

  /**
   * Initialization method.
   */
  public initializeChart(): void {
    this.colorScheme = getInSafe(this.displayOptions, x => {
      return {domain: x.ColorScheme}
    }, {domain: []});

    const labelSerie: ISerie = (this.getDataSeries()).Series[this.currentChart.LabelSeriesId];
    const labels: SerieValue[] = parseKeyItemToArray<SerieValue>(labelSerie.Values);

    const series: ISerie[] = parseKeyItemToArray<ISerie>(this.getDataSeries().Series)
      .filter((v: ISerie) => v['key'] !== this.currentChart.LabelSeriesId);

    this.data = [];

    series.map((s: ISerie) => {
      const values: SerieValue[] = parseKeyItemToArray<SerieValue>(s.Values);

      this.data.push({
        name: s.Name,
        series: labels.map((label: SerieValue) => ({name: label.Value, value: values[label.Id].Value}))
      });
    });

    this.numSeries = labels.length;
    this.view = this.GetViewValueOnInitializeChart();
    this.chart.update();
    this.cdReference.changeDetector.detectChanges();
  }

  onNgxChartResized(event: any): void {
    this.view = this.GetViewValueOnResize();
    this.cdReference.changeDetectorParent.detectChanges();
  }

  onNgxChartResizedWithDirective(): void {
    this.view = this.GetViewValueOnResize();
    this.cdReference.changeDetectorParent.detectChanges();
  }

  /**
   * Custom responsive management
   */
  public getLegendDisplayOptions(): ILegendChartDisplayOption {
    if (!this.displayOptions) {
      return null;
    }
    const value: NGXChartDisplayOptions = Object.assign(new NGXChartDisplayOptions(), this.displayOptions);
    if (window.innerWidth < 600) {
      value.LegendPosition = 'bottom';
    }
    return value;
  }

  public abstract GetViewValueOnInitializeChart(): [number, number];

  public abstract GetViewValueOnResize(): [number, number];
}
