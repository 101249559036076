import { Params } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { MenuItemCompiledFrontend } from './MenuItemCompiledFrontend.class';
import { MenuQueryResponse } from '../../models/ETG_SABENTISpro_Application_Core_models';

/**
 * Petición a backend de navegación
 */
export class NavigationRequest {

  /**
   * Como estos requests se van guardando en una caché en memoria, guardamos el timestamp
   * para detectar aquellos que están en menor uso.
   */
  public requestLastHit: Date;

  /**
   * The resolved controller for this request
   */
  public requestController: string;

  /**
   * The backend path for this request, calculated from the initial controller mapping
   */
  public requestBackendPath: string;

  /**
   * La profundidad en la navegación de esta ruta
   */
  public requestBackendPathDepth: number;

  /**
   * The backend path for this request, with any arguments replaced
   */
  public requestBackendExpandedPath: string;

  /**
   * The original angular path (Frontend) for this request
   */
  public requestPath: string;

  /**
   * The path expanded path (frontend) with the specific arguments
   */
  public requestExpandedPath: string;

  /**
   * The original angular (Frontend) path arguments for this menu request
   */
  public requestArguments: { [id: string]: string };

  /**
   * Query string arguments for the request
   */
  public requestQueryParams: Params;

  /**
   * Respuesta del servidor original
   */
  public responseRemoteMenu: MenuQueryResponse;

  /**
   * Esto es para soportar la eplotación legacy, donde muchas funciones
   * necesitan un path que va desde el nodo actual (el más bajo) hasta la raíz.
   */
  public responseMenuPath: MenuItemCompiledFrontend[];

  /**
   * Node de navegación que se usará para el título y otros atributos descriptivos.
   */
  public responseNodeForTitle: MenuItemCompiledFrontend;

  /**
   * Los menús, transformados al DTO de frontend
   */
  public responseProcessedTree: MenuItemCompiledFrontend[];

  /**
   * Request has completed
   */
  public requestCompleted: ReplaySubject<NavigationRequest> = new ReplaySubject<NavigationRequest>(1);

  /**
   *
   */
  constructor() {
    this.requestController = '';
    this.requestExpandedPath = '';
    this.requestBackendExpandedPath = '';
    this.requestBackendPath = '';
    this.requestPath = '';
    this.requestLastHit = new Date();
  }
}
