<li>
  <!--Este link hardcodeado es para compensar el hecho de que la home NO es la raíz de navegación de la aplicación, ya que se introdujo posteriormente en home/home-->
  <a [routerLink]="navigationService.getHomeURL()">Inicio</a>
</li>
<ng-container *ngFor="let bc of breadrumbService.breadcrumb;">
  <li *ngIf="showInBreadcrumb(bc)">
    <a [appSabentisRouterLink]="bc"
       appSabentisRouterLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="bc.frontendCanNavigate">{{ bc.title }}</a>
    <span *ngIf="!bc.frontendCanNavigate">{{ bc.title }}</span>
  </li>
</ng-container>
