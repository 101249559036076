import { Injectable, ViewContainerRef } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { AppConfigurationService } from '../app.configuration.service';
import { AuthService } from '../core/authentication/auth.service';
import { switchMap, take } from 'rxjs/operators';
import { AuthenticationStatusEnums } from '../core/models/ETG_SABENTISpro_Application_Core_models';
import { NavigationService } from '../core/navigation/navigation.service';

/**
 * Guard que almacena / restaura la URL a la que se quería navegar antes de hacer login
 */
@Injectable()
export class KeepUrlBeforeLoginGuard implements CanLoad, CanActivate {

  /**
   * This attribute is used to specify the container where error notifications
   * will be shown. This variable is set on app bootstrap.
   */
  public container: ViewContainerRef;

  /**
   * AuthGuard class constructor.
   *
   * @param {Router} router
   * @param {AuthService} auth
   * @param {ThemeCustomizerService} themeCustomizerService
   */
  constructor(
      private router: Router,
      private navigationService: NavigationService,
      private auth: AuthService,
      private appConfig: AppConfigurationService
  ) {
  }

  /**
   * Interface that a class can implement to be a guard deciding if a children
   * can be loaded.
   *
   * Usamos la sobrecarga con Segments para calcular la URL a la que nos queriamos dirigir
   * (route.path siempre es "" debido al sitio donde el guard esta declarado).
   * Esto es debido a que canLoad es un pipe que se ejecuta para saber si debemos hacer
   * lazy-load antes de los canActivate. Si el canLoad es false no se ejecutan los canActivate.
   * Esto entra en conflicto con AuthGuard, que es un guard que se coloca en
   * todos las rutas que requieran autenticación, si usaramos canActivate
   * (que sí que tiene la ruta debido a que tiene el ActivatedRoute)
   * nunca almacenariamos la URL origen.
   *
   * @param {Route} route
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const urlWithParams: string = window.location.href;
    // Extract the query parameters using the URLSearchParams API
    const searchParams: URLSearchParams = new URLSearchParams(urlWithParams.split('?')[1]);
    const queryParams: { [key: string]: string } = {};

    searchParams.forEach((value: string, key: string) => {
      queryParams[key] = value;
    });

    console.debug('Keep:' + JSON.stringify(queryParams));
    const fullPath: string = segments.reduce((path, currentSegment) => {
      return `${path}/${currentSegment.path}`;
    }, '/home');

    const finalUrl: UrlTree = this.router.parseUrl(fullPath);
    finalUrl.queryParams = queryParams;

    return this.appConfig
        .isAppBootstrapped$()
        .pipe(take(1),
            switchMap(() => this.checkIsNotAuthenticatedAndSaveUrlPreLogin(finalUrl))
        );
  }

  /**
   * De no estar autenticado y estamos intentando entrar en una ruta que cuelque de /home
   * (esto se calcula por que el guard esta declarado como un canLoad de /home)
   * almacenamos la ruta a la que se quería navegar
   * @param url Url a almacenar
   * @protected
   */
  protected checkIsNotAuthenticatedAndSaveUrlPreLogin(url: UrlTree): Observable<boolean> {
    return this.auth
        .$isAuthenticated
        .pipe(
            switchMap((i) => {
              if (i === AuthenticationStatusEnums.NoAuthenticated && !this.auth.redirectAfterLogin) {
                this.auth.redirectAfterLogin = url;
                console.debug('KeepUrlGuard - Saved path: ' + this.auth.redirectAfterLogin)
              }
              return of(true);
            })
        );
  }

  /**
   * Interface that a class can implement to be a guard deciding if a route can
   * be activated.
   *
   * Este canActivated se activará cuando estemos logados, por que AuthGuard ha liberado todos los canLoad.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.appConfig
        .isAppBootstrapped$()
        .pipe(
            take(1),
            switchMap(() => this.checkIsAuthenticatedAndRestoreUrlPreLogin())
        );
  }

  /**
   * Restaura la url almacenada si hemos pasado a estar autenticados.
   * @param url
   * @protected
   */
  protected checkIsAuthenticatedAndRestoreUrlPreLogin(): Observable<boolean> {
    return this.auth
        .$isAuthenticated
        .pipe(
            switchMap((i) => {
              if (i === AuthenticationStatusEnums.Authenticated && this.auth.redirectAfterLogin) {
                const redirect: UrlTree = this.auth.redirectAfterLogin;
                this.auth.redirectAfterLogin = null;
                console.debug('KeepUrlGuard - Restored path: ' + redirect);
              }
              return of(true);
            })
        );
  }
}
