import { HttpClient, HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';

import { environment } from '../environments/environment';
import { DomReferenceProviderService } from './shared/utils/providers/dom-reference-provider.service';
import { isNullOrUndefined } from './shared/utils/typescript.utils';
import { PrimeUtils } from './shared/utils/prime.utils';
import { catchError, filter, flatMap, take } from 'rxjs/operators';

/**
 * This service is only executed at the `APP_INITIALIZER` event triggered by
 * angular. The `load` method must return a `Promise` that when resolved (truthy
 * or falsy) indicates that the bootstrap progress could continue, otherwise
 * the app is not safe to be bootstrapped.
 *
 * This method shouldn't persist app data more other than in the subjects.
 * That's because this only works as data layer, trying to separate from
 * other business concerns that are addressed on the `AppConfigurationService`.
 *
 * IMPORTANT: this service must be as lean as possible, this means this service
 * shouldn't have other dependences than Angular ones. The reason is to
 * prevent creating a dependency chain that complexes the app bootstrapping
 * (an also prevent a dependency chain hell on testing).
 */
@Injectable({providedIn: 'root'})
export class BootstrapService {

  /**
   * This subject emits configuration data.
   */
  private configurationReady$ = new ReplaySubject<object>(1);

  /**
   * This subject emits boostrap data.
   */
  private bootstrapDataReady$ = new ReplaySubject<object>(1);

  /**
   * Class constructor for `BootstrapService`.
   *
   * @param {HttpClient} http
   * @param {DomReference} domReference
   */
  constructor(
      private http: HttpClient,
      private domReference: DomReferenceProviderService) {
    if (isDevMode()) {
      console.log('Bootstrap service started.');
    }
  }

  /**
   * This method is used as APP_INITIALIZER callback.
   *
   * APP_INITIALIZER: Callback is invoked before an app is initialized.
   * All registered initializers can optionally return a Promise.
   * All initializer functions that return Promises must be resolved before
   * the application is bootstrapped. If one of the initializers fails
   * to resolves, the application is not bootstrapped.
   *
   * @see https://angular.io/guide/dependency-injection-providers#predefined-tokens-and-multiple-providers
   *
   * This is an abstraction of `initializeApp` that returns promises... ugh.
   */
  load(): Promise<boolean> {
    return this.initializeApp().toPromise();
  }

  /**
   * Returns an observable for the `configuration.[environment].json` object.
   */
  configurationDataReady$(): Observable<object> {
    return this.configurationReady$
        .asObservable()
        .pipe(
            filter(c => c != null),
            take(1));
  }

  /**
   * Returns an observable for the `core-system-base/boot-data` data object.
   */
  bootDataReady$(): Observable<object> {
    return this.bootstrapDataReady$
        .pipe(take(1))
  }

  /**
   * This method load the `config file` and `core-system-base/boot-data` to define
   */
  initializeApp(): Observable<boolean> {

    if (isDevMode()) {
      console.log('⌛ Loading configuration data.');
    }

    return this.loadConfiguration$()
        .pipe(
            catchError((err) => {
              // This errors are normally handled by the error interceptor and
              // doesn't end up here.
              console.error('%c❌ Error while getting configuration data.', 'color: red');
              this.processError(err);
              return throwError(err);
            }),
            flatMap((configuration: any) => {
              const domain: string = PrimeUtils.GenerateApiConnection(configuration);
              this.configurationReady$.next(configuration);

              if (isDevMode()) {
                console.log('⌛ Loading boot data.');
              }

              return this.loadBoot$(domain);
            }),
            catchError((err) => {
              // This errors are normally handled by the error interceptor and
              // doesn't end up here.
              console.error('%c❌ Error while getting boot data.', 'color: red');
              this.processError(err);
              return of(null);
            }),
            flatMap((bootstrapData: object) => {
              this.bootstrapDataReady$.next(bootstrapData);

              if (!isNullOrUndefined(bootstrapData)) {
                return of(true);
              }

              console.error('%c❌ Error on app bootstrap.', 'color: red');

              // This return should only be reached on DEV environments. As we
              // response nothing, then the boostrap won't be finalized, thus
              // showing only a blank screen and some modal errors.

              // On production this case is handled by the ErrorCatcherInterceptor
              // redirecting the user to the out-of-service page.
              return of(false);
            })
        );
  }

  /**
   * On case of an error then move to the error page or show an alert to the dev.
   * @param err
   */
  private processError(err: Error): void {
    const window: Window = this.domReference.windowRef;
    console.error(JSON.stringify(err));
    if (!isDevMode()) {
      window.location.href = window.location.origin + '/error?id=LOADCONFIGURATION';
    } else {
      console.error(err);
      window.alert('DEBUG: Error en BootstrapService.initializeApp(). Vea la consola para más detalles: ' + err.message);
      throwError(err);
    }
  }


  /**
   * Load "configuration.[environment].json" to get all variables
   * (e.g.: 'assets/configuration/configuration.production.json').
   */
  private loadConfiguration$(): Observable<object> {
    const configurationPath: string = environment.configFilePath;
    return this.http.get(configurationPath)
        .pipe(
            take(1)
        );
  }

  /**
   * Loads the boot datLoading configuration da object form the `core-system-base/boot-data` controller.
   * @param url
   */
  private loadBoot$(url: string): Observable<object> {
    return this.http.get(
        `${url}core-system-base/boot-data`,
        // Solo nos quedamos con los código 503 y 0 (0=sin conexión o error OPTIONS)
        {
          // Desde angular 12, se incorpora la posibilidad de añadir metadatos a un request. En este caso añadimos un flag que indica que estamos en el boot
          context: new HttpContext().set(BOOTSTRAP_REQUEST_METADATA, true),
          headers: new HttpHeaders({'meta-codepassthrough': '503,0'})
        }
    )
        .pipe(
            take(1)
        );
  }
}

export const BOOTSTRAP_REQUEST_METADATA: HttpContextToken<boolean> = new HttpContextToken(() => false);

/**
 * Factory to run the `bootstrapService.load` method. This function is used
 * as APP_INITIALIZER callback for `AppModule`.
 *
 * APP_INITIALIZER: Callback is invoked before an app is initialized.
 * All registered initializers can optionally return a Promise.
 * All initializer functions that return Promises must be resolved before
 * the application is bootstrapped. If one of the initializers fails
 * to resolves, the application is not bootstrapped.
 *
 * @see https://angular.io/guide/dependency-injection-providers#predefined-tokens-and-multiple-providers
 *
 * @param {BootstrapService} bootstrapService
 */
export function appBootstrapFactory(bootstrapService: BootstrapService): Function {
  return (): Promise<boolean> => {
    return bootstrapService.load();
  };
}
