import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appObserveElement]',
  exportAs: 'intersection',
})
/**
 * Intersection Observer API Directive
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * See (inspiration): https://blog.prototyp.digital/how-to-implement-intersection-observer-api-in-angular/
 */
export class ObserveElementDirective implements OnInit, OnDestroy {
  /***
   * The element that is used as the viewport for checking visibility of the target. Must be the ancestor of the target.
   * Defaults to the browser viewport if not specified or if null.
   * (FROM: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API)
   */
  @Input() public root: HTMLElement | null = null

  /**
   * Margin around the root.
   * FROM: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
   * Margin around the root.
   * Can have values similar to the CSS margin property, e.g. "10px 20px 30px 40px" (top, right, bottom, left).
   * The values can be percentages.
   * This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections.
   * Defaults to all zeros.
   */
  @Input() public rootMargin = '0px 0px 0px 0px'

  /***
   * Porcentaje del elemento que debe verse para emitir el evento (0-1)
   * FROM: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
   * Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
   * If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5.
   * If you want the callback to run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1].
   * The default is 0 (meaning as soon as even one pixel is visible, the callback will be run).
   * A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
   */
  @Input() public threshold = 0

  /**
   * Intérvalo de compronación
   */
  @Input() public debounceTimeValue = 500

  /**
   * La comprobación es continua, de forma que si un componente está y luego desaparece del viewport, emitirá el evento conforme ha desaparecido.
   */
  @Input() public isContinuous = false

  /**
   * Evento output para determinar si el elemento se está viendo en el viewport
   */
  @Output() public isIntersecting: EventEmitter<boolean> = new EventEmitter<boolean>()

  /***
   * Indica si el componente se está viendo en el viewport. Útil para estilos, por ejemplo:
   * [class.example-component--is-intersecting]="intersection.currentComponentIsIntersecting"
   */
  currentComponentIsIntersecting = false

  /**
   * Current Subscription
   */
  private subscription: Subscription

  /***
   * Constructor
   * @param element
   */
  constructor(private element: ElementRef) {
    this.subscription = this.createAndObserve();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // Do nothing
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.subscription = null;
  }

  /***
   * Crea y se suscribe al observable IntersectionObserver
   */
  createAndObserve(): any {
    const options: IntersectionObserverInit = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    }

    return new Observable<boolean>(subscriber => {
      const intersectionObserver: IntersectionObserver =
          new IntersectionObserver(entries => {
            entries.forEach((entry: IntersectionObserverEntry) => {
              const entryIsIntersecting: boolean = entry.isIntersecting;
              subscriber.next(entryIsIntersecting)
              // Si ya está en el viewport y no queremos comprobarlo continuamente, nos desconectamos de la API
              if (entryIsIntersecting && !this.isContinuous) {
                intersectionObserver.disconnect()
              }
            });
          }, options)

      intersectionObserver.observe(this.element.nativeElement);

      return {
        unsubscribe(): void {
          intersectionObserver.disconnect()
        },
      }
    })
        .pipe(
            debounceTime(this.debounceTimeValue))
        .subscribe(status => {
          this.isIntersecting.emit(status)
          this.currentComponentIsIntersecting = status
        })
  }
}

