import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { AppConfigurationService } from '../../app.configuration.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { WebServiceResponse } from '../models/ETG_SABENTISpro_Application_Core_models';
import { getInSafe } from '../../shared/utils/typescript.utils';

/**
 * This interceptor check the version of the application to reload
 */
@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  /**
   * Command Interceptor class constructor.
   * @param {AppConfigurationService} appConfigurationService
   */
  constructor(public appConfigService: AppConfigurationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Añadimos al request la versión del cliente, para que éste determine si está o no actualizado
    if (null !== this.appConfigService.config) {
      request = request.clone({
        setHeaders: {
          AppVersion: this.appConfigService.get('version')
        },
      });
    }

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse, obs: Observable<any>) => {
          const wsResponse: WebServiceResponse = getInSafe((error), (x) => x.error, null);

          if (wsResponse && wsResponse.error && wsResponse.error.id === 'invalid-client-version') {
            window.alert('La aplicación necesita actualizarse. Pulse aceptar para refrecar el navegador.');
            location.reload();
            return NEVER;
          }

          return throwError(error);
        })
      );
  }
}
