<button *ngFor="let opt of config.options"
        type="button"
        [id]="'opt_'+opt.Key"
        (click)="selectOption(opt.Key)"
        class="o-btn-bool"
        [ngClass]="opt.CssClasses"
        (blur)="propagateTouch()"
        [class.--off]="opt.Key !== _boolValue"
        [disabled]="isDisabled"
        #boolElement> {{ opt.Name }}
         </button>
