import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { NavigationService } from '../../../core/navigation/navigation.service';
import { FormManagerComponent } from '../../../shared/form/form-manager/form-manager.component';

@Component({
  selector: 'app-predefined-details',
  templateUrl: './predefined-corrective-action-details.component.html',
  styleUrls: ['./predefined-corrective-action-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PredefinedCorrectiveActionDetailsComponent implements OnInit {

  @ViewChild('form', { static: true }) form: FormManagerComponent;
  params: { predefinedId: string };

  /**
   * Creates an instance of PredefinedCorrectiveActionDetailsComponent
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private navigationService: NavigationService) {
    this.params = {
      predefinedId: null
    }
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    const routeParams: object[] = this.navigationService.findParamsFromRoute(this.activatedRoute.snapshot);
    const predefined: object = routeParams.find(x => x.hasOwnProperty('predefined'));
    if (!isNullOrUndefined(predefined)) {
      this.params['predefinedId'] = predefined['predefined'];
    }
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    // redirect to list
    if (isNullOrUndefined(this.params['predefinedId'])) {
      this.router.navigate(['..'], {relativeTo: this.activatedRoute});
    } else {
      this.router.navigate(['../..'], {relativeTo: this.activatedRoute});
    }
  }

  /**
   * Handler for form succeed event.
   * @param e
   */
  saveSucceed(e: any): void {
    // redirect to list
    if (isNullOrUndefined(this.params['predefinedId'])) {
      this.router.navigate(['..'], {relativeTo: this.activatedRoute});
    } else {
      this.router.navigate(['../..'], {relativeTo: this.activatedRoute});
    }
  }

}
