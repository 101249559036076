import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../core/navigation/navigation.service';
import { BackNavigateOptions } from '../../core/navigation/object-navigation';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';

/**
 * UserDetailComponent: component that controls the user file and the user group list
 *
 * @export
 * @class UserDetailComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-personaldata',
  templateUrl: './personalData.component.html',
  styles: []
})

/**
 * This view controls the visualization of information for users and asociated groups
 */
export class PersonalDataComponent extends DestroyableObjectTrait implements OnInit {
  @ViewChild('form', {static: true}) form;
  public params: { editProfile: boolean };

  /**
   * Creates an instance of PersonalDataComponent.
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   * @memberof PersonalDataComponent
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    super();
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    this.params = {
      editProfile: true
    };
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    this.navigationService.back({relativePath: ['../..'], activatedRoute: this.activatedRoute} as BackNavigateOptions);
  }
}
