import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { ViewsselectorComponent } from '../viewsselector/viewsselector.component';

@Component({
  selector: 'app-viewsselectorwrapper',
  templateUrl: './viewsselectorwrapper.component.html'
})
export class ViewsselectorwrapperComponent extends FrontendFormElementWrapper {

  @ViewChild('formInput', {static: true})
  formElementInstanceValue: ViewsselectorComponent;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get getClasses(): string {
    return super.getComponentClassesRendered();
  }

  getId(): string {
    return this.config.ClientId;
  }
}
