import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { FuseNavigation, FuseNavigationItem } from '../../../types';
import { FuseNavigationService } from '../navigation.service';
import { MenuType } from '../../../../app/core/models/ETG_SABENTISpro_Application_Core_models';

@Directive({
  selector: '[appItemActiveIfAnyChildIsActiveDirective]',
  exportAs: 'appItemActiveIfAnyChildIsActiveDirective'
})

/**
 *                !!!!!!!
 *                !!!!!!!
 *                !!!!!!!
 *                !!!!!!!
 *                !!!!!!!
 *                !!!!!!!  CAUTION! This is a CUSTOM DIRECTIVE!!!!
 *                !!!!!!!
 *                !!!!!!!
 *                !!!!!!!
 *                !!!!!!!
 *
 *                *******
 *                *******
 *                *******
 *
 * DESCRIPCIÓN:
 *
 * Esta directiva analiza las URLs.
 * Dado un nodo, se analiza si su URL o la de alguno de sus hijos está cargada
 *
 * EN CASO DE MIGRACIÓN
 *
 * Si se actualiza FUSE hay que migrar los siguientes componentes:
 * - FuseNavVerticalCollapsableComponent
 * - FuseNavVerticalGroupComponent
 * - FuseNavVerticalItemComponent
 * En los HTML se han de modificar:
 * [routerLinkActive]="['active', 'accent']" [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
 * por
 * [appItemActiveIfAnyChildIsActiveDirective]="['active', 'accent']" [navigationItem]="item"
 *
 * Esta directiva se debe declarar y exporta en el módulo
 *
 * FuseNavigationModule
 *   exports: [
 *      ...
 *      ItemActiveIfAnyChildIsActiveDirective
 *   ],
 *   declarations: [
 *      ...
 *      ItemActiveIfAnyChildIsActiveDirective
 *   ]
 */

export class ItemActiveIfAnyChildIsActiveDirective implements OnChanges,
  OnDestroy, AfterContentInit {

  private classes: string[] = [];

  private item: FuseNavigation;

  private subscription: Subscription;
  public readonly isActive: boolean = false;

  @Input() routerLinkActiveOptions: { exact: boolean } = {exact: false};

  constructor(private router: Router, private element: ElementRef, private renderer: Renderer2, private navigationService: FuseNavigationService) {
    this.subscription = router.events.subscribe((s: RouterEvent) => {
      if (s instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  @Input()
  set appItemActiveIfAnyChildIsActiveDirective(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    this.classes = classes.filter(c => !!c);
  }

  @Input()
  set navigationItem(item: FuseNavigation) {
    this.item = item;
  }

  ngAfterContentInit(): void {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private update(): void {
    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveChild();
      if (this.isActive !== hasActiveLinks) {
        (this as any).isActive = hasActiveLinks;
        this.classes.forEach((c) => {
          if (hasActiveLinks) {
            this.renderer.addClass(this.element.nativeElement, c);
          } else {
            this.renderer.removeClass(this.element.nativeElement, c);
          }
        });
      }
    });
  }

  private isLinkActive(router: Router): (link: FuseNavigation) => boolean {
    return (link: FuseNavigation) => {
      // return router.isActive(router.parseUrl(link), true);
      if (link.menuType === MenuType.Anchor) {
        return false;
      }
      let result = router.url.indexOf(link.url) !== -1

      if (result) {
        const currentUrlTree: string[] = this.router.url.split('/');
        const nodeUrlTree: string[] = link.url.split('/');
        if (currentUrlTree && nodeUrlTree && currentUrlTree.length === nodeUrlTree.length) {
          return currentUrlTree[currentUrlTree.length - 1] === nodeUrlTree[nodeUrlTree.length - 1];
        }
      }

      return result;
    };
  }

  private hasActiveChild(): boolean {
    const isActiveCheckFn = this.isLinkActive(this.router);
    if (this.item.url && isActiveCheckFn(this.item)) {
      return true;
    }
    const children: FuseNavigation[] = this.getFlatNavigation(this.item.children);

    for (let i = 0; i < children.length; i++) {
      if (children[i].url && isActiveCheckFn(children[i])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Get flattened navigation array
   *
   * @param navigation
   * @param flatNavigation
   * @returns {any[]}
   */
  getFlatNavigation(navigation, flatNavigation: FuseNavigationItem[] = []): any {
    for (const item of navigation) {
      flatNavigation.push(item);
      if (item.children) {
        this.getFlatNavigation(item.children, flatNavigation);
      }
    }

    return flatNavigation;
  }
}
