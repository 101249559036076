import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { BackNavigateOptions } from '../../../core/navigation/object-navigation';
import { DecoupledModalBridgeService } from '../../decoupled-modal/decoupled-modal-bridge.service';
import { FormManagerComponent } from '../../form/form-manager/form-manager.component';
import { IFormEvent } from '../../form/interfaces/formevent.interface';
import { LocalActionEventData2 } from '../../list_v2/events/localaction.eventdata';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { isNullOrUndefined } from '../../utils/typescript.utils';
import { MappingReportGenerationComponent } from '../mapping-report-generation/mapping-report-generation.component';

/**
 * MappingExecutionComponent: component that controls the mapping execution
 */
@Component({
  selector: 'app-mapping-execution',
  templateUrl: './mapping-execution.component.html',
})

/**
 * This view controls the visualization of information for users and asociated groups
 */
export class MappingExecutionComponent extends DestroyableObjectTrait implements OnInit {

  @ViewChild('form', { read: FormManagerComponent, static: true }) form;
  params = {};

  /**
   * Creates an instance of MappingExecutionComponent
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private dmbs: DecoupledModalBridgeService
  ) {
    super();
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    const routeParams: object[] = this.navigationService.findParamsFromRoute(this.activatedRoute.snapshot);
    const params: object = routeParams.find(x => x.hasOwnProperty('mapping'));
    if (!isNullOrUndefined(params)) {
      this.params['mappingdefinitionId'] = params['mapping'];
    }
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    // redirect to list of mappings
    this.navigationService.back({ skipParametricNode: true } as BackNavigateOptions)
  }

  /**
   * On Save succeded
   * @param data
   */
  saveSucceed(data: any): void {
    // redirect to list
    const url: string = this.router.url.replace('definition', 'history')
    this.router.navigateByUrl(url);
  }

  /**
   * Get events from form
   * @param event
   */
  formEventsHandler(event: IFormEvent): void {
    if (event.emiter && (event.emiter.endsWith('generateTemplateWithData') || event.emiter.endsWith('generateTemplateWithoutData'))) {
        const metadata: Object = event.metadata;
        this.dmbs.showComponent(
          MappingReportGenerationComponent,
          {},
          {
            params: metadata.hasOwnProperty('formArguments') ? metadata['formArguments'] : {},
            formPlugin: metadata.hasOwnProperty('formToCall') ? metadata['formToCall'] : ''
          }
        );
    }
  }

  /**
   * Handler on local action
   * @param {LocalActionEventData2} event
   */
  onLocalAction(event: LocalActionEventData2): void { }
}
