<span 
  *ngIf="config.maxlength > 0" 
  class="o-input__field-seek" 
  [ngClass]="lengthValidationClasses()">
  {{ getRemainingChars() }}
</span>

<ng-container *ngIf="!handleMaxLengthInput()">
  <textarea
    [id]="uniqueComponentId('textarea-')"
    [placeholder]="config?.placeholder"
    [rows]="config?.rows || 2"
    [readonly]="isDisabled"
    (blur)="propagateTouch()"
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    class="o-input">
  </textarea>
</ng-container>

<ng-container *ngIf="handleMaxLengthInput()">
  <textarea
    [id]="uniqueComponentId('textarea-')"
    [placeholder]="config?.placeholder"
    [rows]="config?.rows || 2"
    [readonly]="isDisabled"
    (blur)="propagateTouch()"
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    [maxLength]="config.maxlength"
    class="o-input">
  </textarea>
</ng-container>
