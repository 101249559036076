import { Component, HostBinding, ViewChild } from '@angular/core';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

/**
 * VIEJO COMPONENTE DE FECHAS, DEPRECADO PORQUE TRABAJA SOBRE UNIX TIMESTAMP
 */
@Component({
  selector: 'app-date-picker-wrapper',
  templateUrl: './date-picker-old-wrapper.component.html'
})
export class DatePickerOldWrapperComponent extends FrontendFormElementWrapper {
  @ViewChild('formInput', { static: true })
  formElementInstanceValue: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.formElementInstanceValue;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
