<div class="flex-row">
  <div class="flex-col-sm-12 view-table-container">
    <div class="loader" *ngIf="dataIsLoading">
      <div class="loader__element"></div>
    </div>
    <div class="list-data-table--overlay" *ngIf="dataIsLoading"></div>
    <table [class]="'view-table ' + classes" [@fadeInOut]="!dataIsLoading">
      <thead class="view-table-head" *ngIf="!isNullOrUndefined(headerFields)">
      <tr>
        <th app-view-grid-header *ngFor="let field of headerFields" [headerField]="field"></th>
      </tr>
      </thead>
      <!--Tenemos varios TBODY porque la naturaleza dinámica del gridcontainer requiere vincularse a un contenedor-->
      <tbody class="view-table-body">
      <tr class="list-data-table--bulk-review-tr" *ngIf="showBulkReviewRow()">
        <td [attr.colspan]="fullColspanLength()">
          <app-view-grid-cell-bulk-review></app-view-grid-cell-bulk-review>
        </td>
      </tr>
      </tbody>
      <ng-container #gridcontainer></ng-container>
      <tbody>
      <tr *ngIf="!this.listHasResults()" class="list-data-table--no-results-tr">
        <td [attr.colspan]="headerFields.length">
          <div class="text-center list-data-table--no-results-container">{{ getNoResultText() }}</div>
        </td>
      </tr>
      <tr *ngIf="isNullOrUndefined(data)" class="list-data-table--loading-tr">
        <td [attr.colspan]="2">
          <div class="text-center">Cargando...</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
