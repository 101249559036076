import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { FuseWidgetModule } from '../../../../../../@fuse/components';
import { FuseSharedModule } from '../../../../../../@fuse/shared.module';
import { ChartTypesModule } from './component/chart-types/chart-types.module';
import { FuseChartComponent } from './component/fuse-chart/fuse-chart.component';
import { FuseChartsService } from './fuse-charts.service';
import { CommentModule } from '../../../../../shared/comment/comment.module';
import { ChartFiltersModule } from './component/chart-filters/chart-filters.module';
import { CoreModule } from '../../../../../core/core.module';
import { ChartModeSelectorModule } from './component/chart-mode-selector/fuse-mode-selector.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ChartService } from '../../../../../core/services/ETG_SABENTISpro_Application_Core_chart.service';
import { ChartLoadingPlaceholderComponent } from './component/chart-loading-placeholder/chart-loading-placeholder.component';
import { UtilsModule } from '../../../../../utils/utils.module';

@NgModule({
  declarations: [
    FuseChartComponent,
    ChartLoadingPlaceholderComponent,
  ],
    imports: [
        ChartModeSelectorModule,
        ChartTypesModule,
        CommonModule,
        FuseWidgetModule,
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
        MatProgressBarModule,
        RouterModule,
        CommentModule,
        ChartFiltersModule,
        CoreModule,
        UtilsModule
    ],
  exports: [
    FuseChartComponent
  ],
  providers: [
    FuseChartsService,
    ChartService
  ],
})
export class FuseChartsModule {
}
