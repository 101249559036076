import { HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { concat, Observable, Observer, Subject, Subscription } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { v4 as uuid } from 'uuid';
import { FileManagerResult, UploadedFile } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FileUploaderService } from '../file-uploader.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Defines the available states for the FileWrapper.
 */
export enum UploadStatus {
  WAITING,
  STARTED,
  PAUSED,
  CANCELED,
  COMPLETED,
  ERROR
}

/**
 * Defines de uploader configuration for chunking.
 */
export interface UploaderOptions {
  chunked?: boolean;
  chunkSize?: number;
}

/**
 * This class works as a task orchestrator to upload
 * the files using the FileUploaderService and to represent an already uploaded
 * file.
 */
export class FileWrapper {

  /**
   * Local identifier for file. Attached to all web requests, used for file managing on chunked uploads.
   * @type {string}
   */
  private identifier;

  /**
   * Upload status.
   * @type {UploadStatus}
   */
  private status = UploadStatus.WAITING;

  /**
   * Uploaded files accumulator.
   * @type {number}
   */
  private uploadedBytes = 0;

  /**
   * Subjet that trigger the cancel event to subscriptions on the upload.
   */
  private abort: Subject<void> = new Subject<void>();

  /**
   * Source to emit when an upload is completed.
   */
  private completedSource: Subject<FileManagerResult> = new Subject<FileManagerResult>();

  /**
   * Event emitter when the file status changes (i.e. the progress)
   */
  private fileStatusChangedEvent: Subject<void> = new Subject<void>();

  /**
   * The uploaded file
   */
  public UploadedFile: UploadedFile;

  /**
   * Class constructor.
   * @param {File} file File to upload.
   * @param {FileUploaderService} uploadManager Manager injected to handle upload coms.
   * @param {UploaderOptions} options File upload options.
   */
  constructor(
      private file: File,
      private uploadManager: FileUploaderService,
      private options: UploaderOptions = {chunked: false, chunkSize: 2097152},
      uploadedFile: UploadedFile
  ) {
    this.UploadedFile = uploadedFile;
    this.identifier = uuid();
    this.status = UploadStatus.WAITING;
  }

  /**
   * Getter for the file name.
   * @returns {string}
   */
  get name(): string {
    if (!isNullOrUndefined(this.UploadedFile)) {
      return this.UploadedFile.name;
    } else {
      return this.file.name;
    }
  }

  /**
   * Getter for the file size.
   * @returns {number}
   */
  get size(): number {
    if (!isNullOrUndefined(this.UploadedFile)) {
      return this.UploadedFile.size;
    } else {
      return this.file.size;
    }
  }

  /**
   * Getter for the file type.
   * @returns {string}
   */
  get type(): string {
    return this.file.type;
  }

  /**
   * Getter for the upload progress.
   * @returns {number}
   */
  get progress(): number {
    if (!isNullOrUndefined(this.UploadedFile)) {
      return 100;
    } else {
      return (this.uploadedBytes / this.size) * 100;
    }
  }

  /**
   * Returns the status for the upload.
   * @returns {UploadStatus}
   */
  getStatus(): UploadStatus {
    if (!isNullOrUndefined(this.UploadedFile)) {
      return UploadStatus.COMPLETED;
    } else {
      return this.status;
    }
  }

  /**
   * Returns a uuid for the file.
   * @returns {string}
   */
  getIdentifier(): string {
    if (!isNullOrUndefined(this.UploadedFile)) {
      return String(this.UploadedFile.id);
    } else {
      return this.identifier;
    }
  }

  /**
   * Emits a FileManagerResult when upload is completed.
   */
  get completed(): Observable<FileManagerResult> {
    return this.completedSource.asObservable();
  }

  /**
   * Returns a FormData for a defined file or blob.
   * @param param Object to configure filetotalparts, filepartindex and filepartsize params.
   * @returns {FormData}
   */
  private getFormData({filetotalparts = '1', filepartindex = '0', filepartsize = '0'}: any): FormData {
    filepartsize = filepartsize !== '0' ? filepartsize : this.size.toString();
    const data: FormData = new FormData();
    data.append('file', this.file);
    data.append('filename', this.name);
    data.append('filetotalsize', this.size.toString());
    data.append('filetype', this.type);
    data.append('fileuuid', this.getIdentifier());
    data.append('filetotalparts', filetotalparts);
    data.append('filepartindex', filepartindex);
    data.append('filepartsize', filepartsize);
    return data;
  }

  /**
   * Returns a unique or merged observable to handle the upload.
   * @returns {Observable<object>}
   */
  private getTransferObservable(): Observable<object> {
    const parts: number = Math.ceil(this.file.size / this.options.chunkSize);
    if (parts > 1 && this.options.chunked) {
      let start: number = 0;
      let part: number = 0;

      const list: Observable<object>[] = [];
      while (start < this.file.size) {
        const chunk: Blob = this.file.slice(start, start + this.options.chunkSize);
        const data: FormData = this.getFormData({
          filetotalparts: parts.toString(),
          filepartsize: chunk.size.toString(),
          filepartindex: part.toString()
        });
        data.set('file', chunk);
        list.push(this.uploadManager.upload(data));

        part = +data.get('filepartindex') + 1;
        start = start + this.options.chunkSize;
      }

      const endData: FormData = this.getFormData({
        filetotalparts: parts.toString(),
        filepartsize: null,
        filepartindex: part.toString()
      });
      endData.set('file', null);
      endData.append('done', 'true');
      list.push(this.uploadManager.upload(endData));

      return list.reduce((a, b) => concat(a, b))
    } else {
      const data: FormData = this.getFormData({});
      return this.uploadManager.upload(data);
    }
  }

  /**
   * Handles the upload and returns and observable to abstract the upload.
   * @returns {Observable<FileManagerResult>}
   */
  private uploadHandler(): Observable<FileManagerResult> {
    return Observable.create((observer: Observer<FileManagerResult>) => {
      const subscription: Subscription = this.getTransferObservable()
          .pipe(takeUntil(this.abort))
          .subscribe(
              (response: HttpEvent<FileManagerResult>) => {
                switch (response.type) {
                  case HttpEventType.Response:
                    observer.next(response.body || ({} as FileManagerResult));
                    break;
                  case HttpEventType.UploadProgress:
                    this.uploadedBytes += (response as HttpProgressEvent).loaded;
                    this.fileStatusChangedEvent.next();
                    break;
                }
              },
              (error) => {
                observer.error(error)
              },
              () => {
                observer.complete()
              },
          );

      this.abort.subscribe(() => {
        if ((subscription instanceof Subscription) && !subscription.closed) {
          subscription.unsubscribe();
        }
      });
    });
  }

  /**
   * Observable for status changes in the state of the file
   */
  get statusChangedObservable(): Observable<void> {
    return this.fileStatusChangedEvent.asObservable();
  }

  /**
   * Public method to start the upload.
   */
  upload(): void {
    switch (this.status) {
        // TODO: Implement methods for resume/continue.
      case UploadStatus.WAITING:
        this.status = UploadStatus.STARTED;

        const subscription: Subscription = this.uploadHandler()
            .pipe(takeUntil(this.abort))
            .subscribe(
                (data: FileManagerResult) => {
                  if (!isNullOrUndefined(data.file)) {
                    this.UploadedFile = data.file;
                  }
                },
                (error: Error) => {
                  this.status = UploadStatus.ERROR;
                  this.fileStatusChangedEvent.next()
                  this.abort.complete();
                },
                () => {
                  this.status = UploadStatus.COMPLETED;
                  this.completedSource.next(this.identifier);
                  this.abort.complete();
                }
            );

        this.abort.subscribe(() => {
          if ((subscription instanceof Subscription) && !subscription.closed) {
            this.fileStatusChangedEvent.next()
            subscription.unsubscribe();
          }
        });
        break;
    }
  }

  /**
   * Cancels the upload.
   */
  cancel(): void {
    if ([UploadStatus.STARTED, UploadStatus.PAUSED].indexOf(this.status) !== -1) {
      this.status = UploadStatus.CANCELED;
      if (!this.abort.closed) {
        this.abort.next();
        this.abort.complete();
      }
    }
  }

  /**
   * Pauses the upload.
   */
  pause(): void {
    // TODO: Implement pause.
    throw Error('Method not implemented');
  }

  /**
   * Returns a boolean indicating if uplad is in progress.
   * @returns {boolean}
   */
  isUploading(): boolean {
    return this.status === UploadStatus.STARTED;
  }

  get File(): File {
    return this.file;
  }
}
