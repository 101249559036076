import { Directive, EventEmitter, HostListener, Output, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  @Output() appScroll = new EventEmitter<boolean>();

  constructor(private vcr: ViewContainerRef) {
  }

  /**
   * ACHSPRIME-2168 Revisado funciona OK, se usa en los selectores de contexto.
   *
   * @param event
   */
  @HostListener('scroll', ['$event'])
  scrolled(event: Event): void {
    // visible height + pixel scrolled = total height --> scrolled to end
    // if ((this.vcr.element.nativeElement.offsetHeight + this.vcr.element.nativeElement.scrollTop) === this.vcr.element.nativeElement.scrollHeight) {
    if ((this.vcr.element.nativeElement.offsetHeight + this.vcr.element.nativeElement.scrollTop + 1) >= this.vcr.element.nativeElement.scrollHeight) {
      this.appScroll.emit(true);
    }
  }
}
