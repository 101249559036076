/**
 * Clase que puede usar un emisor para captar resultados de los listeners
 */
import { IResultCollector } from './resultcollector.interface';

export class ResultCollector<T, T2> implements IResultCollector<T, T2> {

  /**
   * The emmiteed argument value
   */
  readonly argumentValue: T;

  /**
   * The collected results
   */
  readonly resultsValue: T2[];

  /**
   * Get an instance of Promisecollector
   */
  constructor(argument: T) {
    this.argumentValue = argument;
    this.resultsValue = [];
  }

  /**
   * The pipe's argument
   */
  get Argument(): T {
    return this.argumentValue;
  }

  /**
   * Let the collector add one or more results
   *
   * @param result
   * @constructor
   */
  AddResult(result: T2): void {
    this.resultsValue.push(result);
  }
}
