import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import {
  ViewsLocalActionType,
  ViewsSingleItemOperationImplementationTypes,
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AppMappingService } from '../mapping.service';
import { LocalActionEventData2 } from '../../list_v2/events/localaction.eventdata';
import { NavigationService } from '../../../core/navigation/navigation.service';

@Component({
  selector: 'app-mapping-list',
  templateUrl: './mapping-list.component.html',
  styles: []
})
export class MappingListComponent {

  /**
   * Creates a new instance of MappingListComponent
   * @param {Router} router
   * @param appMappingService
   * @param navigationService
   */
  constructor(private router: Router,
              private appMappingService: AppMappingService,
              private navigationService: NavigationService) {
  }

  /**
   * Handler on local action
   * @param {LocalActionEventData2} event
   */
  onLocalAction(event: LocalActionEventData2): void {
    if (event.action === ViewsLocalActionType.New) {
      const url: string = this.router.url + '/add'
      this.router.navigateByUrl(url);
    }
  }

  /**
   * Handler on SingleItemOperation
   * @param {SingleItemOperationEventData2} event
   */
  onSingleItemOperation(data: any): void {
    const id: string = data.row.Metadata['field_meta_id'].RawValue;
    if (!isNullOrUndefined(data.row)) {
      switch (data.action) {
        case ViewsSingleItemOperationImplementationTypes.GenerateTemplate:
          this.appMappingService.GenerateTemplateByMappingDefinition(id);
          break;
        case ViewsSingleItemOperationImplementationTypes.Remove:
          this.navigationService.navigateUrlByController('utilities.mappings.details.delete', {'%mapping': id});
          break;
        case ViewsSingleItemOperationImplementationTypes.Copy:
          this.navigationService.navigateUrlByController('utilities.mappings.details.copy', {'%mapping': id});
          break;
        case ViewsSingleItemOperationImplementationTypes.Execute:
          this.navigationService.navigateUrlByController('utilities.mappings.details.execute.definition', {'%mapping': id});
          break;
        case ViewsSingleItemOperationImplementationTypes.Edit:
        case ViewsSingleItemOperationImplementationTypes.Details:
          this.navigationService.navigateUrlByController('utilities.mappings.details', {'%mapping': id});
          break;
        default:
          throw new Error('Invalid operation');
      }
    }
  }
}
