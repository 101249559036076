import { AbstractControl, FormGroup } from '@angular/forms';
import { FormManagerService } from '../form-manager/form-manager.service';
import { FieldConfig } from '../interfaces/field-config.interface';
import {
  FormConfiguration,
  FormElement,
  FormElementFieldSet,
  FormElementType
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { IFrontendFormElement } from '../interfaces/field.interface';
import { AfterViewInit, ChangeDetectorRef, Directive, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { cleanIdentifier, isNullOrUndefined } from '../../utils/typescript.utils';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { Guid } from 'guid-typescript';

/**
 * Clase base para elementos que necesiten comunicarse con un FormElement
 */
@Directive()
@Injectable()
export abstract class FrontendFormElementAware extends DestroyableObjectTrait implements IFrontendFormElement, AfterViewInit, OnDestroy, OnInit {

  /**
   * A trully unique element ID for the component
   */
  protected uniqueElementIdValue: string;

  /**
   * The element's configuration
   */
  protected configValue: FieldConfig;

  /**
   * The group's value
   */
  protected groupValue: FormGroup;

  /**
   * Permite saber si el componente está o no deshabilitado.
   *
   * El valor de esta variable solo es fiable para elementos que implementan
   * ControlValueAccessor
   *
   */
  public get isDisabled(): boolean {
    return this.GetIsDisabledInternalImplementation();
  }

  /**
   * Versión método del getter para que las clases hijas puedan llamarlo
   */
  protected getIsDisabled(component: AbstractControl = null): boolean {
    return this.GetIsDisabledInternalImplementation(component);
  }

  /**
   * Si el componente está visible y es editable
   */
  public getIsVisibleAndEditable(component: AbstractControl = null): boolean {
    return this.config.visible && !this.GetIsDisabledInternalImplementation(component);
  }

  /**
   *
   */
  private GetIsDisabledInternalImplementation(component: AbstractControl = null): boolean {
    // El estado "efectivo" de si el componente está o no habilitado viene del AbstractControl
    if (!component) {
      component = this.formManagerService.getFormComponent(this.config.ClientPath);
    }
    return component.disabled;
  }

  /**
   * Unique element ID, guaranteed.
   */
  get uniqueElementId(): string {
    if (isNullOrUndefined(this.uniqueElementIdValue)) {
      this.uniqueElementIdValue = Guid.create().toString().replace(/ |\.|\-/g, '');
    }
    this.uniqueComponentId('input-')
    return this.uniqueElementIdValue;
  }

  /**
   * Tag para enviar a GA4 que identifica de manera única a este componente de formulario
   */
  get gtmTag(): string {
    return this.formManagerService.getFormState()?.FormId + ':' + this.config.ClientId;
  }

  /**
   * A unique component ID based on the form ID and the component path. There is no guarantee
   * that this ID is fully unique in the document (i.e. if the same form appears twice at the
   * same time in the DOM).
   */
  uniqueComponentId(prefix: string = ''): string {
    let result: string = prefix + this.formManagerService.getFormState().FormId + '_' + this.config.ClientPath;
    result = result.replace(/ |\.|\-/g, '');
    return result.toLowerCase();
  }

  @Input()
  set config(value: FieldConfig) {
    // this.formElement = Object.assign({} as TElementType, getInSafe(value, (i) => i.FormElement, {})) as TElementType;
    this.configValue = value;
  }

  get config(): FieldConfig {
    return this.configValue;
  }

  @Input()
  set group(value: FormGroup) {
    this.groupValue = value;
  }

  get group(): FormGroup {
    return this.groupValue;
  }

  /**
   * Get an instance of
   *
   * @param formManagerService
   * @param cdRef
   */
  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef) {
    super();
  }

  protected forceDetectChanges(): void {
    this.cdRef.detectChanges();
  }

  /**
   *
   * @param config
   * @param type
   */
  private findFirstParentOfType(config: FieldConfig, type: FormElementType[]): FieldConfig {
    let parentConfig: FieldConfig = config.ParentConfig;
    while (!isNullOrUndefined(parentConfig)) {
      if (type.includes(parentConfig.FormElement.Type)) {
        return parentConfig;
      }
      parentConfig = parentConfig.ParentConfig;
    }
    throw new Error('Could not find parent of type: ' + type);
  }

  getComponentClassesRendered(): string {
    const result: string = this.getComponentClasses().join(' ');
    return result;
  }

  /**
   * Saber si el elemento (que debe ser un contenedor - form o fieldset) aplica bootstrap con sus hijos
   *
   * @param element
   */
  public isBootstrapContainer(element: FormElement): boolean {
    switch (element.Type) {
      case FormElementType.FieldSet:
      case FormElementType.Compound:
      case FormElementType.Table:
        const fieldsetElement: FormElementFieldSet = element as FormElementFieldSet;
        if (fieldsetElement.DisableBootstrapColumns === true) {
          return false;
        }
        break;
      case FormElementType.Form:
        const formElement: FormConfiguration = element as FormConfiguration;
        if (formElement.DisableBootstrapColumns === true) {
          return false;
        }
        break;
      default:
        throw new Error('Not supported operation.');
    }

    return true;
  }

  /**
   * Si el componente usa o no columnas de bootstrap sobre si mismo, depende de donde está contenido
   */
  public useBootstrap(): boolean {

    let parentFieldSet: FieldConfig;

    // Únicamente Fieldset y Form son componentes que pueden contener otros componentes
    parentFieldSet = this.findFirstParentOfType(this.config, this.formManagerService.containerTypes);

    if (!isNullOrUndefined(parentFieldSet)) {
      return this.isBootstrapContainer(parentFieldSet.FormElement);
    }
  }

  /**
   * Obtiene las clases a nivel de componente (wrapper del elemento) de la API de formularios.
   *
   * Está muy vinculado al HOST container de cada control, y relacionado con el tipo de maquetación
   * que se usa en la API de formularios
   */
  protected getComponentClasses(): string[] {

    const result: string[] = this.populateComponentClasses(this.config);

    // El formulario no tiene padres!
    if (this.config.FormElement.Type === FormElementType.Form) {
      return result;
    }

    const useBootstrap: boolean = this.useBootstrap();

    if (useBootstrap) {
      const sm: string = 'flex-col-sm-' + this.config.width;
      const md: string = 'flex-col-md-' + this.config.width;
      const lg: string = 'flex-col-lg-' + this.config.width;
      const xs: string = 'flex-col-xs-12';
      result.push(sm);
      result.push(md);
      result.push(lg);
      result.push(xs);
    }

    return result;
  }

  /**
   * Populates common css classes for a single element, withou considering row rendering
   *
   * @param config
   * @param classes
   */
  private populateComponentClasses(config: FieldConfig, classes: string[] = null): string[] {

    if (isNullOrUndefined(classes)) {
      classes = [];
    }

    if (!isNullOrUndefined(this.config.classes)) {
      classes = classes.concat(this.config.classes);
    }

    // El elemento raíz (formulario) no tiene un ClientId ni un Name.
    if (!isNullOrUndefined(this.config.name)) {
      classes.push(`elem-${cleanIdentifier(this.config.name)}`);
    }

    classes.push(`elemtype-${cleanIdentifier(FormElementType[this.config.type])}`);

    classes.push(`element-item`);

    if (this.config.visible === false) {
      classes.push('hidden');
    }

    if (this.config.editable !== true) {
      classes.push('disabled');
    }

    if (this.config.FormElement.ComponentClass) {
      classes.push(this.config.FormElement.ComponentClass)
    }

    return classes;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
    // Do nothing
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit(): void {
    // Do nothing
  }

  initializeDynamicComponent(params: any): void {
  }
}
