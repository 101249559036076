import { Component, Input } from '@angular/core';

import { AbstractDecoupledModalComponent } from '../models/abstract-decoupled-modal.component';
import { BatchDialogInterface } from '../models/modal-params.interface';

@Component({
  selector: 'app-batch-dialog',
  templateUrl: './batch-dialog.component.html',
  styleUrls: ['./batch-dialog.component.scss']
})
export class BatchDialogComponent extends AbstractDecoupledModalComponent {

  /**
   * @inheritdoc
   */
  @Input()
  data: BatchDialogInterface;

}
