<ng-container [ngSwitch]="configuration.EmbedType">
  <div *ngSwitchCase="FormElementHtmlPlaceholderEmbedType.Unsafe" [innerHtml]="this.config.Html"
       [appUrlify]="this.configuration.EnableUrlify" [appGlobalizeUrls]="true"
  #unsafeHtml></div>
  <div *ngSwitchCase="FormElementHtmlPlaceholderEmbedType.SafeHtml" [innerHtml]="this.config.Html | safeHtml"
       [appUrlify]="this.configuration.EnableUrlify" [appGlobalizeUrls]="true"
  #safeHtml></div>
  <div *ngSwitchCase="FormElementHtmlPlaceholderEmbedType.SafeHtmlWithScripts" [innerHtml]="this.config.Html | safeHtml"
       appRunScripts [appUrlify]="this.configuration.EnableUrlify" [appGlobalizeUrls]="true"
  #safeHtmlWithScripts></div>
  <ng-container *ngSwitchCase="FormElementHtmlPlaceholderEmbedType.Iframe">
    <iframe #frame src="about:blank" style="border:none;overflow:hidden;width:100%;"></iframe>
  </ng-container>
  <div *ngSwitchDefault><p>Unsupported embed type</p></div>
</ng-container>
