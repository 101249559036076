/**
 * Clase para pasar a través del router configuración estática del componente
 */
import { BackButton } from '../../back-button/interface/back-button.interface';
import { IBackButtonOptions } from '../../back-button/interface/back-button-options.intefrace';

export class GenericListPageSimpleArguments {

  /**
   * El ide del formulario
   */
  listId: string;

  /**
   * Map of route arguments to form parameteres
   */
  listArgumentMapping: { [id: string]: string };

  /**
   *
   */
  enableBackButton: boolean = false;

  /**
   *
   */
  skipWithBackButton: Number = 0;
  /**
   *
   */
  backButtonOptions: IBackButtonOptions = {
    backButton: null,
    enableBackButton: false,
    skipWithBackButton: 0
  };

  constructor(listId: string, listArgumentMapping: { [id: string]: string }, enableBackButton: boolean = false, skipWithBackButton: Number = 0, backButton: BackButton = null) {
    this.listId = listId;
    this.listArgumentMapping = listArgumentMapping;
    this.backButtonOptions.enableBackButton = enableBackButton;
    this.backButtonOptions.skipWithBackButton = skipWithBackButton;
    this.backButtonOptions.backButton = backButton;
  }
}
