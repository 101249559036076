import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { getInSafe } from '../../../../shared/utils/typescript.utils';
import { Message, MessageBarService } from '../../services/message-bar.service';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBarComponent implements OnInit, OnDestroy {

  /**
   * Container Template Reference.
   */
  @ViewChild('container', { static: true }) el: ElementRef;

  reference: TemplateRef<any> | any;

  /**
   * This boolean indicates if the message bar must be shown.
   */
  private showBar = false;

  /**
   * This boolean indicates if the content must be shown.
   */
  private showContent = true;

  /**
   * This property controls the message shown on screen;
   */
  private contentIterator = 0;

  private destroySubject$: Subject<void> = new Subject<void>();
  private serviceInstance: MessageBarService;

  constructor(private cd: ChangeDetectorRef) { }

  get messages(): Message[] {
    return this.serviceInstance.getMessages();
  }

  get classes(): object {
    return { in: this.showBar };
  }

  get classesContent(): object {
    return {
      in: this.showContent
    }
  }

  get content(): string {
    const message: Message = this.messages[this.messageNumber];
    return message.content;
  }

  get messageNumber(): number {
    return Math.abs(this.contentIterator) % this.messages.length;
  }

  get totalMessages(): number {
    return this.messages.length;
  }

  get disableNavigation(): boolean {
    return this.totalMessages < 2;
  }

  isRemovable(): boolean {
    const message: Message = this.messages[this.messageNumber];
    return getInSafe(message, m => m.options.closeable, true);
  }


  ngOnInit(): void {
    setTimeout(() => { this.show(); }, 200);
  }

  ngOnDestroy(): void {
    this.toggleBodyTopPadding(false);
  }

  destroyMessage(): void {
    if (this.totalMessages === 1) {
      this.toggleBodyTopPadding(false);
      this.destroySubject$.next();
      this.destroySubject$.complete();
      return;
    }

    this.serviceInstance.removeMessage(this.messages[this.messageNumber].sym);
    this.cd.detectChanges();
    this.toggleBodyTopPadding(true);
  }

  getDestroyObservable$(): Observable<void> {
    return this.destroySubject$.asObservable();
  }

  setServiceInstance(service: MessageBarService): void {
    this.serviceInstance = service;
  }

  getContainerHeight(): number {
    return getInSafe(this.el, e => e.nativeElement.offsetHeight, 0);
  }

  update(): void {
    this.cd.detectChanges();
  }

  prev(): void {
    this.contentIterator--;
    this.showContentCycle();
  }

  next(): void {
    this.contentIterator++;
    this.showContentCycle();
  }

  showContentCycle(): void {
    this.showContent = false;
    this.cd.detectChanges();
    this.toggleBodyTopPadding(true);

    setTimeout(() => {
      this.showContent = true;
      this.cd.detectChanges();
    }, 400)
  }

  private show(): void {
    this.showBar = true;
    this.toggleBodyTopPadding(true);

    this.cd.detectChanges();
  }

  private toggleBodyTopPadding(bool: Boolean): void {
    if (bool) {
      document.body.style.paddingTop = this.getContainerHeight() + 'px';
      return;
    }

    document.body.style.paddingTop = '0';
  }
}
