import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { ThemeDemoComponent } from './theme-demo/theme-demo.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [ThemeDemoComponent],
  exports: [
    ThemeDemoComponent
  ],
})
export class ThemeManagerModule {
}
