import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '../../../../../../../../../../@fuse/shared.module';
import { BarChartModule, ChartCommonModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../../utils/utils.module';
import { SimpleHorizontalBarChartComponent } from './simple-horizontal-bar-chart.component';


@NgModule({
    declarations: [SimpleHorizontalBarChartComponent],
    imports: [
        FuseSharedModule,
        CommonModule,
        BarChartModule,
        UtilsModule,
        ChartCommonModule
    ]
})
export class SimpleHorizontalBarChartModule {
}
