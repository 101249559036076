import { Component, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FuseChartsService } from '../../../fuse-charts.service';
import { DestroyableObjectTrait } from '../../../../../../../../shared/utils/destroyableobject.trait';
import { FormChartGeneratorDirective } from '../form-chart-generator.directive';
import { getInSafe, isNullOrUndefined } from '../../../../../../../../shared/utils/typescript.utils';
import { ChangedetectorReference } from '../../../../../../../../core/changedetector/changedetectoreference';
import {
  ChartExecutionResponse,
  ChartUserConfiguration,
  FormState,
  FormSubmitData, Payload
} from '../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { Formstatecontainer } from '../../../../../../../../shared/form/formstatecontainer.class';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-chart-form',
  templateUrl: './chart-form.component.html',
  styleUrls: ['./chart-form.component.scss'],
  providers: [ChangedetectorReference]
})
export class ChartFormComponent extends DestroyableObjectTrait implements OnInit {

  @ViewChild(FormChartGeneratorDirective) exposedFilterContainer: FormChartGeneratorDirective;
  public currentChartResponse: ChartExecutionResponse;
  public filtersFormState: Formstatecontainer;

  public firstFilterFormState: Formstatecontainer;
  private init = false;

  constructor(
    protected cdReference: ChangedetectorReference,
    protected chartService: FuseChartsService) {
    super();
    this.chartService.currentChartUserConfiguration
      .pipe(
        takeUntil(this.componentDestroyed$)
      ).subscribe((x: ChartUserConfiguration) => {
      this.filtersFormState = this.parseFormState(x.SignedFormState);
      this.cdReference.changeDetector.detectChanges();
    });
    this.chartService.lastChartExecutionResponseChanges
      .pipe(
        takeUntil(this.componentDestroyed$)
      ).subscribe((value: ChartExecutionResponse) => {
      this.currentChartResponse = value;
      if (this.currentChartResponse && this.currentChartResponse.UserConfiguration) {
        this.filtersFormState = getInSafe(
          this.currentChartResponse.UserConfiguration,
          x => this.parseFormState(x.SignedFormState),
          null);
        if (!isNullOrUndefined(this.firstFilterFormState)) {
          const inputs: object = getInSafe(
            this.currentChartResponse.UserConfiguration,
            x => x.FormSubmitData.formInput,
            null);
          if (inputs === null) {
            this.exposedFilterContainer.resetForm();
          }
        } else if (!isNullOrUndefined(this.filtersFormState)) {
          this.firstFilterFormState = Object.assign(new FormState(), this.filtersFormState);
        }
      } else {
        this.filtersFormState = null;
      }
      if (this.init) {
        this.cdReference.changeDetector.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    this.init = true;
  }

  /**
   * Refresh the view without modify searching and page
   */
  applyFilters(): void {
    const submitData: FormSubmitData = this.exposedFilterContainer.getFormSubmitDataForSubmit();
    this.chartService.applyChartFilters(submitData)
  }

  /**
   * Refresh the view without modify searching and page
   */
  resetFilters(): void {
    this.filtersFormState = Object.assign(new FormState(), this.firstFilterFormState);
    const submitData: FormSubmitData = this.exposedFilterContainer.getFormSubmitDataForSubmit();
    submitData.formInput = null;
    this.chartService.applyChartFilters(submitData, this.firstFilterFormState)
  }

  /**
   * TODO: Unificar con la versión que hay en el motor de formularios
   * @param signedFormState
   */
  parseFormState(signedFormState: string): Formstatecontainer {
    const container: Formstatecontainer = new Formstatecontainer();
    container.formState = JSON.parse((jwt_decode(signedFormState) as Payload).data);
    container.signedFormState = signedFormState;
    return container;
  }
}
