import { AfterViewInit, Component, HostBinding, ViewChild, ViewEncapsulation } from '@angular/core';

import { FormElementType, } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-check.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InputCheckComponent extends FrontendFormElementWrapper implements AfterViewInit {

  @ViewChild('formInput')
  formElementInstanceValue: FrontendFormElementInput;

  @ViewChild('formInput2')
  formElementInstanceValue2: FrontendFormElementInput;

  formElementInstance(): FrontendFormElementInput {
    return this.isCheckbox() ? this.formElementInstanceValue : this.formElementInstanceValue2;
  }

  isCheckbox(): boolean {
    return this.config.type === FormElementType.Checkbox;
  }

  isCheckboxes(): boolean {
    return this.config.type === FormElementType.Checkboxes;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
