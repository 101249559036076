import { Injectable } from '@angular/core';
import { ClientThemeService } from '../theme-manager/theme.service';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';

@Injectable()
export class LayoutManagerService extends DestroyableObjectTrait {

  constructor(
    private themeService: ClientThemeService,
  ) {
    super();
  }
}
