/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { MappingDefinitionNonGeneric } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class SpreadSheetService
{
  constructor(public generalService: CommunicationService) {}

  /**
  * @param {MappingDefinitionNonGeneric} mappingDefinition
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postGeneratetemplate(mappingDefinition: MappingDefinitionNonGeneric, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'mappingDefinition' : JSON.stringify(mappingDefinition)};
    return this.generalService.post(`core-mapping/generate-template`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  * @param {string} mappingDefinitionId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postGeneratetemplateguid(mappingDefinitionId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'mappingDefinitionId' : mappingDefinitionId};
    return this.generalService.post(`core-mapping/generate-template-guid`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }
}
