<ng-container *ngIf="!selectedItem; else elseStatement">
  <div class="c-company-selector__content">
    <i class="fa fa-plus fa-sm" aria-hidden="true"></i>
    <input class="c-company-selector__input"
           type="text"
           [placeholder]="placeholder"
           (keydown.enter)="search($event); "
           [disabled]="disabled">
  </div>

  <div (appScroll)="scrolledToEndHandler()" class="c-list-group"
    *ngIf=" noResults === true || possibleResults.length > 0">
    <div class="c-list-group__item" *ngIf="noResults">No hay resultados</div>
    <div class="c-list-group__item"
      *ngFor="let item of possibleResults; let i = index;trackBy: itemTrackBy"
      (click)="selectItem(i)">{{item[selector].RawValue}}</div>
  </div>
</ng-container>

<ng-template #elseStatement>
  <div class="c-company-selector__content">
    <i class="fa fa-building fa-2x af af-building af-2x"></i>
    <div class="c-company-selector__title">
      <div>{{ getSelectorTitle() }}</div>
      <div> {{ selectedItem[selector] }} </div>
    </div>

      <i class="fa fa-times fa-lg remove-icon" (click)="unselect()" *ngIf="canUnselect" aria-hidden="true"></i>

  </div>
</ng-template>
