<div class="c-field" [formGroup]="group">
  <app-formelementinputlabel [config]="config" [group]="group">
  </app-formelementinputlabel>
  <app-formelementinputdescription [config]="config" [group]="group">
  </app-formelementinputdescription>
  <app-formelementinput-validationmessages [config]="config" [group]="group">
  </app-formelementinput-validationmessages>

  <ng-container *ngIf="config && task">
    <app-batch-item
      [initialTask]="task"
      [showCloseButton]="false">
    </app-batch-item>
  </ng-container>
</div>
