<div
  class="c-popup-config"
  *ngIf="task !== null && task !== undefined; then taskcontainer; else formcontainer"
></div>
<ng-template #taskcontainer>
  <div>
    <app-batch-item
      [initialTask]="task"
      [showCloseButton]="true"
      (onClose)="closeModal()"
      (onTaskCompleted)="dispatchEventOnTaskCompleted($event)"
    >
    </app-batch-item>
  </div>
</ng-template>
<ng-template #formcontainer>
  <ng-container
    #formcontainer
    *ngIf="operation && operation.Form"
    appFormViewGenerator
    [formPlugin]="operation?.Form"
    [params]="formParams"
    (saveSucceed)="saveSucceed($event)"
    (canceled)="closeModal()"
  ></ng-container>
</ng-template>
