import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LockerModule } from '../locker/locker.module';
import { TranslatorServicePipe } from './pipes/rest-translator-service.pipe';
import { TranslatorDatabaseService } from './services/rest-translator-database.service';
import { LocaleService } from '../services/ETG_SABENTISpro_Application_Core_locale.service';

@NgModule({
  imports: [CommonModule, LockerModule],
  declarations: [TranslatorServicePipe],
  exports: [TranslatorServicePipe]
})
export class RestTranslatorModule {
  static forRoot(): ModuleWithProviders<RestTranslatorModule> {
    return {
      ngModule: RestTranslatorModule,
      providers: [
        TranslatorDatabaseService,
        LocaleService
      ]
    };
  }

  constructor() {
  }
}
