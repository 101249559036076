import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicPieChartComponent } from './basic-pie-chart.component';
import { FuseSharedModule } from '../../../../../../../../../../@fuse/shared.module';
import { PieChartModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../../utils/utils.module';

@NgModule({
    declarations: [BasicPieChartComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        PieChartModule,
        UtilsModule
    ]
})
export class BasicPieChartModule {
}
