import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { isNullOrUndefined, UtilsTypescript } from 'app/shared/utils/typescript.utils';
import { getInSafe } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import {
  DtoFrontendModal,
  FormElementListSelectorResult,
  FormElementSelectDynamic,
  FormState,
  FormSubmitData,
  FormSubmitResult,
  FormSubmitStatusType,
  IFormElementOption
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { filter, finalize, map, take, takeUntil } from 'rxjs/operators';
import { ModalReference } from '../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { EventFormSucceededInterface } from '../../../event-form-succeeded.interface';
import { DecoupledModalBridgeService } from '../../../../decoupled-modal/decoupled-modal-bridge.service';

@Component({
  selector: 'app-selectdynamic',
  templateUrl: './selectdynamic.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectDynamicComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectDynamicComponent), multi: true}
  ]
})
export class SelectDynamicComponent extends FrontendFormElementInput implements AfterViewInit {
  /**
   * Usamos esto para identificar a la opción sin valor
   */
  noOptionValue = 'nooption';
  /**
   * Elementos renderizados de la búsqueda de usuario
   */
  renderedSearchItems: IFormElementOption[] = [];
  private renderSearchUpdated: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Valor seleccionado
   */
  protected selectedValue: string;

  /**
   * Valor seleccionado
   */
  protected newValue: string;

  /**
   * If a serach is in progress
   */
  searching = false;
  /**
   * If a search result has been fetched
   */
  searched = false;

  /**
   * Constructor
   */
  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected dbs: DecoupledModalBridgeService,
              protected translatorService: TranslatorService) {
    super(formManagerService, cdRef, translatorService);
  }

  get elementSelectDynamic(): FormElementSelectDynamic {
    return this.config.FormElement as FormElementSelectDynamic;
  }

  /**
   * Este set solo se llama internamente al cambiar el valor
   *
   * @param value
   */
  set selected(value: any) {
    if (isNullOrUndefined(value) || value === '' || value === this.noOptionValue) {
      value = null;
    }

    if (Array.isArray(value) && !isNullOrUndefined(value[0].Key)) {
      value = value.map(item => item.Key)[0];
    }

    this.selectedValue = value;
    this.propagateChange(value);
  }

  get selected(): string {
    if (isNullOrUndefined(this.selectedValue) || this.selectedValue === '') {
      return this.noOptionValue;
    }
    return this.selectedValue;
  }

  /**
   * The ngModel management works with arrays.
   * Multiselect is not supported yet.
   * @param value
   */
  set selectedItems(value: string[] | string) {
    if (typeof value === 'string') {
      this.selected = value;
      return;
    }
    if (!isNullOrUndefined(value) && value.length === 1) {
      this.selected = value[0];
    }
  }

  get selectedItems(): string[] {
    if (isNullOrUndefined(this.selected)) {
      return [];
    }
    return [this.selected]
  }

  /**
   * Opciones de la combo
   */
  get options(): { key: string, option: IFormElementOption }[] {

    let result: { key: string, option: IFormElementOption }[] = [];

    // Si hay renderedSearchItems es porque se agregó uno nuevo y se fueron a buscar todas las opciones (las anteriores y la nueva)
    // Si no hay, se obtuvieron en back al crear el componente
    if (!isNullOrUndefined(this.renderedSearchItems) && this.renderedSearchItems.length > 0) {
      for (const op of this.renderedSearchItems) {
        result.push({key: op.HashedKey.Key, option: op});
      }
    } else if (this.elementSelectDynamic.PreloadedOptions) {
      for (const op of this.elementSelectDynamic.PreloadedOptions) {
        result.push({key: op.HashedKey.Key, option: op});
      }
    }

    if (isNullOrUndefined(this.config.disableAutoSort) || this.config.disableAutoSort === false) {
      result.sort(
          function (a: { key: string, option: IFormElementOption }, b: {
            key: string,
            option: IFormElementOption
          }): number {
            if (a.option.Name < b.option.Name) {
              return -1;
            }
            if (a.option.Name > b.option.Name) {
              return 1;
            }
            return 0;
          });
    }

    // Añadimos la opción de "seleccione una opción" si hace falta, al principio del arreglo de opciones.
    if (this.config.required === false || isNullOrUndefined(this.selectedValue)) {
      result = [{
        key: this.noOptionValue,
        option: {
          Key: this.noOptionValue,
          Name: `-- ${this.translatorService.get('Seleccione una opción')} --`
        } as IFormElementOption
      }, ...result];
    }
    return result;
  }


  /**
   * This function is used to communicate with the server and get
   * the options list
   * @param {string} search: search string. Is used in backend to
   * return the values that contains this string
   * @param page
   */
  getOptionList(): void {
    // Evitar solapar búsquedas
    if (this.searching === true) {
      return;
    }
    this.renderedSearchItems = [];
    this.renderSearchUpdated.next();
    const formSubmitData: FormSubmitData = new FormSubmitData();
    formSubmitData.formInput = this.formManagerService.getFormComponentValue('');
    formSubmitData.submitElement = this.config.name;
    this.searching = true;
    this.formManagerService.getFieldSelectorDynamiccallback(
        formSubmitData,
        this.config.name
    )
        .pipe(
            finalize(() => {
              this.searching = false;
            }),
            takeUntil(this.componentDestroyed$),
            takeUntil(this.renderSearchUpdated),
            take(1)
        )
        .subscribe(
            (response: FormElementListSelectorResult) => {
              this.searched = true;
              this.renderedSearchItems = response.Options;
              this.forceDetectChanges();
            }
        );
  }

  /**
   * Los ítems individuales pueden tener una descripción
   */
  get currentItemDescription(): string {
    return getInSafe(this.config.options, (i) => i.find((j) => j.Key === this.selected).Description, null);
  }

  writeValue(value: string): void {
    this.selected = value;
  }

  /**
   * Condiciones para mostrar o no la posiblidad de añadir un nuevo elemento
   */
  get showCreateNewItem(): boolean {
    // Si se ha habilitado en backend
    return this.elementSelectDynamic.CreateNewItem;
  }

  /**
   * Handler para el botón de crear nuevo item
   */
  createNewItem(): void {
    if (this.getIsDisabled() === true) {
      return;
    }
    const modalSettings: DtoFrontendModal = this.getModalSettingsNew();
    // A los argumentos existentes, le añadimos unos argumentos automáticos
    // que pueden permitir tener conocimiento de "donde vengo"
    const formArguments: {
      [key: string]: any
    } = UtilsTypescript.jsonClone(this.elementSelectDynamic.AddNewItemFormPluginRequest.Arguments) || {};
    const state: FormState = this.formManagerService.getFormState();
    formArguments['request_form_id'] = state.FormId;
    formArguments['request_form_field_path'] = this.config.ClientPath;
    formArguments['request_form_values'] = this.formManagerService
        .getForm()
        .getRawValue();
    const modal: ModalReference<EventFormSucceededInterface> = this.dbs
        .showForm(
            this.elementSelectDynamic.AddNewItemFormPluginRequest.FormId,
            modalSettings,
            formArguments);
    modal.close$
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((i) => !!i),
            map((i) => i.responseData as FormSubmitResult)
        ).subscribe(
        (i: FormSubmitResult) => {
          if (i.Status === FormSubmitStatusType.Success) {
            this.getOptionList();
            this.selectedValue = i.Result.id;
            this.writeValue(this.selectedValue);
          }
        });
  }

  getModalSettingsNew(): DtoFrontendModal {
    if (isNullOrUndefined(this.elementSelectDynamic.AddNewItemFormPluginRequest.ModalSettings)) {
      return this.createDefaultModalSettings();
    }
    return this.elementSelectDynamic.AddNewItemFormPluginRequest.ModalSettings;
  }

  createDefaultModalSettings(): DtoFrontendModal {
    const modalSettings: DtoFrontendModal = new DtoFrontendModal();
    modalSettings.HideClose = true;
    return modalSettings;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }
}
