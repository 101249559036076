/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { CallbackTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class CoreCallbackService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Execute a Backend Callback
  *
  * @param {CallbackTyped} callback
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any} The CallbackTyped result
  */
  postExecutecallbacktyped(callback: CallbackTyped, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'callback' : JSON.stringify(callback)};
    return this.generalService.post(`core-callback/execute-callbacktyped`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Esto se ha desarollado exclusivamente para poder dar cobertura de test con mayor facilidad a temas relacionados con la sesión. Permite ejecutar código de "servidor" desde los test de backend, sin tener que implementar endpoints de verdad.
  *
  * @param {string} serializedLambda
  * @param {{ [id: string]: any }} additionalArguments
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postExecutelambda(serializedLambda: string, additionalArguments: { [id: string]: any } = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'serializedLambda' : serializedLambda, 'additionalArguments' : JSON.stringify(additionalArguments)};
    return this.generalService.post(`core-callback/execute-lambda`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }
}
