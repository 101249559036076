import { Translation } from '../models/translation';
import { Observable, of } from 'rxjs';
import { RestTranslatorInterface } from './rest-translator.interface';

export class TranslatorPassthrough implements RestTranslatorInterface {

  constructor() {
  }

  /**
   * Obtener todas las traducciones almacenads de un idioma determinado
   *
   * @param language
   */
  getTranslationsForLanguage(language: string): Observable<Translation[]> {
    return of([]);
  }

  /**
   * Borra todas las traducciones para un idioma determinado, se usa
   * para resincronizar las traducciones de ese idioma.
   *
   * @param language
   */
  clearTranslationsForLanguage(language: string): Observable<number> {
    return of(0);
  }

  putTranslations(translations: Translation[]): Observable<number> {
    return of(0);
  }

  /**
   * Clear all the translations and remove all the localstorage locale keys
   */
  clear(): Observable<void> {
    return of(null);
  }
}
