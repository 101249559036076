import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { Observable } from 'rxjs';
import { getInSafe } from '../../shared/utils/typescript.utils';
import { delayWhen, finalize, map, retryWhen, tap } from 'rxjs/operators';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { Guid } from 'guid-typescript';
import { AppBootstrapSpinnerService } from '../../app-bootstrap-spinner.service';
import { WebServiceResponse } from '../models/ETG_SABENTISpro_Application_Core_models';
import { BOOTSTRAP_REQUEST_METADATA, BootstrapService } from '../../app-bootstrap.service';

/**
 * Este interceptor se encarga de gestionar la situación
 * en la que un usuario está "trabajando" en la plataforma
 * y esta recibe un reseteo y se encuentra temporalmente en 503.
 *
 * Esto no evita que se refresque el aplicativo si se detecta un cambio de versión...
 */
@Injectable()
export class AppbootstrapInterceptor implements HttpInterceptor {

  /**
   * Nos traemos los dos servicios de spinner, ya que AppBootstrapSpinnerService sirve para antes de tener
   * cargado nada (no hay theme, ni estilos, etc..) y el SpinnerService ya es dentro del aplicativo
   *
   * @param spinnerService
   * @param bootstrapSpinner
   */
  constructor(
      private spinnerService: SpinnerService,
      private bootstrapSpinner: AppBootstrapSpinnerService,
      private bootstrapService: BootstrapService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let retryCount: number = 0;
    let spinnerId: Guid = null;
    req = req.clone();

    return next.handle(req)
        .pipe(
            retryWhen((errors) => {
              let delayTime: number = 750;
              return errors.pipe(
                  map((error): HttpErrorResponse => {
                    // Miramos que el request tenga el metadato de bootstrap. Si no lo tiene directamente pasamos al siguiente interceptor
                    if (req.context.get(BOOTSTRAP_REQUEST_METADATA) !== true) {
                      throw error;
                    }

                    if (error.status === 543 || error.status === 503 || error.status === 0) {
                      if (!spinnerId && !this.bootstrapSpinner.available()) {
                        spinnerId = this.spinnerService.showSpinner('Esperando...');
                      }
                      return error;
                    }
                    throw error;
                  }),
                  // log error message
                  tap(error => {
                    const retry: any = error.headers.get('Retry-After');
                    retryCount++;
                    delayTime = retry ? retry * 1000 : 750 + ((retryCount > 3 ? 3 : retryCount) * 1250 * Math.random());
                    // Actualizar el estado...
                    if (error.status === 0) {
                      if (spinnerId) {
                        this.spinnerService.updateSpinnerText(spinnerId, 'Sin conexión. Reintentando...');
                      } else {
                        this.bootstrapSpinner.setText('Sin conexión. Reintentando...');
                      }
                      return;
                    }
                    const wsResponse: WebServiceResponse = getInSafe((error), (x) => x.error, null);
                    if (wsResponse) {
                      let message: string = 'Servicio no disponible...';
                      if (wsResponse && wsResponse.result && wsResponse.result.message) {
                        message = wsResponse.result.message;
                      }
                      if (spinnerId) {
                        this.spinnerService.updateSpinnerText(spinnerId, message);
                      } else {
                        this.bootstrapSpinner.setText(message);
                      }
                      if (wsResponse && wsResponse.result && wsResponse.result.log) {
                        console.debug(wsResponse.result['log']);
                      }
                    }
                  }),
                  // restart in 0.5 seconds
                  delayWhen(() => interval(delayTime))
              )
            }),
            finalize(
                () => {
                  if (spinnerId) {
                    this.spinnerService.removeSpinner(spinnerId);
                    spinnerId = null;
                  }
                }
            ),
        );
  }
}
