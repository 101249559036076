import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  CoreRenderTemplateSimpleMessageMessage,
  CoreRenderTemplateSimpleMessageType,
  SystemInfoDTO
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { SystemService } from '../../core/services/ETG_SABENTISpro_Application_Core_update.service';

@Component({
  selector: 'app-system-info',
  templateUrl: './system-info.component.html',
  styleUrls: ['./system-info.component.scss']
})
export class SystemInfoComponent extends DestroyableObjectTrait implements OnInit {

  public systemInfo: SystemInfoDTO;

  CoreRenderTemplateSimpleMessageTypeInfo = CoreRenderTemplateSimpleMessageType.Info;
  CoreRenderTemplateSimpleMessageTypeError = CoreRenderTemplateSimpleMessageType.Error;
  CoreRenderTemplateSimpleMessageTypeSuccess = CoreRenderTemplateSimpleMessageType.Success;
  CoreRenderTemplateSimpleMessageTypeWarning = CoreRenderTemplateSimpleMessageType.Warning;

  /**
   * Constructor
   */
  constructor(private changeDetector: ChangeDetectorRef,
              private updateService: SystemService) {
    super();
    changeDetector.detach();
  }

  ngOnInit(): void {
    this.refreshSystemInfo(false);
  }

  onRefreshButtonClicked(event: MouseEvent): void {
    this.refreshSystemInfo(true);
  }

  private refreshSystemInfo(force: boolean): void {
    this.systemInfo = null;
    this.changeDetector.detectChanges();
    this.getSystemInfo(force, false)
        .pipe(
            finalize(() => {
              this.changeDetector.detectChanges();
            })).subscribe(result => {
      this.systemInfo = result;
    });
  }

  public getSystemInfo(force: boolean, showSpinner: boolean): Observable<SystemInfoDTO> {
    return this.updateService.getSystemInfo(force, {showSpinner: showSpinner})
        .pipe(
            takeUntil(this.componentDestroyed$),
            map(response => {
              return response.result;
            }));
  }

  public filterMessages(messages: CoreRenderTemplateSimpleMessageMessage[], filter: CoreRenderTemplateSimpleMessageType): CoreRenderTemplateSimpleMessageMessage[] {
    return messages?.filter(x => x.Type === filter);
  }
}
