import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { isNullOrUndefined, JsonClone } from '../utils/typescript.utils';

export class ContextUtils {

  public static lastContext: string;

  /**
   * Adds the application context to a HttpRequest.
   * @param {HttpRequest<T>} req
   */
  public static AddContextToHeaders<T>(req: HttpRequest<T>): HttpRequest<T> {
    let context: string = sessionStorage.getItem('context');

    if (isNullOrUndefined(this.lastContext) && !isNullOrUndefined(context)) {
      console.debug('Seteo del contexto con valor' + context);
      this.lastContext = JsonClone(context);
    }

    if (!isNullOrUndefined(this.lastContext) && isNullOrUndefined(context)) {
      console.error('El contexto se ha perdido');
    }

    if (!isNullOrUndefined(this.lastContext) && !isNullOrUndefined(context)) {
      const parsedLastContext: any = JSON.parse(this.lastContext);
      const parsedContext: any = JSON.parse(context);
      if (!parsedContext.hasOwnProperty('signature')) {
        throw new Error('Current context must have a signature');
      }
      if (!parsedLastContext.hasOwnProperty('signature')) {
        throw new Error('Current context must have a signature');
      }
      if (parsedLastContext['signature'] !== parsedContext['signature']) {
        this.lastContext = JsonClone(context);
      }
    }

    if (isNullOrUndefined(context)) {
      context = JSON.stringify({});
    }

    let headers: HttpHeaders = req.headers;

    if (!isNullOrUndefined(context)) {
      headers = headers.set('Context', context);
    }

    return req.clone({headers});
  }
}
