import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { DecoupledModalBridgeService } from '../decoupled-modal-bridge.service';
import { DecoupledModalImplementationInterface } from './decoupled-modal-implementation.interface';
import { BaseModalParamsInterface } from './modal-params.interface';
import { filter, takeUntil } from 'rxjs/operators';

export abstract class AbstractDecoupledModalService
  extends DestroyableObjectTrait
  implements DecoupledModalImplementationInterface {

  /**
   * AbstractDecoupledModalLayoutService class constructor.
   * @param {DecoupledModalBridgeService} dmbs
   */
  constructor(
    protected dmbs: DecoupledModalBridgeService
  ) {
    super();

    dmbs
      .onShowComponent$
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((data: BaseModalParamsInterface) => {
          return data.service.name === this.constructor.name;
        })
      )
      .subscribe((data: BaseModalParamsInterface) => this.handleShowComponent(data));
  }

  /**
   * @inheritdoc
   */
  abstract register(): Promise<boolean>;

  /**
   * Implement custom layout/library modal display logic.
   * @param {BaseModalParamsInterface} data
   */
  abstract handleShowComponent(data: BaseModalParamsInterface): void;
}
