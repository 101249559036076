import { ViewsSingleItemOperationForm } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';

export class SioFormOpenedEventData {

  /**
   * Operación
   */
  sio: ViewsSingleItemOperationForm;

  /**
   * Referencia a la modal abierta, TODO: ACTUALIZAR CON EL NUEVO SISTEMA DE MODALES
   */
  instanceRef: any;
}
