<div class="c-field" [formGroup]="group">
  <app-formelementinputlabel [config]="config" [group]="group">
  </app-formelementinputlabel>

  <div class="progress">
    <div class="progress-bar" role="progressbar" [ngStyle]="{ width: getProgress() + '%' }"></div>
  </div>

  <p class="titulo-porcentaje"><span [innerHTML]="getProgress()"></span>%</p>

  <app-formelementinputdescription [config]="config" [group]="group">
  </app-formelementinputdescription>
</div>
