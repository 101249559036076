<app-form-manager *ngIf="this.configurationArguments.form1Id" [formPlugin]="this.configurationArguments.form1Id"
                  [params]="paramsForm1" #form1>
</app-form-manager>
<app-list2 *ngIf="this.configurationArguments.list1Id"
           [disableIntersectionObserverApi]="this.configurationArguments.disableListIntersectionObserverApi"
           [listId]="this.configurationArguments.list1Id"
           [args]="paramsList1"
           #list1>
</app-list2>
<app-form-manager *ngIf="this.configurationArguments.form2Id" [formPlugin]="this.configurationArguments.form2Id"
                  [params]="paramsForm2" #form2>
</app-form-manager>
<app-form-manager *ngIf="this.configurationArguments.form3Id" [formPlugin]="this.configurationArguments.form3Id"
                  [params]="paramsForm3" #form3>
</app-form-manager>
