import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  Renderer2,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';
import { FrontendFormElementInput } from '../formelementinput.class';
import * as $ from 'jquery';
import {
  FormElementHtmlPlaceholder,
  FormElementHtmlPlaceholderEmbedType
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { NavigationService } from '../../../../core/navigation/navigation.service';

/**
 * Este componente no necesita el Control Value Accessor por que es únicamente de salida
 */
@Component({
  selector: 'app-htmlplaceholder',
  templateUrl: './htmlplaceholder.component.html'
})
export class HtmlplaceholderComponent extends FrontendFormElementWrapper implements AfterViewInit, OnDestroy {

  FormElementHtmlPlaceholderEmbedType = FormElementHtmlPlaceholderEmbedType;

  @ViewChild('frame') iframe: ElementRef;

  @ViewChild('safeHtml') safeHtml: ElementRef;

  @ViewChild('unsafeHtml') unsafeHtml: ElementRef;

  @ViewChild('safeHtmlWithScripts') safeHtmlWithScripts: ElementRef;

  listenClickForDataControllerFunc: Function;

  listenClickForDataUrlFunc: Function;

  get configuration(): FormElementHtmlPlaceholder {
    return this.config.FormElement as FormElementHtmlPlaceholder;
  }

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  constructor(
    protected formManager: FormManagerService,
    protected sanitizer: DomSanitizer,
    protected cdRef: ChangeDetectorRef,
    @SkipSelf()
    protected cdRefParent: ChangeDetectorRef,
    private navigationService: NavigationService,
    private renderer: Renderer2) {
    super(formManager, cdRef, cdRefParent);
  }

  @HostBinding('class')
  get getClasses(): string {
    return super.getComponentClassesRendered();
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    const elementRef: ElementRef = this.getElementRef();
    if (this.configuration.EmbedType === FormElementHtmlPlaceholderEmbedType.Iframe) {
      if (elementRef?.nativeElement) {
        const frame: HTMLIFrameElement = this.iframe.nativeElement;
        const doc: any = frame.contentWindow.document;
        // Nos colgamos de algunos eventos...
        $(frame).on('load', function (): void {
          frame.height = String(frame.contentWindow.document.body.scrollHeight);
        });
        // Cargamos el HTML en la ventana
        doc.open();
        doc.write(this.configuration.Html);
        doc.close();

      }
    } else {
      this.generateNavigationListeners(elementRef);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    // this.listenClickForDataUrlFunc();
  }

  private generateNavigationListeners(element: ElementRef): void {
    this.listenClickForDataControllerFunc = this.generateNavigationListenersForAttribute(element, ['a', 'button'], 'data-controller', ((elem, attributeValue) => {
      let controller: string = attributeValue;
      const args: {} = {};

      if (controller.includes('?')) {
        controller = attributeValue.split('?')[0];
        attributeValue.split('?')[1].split('&').forEach(x => {
          const arg: string[] = x.split('=');
          args[arg[0]] = arg[1];
        })
      }

      this.navigationService.navigateUrlByController(controller, args, null)
        .then((x: boolean) => {
        });
    }));

    this.listenClickForDataUrlFunc = this.generateNavigationListenersForAttribute(element, ['a', 'button'], 'data-routerLink', ((elem, attributeValue) => {
      this.navigationService.navigateByUrl(attributeValue, null)
        .then((x: boolean) => {
        });
    }));
  }

  private generateNavigationListenersForAttribute(
    container: ElementRef, elementTags: string[], attribute: string, action: (elem: any, attributeValue: string) => void): Function {
    if (container?.nativeElement) {
      const navigationElements: [] = [];

      elementTags.forEach((elementTag: string) => {
        const elements: [] = Array.prototype.slice.call(container.nativeElement.querySelectorAll(`${elementTag}[${attribute}]`));
        elements.forEach(x => navigationElements.push(x));
      })

      let res: Function;

      navigationElements.forEach((elem: any) => {
        res = this.renderer.listen(elem, 'click', (event) => {
          event.preventDefault();
          const attributeValue: string = elem.getAttribute(attribute);
          action(elem, attributeValue);
        });
      });

      return res;
    }
  }

  private getElementRef(): ElementRef {
    if (this.configuration.EmbedType === FormElementHtmlPlaceholderEmbedType.SafeHtml) {
      return this.safeHtml;
    } else if (this.configuration.EmbedType === FormElementHtmlPlaceholderEmbedType.Unsafe) {
      return this.unsafeHtml;
    } else if (this.configuration.EmbedType === FormElementHtmlPlaceholderEmbedType.SafeHtmlWithScripts) {
      return this.safeHtmlWithScripts;
    } else if (this.configuration.EmbedType === FormElementHtmlPlaceholderEmbedType.Iframe) {
      return this.iframe;
    }
  }
}


