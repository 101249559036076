import { ViewMenuBarComponent } from './view-menu-bar/components/view-menu-bar.component';
import { Type } from '@angular/core';
import { IViewHeaderComponent } from './view-header-component.interface';
import { ViewSmallBarComponent } from './view-small-bar/components/view-small-bar.component';
import { ViewMaterialBarComponent } from './view-material-bar/components/view-material-bar.component';


const ViewHeaderComponentsConstants: { [type: string]: Type<IViewHeaderComponent> } = {
  ViewHeaderSmall: ViewSmallBarComponent,
  ViewHeaderDefault: ViewMenuBarComponent,
  ViewHeaderMaterial: ViewMaterialBarComponent,
  ViewHeaderNone: null,
};

export { ViewHeaderComponentsConstants };
