import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RestTranslatorModule } from '../../../../core/translator/rest-translator.module';
import { ExposedFiltersModule } from '../../exposed-filters/exposed-filters.module';
import { ViewHeadersSharedModule } from '../view-headers-shared/view-headers-shared.module';
import { ViewMenuBarComponent } from './components/view-menu-bar.component';
import { ViewMaterialBarModule } from '../view-material-bar/view-material-bar.module';

@NgModule({
    imports: [
        CommonModule,
        ExposedFiltersModule,
        ViewHeadersSharedModule,
        RestTranslatorModule,
        TooltipModule.forRoot(),
        ViewMaterialBarModule
    ],
    declarations: [
        ViewMenuBarComponent,
    ],
    exports: [
        ViewMenuBarComponent,
    ]
})
export class ViewMenuBarModule {
}
