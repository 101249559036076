import { FormState } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FormManagerComponent } from './form-manager/form-manager.component';
import { FormManagerService } from './form-manager/form-manager.service';

/**
 * On form loaded event
 */
export class FormLoadedEventData {

  /**
   * The name of the control
   */
  formState: FormState;

  /**
   * The form manager component
   */
  formManagerComponent: FormManagerComponent;

  /**
   * The form manager service
   */
  formManagerService: FormManagerService;
}
