import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import {
  ViewConfiguration,
  ViewExecutableCountResult,
  ViewResult,
  ViewsPagerComplete,
  ViewsPagerLoadCountModeEnum,
  ViewsPagerUserConfigurationSimple,
  ViewUserConfiguration
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../list.service';
import { PagerInterface } from '../interfaces/pager.interface';
import { DestroyableObjectTrait } from '../../../utils/destroyableobject.trait';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { ViewsuserconfigchangedAction, ViewsuserconfigchangedEventdata } from '../../viewsuserconfigchanged.eventdata';
import { LoadingLengthNumber, NoLengthLengthNumber } from './mat-paginator-intl';
import { isNullOrUndefined } from '../../../utils/typescript.utils';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-material-pager',
  templateUrl: './material-pager.component.html',
  styleUrls: ['./material-pager.component.scss']
})
export class MaterialPagerComponent extends DestroyableObjectTrait implements OnInit, PagerInterface, OnDestroy {

  /**
   * TimeOuts
   */
  timeOutIDs: number[] = [];

  /**
   * Current page number (index).
   */
  currentPage = 0;

  /**
   * Numbers of rows to be shown.
   */
  pageSize = 25;

  /**
   * Available row sizes.
   */
  availablePageSizes: number[];

  /**
   * Load mode of count
   */
  loadCountMode: ViewsPagerLoadCountModeEnum;

  /**
   * The total result count
   */
  totalResultCount: ViewExecutableCountResult = null;

  /**
   * Length of the complete data set
   */
  length: number = NoLengthLengthNumber;

  /**
   * Usamos este evento para cancelar cualquier llamada "count"
   */
  countRequestTakeUntil: EventEmitter<void> = new EventEmitter<void>();

  /**
   * If the component was touched (solo cargamos count si tocamos el paginador)
   */
  touched: boolean = false;

  /**
   * Is LoadCountButton visible
   */
  isLoadCountButtonVisible: boolean = false;

  /**
   * View child reference for the paginator.
   */
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;

  /**
   * View result input. Used to calculate the number of pages allowed to be
   * navigated.
   */
  @Input() viewResult: ViewResult;

  /**
   * MaterialPagerComponent class constructor.
   *
   * @param {ListComponent2Service} listComponentService
   * @param {ChangeDetectorRef} changeDetector
   * @param {ElementRef} elementRef
   */
  constructor(
    public listComponentService: ListComponent2Service,
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef) {
    super();
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {

    // Inicializamos el paginador con los datos actuales del viewresult
    this.updatePagerFromViewResult();

    // Cada vez que obtengo resultados de backend, si hay paginación intento cogerla.
    this.listComponentService
      .viewDataLoadedWithReplay
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.updatePagerFromViewResult();
      });

    // Si cambia la configuración de usuario, tengo que resetear el count interno.
    this.listComponentService
      .userConfigurationChanged
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((i) => !i.tags.includes(ListComponent2Service.userConfigurationChangeNotAffectingCountTag))
      )
      .subscribe((event: ViewsuserconfigchangedEventdata) => {
        // Stop any currently active count request...
        this.countRequestTakeUntil.emit();
        this.totalResultCount = null;
        this.length = NoLengthLengthNumber;
        this.touched = false;
      });

    this.refresh();
  }

  /**
   * Actualiza el paginador a partir de los datos de un view result,
   * si éste tiene información sobre la paginación.
   */
  protected updatePagerFromViewResult(): void {
    const data: ViewResult = this.listComponentService.data;
    if (data && data.ResultCount) {
      this.totalResultCount = data.ResultCount;
      this.countRequestTakeUntil.emit();
      this.refresh();
    }
  }

  /**
   *
   * @param changes
   */
  refresh(): void {
    const configuration: ViewConfiguration = this.listComponentService.getConfiguration();
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();

    this.pageSize = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).PageSize;
    this.currentPage = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage;
    this.availablePageSizes = (configuration.Pager as ViewsPagerComplete).AvailablePageSizes;
    this.loadCountMode = (configuration.Pager as ViewsPagerComplete).LoadCountMode;

    if (this.totalResultCount && !isNullOrUndefined(this.totalResultCount.Count)) {
      this.length = this.totalResultCount.Count;
    } else if (this.loadCountMode === ViewsPagerLoadCountModeEnum.Always || this.touched === true) {
      this.loadCount();
    }

    if (this.loadCountMode === ViewsPagerLoadCountModeEnum.OnDemand) {
      this.isLoadCountButtonVisible = true;
    }

    // On initialization trigger a detection cycle so the pager component
    // updates values to be shown.
    this.timeOutIDs.push(setTimeout(() => this.executeChangeDetection()));
  }

  /**
   * A lifecycle hook that is called on Angular destroys the component
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.timeOutIDs.forEach(id => clearTimeout(id));
  }

  /**
   * Unfortunately, due to event blacklisting, events triggered on the app
   * components doesn't propagate to inner components of material elements like
   * the paginator (and backwards).
   *
   * The only way to propagate those events/changes is to force the exectution
   * of change detection/cycles.
   *
   * We need to capture click events outside this component, because the
   * `paginator` component of material instantiates a select component outside
   * this component scope. The select is created inside a ng-container appended
   * to the document.
   *
   * So we capture possible backdrop and selection events with a document:click
   * and trigger detection cycles on this component.
   *
   * @param {MouseEvent} event
   */
  @HostListener('document:click', ['$event'])
  clickedOutside(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // Detect changes only in this component.
      this.executeChangeDetection();
    }
  }

  /**
   * Paginator configuration update method.
   */
  changeCurrentPage(e: PageEvent): void {
    const currentPage: number = e.pageIndex;
    const pageSize: number = e.pageSize;

    let configUpdated: boolean = false;
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    const currentConfiguration: ViewsPagerUserConfigurationSimple = userConfiguration.Pagination as ViewsPagerUserConfigurationSimple;

    if (currentConfiguration.CurrentPage !== currentPage) {
      currentConfiguration.CurrentPage = currentPage;
      configUpdated = true;
      // Al cambiar de página, obtenemos el count para ayudar en la UX
      if (this.touched === false) {
        this.touched = true;
        this.refresh();
      }
    }

    if (currentConfiguration.PageSize !== pageSize) {
      currentConfiguration.PageSize = pageSize;
      configUpdated = true;
    }

    if (configUpdated) {
      this.listComponentService.setUserConfiguration(userConfiguration, true, ViewsuserconfigchangedAction.Refresh, [ListComponent2Service.userConfigurationChangeNotAffectingCountTag]);
    }
  }

  /**
   * Trigger a change detection cycle that affects this and child components.
   */
  executeChangeDetection(): void {
    this.changeDetector.detectChanges();
  }

  clickHandler(): void {
    this.executeChangeDetection();
  }

  mouseOverHandler(): void {
    this.executeChangeDetection();
  }

  loadCount(): void {
    this.length = LoadingLengthNumber;
    this.listComponentService.getTotalItemCount()
      .pipe(
        delay(1500),
        takeUntil(this.componentDestroyed$),
        takeUntil(this.countRequestTakeUntil),
      )
      .subscribe((count: ViewExecutableCountResult) => {
        this.totalResultCount = count;
        this.refresh();
      });
  }
}
