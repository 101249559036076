<div #anchor></div>
<h2 *ngIf="title !== null && title !== undefined">{{ title }}</h2>
<h4 *ngIf="subtitle !== null && subtitle !== undefined"> {{ subtitle }}</h4>
<div class="flex-row form-msg-box" *ngIf="validationMessages.length > 0">
  <div class="c-msg-box flex-col-lg-12 flex-col-sm-12 flex-col-xs-12"
       [ngClass]="'c-msg-box--error'">
    <i class="m-fi "
       [ngClass]="'m-fi--exclamation-on-circle'"></i>
    <ul class="c-msg-box__text">
      <li *ngFor="let message of validationMessages" [innerHtml]="message.Message"></li>
    </ul>
  </div>
</div>
<!--todo este ID está mal, ya que impide que haya más de un formulario del mismo ID en la pantalla al mismo tiempo...-->
<form [id]="'form-' + formPlugin" [formGroup]="group" class="sabentis-form"
      [attr.data-submitcount]="this.formManagerService.submitCount"
      [attr.data-stateapicount]="this.formManagerService.stateApiCalculateCount">
  <div
      [ngClass]="{'flex-row': isBootstrapContainer(this.config.FormElement), 'flex-row-nocols': !isBootstrapContainer(this.config.FormElement)}"
      *ngFor="let row of rows">
    <ng-container
        *ngFor="let field of row;"
        appGenericInput
        [config]="field"
        [group]="group">
    </ng-container>
  </div>
</form>
