/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class SessionService
{
  constructor(public generalService: CommunicationService) {}

  /**
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string}
  */
  getCurrentuser(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    return this.generalService.get(`core-session/current-user`, null, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }

  /**
  *
  * From the current session, this function gets the information to show where it is needed
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  getDisplayableinfo(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    return this.generalService.get(`core-session/displayableinfo`, null, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Sample endpoint to retrieve logged in user. Returns null when session did not work.
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string}
  */
  getOwnuserid(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    return this.generalService.get(`core-session/ownuserid`, null, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }

  /**
  *
  * Return the list of permissions by person's id of the user authentificated.
  *
  * @param {string} personId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string[]}
  */
  getPermissionsbypersonid(personId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string[]>>
  {
    const params: any = {'personId' : personId};
    return this.generalService.get(`core-session/permissionsbypersonid`, params, clientOpts) as Observable<WebServiceResponseTyped<string[]>>;
  }

  /**
  *
  * Fuerza el refresco del estado de la sesión. Por defecto, esto se hace solo cuando los tokens tiene algo de antigüedad.
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  getRefresh(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    return this.generalService.get(`core-session/refresh`, null, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Autenticar un usuario con login y password
  *
  * @param {string} login
  * @param {string} password
  * @param {string} device
  * @param {string} data
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postAuthenticate(login: string, password: string, device: string = 'DEVICE', data: string = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'login' : login, 'password' : password, 'device' : device, 'data' : data};
    return this.generalService.post(`core-session/authenticate`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Get a new refresh and access token.
  *
  * @param {string} personId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postChangeperson(personId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'personId' : personId};
    return this.generalService.post(`core-session/change-person`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * End impersonation of a user session.
  *
  * @param {string} device
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postEndimpersonate(device: string = 'DEVICE', clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'device' : device};
    return this.generalService.post(`core-session/end-impersonate`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Return the true or false if the person has the permissions.
  *
  * @param {string[]} permissions
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {boolean}
  */
  postHaspermission(permissions: string[], clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<boolean>>
  {
    const params: any = {'permissions' : JSON.stringify(permissions)};
    return this.generalService.post(`core-session/haspermission`, params, clientOpts) as Observable<WebServiceResponseTyped<boolean>>;
  }

  /**
  *
  * Impersonate a user by his id.
  *
  * @param {string} userid
  * @param {string} device
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postImpersonate(userid: string, device: string = 'DEVICE', clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'userid' : userid, 'device' : device};
    return this.generalService.post(`core-session/impersonate`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * In order to logout we need the refreshtoken
  *
  * @param {boolean} allDevices
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  postLogout(allDevices: boolean = false, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'allDevices' : JSON.stringify(allDevices)};
    return this.generalService.post(`core-session/logout`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }
}
