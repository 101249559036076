import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupedHorizontalBarChartComponent } from './grouped-horizontal-bar-chart.component';
import { FuseSharedModule } from '../../../../../../../../../../@fuse/shared.module';
import { BarChartModule, ChartCommonModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../../utils/utils.module';


@NgModule({
    declarations: [GroupedHorizontalBarChartComponent],
    imports: [
        FuseSharedModule,
        CommonModule,
        BarChartModule,
        UtilsModule,
        ChartCommonModule
    ]
})
export class GroupedHorizontalBarChartModule {
}
