import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  IViewField,
  IViewModeColumnBased,
  IViewModeSortableField,
  IViewModeUserConfiguration,
  IViewModeUserConfigurationColumn,
  IViewModeUserConfigurationColumnsBased,
  ViewConfiguration,
  ViewFieldSingleItemOperations,
  ViewModeTableColumn,
  ViewModeTableUserConfigurationColumn, ViewsFieldVbo,
  ViewSortDirection,
  ViewUserConfiguration
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AbstractDecoupledModalComponent } from '../../../../decoupled-modal/models/abstract-decoupled-modal.component';
import { backendTypeMatch, getInSafe, JsonClone, UtilsTypescript } from '../../../../utils/typescript.utils';
import { ViewModeUtils } from '../../../grid/viewmode.utils';
import { ListComponent2Service } from '../../../list.service';
import { ViewsuserconfigchangedAction } from '../../../viewsuserconfigchanged.eventdata';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-configuration-selector',
  templateUrl: './view-configuration-selector.component.html'
})
export class ViewConfigurationSelectorComponent
    extends AbstractDecoupledModalComponent implements OnInit {

  viewConfigurationSelectorColumns: ViewConfigurationSelectorColumn[] = [];
  userConfig: ViewUserConfiguration;

  /**
   * Creates a new instance of ViewConfigurationSelectorComponent
   */
  constructor(
      private listComponentConfiguration: ListComponent2Service,
      private cdRef: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Initializes component
   */
  ngOnInit(): void {
    this.initialize(this.listComponentConfiguration.getUserConfiguration());
    this.listComponentConfiguration.userConfigurationChanged.pipe(takeUntil(this.componentDestroyed$)).subscribe(o => {
      if (o.refreshAction === ViewsuserconfigchangedAction.ViewModeChange) {
        this.initialize(o.userConfiguration)
      }
    });
  }

  initialize(userConfig: ViewUserConfiguration): void {
    this.userConfig = userConfig;
    const currentViewMode: IViewModeUserConfiguration =
        getInSafe(this.userConfig, x => x.CurrentViewMode, null);

    if (!currentViewMode) {
      return;
    }
    if (!currentViewMode.hasOwnProperty('Columns')) {
      throw new Error('The Current ViewMode must implements IViewModeUserConfigurationColumnsBased')
    }
    const viewModeUserConfig: IViewModeUserConfigurationColumnsBased = currentViewMode as IViewModeUserConfigurationColumnsBased;
    this.initializeViewConfigurationSelectorColumns(JsonClone(viewModeUserConfig));
  }


  /**
   * Initialize the user columns
   * @param viewModeUserConfig
   * @param userConfig
   */
  protected initializeViewConfigurationSelectorColumns(viewModeUserConfig: IViewModeUserConfigurationColumnsBased): void {
    // Aunque normalmente ya viene precargado y correctamente desde backend,
    // normalizamos aquí la configuración de usuario para asegurarnos de que está OK y en sincronización
    // con lo que está puesto en el ViewMode
    const tableConfig: IViewModeColumnBased = ViewModeUtils.GetCurrentViewModeColumnBasedFromService(this.listComponentConfiguration);
    let userColumns: IViewModeUserConfigurationColumn[] = viewModeUserConfig.Columns;
    // Quitar aquellas que no están en las columnas disponibles
    userColumns = userColumns.filter((userColumn) => viewModeUserConfig.Columns.findIndex(c => c.Field === userColumn.Field) !== -1);
    // Añadir las que falten
    UtilsTypescript.ObjectValues(tableConfig.Columns).forEach((c) => {
      if (userColumns.findIndex((x) => x.Field === c.Field) !== -1) {
        return;
      }
      if (c.Access === false) {
        return;
      }
      const uc: ViewModeTableUserConfigurationColumn = new ViewModeTableUserConfigurationColumn();

      uc.Field = c.Field;
      uc.Visible = false;
      uc.SortDirection = ViewSortDirection.None;
      uc.Weight = 0;

      userColumns.push(uc);
    });

    const config: ViewConfiguration = this.listComponentConfiguration.getConfiguration();
    const viewModeConfig: IViewModeColumnBased = ViewModeUtils.GetCurrentViewModeColumnBasedFromService(this.listComponentConfiguration);

    this.viewConfigurationSelectorColumns = userColumns
        .map(userConfigurationColumn => {
          const field: IViewField = config.Fields[userConfigurationColumn.Field];
          const viewModeConfigurationColumn: IViewModeSortableField = viewModeConfig.Columns[userConfigurationColumn.Field];
          return new ViewConfigurationSelectorColumn(field, viewModeConfigurationColumn, userConfigurationColumn);
        });

    this.cdRef.detectChanges();
  }

  /**
   * Add all items to selected columns
   */
  addAllItems(): void {
    this.viewConfigurationSelectorColumns.filter(x => !x.Disabled).forEach(x => x.Visible = true);
    this.cdRef.detectChanges();
  }

  /**
   * Add all items to selected columns
   */
  removeAllItems(): void {
    this.viewConfigurationSelectorColumns.filter(x => !x.Disabled).forEach(x => x.Visible = false);
    this.cdRef.detectChanges();
  }

  /**
   * closes modal
   */
  closeHandler(): void {
    this.closeModal();
  }

  /**
   * Save data and close modal
   */
  saveAndCloseHandler(): void {
    (this.userConfig.CurrentViewMode as IViewModeUserConfigurationColumnsBased).Columns = this.viewConfigurationSelectorColumns
        .sort((a, b) => a.UserConfigurationField.Weight - b.UserConfigurationField.Weight)
        .map(x => x.UserConfigurationField);
    this.listComponentConfiguration.setUserConfiguration(this.userConfig);
    this.closeModal();
  }

  get noColumnsSelected(): boolean {
    return !this.viewConfigurationSelectorColumns.some(x => x.Visible && !x.Disabled);
  }

}

class ViewConfigurationSelectorColumn {

  private readonly _field: IViewField;

  /**
   * The column data from ViewConfiguration
   * @private
   */
  private readonly _configColumn: IViewModeSortableField;

  /**
   * The column data from ViewUserConfiguration
   * @private
   */
  private readonly _userConfigurationColumn: IViewModeUserConfigurationColumn;

  /**
   * Ctor
   * @param field
   * @param configColumn
   * @param userConfigurationColumn
   */
  public constructor(field: IViewField, configColumn: IViewModeSortableField, userConfigurationColumn: IViewModeUserConfigurationColumn) {
    this._field = field;
    this._configColumn = configColumn;
    this._userConfigurationColumn = userConfigurationColumn;
  }

  /**
   * The column name
   * @constructor
   */
  get Name(): string {
    if (backendTypeMatch(ViewModeTableColumn.$type, this._configColumn)) {
      const column: ViewModeTableColumn = this._configColumn as ViewModeTableColumn;
      return column.Header;
    } else if (this._configColumn.hasOwnProperty('Header')) {
      return this._configColumn['Header'];
    }

    throw new Error('Unable to get the column name')
  }

  /**
   * Sets the IViewModeUserConfigurationColumn.Visible property and use it with two-way binding
   * @constructor
   */
  get Visible(): boolean {
    return this.UserConfigurationField.Visible;
  }

  /**
   * Gets the IViewModeUserConfigurationColumn.Visible property and use it with two-way binding
   * @constructor
   */
  set Visible(value: boolean) {
    this.UserConfigurationField.Visible = value;
  }

  /**
   * Gets the IViewModeUserConfigurationColumn
   * @constructor
   */
  get UserConfigurationField(): IViewModeUserConfigurationColumn {
    return this._userConfigurationColumn;
  }

  /**
   * Gets if the column visibility can be changed
   * @constructor
   */
  get Disabled(): boolean {
    return backendTypeMatch(ViewFieldSingleItemOperations.$type, this._field) || backendTypeMatch(ViewsFieldVbo.$type, this._field);
  }
}
