import { ChangeDetectorRef, Component, HostBinding, OnInit, SkipSelf } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  FormElement,
  FormElementFieldSet,
  FormFieldsetType
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FieldConfig } from '../../interfaces/field-config.interface';
import { filter, takeUntil } from 'rxjs/operators';
import { ClientCache } from '../../../../core/clientcache/ClientCacheClass';
import { isNullOrUndefined } from '../../../utils/typescript.utils';

@Component({
  templateUrl: './fieldset.component.html',
  selector: 'app-fieldset'
})
export class FieldsetComponent extends FrontendFormElementWrapper implements OnInit {

  /**
   * Identify the FormGroup that will be propagated to the child elements
   */
  fieldsetGroup: FormGroup;

  /**
   * Row config used to build the components
   */
  rows: { [key: number]: FieldConfig[] } = [];

  /**
   * Fieldset Type
   */
  fieldsetType: FormFieldsetType;

  /**
   * FieldsetType Type used in the template
   */
  FormFieldsetType = FormFieldsetType;

  /**
   * If the FormFieldsetType = FormFieldsetType.Collapsible, the current collapsed status
   */
  isCollapsed: boolean;

  /**
   * Storage Key Cache to preserve the collapsed status
   */
  preserveCollapsedValueKey: string;

  /**
   *
   * @param {FormManagerService} formManagerService
   */
  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected cache: ClientCache,
              @SkipSelf()
              protected cdRefParent: ChangeDetectorRef) {
    super(formManagerService, cdRef, cdRefParent);
  }

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported for a fieldset component.');
  }

  /**
   * Toggles the `isCollapsed` state
   */
  collapseToogle(): void {
    if (this.isCollapsed) {
      this.isCollapsed = false;
    } else {
      this.isCollapsed = true;
    }

    if (!isNullOrUndefined(this.preserveCollapsedValueKey)) {
      this.cache.setItem(this.preserveCollapsedValueKey, this.isCollapsed, 'browser');
    }

    this.cdRefParent.detectChanges();
    this.cdRef.detectChanges();
  }

  /**
   * Set the state of `isCollapsed` to the provided argument.
   * @param isCollapsed
   */
  collapseSet(isCollapsed: boolean): void {
    this.isCollapsed = isCollapsed;

    if (!isNullOrUndefined(this.preserveCollapsedValueKey)) {
      this.cache.setItem(this.preserveCollapsedValueKey, this.isCollapsed, 'browser');
    }

    this.cdRefParent.detectChanges();
    this.cdRef.detectChanges();
  }

  /**
   * Initializes fieldset component
   */
  ngOnInit(): void {
    super.ngOnInit();
    const formElement: FormElement = this.formManagerService.getConfigFromSelector(this.config.ClientPath);
    this.fieldsetGroup = this.formManagerService.materializeControlGroup(formElement, null, this.rows);

    this.group.setControl(formElement.ClientId, this.fieldsetGroup, {emitEvent: false});

    /** QUERIDO PROGRAMADOR:
     * Si todos los componentes hijos estan deshabilitados, angular deshabilita
     * el padre. WTF.
     *
     * Reestablecer el estado seria ineficiente, puesto que (des)habilitar un
     * componente afecta a todos sus hijos.
     *
     * Por esa razon lo que se recomienda es crear un fieldset vacio con readonly: true
     * en el backend
     *
     * this.status = this._allControlsDisabled() ? DISABLED : VALID;
     */

    this.fieldsetType = (formElement as FormElementFieldSet).FieldsetType;
    this.preserveCollapsedValueKey = (formElement as FormElementFieldSet).PreserveCollapsedValueKey;

    const defaultIsCollapsed: boolean = (formElement as FormElementFieldSet).Collapsed;
    const isCollapsed: boolean = this.preserveCollapsedValueKey ? this.cache.getItem(this.preserveCollapsedValueKey, defaultIsCollapsed) : defaultIsCollapsed;
    this.isCollapsed = isCollapsed;
    // Si la configuración de un fieldset ha cambiado, esto puede
    // afectar a todos sus hijos, emitimos eventos para cada hijo
    this.formManagerService.elementConfigChanged
      .pipe(
        filter((clientPath: string) => clientPath === this.config.ClientPath),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        // Hay que avisar a todos los controles HIJO de que ha habido un cambio, porque podrían (o no)
        // haber sido afectados
        if (this.config && this.config.FormElement && this.config.FormElement.Children) {
          for (const element of Object.values(this.config.FormElement.Children)) {
            this.formManagerService.elementConfigChanged.emit(element.ClientPath);
          }
        }
      });
  }

  /**
   * Returns fieldset class.
   * @returns {(string | number)[]}
   */
  getComponentClasses(): string[] {

    const result: string[] = super.getComponentClasses();

    switch ((this.config.FormElement as FormElementFieldSet).FieldsetType) {
      case FormFieldsetType.Boxed:
        result.push('c-fieldset-boxed');
        break;
      case FormFieldsetType.Invisible:
        result.push('c-fieldset-invisible');
        break;
      case FormFieldsetType.Collapsible:
        result.push('c-fieldset-collapsible');
        if (this.isCollapsed) {
          result.push('c-fieldset-collapsed')
        }
        break;
    }

    return result;
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }
}
