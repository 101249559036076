import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RestTranslatorModule } from '../core/translator/rest-translator.module';
import { SFormModule } from '../shared/form/form.module';
import { PredefinedModule } from './predefined-corrective-action/predefined.module';
import { ConfigurationModule } from './configurations/configuration.module';
import { QualaMaintainerModule } from './quala-maintainer/quala-maintainer.module';

@NgModule({
  imports: [
    SFormModule,
    RestTranslatorModule,
    CommonModule,
    PredefinedModule,
    ConfigurationModule,
    QualaMaintainerModule
  ],
  providers: [],
  declarations: [],
  exports: []
})
export class ParametricsModule { }
