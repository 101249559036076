import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavVerticalCollapsableComponent } from '../collapsable/collapsable.component';

@Component({
  selector: 'fuse-nav-vertical-collapsable-item',
  templateUrl: './collapsable-item.component.html',
  styleUrls: ['./collapsable-item.component.scss'],
  animations: fuseAnimations
})
export class FuseNavVerticalCollapsableItemComponent extends FuseNavVerticalCollapsableComponent implements OnInit, OnDestroy {

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {Router} _router
   */
  constructor(
      protected _changeDetectorRef: ChangeDetectorRef,
      protected _fuseNavigationService: FuseNavigationService,
      protected _router: Router
  ) {
    super(_changeDetectorRef, _fuseNavigationService, _router);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    super.ngOnInit();

    // By default, if the current item url is in router url, this node must be opened
    if (this.currentRouterUrlIsInNode(false)) {
      this.isOpen = true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check if the current node has the URL obtained from the router
   * @param exact
   */
  currentRouterUrlIsInNode(exact: boolean): boolean {
    return this.isUrlInNode(this._router.url, exact);
  }

  /**
   * Check if the current node has the url
   * @param url
   * @param exact
   */
  isUrlInNode(url: string, exact: boolean): boolean {
    if (exact) {
      return url === this.item.url;
    }
    return url.includes(this.item.url);
  }

  /**
   * Manage how the component manage the NavigationEnd Event
   * @param event
   * @protected
   */
  protected override manageNavigationEndEvent(event: NavigationEnd) {
    // Check if the url can be found in
    // one of the children of this item

    if (this.isUrlInNode(event.urlAfterRedirects, false) || this.isUrlInChildren(this.item, event.urlAfterRedirects)) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  public getNavContainerClasses(): string[] {
    const value: string[] = ['nav-container'];
    if (this.currentRouterUrlIsInNode(true)) {
      value.push('active');
      value.push('accent');
    }
    return value;
  }
}
