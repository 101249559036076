import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { StyleConstants } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { MenuItemCompiledFrontend } from '../../core/navigation/models/MenuItemCompiledFrontend.class';
import { NavigationService } from '../../core/navigation/navigation.service';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { UtilsTypescript } from '../utils/typescript.utils';

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss']
})
export class NextButtonComponent extends DestroyableObjectTrait implements OnInit {

  // Navegación de destino
  nextDestionation: MenuItemCompiledFrontend;

  // Argumentos para el formulario
  formParams: any = {};

  // Parametro para poder personalizar el titulo del botón
  @Input() titleButton: string;

  constructor(protected navigationService: NavigationService) {
    super();

    this.formParams = {
      buttonText: 'Siguiente',
      buttonClass: StyleConstants.BTN_PRIMARY
    };
  }

  ngOnInit(): void {
    if (!UtilsTypescript.isNullOrUndefined(this.titleButton)) {
      this.formParams.buttonText = this.titleButton;
    }

    this.navigationService
      .lastResolvedNavigationRequest
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((i) => {
        // Si existe la posibilidad de tener navegación lateral, muestro el botón
        this.nextDestionation = this.navigationService.getHorizontalNavigationNode(i, true);
      });
  }

  onClick(): void {
    this.navigationService.navigateToMenuItem(this.nextDestionation);
  }
}
