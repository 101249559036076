import { NgModule } from '@angular/core';
import { ChartModeSelectorComponent } from './chart-mode-selector.component';
import { CommonModule } from '@angular/common';
import { FuseWidgetModule } from '../../../../../../../../@fuse/components';
import { FuseSharedModule } from '../../../../../../../../@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    ChartModeSelectorComponent
  ],
  imports: [
    CommonModule,
    FuseWidgetModule,
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
  ],
  exports: [
    ChartModeSelectorComponent
  ],
})
export class ChartModeSelectorModule {
}
