import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenericFormPageSimpleComponent } from '../shared/generic-pages/generic-form-page-simple/generic-form-page-simple.component';
import { GenericFormPageSimpleArguments } from '../shared/generic-pages/generic-form-page-simple/GenericFormPageSimpleArguments';
import { GenericListPageSimpleArguments } from '../shared/generic-pages/generic-list-page-simple/GenericListPageSimpleArguments';
import { GenericListPageSimpleComponent } from '../shared/generic-pages/generic-list-page-simple/generic-list-page-simple.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'boarding',
        component: GenericFormPageSimpleComponent,
        data: {componentConfig: new GenericFormPageSimpleArguments('estructuraorganizativa-boarding', null)},
      },
      { path: 'statistics',
      component: GenericFormPageSimpleComponent,
      data: {
        componentConfig:
          new GenericFormPageSimpleArguments(
            'sabentis-eo-statistics',
            {}),
          }
        },
      { path: 'organizational-unit',
        children: [
          {
            path: '',
            component: GenericListPageSimpleComponent,
            data: {
              componentConfig: new GenericListPageSimpleArguments('sabentis-organizationalunit-view', {
              })
            }
          },
          {
            path: 'add',
            component: GenericFormPageSimpleComponent,
            data: {
              componentConfig:
                  new GenericFormPageSimpleArguments(
                      'sabentis-organizationalunit-form', {})
            }
          },
          {
            path: ':organizational-unit',
            children:
                [
                  {path: '', redirectTo: 'details', pathMatch: 'full'},
                  {
                    path: 'details',
                    component: GenericFormPageSimpleComponent,
                    data: {
                      componentConfig:
                          new GenericFormPageSimpleArguments(
                              'sabentis-organizationalunit-form',
                              {'organizationalunitId': 'organizational-unit'})
                    }
                  },
                  {path: '', redirectTo: 'trabajadores', pathMatch: 'full'},
                  {
                    path: 'trabajadores',
                    component: GenericListPageSimpleComponent,
                    data: {
                      componentConfig:
                          new GenericListPageSimpleArguments(
                              'sabentis-organizationalunitemployee-view',
                              {'organizationalunitId': 'organizational-unit'},
                              true, 1)
                    }
                  }
                ]
          }
        ]
      },
      {
        path: 'business-group',
        data: {breadcrumb: 'structure.businessgroups'},
        loadChildren: () => import('./business-group/business-group.module').then(m => m.BusinessGroupModule),
      },
      {
        path: 'centers',
        data: {breadcrumb: 'structure.productiveunits'},
        loadChildren: () => import('./center/center.module').then(m => m.CentersModule)
      },
      {
        path: 'company',
        data: {breadcrumb: 'structure.company', mapping: 'structure.company'},
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
      },
      {
        path: 'job',
        data: {breadcrumb: 'structure.job'},
        loadChildren: () => import('./job/job.module').then(m => m.JobModule)
      },
      {
        path: 'occupation',
        data: {breadcrumb: 'structure.occupation'},
        loadChildren: () => import('./occupation/occupation.module').then(m => m.OccupationModule)
      },
      {
        path: 'process',
        data: {breadcrumb: 'structure.process'},
        loadChildren: () => import('./process/process.module').then(m => m.ProcessModule)
      },
      {
        path: 'profiles',
        data: {breadcrumb: 'structure.profiles'},
        loadChildren: () => import('./profile/profiles.module').then(m => m.ProfilesModule)
      },
      {
        path: 'ulf',
        data: {breadcrumb: 'structure.ulf'},
        loadChildren: () => import('./ulf/ulf.module').then(m => m.UlfModule)
      },
      {
        path: 'ulo',
        data: {breadcrumb: 'structure.ulo'},
        loadChildren: () => import('./ulo/ulo.module').then(m => m.UloModule)
      },
      {
        path: 'workers',
        data: {breadcrumb: 'structure.workers'},
        loadChildren: () => import('./workers/workers.module').then(m => m.WorkersModule)
      },
      {
        path: 'workplace',
        data: {breadcrumb: 'structure.workplace'},
        loadChildren: () => import('./workplace/workplace.module').then(m => m.WorkplaceModule)
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class OrganizationalStructureRoutingModule {
}
