import {
  ChartExecutionResponse,
  ChartUserConfiguration,
  CompiledChart, IChart
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

export class FuseChartUtils {

  /**
   * Return the Current Chart using ChartExecutionResponse
   * @param response
   * @constructor
   */
  public static GetCurrentChartFromChartExecutionResponse(response: ChartExecutionResponse): IChart {
    return this.GetCurrentChartFromUserConfiguration(response.CompiledChart, response.UserConfiguration)
  }

  /**
   * Return the Current Chart using CompiledChart and the ChartUserConfiguration
   * @param compiledChart
   * @param userConfiguration
   * @constructor
   */
  public static GetCurrentChartFromUserConfiguration(compiledChart: CompiledChart, userConfiguration: ChartUserConfiguration): IChart {
    return this.GetChart(compiledChart, userConfiguration.SelectedChart)
  }

  /**
   * Return the Current Chart using CompiledChart and the ChartUserConfiguration
   * @param compiledChart
   * @param id
   * @constructor
   */
  public static GetChart(compiledChart: CompiledChart, id: string): IChart {
    return compiledChart.AvailableCharts[id];
  }
}
