import { Component } from '@angular/core';

/**
 * This component show the form for config the help.
 */
@Component({
  selector: 'app-helpconfig',
  templateUrl: './helpconfig.component.html',
  styleUrls: ['./helpconfig.component.css']
})
export class HelpconfigComponent {
  formPlugin = 'form-helpConfig-param';
  formParams = {};
}
