import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BurgerColor } from './enums/burger-color.enum';
import { MenuService } from '../menu.service';
import { ChangedetectorReference } from '../../../../core/changedetector/changedetectoreference';

@Component({
  selector: 'app-menu-hamburger',
  templateUrl: './menu-hamburger.component.html',
  providers: [ChangedetectorReference]
})
export class MenuHamburgerComponent implements OnInit {

  open = true;

  @Input() burgerColor: BurgerColor = BurgerColor.Black;

  @Output() changed = new EventEmitter<boolean>();

  constructor(private elemRef: ElementRef,
              private menuService: MenuService,
              private cdRef: ChangedetectorReference) {
  }

  ngOnInit(): void {
    this.menuService.registerEmitter('menu-left', this.elemRef);
    this.menuService.toggleChanges().subscribe(
      (next) => {
        this.open = !next.closedState;
      }
    );
  }

  toggle(): void {
    this.open = !this.open;
    this.changed.emit(this.open);
  }

  getClass(): any[] {
    const classes: any = [];
    if (this.open) {
      classes.push('is-active');
    }
    return classes;
  }
}
