import { Component, ElementRef, OnInit } from '@angular/core';

import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { NGXSimpleSeriesChartComponent } from '../../ngx-simple-series-chart.class';
import {
  PieChart,
  PieChartDisplayOptions
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';


@Component({
  selector: 'app-basic-pie-chart',
  templateUrl: './basic-pie-chart.component.html',
  styleUrls: ['./basic-pie-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class BasicPieChartComponent extends NGXSimpleSeriesChartComponent<PieChart, PieChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  GetViewValueOnInitializeChart(): [number, number] {
    return this.GetDefaultViewValueOnInitializeChart(this.elementRef);
  }

  GetViewValueOnResize(): [number, number] {
    return this.GetDefaultViewValueOnResize(this.elementRef);
  }
}
