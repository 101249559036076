import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ViewModeCalendarComponent } from './components/view-mode-calendar.component';
import { ViewModeCalendarMonthComponent } from './components/view-mode-calendar-month/view-mode-calendar-month.component';
import { ViewModeCalendarWeekComponent } from './components/view-mode-calendar-week/view-mode-calendar-week.component';
import { ViewModeCalendarDayComponent } from './components/view-mode-calendar-day/view-mode-calendar-day.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,

    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ColorPickerModule,

    FuseSharedModule,
    FuseConfirmDialogModule
  ],
  exports: [RouterModule, ViewModeCalendarComponent],
  declarations: [ViewModeCalendarComponent, ViewModeCalendarMonthComponent, ViewModeCalendarWeekComponent, ViewModeCalendarDayComponent],
})
export class ViewModeCalendarModule {
}
