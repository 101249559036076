import { Component, forwardRef, Input, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { getInSafe, isNullOrUndefined } from '../../../../utils/typescript.utils';

@Component({
  selector: 'app-inner-bool',
  templateUrl: './input-bool.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputBoolComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputBoolComponent), multi: true }
  ]
})
export class InputBoolComponent extends FrontendFormElementInput {

  @Input('boolValue') _boolValue: string;
  @ViewChildren('boolElement') boolElement: QueryList<ElementRef>;

  /**
   * Getter for the _boolValue
   * @returns {any}
   */
  get boolValue(): string {
    return this._boolValue;
  }

  /**
   * Setter for the _boolValue. On the setter the value is propagated
   * to let angular Forms know about this change
   * @param val: is the setter value
   */
  set boolValue(val: string) {
    if (this.config.editable) {
      this._boolValue = val;
      this.propagateChange(val);
    } else {
      this._boolValue = this._boolValue;
    }
  }

  /**
   * This function is described on the ControlValueAccessor interfacte.
   * Is used by the Angular Form to set the value that comes from the server
   * @param value: value set by Angular Forms Manager
   */
  writeValue(value: any): void {
    this._boolValue = value;
  }

  /**
   * Set selected option
   * @param key
   */
  selectOption(key: any): void {
    if (this.boolValue === key) {
      return;
    }
    this.boolValue = key;
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    if (isNullOrUndefined(this.boolElement)) {
      return false;
    }

    const elementsRef: ElementRef[] = this.boolElement.toArray();
    const boolElementChosen: ElementRef = elementsRef.find(i => i.nativeElement.id === 'opt_' + this.boolValue);
    if (isNullOrUndefined(boolElementChosen)) {
      elementsRef[0].nativeElement.focus()
      return true;
    } else {
      boolElementChosen.nativeElement.focus();
      return true;
    }
  }
}
