import { NgModule } from '@angular/core';
import { NgxDatepickerChangedetectionFixerDirective } from './ngx-datepicker-changedetection-fixer.directive';
import { NgxSelectChangedetectionFixerDirective } from './ngx-select-changedetection-fixer.directive';
import { ScrollPropagateToWindowDirective } from './scroll-propagate-to-window.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDaterangepickerChangedetectionFixerDirective } from './ngx-daterangepicker-changedetection-fixer.directive';

@NgModule({
  imports: [
    BsDatepickerModule
  ],
  declarations: [
    NgxDatepickerChangedetectionFixerDirective,
    NgxDaterangepickerChangedetectionFixerDirective,
    NgxSelectChangedetectionFixerDirective,
    ScrollPropagateToWindowDirective
  ],
  exports: [
    NgxDatepickerChangedetectionFixerDirective,
    NgxDaterangepickerChangedetectionFixerDirective,
    NgxSelectChangedetectionFixerDirective,
    ScrollPropagateToWindowDirective
  ]
})

export class SabentisUtilsModule {
}
