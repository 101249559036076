import { ChangeDetectorRef, Component, OnInit, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommunicationService } from '../../../../core/communication/communication.service';
import {
  BatchTaskInfo,
  WebServiceResponseTyped
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from '../../../utils/typescript.utils';
import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';
import { TranslatorService } from '../../../../core/translator/services/rest-translator.service';
import { FileDownloaderService } from '../../../files/file-downloader.service';
import { BatchService } from '../../../../core/services/ETG_SABENTISpro_Application_Core_batch.service';
import { FileService } from '../../../../core/services/ETG_SABENTISpro_Application_Core_file.service';
import { take, takeUntil } from 'rxjs/operators';


/**
 * Embedded document form element.
 *
 * This element doesn't requires a control value accessor as only
 * performs a document display job.
 */
@Component({
  selector: 'app-form-element-batch-task',
  templateUrl: './form-element-batch-task.component.html'
})
export class FormElementBatchTaskComponent extends FrontendFormElementWrapper implements OnInit {

  /**
   * Queue task.
   */
  task: BatchTaskInfo;

  /**
   * Deben implementarlo todos los wrappers para acceder al Control Value Accessor.
   */
  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  /**
   * FormElementBatchTaskComponent class constructor.
   *
   * @param {FormManagerService} formManager
   * @param {CommunicationService} communicationService
   * @param {DomSanitizer} sanitizer
   * @param {ChangeDetectorRef} cdRef
   * @param {ChangeDetectorRef} cdRefParent
   * @param {FileService} fileService
   * @param {BatchService} batchService
   * @param translatorService
   * @param fileDownloaderService
   */
  constructor(
      protected formManager: FormManagerService,
      protected communicationService: CommunicationService,
      protected sanitizer: DomSanitizer,
      protected cdRef: ChangeDetectorRef,
      @SkipSelf()
      protected cdRefParent: ChangeDetectorRef,
      protected fileService: FileService,
      protected batchService: BatchService,
      protected translatorService: TranslatorService,
      protected fileDownloaderService: FileDownloaderService) {
    super(formManager, cdRef, cdRefParent);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!isNullOrUndefined(this.config.defaultValue)) {
      if (isNullOrUndefined(this.config.defaultValue.QueueId)) {
        this.task = Object.assign(new BatchTaskInfo(), this.config.defaultValue)
        this.cdRef.detectChanges();
        return;
      }
      this.batchService.getTask(this.config.defaultValue.QueueId)
          .pipe(takeUntil(this.componentDestroyed$), take(1))
          .subscribe((response: WebServiceResponseTyped<BatchTaskInfo>) => {
            this.task = response.result;
            this.cdRef.detectChanges();
          });
    }
  }
}


