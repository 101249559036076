import { Component, ElementRef, Input } from '@angular/core';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent extends DestroyableObjectTrait {
  private innerValue: string;
  private commentCreated = false;

  @Input()
  get value(): string {
    return this.innerValue;
  }

  set value(value: string) {
    this.innerValue = value;
    setTimeout(() => this.createComment());
  }

  constructor(private elementRef: ElementRef) {
    super();
  }

  private createComment(): void {
    const htmlElement: HTMLElement = this.elementRef.nativeElement as HTMLElement;
    if (this.commentCreated) {
      htmlElement.parentNode.removeChild(htmlElement.previousSibling);
    }
    htmlElement.parentNode.insertBefore(
      document.createComment(htmlElement.textContent), htmlElement);
    this.commentCreated = true;
  }
}
