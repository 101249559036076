<div [id]="uniqueComponentId('input-')" class="c-radio-checkbox">
  <input type="checkbox"
         [id]="uniqueElementId"
         [(ngModel)]="checked"
         [ngModelOptions]="{standalone: true}"
         (blur)="propagateTouch()"
         [disabled]="isDisabled"
         #checkElement/>
  <label [for]="uniqueElementId" class="o-checkradio__label t-check">
  </label>
</div>
