import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExposedFiltersComponent } from './exposed-filters.component';
import { ViewsSharedModule } from '../shared/views-shared.module';


@NgModule({
  declarations: [ExposedFiltersComponent],
  exports: [
    ExposedFiltersComponent
  ],
  imports: [
    CommonModule,
    ViewsSharedModule
  ]
})
export class ExposedFiltersModule {
}
