import { Injectable } from '@angular/core';
import { CommunicationService } from '../../core/communication/communication.service';
import {
  BatchTaskInfo,
  BatchTaskResultFileRef,
  BatchTaskResultUploadedFiles,
  BatchTaskResultWordAndPdfUploadedFile,
  IBatchTaskResult,
  UploadedFile,
  WebServiceResponseTyped
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { EMPTY, Observable, throwError } from 'rxjs';
import { backendTypeMatch } from '../utils/typescript.utils';
import { HttpHeaders } from '@angular/common/http';
import { catchError, filter, map } from 'rxjs/operators';
import { BatchService } from '../../core/services/ETG_SABENTISpro_Application_Core_batch.service';
import { FileService } from '../../core/services/ETG_SABENTISpro_Application_Core_file.service';

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {

  constructor(private communicationService: CommunicationService, private fileManager: FileService, private batchService: BatchService) {
  }

  /**
   * Seguridad verificado OK.
   *
   * @param fileRef
   * @constructor
   */
  DownloadFileFromFileRef(fileRef: string): void {
    const url: string = this.communicationService.generateFileUrl(fileRef);
    window.open(url, '_blank');
  }

  /**
   * Seguridad verificado OK.
   *
   * @param fileRef
   * @constructor
   */
  DownloadFileFromUploadedFile(fileRef: UploadedFile): void {
    const url: string = this.communicationService.generateFileUrl(fileRef.url);
    window.open(url, '_blank');
  }

  /**
   * Seguridad verificado OK.
   *
   * @param fileRef
   * @constructor
   */
  GetFileFromFileRef(fileRef: string): string {
    return this.communicationService.generateFileUrl(fileRef);
  }

  /**
   * Seguridad verificado KO, en backend se comprueban
   * los permisos específicos del contexto
   *
   * @param fileRef
   * @constructor
   *
   * @deprecated
   *   Si necesitas un fileRef para un contexto y un ID genéralo en backend
   *   y envíalo firmado a frontend. Aunque se comprueban permisos, no evita
   *   el tampering de identificadores cuando pedimos esto de frontend. Se podría
   *   corregir trabajando con ID's firmados.
   */
  GetFileFromContextAndId(context: string, id: string, skipError: boolean): Observable<string> {
    return this.fileManager.getFilefromcontextandid(context, id, {headers: new HttpHeaders({'meta-donothandleerrors': 'true'})})
        .pipe(
            filter((result: WebServiceResponseTyped<string>) => {
              // El endpoint puede devolver nulo si no hay ningún fichero, o hay más de uno
              return !!result.result;
            }),
            map((result: WebServiceResponseTyped<string>) => {
              return this.GetFileFromFileRef(result.result);
            }),
            catchError((err) => {
              if (skipError === true) {
                return EMPTY;
              } else {
                return throwError(() => err);
              }
            })
        );
  }

  /**
   * Seguridad verificado KO, en backend se comprueban
   * los permisos específicos del contexto.
   *
   * @param context
   * @param id
   * @constructor
   *
   * @deprecated
   *   Si necesitas un fileRef para un contexto y un ID genéralo en backend
   *   y envíalo firmado a frontend. Aunque se comprueban permisos, no evita
   *   el tampering de identificadores cuando pedimos esto de frontend. Se podría
   *   corregir trabajando con ID's firmados.
   */
  DownloadFileFromContextAndId(context: string, id: string): void {
    this.fileManager.getFilefromcontextandid(context, id)
        .subscribe((result: WebServiceResponseTyped<string>) => {
          this.DownloadFileFromFileRef(result.result);
        });
  }

  /**
   * Seguridad verificado OK, los fileRef vienen desde backend
   * firmados.
   *
   * @param context
   * @param id
   * @constructor
   */
  DownloadFileFromTask(task: BatchTaskInfo): void {
    return this.DownloadFileFromQueue(task.QueueId);
  }

  /**
   * Seguridad verificado OK, los fileRef vienen desde backend
   * firmados.
   *
   * @param context
   * @param id
   * @constructor
   */
  DownloadFileFromQueue(queueId: string): void {
    this.batchService.postTaskresult(queueId, null, null)
        .subscribe((result: WebServiceResponseTyped<IBatchTaskResult>) => {
          if (backendTypeMatch(BatchTaskResultFileRef.$type, result.result)) {
            const batchResult: BatchTaskResultFileRef = result.result as BatchTaskResultFileRef;
            this.DownloadFileFromFileRef(batchResult.FileRef);
          }
          if (backendTypeMatch(BatchTaskResultWordAndPdfUploadedFile.$type, result.result)) {
            const batchResult: BatchTaskResultWordAndPdfUploadedFile = result.result as BatchTaskResultWordAndPdfUploadedFile;
          }
          if (backendTypeMatch(BatchTaskResultUploadedFiles.$type, result.result)) {
            const batchResult: BatchTaskResultUploadedFiles = result.result as BatchTaskResultUploadedFiles;
            Object.values(batchResult.Files)
                .forEach(file => {
                  this.DownloadFileFromUploadedFile(file.File);
                });
          }
        });
  }
}
