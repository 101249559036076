import { ChangeDetectorRef, Component, ElementRef, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { ChangedetectorService } from '../../../../../core/changedetector/changedetector.service';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormElementDatePicker } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import {
  DateLimitGranularity,
  PrecisionToGranularity,
  UnixTimestampToUserDateFromOffset,
  UserDateToUnixTimestampFromOffset
} from '../../../../utils/date.utils';
import * as moment from 'moment';
import { Moment } from 'moment-timezone/moment-timezone';
import { take, takeUntil } from 'rxjs/operators';
import { NgxDatepickerChangedetectionFixerDirective } from '../../../../../sabentisutils/ngx-datepicker-changedetection-fixer.directive';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';


@Component({
  selector: 'app-form-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => DatepickerComponent), multi: true},
    ChangedetectorReference
  ]
})
export class DatepickerComponent extends FrontendFormElementInput implements OnDestroy, OnInit {

  @ViewChild('inputElement', {static: true})
  protected inputElement: ElementRef;

  @ViewChild(BsDatepickerDirective, {static: true})
  protected inputElementBsDatepicker: BsDatepickerDirective;

  @ViewChild(NgxDatepickerChangedetectionFixerDirective, {static: true})
  protected ngxDatepickerChangedetectionFixerDirective: NgxDatepickerChangedetectionFixerDirective;

  /**
   * Entrada para el campo de fecha
   */
  protected inputValueDate: Date;

  public initialized: boolean;

  /**
   * La configuración no puede ser inyectada, ya que afecta a todos el aplicativo...
   */
  public bsConfig: BsDatepickerConfig = new BsDatepickerConfig();

  get formElementDateTimePicker(): FormElementDatePicker {
    return this.config.FormElement as FormElementDatePicker;
  }

  /**
   * Al cambiar el input de fecha
   * @param value
   */
  set dateInput(value: Date) {

    // Al valor que nos entra por el componente, le quitamos las partes
    // que estén fuera de la precisión definida en el componente
    if (value) {
      value = DateLimitGranularity(value, this.formElementDateTimePicker.Precision);
    }

    const controlValue: Date = this.inputValueDate;
    this.inputValueDate = value;

    // Solo se propagan cambios en caso de que cambie la fecha, porque si no, se modifican los formularios y se ponen en estado "dirty"
    // por lo que da problemas si se quiere comprobar si hubo alguna modificación en ellos (Por ejemplo para los botones que se deshabilitan con los cambios).
    // Este problema viene porque se sobreescribe la hora con las 12:00, por lo que se toma como una modificación de formulario.
    if (!moment(this.inputValueDate).isSame(moment(controlValue)) && !(!this.inputValueDate && !controlValue)) {
      const valueToPropagate: number = UserDateToUnixTimestampFromOffset(this.inputValueDate, this.formElementDateTimePicker.TimeZoneInfo)
      this.propagateChange(valueToPropagate, false, false);
    }
  }

  /**
   * Obtener el valor del input de fecha
   */
  get dateInput(): Date {
    return this.inputValueDate;
  }

  /**
   * Get an instance of DatetimeComponent
   * @param formManagerService
   */
  constructor(protected formManager: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected cdReference: ChangedetectorReference,
              protected cdService: ChangedetectorService,
              protected translatorService: TranslatorService) {

    super(formManager, cdRef, translatorService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.generateBsConfig();
    this.ngxDatepickerChangedetectionFixerDirective.bsDatepicker = this.inputElementBsDatepicker;
  }

  handleInitialized(): void {
    this.initialized = true;
  }

  /**
   * Overriden to consider datepicker initialization
   */
  propagateTouch(): void {
    if (!this.initialized) {
      return;
    }
    super.propagateTouch();
  }


  /**
   * Generate BS config
   */
  protected generateBsConfig(): void {
    this.bsConfig.containerClass = 'theme-green';
    this.bsConfig.showWeekNumbers = false;
    this.bsConfig.dateInputFormat = 'DD/MM/YYYY';
    this.bsConfig.adaptivePosition = true;
    this.bsConfig.maxDate = UnixTimestampToUserDateFromOffset(this.formElementDateTimePicker.MaxDateEffective, this.formElementDateTimePicker.TimeZoneInfo);
    this.bsConfig.minDate = UnixTimestampToUserDateFromOffset(this.formElementDateTimePicker.MinDateEffective, this.formElementDateTimePicker.TimeZoneInfo);
  }

  /**
   * This function is described on the ControlValueAccessor interface.
   * Is used by the Angular Form to set the value that comes from the server
   * @param value: value set by Angular Forms Manager
   */
  writeValue(value: number): void {
    let date: Date = UnixTimestampToUserDateFromOffset(value, this.formElementDateTimePicker.TimeZoneInfo);
    // Esto de quitar la precisión lo hacemos para que fechas que se guardaron originalmente
    // con la hora del usuario... ya no lo tengan
    if (date) {
      date = DateLimitGranularity(date, this.formElementDateTimePicker.Precision);
    }
    this.inputValueDate = date;

    // Existe un BUG en el datepicker por el cual
    // si durante un mismo ciclo de selección de valor,
    // cambio el propio valor, el binding del bsValue
    // no actualiza el valor del input del DOM cuando
    // el nuevo valor es NULL (no pasa con valores de destino
    // que sí son fecha)
    if (this.inputValueDate == null && this.inputElement && this.inputElement.nativeElement) {
      this.inputElementBsDatepicker
        .onHidden
        .pipe(
          takeUntil(this.componentDestroyed$),
          takeUntil(this.inputElementBsDatepicker.onShown),
          take(1)
        )
        .subscribe(() => {
          this.inputElement.nativeElement.value = '';
        });
    }
  }

  /**
   * Validation method for the control.
   * @param {AbstractControl} c
   */
  doValidate(c: AbstractControl): ValidationErrors {
    const errors: ValidationErrors = super.doValidate(c);

    // Internamente siempre funcionamos con esto...
    const unixTimestampDate: number = c.value;

    if (unixTimestampDate && !isFinite(unixTimestampDate)) {
      errors['invalid-date'] = 'La fecha introducida no es válida.';
      return;
    }

    // Verificar fechas máximas y mínimas
    if (this.formElementDateTimePicker.MaxDateEffective && unixTimestampDate > this.formElementDateTimePicker.MaxDateEffective) {
      errors['max-date'] = 'La fecha introducida debe ser inferior a ' + moment(this.bsConfig.maxDate).format('DD/MM/YYYY');
    }

    if (this.formElementDateTimePicker.MinDateEffective && unixTimestampDate < this.formElementDateTimePicker.MinDateEffective) {
      errors['min-date'] = 'La fecha introducida debe ser superior a ' + moment(this.bsConfig.minDate).format('DD/MM/YYYY');
    }

    return errors;
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    if (this.inputElement && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.focus();
      return true;
    }
    return false;
  }

  /**
   *
   * @param valueA
   * @param valueB
   */
  equalValues(valueA: any, valueB: any): boolean {
    const granularity: moment.unitOfTime.StartOf = PrecisionToGranularity(this.formElementDateTimePicker.Precision);
    return moment(valueA, 'X').isSame(moment(valueB, 'X'), granularity);
  }

  /**
   * @param valueA
   * @param valueB
   */
  compareValues(valueA: any, valueB: any): number {
    const granularity: moment.unitOfTime.StartOf = PrecisionToGranularity(this.formElementDateTimePicker.Precision);
    const momentA: Moment = moment(valueA, 'X');
    const momentB: Moment = moment(valueB, 'X');
    if (momentA.isSame(momentB, granularity)) {
      return 0;
    }
    if (momentA.isAfter(momentB, granularity)) {
      return 1;
    }
    return -1;
  }
}
