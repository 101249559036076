import { Input, Output, Directive } from '@angular/core';
import { Subject } from 'rxjs';

import { DtoFrontendModal } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { getInSafe } from '../../utils/typescript.utils';
import { DecoupledModalComponentInterface } from './decoupled-modal-component.interface';
import { BaseModalParamsInterface } from './modal-params.interface';

/**
 * Abstract class to implement custom modal components.
 */
@Directive()
export abstract class AbstractDecoupledModalComponent
  extends DestroyableObjectTrait
  implements DecoupledModalComponentInterface {

  /**
   * @inheritdoc
   */
  @Input()
  data: BaseModalParamsInterface;

  /**
   * @inheritdoc
   */
  @Output()
  close: Subject<unknown> = new Subject<unknown>();

  /**
   * AbstractDecoupledModalComponent class constructor.
   */
  constructor() {
    super();
  }

  /**
   * Return the modal configuration.
   */
  get config(): DtoFrontendModal {
    return getInSafe(this.data, data => data.config, {});
  }

  /**
   * @inheritdoc
   */
  closeModal(data?: unknown): void {
    if (!this.close) {
      // ¿Puede que el modal ya estuviera cerrado? Esto salta en los tests e2e y requiere ser revisado...
      console.debug('Close called on al already closed modal!');
      return;
    }
    this.close.next(data);
    this.close.complete();
    this.close = null;
  }
}
