import { Component, HostBinding, OnInit } from '@angular/core';
import { FrontendFormElementAware } from '../formelement.class';
import { UtilsTypescript } from '../../../utils/typescript.utils';

@Component({
  selector: 'app-formelementinputlabel',
  templateUrl: './formelementinputlabel.component.html'
})
/**
 * Este componente centraliza el pintado de las etiquetas de todos los
 * elementos de formulario
 */
export class FormelementinputlabelComponent extends FrontendFormElementAware implements OnInit {

  @HostBinding('class')
  get hostWrapperClasses(): string {
    // Si no hay etiqueta, mejor ocultar completamente el componente
    if (UtilsTypescript.isNullOrWhitespace(this.config.label)) {
      return 'hidden';
    }
    return '';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   *
   */
  public isRequiredEffective(): boolean {
    // El hecho de que sea o no requerido de manera efectiva depende
    // de si estoy visible y habilitado también
    return this.config.required && this.getIsVisibleAndEditable();
  }
}
