<span
    *ngIf="config.maxlength > 0 && config.showRemainingCharCounter"
    class="o-input__field-seek"
    [ngClass]="lengthValidationClasses()"
>
  {{ getRemainingChars() }}
</span>

<ng-container *ngIf="!handleMaxLengthInput()">
  <input
      [id]="this.uniqueComponentId('input-')"
      [type]="getInputType()"
      [(ngModel)]="inputValue"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="isDisabled"
      [class.--disabled]="isDisabled"
      (keydown)="onKeydown($event)"
      (blur)="blur()"
      [autocomplete]="'off-' + this.uniqueElementId"
      [placeholder]="config?.placeholder"
      #inputElement
  />
</ng-container>

<ng-container *ngIf="handleMaxLengthInput()">
  <input
      [id]="this.uniqueComponentId('input-')"
      [type]="getInputType()"
      [(ngModel)]="inputValue"
      [ngModelOptions]="{ standalone: true }"
      [disabled]="isDisabled"
      [class.--disabled]="isDisabled"
      (keydown)="onKeydown($event)"
      (blur)="blur()"
      [autocomplete]="'off-' + this.uniqueElementId"
      [placeholder]="config?.placeholder"
      [maxLength]="config.maxlength"
      #inputElement
  />
</ng-container>
