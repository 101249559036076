import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

@Injectable()
export class MenuService {
  // private properties
  private toggleSubject: Subject<{menuName: string, closedState: boolean}> = new Subject<{menuName: string, closedState: boolean}>();
  private menuEmitters = {};

  /**
   * toggle menu status
   * @param {{string, boolean}} o
   */
  toggleMenu(o: {menuName: string, closedState: boolean}): void {
    this.toggleSubject.next(o);
  }

  /**
   * method to subscribe to toggle changes
   * @returns {Observable<{string, boolean}>}
   * @constructor
   */
  toggleChanges():  Observable<{menuName: string, closedState: boolean}> {
    return this.toggleSubject.asObservable();
  }

  /**
   * register emitters that can emit a menu change and registers it on a menuEmitters dictionary. That is used in case
   * that we detect changes to close menus, to know if the click is on any element that is not an emitter
   * @param {string} menuName
   * @param {ElementRef} emitter
   */
  registerEmitter(menuName: string, emitter: ElementRef): void {
    if (isNullOrUndefined(this.menuEmitters[menuName])) {
      this.menuEmitters[menuName] = [];
    }
    this.menuEmitters[menuName].push(emitter);
  }

  /**
   * this method returns true if any of the native elements registered for a menu contains the html element passed
   * on the parameters
   * @param el
   * @param {string} menuName
   * @returns {boolean}
   */
  isElementRegistered(el: any, menuName: string): boolean {
    let result: boolean = false;
    this.menuEmitters[menuName].forEach(
      (emitter) => {
        if (emitter.nativeElement.contains(el)) {
          result =  true;
        }
      }
    );
    return result;
  }
}
