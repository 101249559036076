import { EventEmitter, Injectable, Optional, SkipSelf } from '@angular/core';
import { filter, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { asIterableObject, backendTypeMatch, isNullOrUndefined } from '../../shared/utils/typescript.utils';
import { IResultCollector } from '../commands/resultcollector.interface';
import { defer, Observable, of } from 'rxjs';
import { CommandService } from '../commands/command.service';
import { CallbackCommandResultEvent } from './callback-command-result-event.interface';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';
import {
  CoreBatchSeeProgressCommand,
  CoreCommandCallbackTyped,
  CoreExecuteTemplateCommand,
  WebServiceResponse
} from '../models/ETG_SABENTISpro_Application_Core_models';
import { CoreCallbackService } from '../services/ETG_SABENTISpro_Application_Core_corecallback.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root'
})
export class CallbackService extends DestroyableObjectTrait {

  public readonly commandExecutedResult: EventEmitter<CallbackCommandResultEvent> = new EventEmitter<CallbackCommandResultEvent>();

  /**
   * Callback Manager.
   * Manage all the backend callbacks, now using commands.
   * @param corecallbackmanager
   * @param commandService
   * @param parentModule
   */
  constructor(
      private corecallbackmanager: CoreCallbackService,
      private commandService: CommandService,
      @Optional() @SkipSelf() parentModule?: CallbackService) {

    super();
    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
          'CommandService is already loaded. Import it in the AppModule only');
    }
    this.registerCommands();
  }

  /**
   * Register the available commands
   * @private
   */
  private registerCommands(): void {
    this.registerCoreCommandCallback();
  }

  /**
   * Register the
   * @private
   */
  private registerCoreCommandCallback(): void {
    // CoreCommandCallback
    this.commandService.CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreCommandCallbackTyped.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreCommandCallbackTyped, (() => Promise<boolean>) | Observable<boolean>>),
        )
        .subscribe((next) => {
          next.AddResult(
              defer(() => this.corecallbackmanager.postExecutecallbacktyped(next.Argument.Callback)
                  .pipe(
                      map((wsr: WebServiceResponse) => {

                        const result: CallbackCommandResultEvent = {
                          command: next.Argument,
                          result: wsr.result,
                          success: isNullOrUndefined(wsr.error)
                        } as CallbackCommandResultEvent;

                        this.commandExecutedResult.emit(result);

                        // Aun en el caso de que la ejecución del callback haya producido error,
                        // permitimos continuar la ejecución de la cadena de callbacks
                        // Por defecto estos comandos tienen StopPropagationOnError = true,
                        // no obstante se puede definir manualmente que esto no ocurra.
                        if (!next.Argument.StopPropagationOnError) {
                          return true;
                        }
                        return isNullOrUndefined(wsr.error)
                      }))));
        });

    this.commandService.CommandObservable
        .pipe(
            filter((obj: any) => backendTypeMatch(CoreExecuteTemplateCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreExecuteTemplateCommand, (() => Promise<boolean>) | Observable<boolean>>),
        )
        .subscribe((next) => {
          next.AddResult(
              of(true)
                  .pipe(
                      tap(() => {
                        asIterableObject(next.Argument.ExecuteCommands).forEach(executeCommand => {
                          if (backendTypeMatch(CoreCommandCallbackTyped.$type, executeCommand)) {
                            this.commandExecutedResult.pipe(
                                takeWhile(value => value.command.Callback.Signature === (executeCommand as CoreCommandCallbackTyped).Callback.Signature),
                                take(1))
                                .subscribe(s => {
                                  if (next.Argument.SeeProgress) {
                                    const progressCommand: CoreBatchSeeProgressCommand = new CoreBatchSeeProgressCommand();
                                    progressCommand.Job = s.result;
                                    if (next.Argument.OnFinishCommands) {
                                      progressCommand.OnCloseCommands = next.Argument.OnFinishCommands;
                                    }
                                    return this.commandService.executeCommandChain([progressCommand]);
                                  }
                                  if (next.Argument.OnFinishCommands) {
                                    return this.commandService.executeCommandChain(Object.values(next.Argument.OnFinishCommands));
                                  }
                                  return true;
                                });
                          }
                        });
                      }),
                      switchMap(() => {
                        return fromPromise(this.commandService.executeCommandChain(Object.values(next.Argument.ExecuteCommands)));
                      })
                  )
          )
        });
  }
}
