import { Type } from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { GroupedVerticalBarChartComponent } from './bar-chart/grouped-vertical-bar-chart/grouped-vertical-bar-chart.component';
import { BasicLineChartComponent } from './line-chart/components/basic-line-chart.component';
import { BasicMapComponent } from './map-chart/components/basic-map.component';
import { BasicPieChartComponent } from './pie-chart/basic-pie-chart/basic-pie-chart.component';
import { BasicRadarChartComponent } from './radar-chart/components/basic-radar-chart.component';
import { SingleNumberChartComponent } from './single-number-chart/components/single-number-chart.component';
import { ProgressBarChartComponent } from './progress-bar-chart/components/progress-bar-chart.component';
import { IFuseChartTypeInterface } from './i-fuse-chart-type.interface';
import { GroupedHorizontalBarChartComponent } from './bar-chart/grouped-horizontal-bar-chart/grouped-horizontal-bar-chart.component';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { SimpleVerticalBarChartComponent } from './bar-chart/simple-vertical-bar-chart/simple-vertical-bar-chart.component';
import { SimpleHorizontalBarChartComponent } from './bar-chart/simple-horizontal-bar-chart/simple-horizontal-bar-chart.component';
import { CustomDispersionChartComponent } from './custom-dispersion-chart/components-custom/custom-dispersion-chart.component';
import { BasicBubbleChartComponent } from './bubble-chart/components/basic-bubble-chart.component';
import {
  AreaChart,
  BubbleChart,
  Chart,
  CustomDispersionChart,
  GroupedHorizontalBarChart,
  GroupedVerticalBarChart,
  InversalLineChart,
  LineChart,
  MapLocationsChart,
  PieChart,
  ProgressBarChart,
  RadarChart,
  SimpleHorizontalBarChart,
  SimpleVerticalBarChart,
  SingleNumberChart
} from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { InversalLineChartComponent } from './inversal-line-chart/components/inversal-line-chart.component';

function fuseChartsComponentsConstants(): { [type: string]: Type<IFuseChartTypeInterface> } {
  const charts: { [type: string]: Type<IFuseChartTypeInterface> } = {};

  charts[GroupedVerticalBarChart.$type] = GroupedVerticalBarChartComponent;
  charts[GroupedHorizontalBarChart.$type] = GroupedHorizontalBarChartComponent;
  charts[SimpleVerticalBarChart.$type] = SimpleVerticalBarChartComponent;
  charts[SimpleHorizontalBarChart.$type] = SimpleHorizontalBarChartComponent;
  charts[AreaChart.$type] = AreaChartComponent;
  charts[PieChart.$type] = BasicPieChartComponent;
  charts[SingleNumberChart.$type] = SingleNumberChartComponent;
  charts[MapLocationsChart.$type] = BasicMapComponent;
  charts[LineChart.$type] = BasicLineChartComponent;
  charts[BubbleChart.$type] = BasicBubbleChartComponent;
  charts[RadarChart.$type] = BasicRadarChartComponent;
  charts[CustomDispersionChart.$type] = CustomDispersionChartComponent;
  charts[ProgressBarChart.$type] = ProgressBarChartComponent;
  charts[InversalLineChart.$type] = InversalLineChartComponent;

  return charts;
}

function getChartName(chart: Chart): string {
  const chartType: string = chart.$type;

  if (isNullOrUndefined(chartType)) {
    return null;
  }

  switch (chartType) {

    // TODO: Add specific chart type

    default:
      const nameParts: string[] = chartType.split('.');
      return nameParts[nameParts.length - 1];
  }
}

export { fuseChartsComponentsConstants, getChartName };

