import { Injectable, Optional, SkipSelf } from '@angular/core';
import * as moment from 'moment-timezone';

import { DateUtils } from '../../shared/utils/date.utils';
import { AppConfigurationService } from '../../app.configuration.service';
import { take } from 'rxjs/operators';
import { BootstrapService } from '../../app-bootstrap.service';

/**
 * Service to manage dates and time taking into consideration system's timezone
 */
@Injectable(
  {providedIn: 'root'}
)
export class DateTimeService {

  /**
   * timezone took from server
   */
  systemTimeZone;

  DateFormat: string;
  TimeFormat: string
  DateAndTimeFormat: string

  /**
   * Creates a new instance of DateTimeService
   * @param {DateService} dateService. Service used to backend communication
   */
  constructor(private configurationManager: AppConfigurationService,
              private bootstrapService: BootstrapService,
              @Optional() @SkipSelf() parentModule?: DateTimeService) {
    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
        'DateTimeService is already loaded. Import it in the AppModule only');
    }

    this.configurationManager.addBootstrapKey('system-timezone');

    this.bootstrapService.bootDataReady$()
      .pipe(take(1))
      .subscribe((responseData: any) => {
        this.configurationManager.removeBootstrapKey('system-timezone');
        this.setTimezone(responseData.result['system-timezone'] as { TimeZoneId: any, DateFormat: string, TimeFormat: string, DateAndTimeFormat: string });
        this.configurationManager.triggerBootstrapDone(true);
      });
  }

  /**
   * returns client's local utc's offset
   * @returns {number}
   */
  getLocalOffset(): number {
    const result: number = (new Date()).getTimezoneOffset() * -1;
    return result;
  }

  /**
   * returns system utc's offset
   * @returns {number}
   */
  getSystemOffset(): number {
    return moment.tz(this.systemTimeZone).utcOffset();
  }

  setTimezone(info: { TimeZoneId: any, DateFormat: string, TimeFormat: string, DateAndTimeFormat: string }): void {
    this.systemTimeZone = info;
    this.DateFormat = info.DateFormat;
    this.DateAndTimeFormat = info.DateAndTimeFormat;
    this.TimeFormat = info.TimeFormat
  }

  /**
   * returns the number of seconds to add to server's received dates
   * @returns {number}
   */
  toAdd2server(): number {
    return this.toAdd2user() * -1;
  }

  /**
   * returns the number of seconds to add to user date inputs
   * @returns {number}
   */
  toAdd2user(): number {
    return (this.getLocalOffset()) * 60;
  }

  public ParseDate(value: any): Date {
    // Is a UTC Date
    if (value instanceof Date) {
      return value;
    } else if (!isNaN(value)) {
      return DateUtils.ParseFromUnixTimeStamp(Number(value));
    } else if (DateUtils.DateTimeRegex.test(value)) {
      return DateUtils.ConvertToFrontendDate('YYYY-MM-DD HH:mm:ss', value);
    } else if (DateUtils.DateRegex.test(value)) {
      return DateUtils.ConvertToFrontendDate('DD/MM/YYYY HH:mm:ss', value);
    }
    return null;
  }
}
