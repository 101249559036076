<input
    #dateInput
    bsDatepicker
    appNgxDatepickerChangedetectionFixerDirective
    (initialized)="handleInitialized()"
    [bsConfig]="bsConfig"
    [(bsValue)]="controlValue"
    [id]="uniqueComponentId('input-')"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [isDisabled]="config.editable===false"
    [disabled]="config.editable===false"
    (blur)="propagateTouch()"
    (onShown)="propagateTouch()"
    [placeholder]="bsConfig.dateInputFormat"
    [autocomplete]="'off-' + this.uniqueElementId">
