import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewConfigurationSelectorComponent } from './components/view-configuration-selector.component';
import { ViewConfigurationModalComponent } from './components/view-configuration-modal.component';
import { ViewLocalActionsComponent } from './components/view-localactions.component';
import { ViewSearcherComponent } from './components/view-searcher.component';
import { ViewFilterTabsComponent } from './components/view-filter-tabs.component';
import { ViewFiltersModule } from './filters/view-filters.module';
import { ViewFilterContainerComponent } from './filters/components/view-filter-container.component';
import { ViewFilterModalComponent } from './filters/components/view-filter-modal.component';
import { ViewFilterTriggerComponent } from './filters/components/view-filter-trigger.component';
import { UtilsModule } from '../../../utils/utils.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { RestTranslatorModule } from '../../../../core/translator/rest-translator.module';
import { ViewSearcherNewComponent } from './components/view-searcher-new/view-searcher-new.component';
import { ViewConfigurationComponent } from './components/view-configuration.component';
import { ViewOperationsModule } from '../../operations/view-operations.module';


@NgModule({
    declarations: [
        ViewConfigurationSelectorComponent,
        ViewConfigurationModalComponent,
        ViewLocalActionsComponent,
        ViewSearcherComponent,
        ViewSearcherNewComponent,
        ViewFilterTabsComponent,
        ViewSearcherNewComponent,
        ViewConfigurationComponent
    ],
    exports: [
        ViewConfigurationSelectorComponent,
        ViewConfigurationModalComponent,
        ViewLocalActionsComponent,
        ViewSearcherComponent,
        ViewSearcherNewComponent,
        ViewFilterContainerComponent,
        ViewFilterModalComponent,
        ViewFilterTriggerComponent,
        ViewFilterTabsComponent,
        ViewConfigurationComponent
    ],
    imports: [
        CommonModule,
        ViewFiltersModule,
        UtilsModule,
        TooltipModule.forRoot(),
        FormsModule,
        RestTranslatorModule,
        ViewOperationsModule,
    ]
})
export class ViewHeadersSharedModule {
}
