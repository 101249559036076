import { ComponentFactoryResolver, Injectable, Type, ComponentFactory, Optional, SkipSelf, isDevMode } from '@angular/core';
import { DecoupledModalBridgeService } from '../shared/decoupled-modal/decoupled-modal-bridge.service';

@Injectable()
export class CoalescingComponentFactoryResolver extends ComponentFactoryResolver {
  /**
   * Función resolvedora original del servicio `ComponentFactoryResolver`.
   */
  private rootResolve: (component: Type<any>) => ComponentFactory<any>;

  /**
   * Booleano para referencia de consumo.
   *
   * Este booleano indica si actualmente el servicio esta "resolviendo" un
   * componente. Util para evitar recursión.
   */
  private inCall = false;

  /**
   * Mapa de funciones resolvedoras de componentes.
   */
  private readonly resolvers = new Map<ComponentFactoryResolver, (component: Type<any>) => ComponentFactory<any>>();

  /**
   * Constructor de clase CoalescingComponentFactoryResolver.
   * @param {ComponentFactoryResolver} rootResolver Resolvedor raiz del proyecto.
   * @param parentModule Proteccion para multiplicidad del servicio.
   */
  constructor(
    private readonly rootResolver: ComponentFactoryResolver,
    @Optional() @SkipSelf() parentModule?: DecoupledModalBridgeService
  ) {
    super();

    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
        'DecoupledModalBridgeService is already loaded. Import it in the AppModule only');
    }

    if (isDevMode()) {
      console.log('👨🏽‍🔧 Inicializando CoalescingComponentFactoryResolver');
    }
  }

  /**
   * Este metodo debe ser llamado desde la razi del proyecto, y realiza un reemplazo
   * de la función `resolveComponentFactory` en el servicio ComponentFactoryResolver
   * por la propia.
   *
   * De igual manera, guardamos una referencia a la funcion ComponentFactoryResolver
   * para consumirla internamente.
   */
  register(): void {
    this.rootResolve = this.rootResolver.resolveComponentFactory;
    this.rootResolver.resolveComponentFactory = this.resolveComponentFactory;
  }

  /**
   * Este servicio se llama desde cada uno de los módulos lazy-loaded.
   *
   * Cuando se consume anexa cada uno de los ComponentFactoryResolver locales
   * de los módulos al mapa de fabricas de componentes.
   *
   * @param {ComponentFactoryResolver} resolver
   */
  public registerResolver(resolver: ComponentFactoryResolver): void {
    const factory: <T>(component: Type<T>) => ComponentFactory<T> = resolver.resolveComponentFactory;
    this.resolvers.set(resolver, factory);
  }

  /**
   * Metodo de fabrica de componentes. Similar a
   * ComponentFactoryResolver.resolveComponentFactory, este metodo debe de
   * consumirse para evitar el problema de resolución de componentes.
   */
  public resolveComponentFactory = <T>(component: Type<T>): ComponentFactory<T> => {
    // Fix para evitar problemas de recursion.
    if (this.inCall) {
      return null;
    }

    this.inCall = true;

    try {
      const result: ComponentFactory<T> = this.resolveInternal(component);

      return result;
    } finally {
      this.inCall = false;
    }
  };

  /**
   * Resolvedor interno.
   */
  private resolveInternal = <T>(component: Type<T>): ComponentFactory<T> => {
    // Iteramos por los resolver locales.
    for (const [resolver, fn] of Array.from(this.resolvers.entries())) {
      try {
        const factory: any = fn.call(resolver, component);
        if (factory) {
          return factory;
        }
      } catch { }
    }

    // Si ningún resolvedor local ha dado solución para un componente
    // mandamos al resolvedor raíz.
    return this.rootResolve.call(this.rootResolver, component);
  };
}
