<div [id]="uniqueComponentId('input-')" fxLayout="row">
  <input
      bsDatepicker
      appNgxDatepickerChangedetectionFixerDirective
      [bsConfig]="bsConfig"
      [(bsValue)]="dateInput"
      (initialized)="handleInitialized()"
      (blur)="propagateTouch()"
      [isDisabled]="config.editable===false"
      [disabled]="config.editable===false"
      [placeholder]="bsConfig.dateInputFormat"
      (onShown)="propagateTouch()"
      class="t-date-input"
      [autocomplete]="'off-' + this.uniqueElementId">
  <timepicker [(ngModel)]="timeInput"
              [ngModelOptions]="{standalone: true}"
              (click)="detectChanges()"
              (blur)="propagateTouch()"
              (show)="detectChanges()"
              [id]="getId()"
              class="t-time-input"
              [disabled]="config.editable===false"
              [showSpinners]="config.editable===true"
              [showMeridian]="!is24HourClock()"
              [minuteStep]=1
              #timepicker>
  </timepicker>
</div>
