/**
 * Sirve para identificar informacion sobre el elemento que lanza un trigger
 * de la API de estados
 */
export class stateApiTriggerSourceData {

  /**
   * The control's path
   */
  path: string;

  /**
   * The previous value of the control
   */
  prevValue: any;

  /**
   * The new value of the control
   */
  newValue: any;
}
