// https://github.com/ReactiveX/rxjs/issues/2823

import { defer, MonoTypeOperatorFunction, never, Observable, of, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export enum DisposeReason {
  Unsubscribe = 'unsubscribe',
  Complete = 'complete',
  Error = 'error',
}

type CallbackFunc = (reason: DisposeReason) => void;

// eslint-disable-next-line 
export const finalizeWithReason = <T>(callback: CallbackFunc): MonoTypeOperatorFunction<T> =>
  (source: Observable<T>) =>
    defer(() => {
      // eslint-disable-next-line 
      let completed = false;
      // eslint-disable-next-line 
      let errored = false;

      return source.pipe(
        tap({
          error: () => errored = true,
          complete: () => completed = true,
        }),
        finalize(() => {
          if (errored) {
            callback(DisposeReason.Error);
          } else if (completed) {
            callback(DisposeReason.Complete);
          } else {
            callback(DisposeReason.Unsubscribe);
          }
        }),
      );
    });

// eslint-disable-next-line 
const finalizeCallback = (reason: DisposeReason) => console.log(reason);
// eslint-disable-next-line
const observer = {
  next: () => {
  },
  error: () => {
  },
  complete: () => {
  },
};

throwError(new Error()).pipe(
  finalizeWithReason(finalizeCallback),
).subscribe(observer);

of().pipe(
  finalizeWithReason(finalizeCallback),
).subscribe(observer);

never().pipe(
  finalizeWithReason(finalizeCallback),
).subscribe(observer).unsubscribe();

const s = of().pipe(
  finalizeWithReason(finalizeCallback),
)
