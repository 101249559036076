import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BatchTaskInfo,
  CoreFormSuccessEventCommand,
  FormState,
  WebServiceResponseTyped
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AbstractDecoupledModalComponent } from '../../decoupled-modal/models/abstract-decoupled-modal.component';
import { asIterableObject, backendTypeMatch } from '../../utils/typescript.utils';
import { BatchService } from '../../../core/services/ETG_SABENTISpro_Application_Core_batch.service';
import { CORE_QUEUE } from '../../../core/models/ETG_SABENTISpro_Models_models';


@Component({
  selector: 'app-mapping-report-generation',
  templateUrl: './mapping-report-generation.component.html',
  styleUrls: ['./mapping-report-generation.component.css']
})
export class MappingReportGenerationComponent
  extends AbstractDecoupledModalComponent implements OnInit {

  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  /**
   * option
   * @type {Number}
   */
  option: Number = 0;
  task: BatchTaskInfo = new BatchTaskInfo();
  @Input() params = {};
  @Input() formPlugin: String = '';
  mappingDefinitionId: String = '';

  constructor(private batchService: BatchService) {
    super();
  }

  ngOnInit(): void {
    this.option = 0;
    this.task = new BatchTaskInfo();
    this.task.Job = new CORE_QUEUE();
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    this.closeModal();
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  loaded(e: any): void {
    this.saveSucceed(e);
  }

  /**
   * Handler for form succeed event.
   * @param e
   */
  saveSucceed(e: any): void {
    let resultId: string = null;
    if ((e.formState as FormState).SubmitResult.Actions) {
      resultId = (asIterableObject((e.formState as FormState).SubmitResult.Actions).find(x => backendTypeMatch(CoreFormSuccessEventCommand.$type, x)) as CoreFormSuccessEventCommand).Result.id;
    } else if (e.formState.SubmitResult.Result) {
      resultId = e.formState.SubmitResult.Result.id
    }
    if (resultId) {
      this.batchService.getTask(resultId).subscribe((response: WebServiceResponseTyped<BatchTaskInfo>) => {
        this.task = response.result;
        this.option = 1;
      });
    }
  }
}
