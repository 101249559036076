import { Component, ElementRef, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { NGXTrendSeriesChartComponent } from '../../ngx-trend-series-chart.class';
import {
  LineChart,
  LineChartDisplayOptions
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-basic-line-chart',
  templateUrl: './basic-line-chart.component.html',
  styleUrls: ['./basic-line-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class BasicLineChartComponent extends NGXTrendSeriesChartComponent<LineChart, LineChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  GetViewValueOnInitializeChart(): [number, number] {
    return this.GetDefaultViewValueOnInitializeChart(this.elementRef);
  }

  GetViewValueOnResize(): [number, number] {
    return this.GetDefaultViewValueOnResize(this.elementRef);
  }
}
