import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommunicationService } from '../../core/communication/communication.service';
import { FileWrapper, UploaderOptions } from './models/file-wrapper';
import { IClientOptions } from '../../core/communication/interfaces/client-options.intefrace';


/**
 * This services works as an intermediate layer between the
 * file wrapper and the communication service. This way
 * we can change the behaviour of the file uploading request and
 * other params.
 */
@Injectable()
export class FileUploaderService {

  /**
   * Constructor
   * @param {CommunicationService} comService
   */
  constructor(private comService: CommunicationService) {
  }

  /**
   * Returns default upload chunking options needed on the construction
   * of the FileWrapper.
   * @returns {UploaderOptions}
   */
  private getDefaultUploaderOptions(): UploaderOptions {
    return {chunked: true, chunkSize: 1048576} as UploaderOptions;
  }

  /**
   * Creates a FileWrapper instance configured for the service.
   * @param {File} file File to upload
   * @param {UploaderOptions} params Configuration.
   */
  createFile(file: File, params?: IClientOptions): FileWrapper {
    return new FileWrapper(file, this, this.getDefaultUploaderOptions(), null);
  }

  /**
   * Uploads a file or blob to the core-files/upload controller on backend.
   * @param {File|Blob|string} data Data to upload
   * @returns {Observable<object>}
   */
  upload(data: any | null): Observable<object> {
    const endpoint: string = 'core-upload/upload';
    const options: IClientOptions = {
      deflate: false,
      reportProgress: true,
      responseType: 'json',
      observe: 'events',
      mergeHeaders: false,
      headers: new HttpHeaders(),
    };

    return this.comService.post(endpoint, data, options);
  }
}
