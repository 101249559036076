import { AfterViewInit, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { getInSafe } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import {
  FormElementSelect,
  IFormElementOption
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => SelectComponent), multi: true}
  ]
})
export class SelectComponent extends FrontendFormElementInput implements AfterViewInit {

  /**
   * Usamos esto para identificar a la opción sin valor
   */
  noOptionValue = 'nooption';

  /**
   * Valor seleccionado
   */
  protected selectedValue: string;

  /**
   * Constructor
   */
  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected translatorService: TranslatorService) {
    super(formManagerService, cdRef, translatorService);
  }

  /**
   * Este set solo se llama internamente al cambiar el valor
   *
   * @param value
   */
  set selected(value: string) {
    if (isNullOrUndefined(value) || value === '' || value === this.noOptionValue) {
      value = null;
    }
    this.selectedValue = value;
    this.propagateChange(value);
  }

  get elementSelect(): FormElementSelect {
    return this.config.FormElement as FormElementSelect;
  }

  get selected(): string {
    if (isNullOrUndefined(this.selectedValue) || this.selectedValue === '') {
      return this.noOptionValue;
    }
    return this.selectedValue;
  }

  /**
   * The ngModel management works with arrays.
   * Multiselect is not supported yet.
   * @param value
   */
  set selectedItems(value: string[] | string) {
    if (typeof value === 'string') {
      this.selected = value;
      return;
    }

    if (!isNullOrUndefined(value) && value.length === 1) {
      this.selected = value[0];
    }
  }

  get selectedItems(): string[] {
    if (isNullOrUndefined(this.selected)) {
      return [];
    }
    return [this.selected]
  }

  /**
   * Opciones de la combo
   */
  get options(): IFormElementOption[] {
    if (!this.config.options) {
      return [];
    }

    let result: IFormElementOption[] = [...this.config.options];

    if (isNullOrUndefined(this.config.disableAutoSort) || this.config.disableAutoSort === false) {
      result.sort(
          function (a: IFormElementOption, b: IFormElementOption): number {
            if (a.Name < b.Name) {
              return -1;
            }
            if (a.Name > b.Name) {
              return 1;
            }
            return 0;
          });
    }

    // Añadimos la opción de "seleccione una opción" si hace falta, al principio del arreglo de opciones.
    if (this.config.required === false || isNullOrUndefined(this.selectedValue)) {
      result = [{
        Key: this.noOptionValue,
        Name: `-- ${this.translatorService.get('Seleccione una opción')} --`
      } as IFormElementOption, ...result];
    }

    return result;
  }

  /**
   * Los ítems individuales pueden tener una descripción
   */
  get currentItemDescription(): string {
    return getInSafe(this.config.options, (i) => i.find((j) => j.Key === this.selected).Description, null);
  }

  writeValue(value: string): void {
    // Verificar si existe la opción, en caso de no existir, no la guardamos ya que no es compatible con la GUI.
    if (this.options.some((i) => i.Key === value) === false) {
      console.debug('El valor [' + value + '] no es compatible con las opciones del campo.');
      value = null;
    }
    this.selected = value;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }
}
