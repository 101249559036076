import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angular2-qrcode';
import { TagInputModule } from 'ngx-chips';
import { RestTranslatorModule } from '../../core/translator/rest-translator.module';
import { SabentisUtilsModule } from '../../sabentisutils/utils.module';
import { BatchModule } from '../batch/batch.module';
import { FileUploaderModule } from '../file-uploader/file-uploader.module';
import { GeocodingModule } from '../geocoding/geocoding.module';
import { NgxBootStrapModule } from '../ngx-bootstrap/ngx-bootstrap.module';
import {
  AutocompleteMultipleComponent
} from './form-components/acomplete/autocomplete-multiple/autocomplete-multiple.component';
import { AcompleteComponent } from './form-components/acomplete/autocomplete-simple/acomplete.component';
import {
  AutocompleteWrapperComponent
} from './form-components/acomplete/autocomplete-wrapper/autocomplete-wrapper.component';
import {
  AutocompletenewWrapperComponent
} from './form-components/autocompletenew/autocompletenew-wrapper/autocompletenew-wrapper.component';
import { AutocompletenewComponent } from './form-components/autocompletenew/autocompletenew/autocompletenew.component';
import { ButtonComponent } from './form-components/button/button.component';
import { InputchipsWrapperComponent } from './form-components/chips/inputchips-wrapper/inputchips-wrapper.component';
import { InputchipsComponent } from './form-components/chips/inputchips/inputchips.component';
import {
  DatePickerOldWrapperComponent
} from './form-components/date-picker/date-picker-wrapper/date-picker-old-wrapper.component';
import { DatePickerOldComponent } from './form-components/date-picker/date-picker/date-picker-old.component';
import { DatetimepickerComponent } from './form-components/date-time-picker/datepicker/datetimepicker.component';
import {
  DatetimepickerWrapperComponent
} from './form-components/date-time-picker/datetimepicker-wrapper/datetimepicker-wrapper.component';
import {
  DatepickerWrapperComponent
} from './form-components/datepicker/datepicker-wrapper/datepicker-wrapper.component';
import { DatepickerComponent } from './form-components/datepicker/datepicker/datepicker.component';
import { EmbeddedDocumentComponent } from './form-components/embedded-document/embedded-document.component';
import { FieldsetComponent } from './form-components/fieldset/fieldset.component';
import {
  FormelementinputdescriptionComponent
} from './form-components/formelementinputdescription/formelementinputdescription.component';
import {
  FormelementinputlabelComponent
} from './form-components/formelementinputlabel/formelementinputlabel.component';
import {
  FormelementinputvalidationmessagesComponent
} from './form-components/formelementinputvalidationmessages/formelementinputvalidationmessages.component';
import { FormTableComponent } from './form-components/formtable/form-table.component';
import { HtmlplaceholderComponent } from './form-components/htmlplaceholder/htmlplaceholder.component';
import {
  InputCheckModalWrapperComponent
} from './form-components/input-check-modal/input-check-modal-wrapper/input-check-modal-wrapper.component';
import {
  InputCheckModalComponent
} from './form-components/input-check-modal/input-check-modal/input-check-modal.component';
import {
  InputCheckMultipleComponent
} from './form-components/input-check/input-check-multiple/input-check-multiple.component';
import {
  InputCheckSimpleComponent
} from './form-components/input-check/input-check-simple/input-check-simple.component';
import { InputCheckComponent } from './form-components/input-check/input-check-wrapper/input-check.component';
import { FileSelectComponent } from './form-components/input-file/file-select/file-select.component';
import { InputFileWrapperComponent } from './form-components/input-file/input-file-wrapper/input-file.component';
import { SelectWrapperComponent } from './form-components/input-select/select-wrapper/select-wrapper.component';
import { SelectComponent } from './form-components/input-select/select/select.component';
import {
  InputTextAreaWrapperComponent
} from './form-components/input-text-area/input-textarea-wrapper/input-textarea-wrapper.component';
import { InputTextareaComponent } from './form-components/input-text-area/input-textarea/input-textarea.component';
import { InputDirective } from './form-components/input.directive';
import { InputWrapperComponent } from './form-components/input/input-wrapper/input-wrapper.component';
import { InputComponent } from './form-components/input/input/input.component';
import { InputBoolWrapperComponent } from './form-components/inputbool/input-bool-wrapper/input-bool-wrapper.component';
import { InputBoolComponent } from './form-components/inputbool/input-bool/input-bool.component';
import { InnerLabelComponent } from './form-components/label/inner-label/inner-label.component';
import { LabelComponent } from './form-components/label/label-wrapper/label.component';
import { MapsInputWrapperComponent } from './form-components/map-input/map-input-wrapper/maps-input-wrapper.component';
import { MapsInputComponent } from './form-components/map-input/maps-input-inner/maps-input.component';
import { MapsComponent } from './form-components/map/maps.component';
import { InnerQrCodeComponent } from './form-components/qr-code/inner-qr-code/inner-qr-code.component';
import { QrCodeComponent } from './form-components/qr-code/qr-code-wrapper/qr-code.component';
import { RadioWrapperComponent } from './form-components/radio/radio-wrapper/radio-wrapper.component';
import { RadioComponent } from './form-components/radio/radio/radio.component';
import { SecretComponent } from './form-components/secret/secret.component';
import {
  TimePickerTimespanWrapperComponent
} from './form-components/timepicker-timespan/timepicker-wrapper/time-picker-timespan-wrapper.component';
import {
  TimePickerTimespanComponent
} from './form-components/timepicker-timespan/timepicker/time-picker-timespan.component';
import {
  TimePickerWrapperComponent
} from './form-components/timepicker/timepicker-wrapper/time-picker-wrapper.component';
import { TimePickerComponent } from './form-components/timepicker/timepicker/time-picker.component';
import { ViewsEmbedComponent } from './form-components/views-embed/views-embed-wrapper/views-embed.component';
import { ViewsEmbedInnerComponent } from './form-components/views-embed/views-embed/views-embed-inner.component';
import { ViewsselectorComponent } from './form-components/viewsselector/viewsselector/viewsselector.component';
import {
  ViewsselectormodalComponent
} from './form-components/viewsselector/viewsselectormodal/viewsselectormodal.component';
import {
  ViewsselectorwrapperComponent
} from './form-components/viewsselector/viewsselectorwrapper/viewsselectorwrapper.component';
import { FormManagerComponent } from './form-manager/form-manager.component';
import { FormComponent } from './form-manager/form.component';
import { FormScrollDirectiveDirective } from './shared/form-scroll-directive.directive';
import { ViewsFormModule } from './shared/view-form/views-form.module';
import { ChartEmbeddedModule } from './form-components/chart-embedded/chart-embedded.module';
import { FormLoadingPlaceholerComponent } from './form-loading-placeholer/form-loading-placeholer.component';
import {
  FormElementFromContentsProgressComponent
} from './form-components/formelementprogress/formelementprogress.component';
import { CommentModule } from '../comment/comment.module';
import {
  FormElementSimpleMessageComponent
} from './form-components/simple-message/formelement-simple-message.component';
import { CoreModule } from '../../core/core.module';
import { FormElementBatchTaskComponent } from './form-components/batch-task/form-element-batch-task.component';
import { UtilsModule } from '../../utils/utils.module';
import { InputNumberComponent } from './form-components/input-number/input-number/input-number.component';
import {
  InputNumberWrapperComponent
} from './form-components/input-number/input-number-wrapper/input-number-wrapper.component';
import {
  InputAvatarWrapperComponent
} from './form-components/input-avatar/input-avatar-wrapper/input-avatar.component';
import { AvatarSelectComponent } from './form-components/input-avatar/avatar-select/avatar-select.component';
import { FileDropableComponent } from './form-components/input-file/file-dropable/file-dropable.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormService } from '../../core/services/ETG_SABENTISpro_Application_Core_form.service';
import { NumberDirective } from './shared/numers-only.directive';
import {
  SelectDynamicWrapperComponent
} from './form-components/input-selectdynamic/selectdynamic-wrapper/selectdynamic-wrapper.component';
import { SelectDynamicComponent } from './form-components/input-selectdynamic/selectdynamic/selectdynamic.component';
import { VideoComponent } from './form-components/video/video/video.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VideoWrapperComponent } from './form-components/video/video-wrapper/video-wrapper.component';
import { QuillModule } from 'ngx-quill';
import {
  InputRichTextEditorWrapperComponent
} from './form-components/input-rich-text-editor/input-rich-text-editor-wrapper/input-rich-text-editor-wrapper.component';
import {
  InputRichTextEditorComponent
} from './form-components/input-rich-text-editor/input-rich-text-editor/input-rich-text-editor.component';

@NgModule({
  imports: [
    CoreModule,
    GeocodingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RestTranslatorModule,
    NgxBootStrapModule,
    FileUploaderModule,
    TimepickerModule.forRoot(),
    ViewsFormModule,
    TagInputModule,
    BsDropdownModule.forRoot(),
    SabentisUtilsModule,
    QuillModule.forRoot(),
    QRCodeModule,
    RouterModule,
    BatchModule,
    ChartEmbeddedModule,
    CommentModule,
    UtilsModule,
    YouTubePlayerModule,
  ],
  declarations: [
    FormComponent,
    InputComponent,
    InputNumberComponent,
    InputCheckComponent,
    InputCheckMultipleComponent,
    InputCheckSimpleComponent,
    InputBoolWrapperComponent,
    InputTextAreaWrapperComponent,
    InputRichTextEditorWrapperComponent,
    InputFileWrapperComponent,
    InputDirective,
    FormManagerComponent,
    SelectWrapperComponent,
    DatePickerOldComponent,
    SelectComponent,
    ButtonComponent,
    RadioComponent,
    AcompleteComponent,
    AutocompleteMultipleComponent,
    AutocompleteWrapperComponent,
    InputBoolComponent,
    LabelComponent,
    MapsComponent,
    MapsInputWrapperComponent,
    MapsInputComponent,
    FileSelectComponent,
    FieldsetComponent,
    InnerLabelComponent,
    HtmlplaceholderComponent,
    ViewsEmbedComponent,
    ViewsEmbedInnerComponent,
    DatepickerComponent,
    DatepickerWrapperComponent,
    SecretComponent,
    FormelementinputlabelComponent,
    FormelementinputdescriptionComponent,
    FormelementinputvalidationmessagesComponent,
    InputWrapperComponent,
    InputNumberWrapperComponent,
    DatePickerOldWrapperComponent,
    InputTextareaComponent,
    InputRichTextEditorComponent,
    RadioWrapperComponent,
    TimePickerWrapperComponent,
    TimePickerComponent,
    TimePickerTimespanWrapperComponent,
    TimePickerTimespanComponent,
    InputchipsWrapperComponent,
    InputchipsComponent,
    ViewsselectorwrapperComponent,
    ViewsselectorComponent,
    ViewsselectormodalComponent,
    FormScrollDirectiveDirective,
    AutocompletenewWrapperComponent,
    AutocompletenewComponent,
    InputCheckModalWrapperComponent,
    InputCheckModalComponent,
    FormTableComponent,
    QrCodeComponent,
    InnerQrCodeComponent,
    DatetimepickerComponent,
    DatetimepickerWrapperComponent,
    EmbeddedDocumentComponent,
    FormLoadingPlaceholerComponent,
    FormElementFromContentsProgressComponent,
    FormElementSimpleMessageComponent,
    FormElementBatchTaskComponent,
    InputAvatarWrapperComponent,
    AvatarSelectComponent,
    FileDropableComponent,
    NumberDirective,
    SelectDynamicWrapperComponent,
    SelectDynamicComponent,
    VideoComponent,
    VideoWrapperComponent
  ],
  exports: [
    FormManagerComponent,
    FormelementinputlabelComponent,
    FormelementinputdescriptionComponent,
    FormelementinputvalidationmessagesComponent,
    FormComponent
  ],
  providers: [
    FormService
  ]
})
export class SFormModule {
  constructor() {
  }
}
