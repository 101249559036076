import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FormElementInput, FormElementInputNumber, FormValidationTypeEnum } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import {
  floatSafeRemainder,
  isNullOrUndefined,
  isNullOrWhitespace,
  UtilsTypescript
} from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';


/**
 * Input generico para añadir tipos básicos de input.
 * @see https://developer.mozilla.org/es/docs/Web/HTML/Elemento/input
 *
 * Actualmente soporta (components.constant.ts):
 * - FormElementType.Number
 */
@Component({
  selector: 'app-forminputnumber',
  templateUrl: './input-number.component.html',
  // Add custom accesors for validation and form-value-access.
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent
  extends FrontendFormElementInput
  implements AfterViewInit, Validator {

  @ViewChild('inputElement') input: ElementRef;

  /**
   * Internal value form the field.
   */
  protected value: number;

  /**
   * Si el valor de entrada sin procesar es inválido, el detalle del motivo.
   * @protected
   */
  protected invalidFormat: string;

  /**
   * Confirm match with another field
   */
  protected confirmField: string;

  /**
   * The title of the first input element to compare
   */
  protected baseElementTitle: string;

  /**
   * The title of the other input element to compare with
   */
  protected compareElementTitle: string;

  /**
   * Class constructor,
   * @param {FormManagerService} formManagerService
   * @param {ChangeDetectorRef} cdRef
   */
  constructor(
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    protected translatorService: TranslatorService
  ) {
    super(formManagerService, cdRef, translatorService);
  }

  /**
   * Lifecycle hook that is called after a component's view has been fully
   * initialized.
   */
  ngAfterViewInit(): void {

    super.ngAfterViewInit();

    const element: FormElementInput = Object.assign(new FormElementInput(), this.config.FormElement);

    if (!isNullOrUndefined(element.ConfirmField)) {

      this.compareElementTitle = element.Title;
      this.confirmField = element.ConfirmField;
      this.baseElementTitle = this.formManagerService.getFormComponentInputInstance(this.confirmField).config.label;
      const confirmValueComponent: AbstractControl = this.formManagerService.getFormComponent(this.confirmField);

      confirmValueComponent
        .valueChanges
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe((value: string): void => {
          this.group.get(this.config.name)
            .updateValueAndValidity({emitEvent: true, onlySelf: false});
        });
    }
  }

  /**
   * Hay toda una discusión sobre valores inválidos en campos numéricos
   *
   * https://stackoverflow.com/questions/18677323/html5-input-type-number-value-is-empty-in-webkit-if-has-spaces-or-non-numeric-ch
   */
  blur(): void {
    this.propagateTouch();
  }

  /**
   * Getter for the component value.
   *
   * @returns {string}
   */
  get inputValue(): string {
    if (isNullOrUndefined(this.value)) {
      return null;
    }
    return this.value.toString();
  }

  /**
   * Setter for the component value.
   */
  set inputValue(value: string) {
    const normalizedValue: number = this.normalizeValue(value);
    if (!isNaN(normalizedValue)) {
      this.value = this.normalizeValue(normalizedValue);
      this.propagateChange(this.value, true);
    } else {
      this.formManagerService.getFormComponent(this.config.ClientPath).updateValueAndValidity();
    }
  }

  /**
   * Writes a new value to the element.
   *
   * This method will be called by the forms API to write to the
   * view when programmatic (model -> view) changes are requested.
   */
  writeValue(obj: string | number): void {
    const normalizedValue: number = this.normalizeValue(obj);
    this.value = this.normalizeValue(normalizedValue);
    this.cdRef.detectChanges();
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    if (this.input && this.input.nativeElement) {
      this.input.nativeElement.focus();
      return true;
    }
    return false;
  }

  /**
   * Normaliza la entrada a número.
   *
   * Si devuele NaN, significa que la entrada NO es numérica.
   *
   * @param value
   */
  protected normalizeValue(value: any): number {

    // Resetear la validación de entrada
    this.invalidFormat = null;

    if (UtilsTypescript.isNullOrWhitespace(value)) {
      return null;
    }

    if (!isNaN(value - 0)) {
      return Number(value);
    }

    // No hay nada "normalizado" en JS para validar un número, la función
    // parseFloat es demasiado flexibles, tragándose numeros malformados
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseFloat
    // Ver este post con expresiones regulares: https://stackoverflow.com/questions/2811031/decimal-or-numeric-values-in-regular-expression-validation
    const validNumber: boolean = /^-?(0|[1-9]\d*)(\.\d+)?$/.test(value);
    if (!validNumber) {
      this.invalidFormat = `El campo ${this.config.label} debe ser numérico. El separador decimal es ".". No puede utilizar separador de millares.`;
      return NaN;
    }

    return parseFloat(value);
  }

  /**
   * Method that performs synchronous validation against the provided control.
   *
   * @param control The control to validate against.
   *
   * @returns {ValidationErrors} A map of validation errors if validation fails,
   * otherwise null.
   *
   * @see https://angular.io/api/forms/Validator#validate
   */
  doValidate(c: AbstractControl): ValidationErrors {

    const errors: ValidationErrors = super.doValidate(c);

    if (!isNullOrWhitespace(this.invalidFormat)) {
      delete errors[FormValidationTypeEnum.Required];
      errors[FormValidationTypeEnum.IsNumber] = this.invalidFormat;
      return errors;
    }

    // Validación de campo de confirmación
    if (!isNullOrUndefined(this.confirmField)) {
      if (c.touched === true) {
        const value: string = this.formManagerService
          .getFormComponentValue(this.confirmField);

        if (!this.equalValues(value, this.inputValue)) {
          if (!isNullOrUndefined(this.baseElementTitle) && !isNullOrUndefined(this.compareElementTitle)) {
            errors[FormValidationTypeEnum.ConfirmField] = 'Los campos ' + this.baseElementTitle + ' y ' + this.compareElementTitle + ' no coinciden';
          } else {
            errors[FormValidationTypeEnum.ConfirmField] = 'Los campos no coinciden';
          }
        }
      }
    }

    const inputNumber: FormElementInputNumber = Object.assign(new FormElementInputNumber(), this.config.FormElement);

    // Validación de que sea un número válido..
    const numberValue: number = Number(c.value);
    if (!isNullOrWhitespace(c.value)) {
      if (isNaN(numberValue)) {
        errors[FormValidationTypeEnum.IsNumber] = `El campo ${this.config.label} debe ser numérico.`;
      }
    }

    // Validación de step
    if (inputNumber.Step && !isNaN(numberValue)) {
      const remainder: number = floatSafeRemainder(numberValue, inputNumber.Step);
      // Usamos este 0.1 como si fuera un cero, es por un tema de precisión de floats.
      if (remainder > 0) {
        if (inputNumber.Step === 1) {
          errors[FormValidationTypeEnum.StepNumber] = `El campo ${this.config.label} debe ser un número entero`;
        } else {
          errors[FormValidationTypeEnum.StepNumber] = `El campo ${this.config.label} no pertenece a la secuencia de ${inputNumber.Step}`;
        }
      }
    }

    return errors;
  }

  /**
   * Definir el atributo 'inputmode' según la configuración del campo
   */
  getInputMode(): string {
    const inputNumber: FormElementInputNumber = Object.assign(new FormElementInputNumber(), this.config.FormElement);

    if (inputNumber.Step === 1 && inputNumber.MinValue === 0) {
      return 'numeric';
    }

    if (inputNumber.Step !== 1 && inputNumber.MinValue === 0) {
      return 'decimal';
    }

    return 'none';
  }
}
