import { Component, OnInit } from '@angular/core';
import { GenericFormPageSimpleArguments } from './GenericFormPageSimpleArguments';
import { ActivatedRoute } from '@angular/router';
import { skip, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { AppContextService } from '../../context/context.service';
import { SessionstateService } from '../../../core/sessionstate/sessionstate.service';
import { GenericPageComponent } from '../generic-page.class';

@Component({
  selector: 'app-generic-form-page-simple',
  templateUrl: './generic-form-page-simple.component.html',
  styleUrls: ['./generic-form-page-simple.component.scss']
})
export class GenericFormPageSimpleComponent extends GenericPageComponent implements OnInit {

  /**
   * Configuración del componente
   */
  configurationArguments: GenericFormPageSimpleArguments;

  /**
   * Parametros que vienen de ruta o resolver, centralizados para todos los componentes internos
   */
  params: object;

  /**
   * The form ID
   */
  formId: string;

  constructor(protected activatedRoute: ActivatedRoute,
              protected navigationService: NavigationService,
              protected contextService: AppContextService,
              protected route: ActivatedRoute,
              protected sessionStateService: SessionstateService) {
    super(navigationService, activatedRoute, route, sessionStateService, contextService);

    this.activatedRoute
      .data
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data) => {
        if (!data || !(data['componentConfig'] instanceof GenericFormPageSimpleArguments)) {
          throw new Error('');
        }

        this.configurationArguments = data['componentConfig'] as GenericFormPageSimpleArguments;
      });

    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data) => {
        this.updateParams();
      });

    this.contextService
      .contextChanged
      .pipe(
        takeUntil(this.componentDestroyed$),
        // Como es un replay subject, no nos interesa el primer emit.
        skip(1)
      )
      .subscribe(x => this.updateParams());
  }

  ngOnInit(): void {
    this.updateFormId();
    this.updateParams();
  }

  get paramsForm(): object {
    return this.mapParamsToComponentParams(this.configurationArguments.formArgumentMapping);
  }

  updateFormId(): void {
    const formId: string = this.configurationArguments?.formId;

    if (formId.includes('@session:')) {
      if (!this.sessionData) {
        this.getSessionData(() => this.updateFormId());
        return;
      }
      this.formId = this.getSessionDataValue(formId);
      return;
    } else if (formId.includes('@nav-argument:')) {
      this.formId = this.getNavArgumentValue(formId, '@nav-argument:');
      return;
    } else if (formId.includes('@query-param:')) {
      this.formId = this.getQueryParamValue(formId, '@query-param:');
      return;
    } else if (formId.includes('@resolver-param:')) {
      this.formId = this.getQueryParamValue(formId, '@resolver-param:');
      return;
    }

    this.formId = formId;
  }

  /**
   * Agregar todos los argumentos que vamos a necesitar
   *
   * @protected
   */
  protected captureRequiredArguments(): string[] {
    const result: { [name: string]: boolean } = {};

    if (this.configurationArguments.formArgumentMapping) {
      for (const p of Object.values(this.configurationArguments.formArgumentMapping)) {
        result[p] = true;
      }
    }
    return Object.keys(result);
  }

}
