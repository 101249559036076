import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../../shared/shared.module';
import { ViewConfigurationComponent } from './configuration-view/view-configuration.component';
import { ConfigurationDetailsComponent } from './configuration-details/configuration-details.component';
import { ConfigurationRoutingModule } from './configuration-routing.module';

/**
 * Module Configuration
 */
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ConfigurationRoutingModule
  ],
  declarations: [
    ViewConfigurationComponent,
    ConfigurationDetailsComponent
  ]
})
export class ConfigurationModule { }
