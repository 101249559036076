<div class="c-side-nav" role="navigation" #nav>
  <div [ngClass]="closed ? 'sidenav-close' : 'sidenav-open'">
    <div class="c-side-nav-container"
         *ngFor="let child of menuItems; let i = index"
         appSabentisRouterLinkActive="open"
         [menuItem]="child">
      <div class="c-side-nav-item"
           (click)="changeOpenedChildren(i, child)"
           [ngClass]="makeSafeForCssWithPrefix(child.controller)">
        <i class="i-icon" *ngIf="child.CssClasses"
           [ngClass]="getIcon(child.CssClasses)"
           (click)="openItem()"
           [tooltip]="child.NavigationToolTip"
           placement="left"
           triggers=""
           #pop="bs-tooltip"
           container="body"
           (mouseenter)="showTooltip(closed, pop)"
           (mouseleave)="hideTooltips()"
        >
        </i>
        <div class="c-side-nav__link" *ngIf="!closed">
          <ng-container *ngIf="!hasValidChildren(child)">
            <a class="c-side-nav__title"
               [appSabentisRouterLink]="child"
               appSabentisRouterLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}">{{ child.title }}</a>
          </ng-container>
          <div class="c-side-nav__title"
               *ngIf="hasValidChildren(child)">{{ child.title }}</div>
          <i class="fa fa-chevron-down"
             *ngIf="hasValidChildren(child) && !closed"></i>
        </div>
      </div>
      <app-menu-child [open]="(i === openedChildren) && !closed" [tree]="child.children" [depth]="0"></app-menu-child>
    </div>
  </div>
</div>
