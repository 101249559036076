/**
 * Dto extendido para frontend para ampliar la información que hay en los árboles de navegación.
 *
 * Se extendió la clase original MenuItemCompiled para reducir el impacto de la refactorización.
 */
import { MenuItemCompiled } from '../../models/ETG_SABENTISpro_Application_Core_models';


export class MenuItemCompiledFrontend extends MenuItemCompiled {

  protected expandedBackendPathExplodedValue: string[];

  /**
   * El path de frontend
   */
  public frontendPath: string;

  /**
   * El path de frontend en el caso que haya una redireccion
   */
  public redirectedFrontendPath: string;

  /**
   * El path de backend expandido. En los casos de ítems tipo anchor,
   * coincide con el path canónico de destino
   */
  public expandedBackendPath: string;

  /**
   * Aunque un elemento venga con un controller de backend, no significa que lo vaya a tener (y por lo tanto sea navegable) en frontend...
   */
  public frontendCanNavigate: boolean;

  /**
   * Hide base member children: MenuItemCompiled[]
   */
  public children: MenuItemCompiledFrontend[];

  /**
   * Comprueba si tiene comandos de bloqueo de acceso
   */
  public accessBlocked(): boolean {
    return this.AccessBlockedCommands && Object.keys(this.AccessBlockedCommands).length > 0;
  }

  /**
   * Obtiene el path the backend explotado en partes de URL (ya que la API es intensiva en esto para ver si los ítems de menú están activos),
   * mejora el rendimiento sustancialmente en los repintados.
   */
  public get expandedBackendPathExploded(): string[] {
    if (!this.expandedBackendPathExplodedValue) {
      this.expandedBackendPathExplodedValue = this.expandedBackendPath.split('/');
    }
    return this.expandedBackendPathExplodedValue;
  }

  public getFrontendPath(): string {
    if (this.redirectedFrontendPath) {
      return this.redirectedFrontendPath;
    }
    return this.frontendPath;
  }
}
