import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorPageComponent } from './core/error-handling/error-page/error-page.component';
import { SingleSignOnComponent } from './singlesignon/singlesignon.component';

const appRoutes: Routes = [
  {
    path: 'error', component: ErrorPageComponent, canActivate: []
  },
  {
    path: 'sso', component: SingleSignOnComponent
  },
  {
    path: '', loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes, {enableTracing: false, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'}
    )
  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
