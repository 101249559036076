import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Type,
  ViewContainerRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../interfaces/field-config.interface';
import { IFrontendFormElement } from '../interfaces/field.interface';
import {
  FormElementFrontendCustom,
  FormElementType
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { FormManagerService } from '../form-manager/form-manager.service';
import { CustomComponentFactoryService } from '../../custom-component-factory/custom-component-factory.service';
import { take, takeUntil } from 'rxjs/operators';
import { ClientThemeService } from '../../../core/theme-manager/theme.service';

@Directive({
  selector: '[appGenericInput]'
})
export class InputDirective extends DestroyableObjectTrait implements IFrontendFormElement, OnInit, OnChanges, OnDestroy, AfterViewInit {

  private configValue: FieldConfig;

  @Input() public group: FormGroup;

  public component: ComponentRef<IFrontendFormElement>;

  @Input()
  set config(value: FieldConfig) {
    this.configValue = value;
  }

  get config(): FieldConfig {
    return this.configValue;
  }

  constructor(
      private customResolver: CustomComponentFactoryService,
      private resolver: ComponentFactoryResolver,
      private container: ViewContainerRef,
      private formManager: FormManagerService,
      private themeService: ClientThemeService) {
    super();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngAfterViewInit(): void {
    // Do nothing
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(changes: SimpleChanges): void {
    // Do nothing
  }

  ngOnInit(): void {
    this.themeService.getCurrentLayoutVariables()
        .pipe(takeUntil(this.componentDestroyed$), take(1))
        .subscribe(x => {
          const availableFormComponents: { [type: number]: Type<IFrontendFormElement> } = x.AvailableFormComponents;
          // Hay un tipo de component especial cuya implementación se registra dinámicamente
          if (this.config.type === FormElementType.FrontendCustom) {
            const elem: FormElementFrontendCustom = this.config.FormElement as FormElementFrontendCustom;
            this.component = this.customResolver.createComponent(elem.BindingControlName, this.container, null, null);
          } else {
            // Estos son los componentes estándar, propios de la API de formularios
            if (!availableFormComponents[this.config.type]) {
              const existingTypes: string = Object.keys(availableFormComponents).map((i) => FormElementType[i]).join(', ');
              throw new Error(
                  `'El tipo de control especificado (${FormElementType[this.config.type]}) no existe.
        Controles soportados: ${existingTypes}`
              );
            }
            this.component = this.container.createComponent(availableFormComponents[this.config.type]);
          }
          this.component.instance.config = this.config;
          this.component.instance.group = this.group;
          this.formManager.registerFormComponent(this.component);
        })
  }

  initializeDynamicComponent(params: any): void {
  }
}
