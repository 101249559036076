import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ChangedetectorService } from '../../../core/changedetector/changedetector.service';
import { ChangedetectorReference } from '../../../core/changedetector/changedetectoreference';
import {
  BatchTaskInfo,
  CurrentExecutionInfo,
  CurrentExecutionStatus,
  WebServiceResponseTyped
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FileDownloaderService } from '../../../shared/files/file-downloader.service';
import { DestroyableObjectTrait } from '../../../shared/utils/destroyableobject.trait';
import { getInSafe, isNullOrUndefined } from '../../../shared/utils/typescript.utils';
import { PrimeUtils } from '../../../shared/utils/prime.utils';
import { take, takeUntil } from 'rxjs/operators';
import { BatchResultButton } from '../../../shared/batch/result-button.class';
import { BatchGlobalServicesClass } from '../../../shared/batch/batch.global.service.class';
import { BatchService } from '../../../core/services/ETG_SABENTISpro_Application_Core_batch.service';

@Component({
  selector: 'app-batch-item',
  templateUrl: './batch-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChangedetectorReference
  ]
})
export class BatchItemComponent extends DestroyableObjectTrait implements OnInit, OnDestroy {

  /**
   * Interval between batch check requests.
   */
  static readonly INTERVAL = 1000;

  /**
   * Task to initialize the component
   */
  @Input() initialTask: BatchTaskInfo;

  /**
   * Component display option: The close button could be showed
   */
  @Input() showCloseButton: boolean;

  /**
   * @deprecated Es una capa de compatibilidad. Si se ha de mostrar un botón cerrar es responsabilidad del modal, no del componente de seguimiento de un batch.
   *
   * Component work flow semaphore.
   * Marks if the component must call the GetTaskResultButtons function when the task is completed.
   */
  @Input() showResultButtons = true;

  /**
   * The forced Content Disposition for results
   */
  @Input() contentDisposition: 'inline' | 'attachment' = 'attachment';

  /**
   * On Task Completed Event
   */
  @Output() onTaskCompleted: EventEmitter<BatchTaskInfo> = new EventEmitter<BatchTaskInfo>();

  /**
   * On Task Completed Event
   */
  @Output() onTaskCompletedWithResult: EventEmitter<BatchTaskInfo> = new EventEmitter<BatchTaskInfo>();

  /**
   * On Close Event
   */
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Batch task
   */
  protected batchTask: BatchTaskInfo;

  /**
   * Display option: Show the User output container
   */
  showUserOutput: boolean = false;

  /**
   * Timer object to track the next batch update.
   */
  timer: NodeJS.Timeout;

  /**
   * The current result
   */
  currentResult: BatchResultButton[];

  /**
   * BatchItemComponent class constructor.
   */
  constructor(
      protected batchGlobalServices: BatchGlobalServicesClass,
      private batchService: BatchService,
      private fileDownloaderService: FileDownloaderService,
      private cdReference: ChangedetectorReference,
      private cdService: ChangedetectorService
  ) {
    super();
    this.showCloseButton = false;
  }

  /**
   * `task` setter.
   *
   * This setter also triggers the `completed$` EventEmitter.
   */
  set task(task: BatchTaskInfo) {
    this.batchTask = task;
    if (this.batchGlobalServices.TaskIsCompleted(this.batchTask)) {
      this.ExecuteOnComplete();
    }
  }

  /**
   * `task` getter.
   */
  get task(): BatchTaskInfo {
    return this.batchTask;
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.task = this.initialTask;

    if (this.isRunning() && !this.isPaused()) {
      this.update();
    }
  }

  /**
   * A lifecycle hook that is called when a directive, pipe, or service is destroyed.
   * Use for any custom cleanup that needs to occur when the
   * instance is destroyed.
   */
  ngOnDestroy(): void {
    this._clearUpdate();
    super.ngOnDestroy();
  }

  /**
   * Returns a boolean indicating if the task is complete.
   */
  get isStatusCompleted(): boolean {
    return this.batchGlobalServices.TaskIsCompleted(this.task);
  }

  /**
   * Returns a boolean indicating if the task has failed.
   */
  get isStatusError(): boolean {
    return this.batchGlobalServices.TaskIsInErrorState(this.task);
  }

  /**
   * Runs current task.
   */
  start(): void {
    this.batchGlobalServices.RestartTask(this.task)
        .then(task => {
          this.task = task;
          this.update();
        })
  }

  /**
   * Download batch result
   */
  download(): void {
    this.fileDownloaderService.DownloadFileFromTask(this.task);
  }

  /**
   * Updates data for current task.
   */
  update(): void {
    if (this.timer) {
      return;
    }

    this.timer = setTimeout(() => {
      this._runUpdate()
    }, BatchItemComponent.INTERVAL);
  }

  /**
   * Sets an interval function to update current task.
   */
  private _runUpdate(): void {
    if (isNullOrUndefined(this.task.QueueId) || this.task.QueueId === PrimeUtils.DEFAULT_GUID) {
      return;
    }
    this.batchService.getTask(this.task.QueueId, {showSpinner: false})
        .pipe(
            takeUntil(this.componentDestroyed$))
        .pipe(take(1))
        .subscribe((response: WebServiceResponseTyped<BatchTaskInfo>) => {
          this.batchTask = Object.assign(new BatchTaskInfo(), response.result);
          // Si la tarea es reciente puede que el sandbox no esté inicializado
          if (this.task.CurrentExecution) {
            this.showUserOutput = ((this.task.CurrentExecution.UserOutputMessages || []).length > 0);
          }

          if (!this.isStatusCompleted) {
            this.cdReference.changeDetector.detectChanges();
          } else {
            this.ExecuteOnComplete();
          }

          this.cdService.runApplicationChangeDetection();

          if (!this.isRunning() || this.isPaused()) {
            this._clearUpdate();
            return;
          }

          this.timer = setTimeout(() => {
            this._runUpdate()
          }, BatchItemComponent.INTERVAL);
        });
  }

  private ExecuteOnComplete(): void {
    this.onTaskCompleted.next(this.task);

    if (this.showResultButtons && this.task.HasResult) {
      this.currentResult = [];
      this.batchGlobalServices.GetTaskResultButtons(this.task, null, this.contentDisposition)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(x => {
            if (x) {
              this.currentResult = x.buttons;
            }
            this.cdReference.changeDetector.detectChanges();
            this.onTaskCompletedWithResult.emit(this.task)
          })
    } else {
      this.cdReference.changeDetector.detectChanges();
    }
  }

  /**
   * Clears update interval.
   */
  private _clearUpdate(): void {
    clearTimeout(this.timer);
    this.timer = null;
  }

  /**
   * Returns start time for current task.
   */
  started(): string {
    return this.batchGlobalServices.GetStarted(this.task);
  }

  /**
   * Returns creation time for current task.
   */
  created(): string {
    return this.batchGlobalServices.GetCreated(this.task);
  }

  /**
   * Returns lastRun start timefor current task
   */
  lastRun(): string {
    return this.batchGlobalServices.GetLastRun(this.task);
  }

  /**
   * Elapsed time for current task.
   */
  runningTime(): any {
    return this.batchGlobalServices.GetRunningTime(this.task);
  }

  /**
   * Returns style for percentage bar.
   */
  getTaskPercentageStyle(): object {
    const execution: CurrentExecutionInfo = this.batchGlobalServices.GetCurrentExecution(this.task);

    if (!execution) {
      return {
        'width': '0%',
        'transition': 'width 2s'
      };
    }

    if (execution.Status === CurrentExecutionStatus.COMPLETED) {
      return {
        'width': '100%',
        'transition': 'width 2s'
      };
    }

    return {
      'width': execution.Progress + '%',
      'transition': 'width 2s'
    };
  }

  /**
   * Returns style for percentage bar.
   */
  getBarDynamicStyle(): string {
    const execution: CurrentExecutionInfo = getInSafe(this.task, x => x.CurrentExecution, null);
    if (execution && execution.Status === CurrentExecutionStatus.COMPLETED) {
      return 'progress-bar-complete';
    }
    return '';
  }

  /**
   * Returns a boolean indicating current task status.
   */
  isRunning(): boolean {
    return this.batchGlobalServices.TaskIsRunning(this.task);
  }

  /**
   * Returns a boolean indicating current task status.
   */
  isRunnable(): boolean {
    return this.batchGlobalServices.TaskIsRunnable(this.task);
  }

  /**
   * Pauses current execution.
   */
  pause(): void {
    this.batchGlobalServices.PauseTask(this.task)
        .then(completed => {
          if (completed) {
            this.update()
          }
        });
  }

  /**
   * Resumes current execution.
   */
  resume(): void {
    this.batchGlobalServices.ResumeTask(this.task)
        .then(completed => {
          if (completed) {
            this.update()
          }
        });
  }

  /**
   * Cancels current execution.
   */
  cancel(): void {
    this.batchGlobalServices.CancelTask(this.task)
        .then(complete => {
          if (complete) {
            this._clearUpdate();
            this.update();
          }
        })
  }

  /**
   * Cancels current execution.
   */
  close(): void {
    this.onClose.emit()
  }

  /**
   * Toggles pause/resume for current task.
   */
  togglePause(): void {
    if (this.isPaused()) {
      this.batchGlobalServices.ResumeTask(this.task)
          .then(completed => {
            if (completed) {
              this.update();
            }
          });
      return;
    }

    this.pause();
  }

  /**
   * Returns a boolean indicating if current task is paused.
   */
  isPaused(): boolean {
    return this.batchGlobalServices.TaskIsPaused(this.task);
  }

  /**
   * Returns the Status like a text
   */
  getStatus(): string {
    return this.batchGlobalServices.GetTaskStatusDisplayText(this.task);
  }

  /**
   * changes showUserOutput value (true to false Or false to true)
   */
  onClickResultsButton(): void {
    this.showUserOutput = !this.showUserOutput;
    this.cdReference.changeDetector.detectChanges();
  }
}
