import { ChangeDetectorRef, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import {
  DtoFrontendModalType,
  IVboOperation
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import { ListComponent2Service } from '../../../list.service';
import { IViewHeaderComponent } from '../../view-header-component.interface';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { CORE_QUEUE } from '../../../../../core/models/ETG_SABENTISpro_Models_models';

const titleTooltip: string = 'Refrescar';

/**
 * Parent filter component
 * contains filter component and configuration component
 * emits and retrieves configuration changes
 */
@Component({
  selector: 'app-view-small-bar',
  templateUrl: './view-small-bar.component.html',
  styleUrls: ['./view-small-bar.component.scss']
})
export class ViewSmallBarComponent extends DestroyableObjectTrait implements IViewHeaderComponent {


  @ViewChild('columnsModal', {static: true}) columnsModal: TemplateRef<any>;

  /**
   * Configuration modal reference.
   */
  modalRef: ModalReference<unknown>;

  /**
   * ViewSmallBarComponent class constructor.
   */
  constructor(
    protected cdRef: ChangeDetectorRef,
    public listComponentService: ListComponent2Service,
    protected dmbs: DecoupledModalBridgeService,
    protected _elementRef: ElementRef,
    protected translatorService: TranslatorService
  ) {
    super();
  }

  getElementRef(): ElementRef {
    return this._elementRef
  }

  public dispatchEventOnTaskCompleted(operationResult: { operation: IVboOperation, result: { id: string, message: any[], responseData: any } | CORE_QUEUE }): void {
    this.listComponentService.emitOnVboOperationCompleted(operationResult);
  }

  /**
   * Shows the column configuration modal.
   */
  showColumnConfiguration(): void {
    this.modalRef = this.dmbs.showTemplate(this.columnsModal, {
      Title: this.translatorService.get('Añadir / quitar columnas'),
      ModalType: DtoFrontendModalType.Modal,
    });
  }
}
