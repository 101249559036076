  <!--<div class="text-dialog" >
      <span>
        <b>{{ 'MÉTODO FINE' | t }}</b>
      </span>
  </div>
-->
  <div class="flex-row-1">
      <div class="flex-col-sm-12" style="padding-left: 20px">
        <h2>{{ 'Método FINE' | t }}</h2>
        <div class="text-container">
          <label class="label-fine"> {{ 'El método FINE permite calcular el grado de peligrosidad de cada riesgo identificado, a través de una fórmula matemática que vincula la probabilidad de ocurrencia, las consecuencias que pueden originarse en caso de ocurrencia del evento y la exposición a dicho riesgo.' | t }}
          </label>
        </div>
        <div class="text-container">
          <label class="label-fine"> {{ 'El método consiste en valorar tres criterios y multiplicar las notas obtenidas en cada uno. Así, el Grado de Peligrosidad (o Nivel de Riesgo) se obtendrá al multiplicar el factor Consecuencia por el de Exposición y el de Probabilidad.'| t }} </label>
        </div>
      </div>

  </div>
  <div id="wrapper" style="padding-left: 20px">
    <div id="first">
      <table id="riskTable" class="tableN">
        <tbody>
          <tr>
            <th class="widthColumnName"> {{ 'NIVEL DE RIESGO'| t }} </th>
            <th class="widthColumnDes"> {{ 'SIGNIFICADO'| t }} </th>
          </tr>
          <tr>
            <td>
              <div class="methodologyfine methodologyfine_0--legend"> {{ 'URGENTE'| t }} </div>
            </td>
            <td>
              <p>{{ 'Situación crítica. Corrección urgente.'| t }}</p>
            </td>
          </tr>

          <tr>
            <td>
              <div class="methodologyfine methodologyfine_1--legend"> {{ 'PRIORITARIA'| t }} </div>
            </td>
            <td>
              <p>{{ 'Corregir y adoptar medidas de control.'| t }} </p>
            </td>
          </tr>
          <tr>
            <td>
              <div class="methodologyfine methodologyfine_2--legend"> {{ 'PROGRAMABLE'| t }} </div>
            </td>
            <td>
              <p>{{ 'No intervenir, salvo que un análisis más preciso lo justifique.'| t }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="second"></div>

    <div id="third">
      <table id="ActionTable" class="tableN">
        <tbody>
          <tr>
            <th class="widthColumnName"> {{ 'NIVEL DE RIESGO'| t }} </th>
            <th class="widthColumnDes"> {{ 'FECHA PLANIFICADA DE LA ACCIÓN CORRECTORA'| t }} </th>
          </tr>
          <tr>
            <td> {{ 'Programable'| t }} </td>
            <td>
              <p>{{ 'Planificación de la acción correctora antes de un año.'| t }}</p>
            </td>
          </tr>
          <tr>
            <td > {{ 'Prioritario'| t }} </td>
            <td>
              <p> {{ 'Planificación de la acción correctora antes de tres meses.'| t }} </p>
            </td>
          </tr>
          <tr>
            <td> {{ 'Urgente'| t }} </td>
            <td>
              <p>{{ 'Planificación de la accion correctora antes de un mes.'| t }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
