import { ChangeDetectorRef, Component, OnChanges, SimpleChanges } from '@angular/core';
import { ViewModeCalendarComponentAbstract } from '../view-mode-calendar-component-abstract.class';
import { CalendarEvent, CalendarEventTimesChangedEvent, } from 'angular-calendar';
import { CalendarModeWeek, DayOfWeek } from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DateUtils } from '../../../../../utils/date.utils';
import { TranslatorService } from '../../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-view-mode-calendar-week',
  templateUrl: './view-mode-calendar-week.component.html',
  styleUrls: ['./view-mode-calendar-week.component.scss']
})
export class ViewModeCalendarWeekComponent extends ViewModeCalendarComponentAbstract implements OnChanges {

  /**
   * The current Configuration
   */
  weekConfiguration: CalendarModeWeek;

  /**
   * An array of day indexes (0 = sunday, 1 = monday etc) that indicate which days are weekends
   */
  weekendDays: number[];

  /**
   * The start number of the week
   */
  weekStartOn: number;

  /**
   * The start time
   */
  dayStartOn: Date;

  /**
   * The end time
   */
  dayEndOn: Date;

  constructor(public cdr: ChangeDetectorRef,
              private translator: TranslatorService) {
    super(cdr);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Emiters
  // -----------------------------------------------------------------------------------------------------
  onEventClicked(data: { event: CalendarEvent<any> }): void {
    this.eventClicked.emit(data);
  }

  onEventTimesChanged(data: CalendarEventTimesChangedEvent<any>): void {
    this.eventTimesChanged.emit(data);
  }

  protected parseConfiguration(): void {
    this.weekConfiguration = this.configuration as CalendarModeWeek;
    if (this.weekConfiguration) {
      this.weekendDays = this.weekConfiguration.WeekendDays.map((x: DayOfWeek) => x.valueOf());
      this.weekStartOn = this.weekConfiguration.WeekStartsOn.valueOf();
      this.dayStartOn = DateUtils.ConvertTimeSpanToDate(this.weekConfiguration.StartTime as any as string);
      this.dayEndOn = DateUtils.ConvertTimeSpanToDate(this.weekConfiguration.EndTime as any as string);
      this.lang = this.translator.getAngularLang();
    }
  }
}
