import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { ChangedetectorService } from '../../../../../core/changedetector/changedetector.service';
import { ChangedetectorReference } from '../../../../../core/changedetector/changedetectoreference';
import { DateTimeService } from '../../../../../core/date-time/date-time.service';
import { isValidDate, UtilsTypescript } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { NgxDatepickerChangedetectionFixerDirective } from '../../../../../sabentisutils/ngx-datepicker-changedetection-fixer.directive';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

defineLocale('es', esLocale);

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker-old.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatePickerOldComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => DatePickerOldComponent), multi: true},
    ChangedetectorReference
  ]
})
export class DatePickerOldComponent extends FrontendFormElementInput implements OnInit, Validator, AfterViewInit {

  /**
   * Este componente legacy habla con backend utilizando Unix Timestamp, así que eso usamos
   * como valor interno.
   */
  protected controlValueValue: number | null;

  minDate = new Date(1800, 0, 1);
  maxDate = new Date(2500, 0, 1);
  bsConfig: BsDatepickerConfig;

  initialized: boolean;

  @ViewChild('dateInput', {static: true}) inputElement: ElementRef;

  @ViewChild(BsDatepickerDirective, {static: true})
  protected inputElementBsDatepicker: BsDatepickerDirective;

  @ViewChild(NgxDatepickerChangedetectionFixerDirective, {static: true})
  protected ngxDatepickerChangedetectionFixerDirective: NgxDatepickerChangedetectionFixerDirective;

  /**
   * Overriden to consider datepicker initialization
   */
  propagateTouch(): void {
    if (!this.initialized) {
      return;
    }
    super.propagateTouch();
  }

  handleInitialized(): void {
    this.initialized = true;
  }

  get controlValue(): Date {
    if (UtilsTypescript.isNullOrUndefined(this.controlValueValue) || !isFinite(this.controlValueValue)) {
      // Esta asignación a string vacío es por un bug en el componente datepicker por el que, aunque devolvamos
      // nulo aquí, no borra el valor que se ve en el input (Solo en algunos flujos de selección :( ).
      this.inputElement.nativeElement.value = '';
      return null;
    }
    const timeOffset: number = this.dateTimeService.toAdd2server() * 1000;
    return new Date((this.controlValueValue * 1000) + timeOffset);
  }

  set controlValue(value: Date) {
    // Clone the date to prevent infinte value changes
    let nextControlValue: number | null;
    if (!UtilsTypescript.isNullOrUndefined(value)) {
      value = new Date(value.getTime());
    }
    if (UtilsTypescript.isNullOrUndefined(value)) {
      nextControlValue = null;
    } else {
      if (isValidDate(value)) {
        value.setHours(12, 0);
        nextControlValue = ((value.getTime() / 1000)) + this.dateTimeService.toAdd2user();
      } else {
        nextControlValue = NaN;
      }
    }

    // Solo se propagan cambios en caso de que cambie la fecha, porque si no, se modifican los formularios y se ponen en estado "dirty"
    // por lo que da problemas si se quiere comprobar si hubo alguna modificación en ellos (Por ejemplo para los botones que se deshabilitan con los cambios).
    // Este problema viene porque se sobreescribe la hora con las 12:00, por lo que se toma como una modificación de formulario.
    const dt1: string = value ? (new Date(value.getTime())).toDateString() : null;
    const timeOffset: number = this.dateTimeService.toAdd2server() * 1000;
    const dt2: string = !UtilsTypescript.isNullOrUndefined(this.controlValueValue) ? new Date((this.controlValueValue * 1000) + timeOffset).toDateString() : null;
    if (dt1 !== dt2) {
      this.controlValueValue = nextControlValue;
      this.propagateChange(this.controlValueValue, false, false);
    }
  }


  constructor(private bsLocaleService: BsLocaleService,
              protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              protected dateTimeService: DateTimeService,
              protected cdReference: ChangedetectorReference,
              protected cdService: ChangedetectorService,
              protected localeService: TranslatorService) {
    super(formManagerService, cdRef, localeService);
    this.bsLocaleService.use('es');
  }

  writeValue(obj: any): void {
    this.controlValueValue = obj;
  }

  ngOnInit(): void {
    this.bsConfig = new BsDatepickerConfig();
    this.bsConfig.containerClass = 'theme-green';
    this.bsConfig.showWeekNumbers = false;
    this.bsConfig.dateInputFormat = 'DD/MM/YYYY';
    this.bsConfig.adaptivePosition = true;

    const milisecondsMultiplierValue: number = 1000;

    if (this.config.minDate > 0) {
      this.minDate = new Date(this.config.minDate * milisecondsMultiplierValue);
    }

    if (this.config.maxDate > 0) {
      this.maxDate = new Date(this.config.maxDate * milisecondsMultiplierValue);
    }

    this.ngxDatepickerChangedetectionFixerDirective.bsDatepicker = this.inputElementBsDatepicker;
  }

  doValidate(c: AbstractControl): ValidationErrors {

    const errors: ValidationErrors = super.doValidate(c);

    if (!isFinite(c.value)) {
      errors['invalid-date'] = 'Debe seleccionar una fecha válida.';
    }

    return errors;
  }
}
