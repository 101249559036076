import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  QueryList,
  SkipSelf,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CommandService } from '../../../../core/commands/command.service';
import { FormElementButton } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { asIterable, asIterableObject, isNullOrUndefined } from '../../../utils/typescript.utils';
import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';
import { ButtonClickedEventData } from './buttonclicked.eventdata';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ButtonComponent extends FrontendFormElementWrapper implements AfterViewInit {

  /**
   * Flag for the DisableIfDirtyMessage functionality.
   */
  protected disableIfPristineForm: boolean = false;

  /**
   * Flag para gestionar la deshabilitación del formulario mientras
   * esté inválido
   */
  protected disableIfInvalidForm: boolean = false;

  /**
   * Button element
   */
  @ViewChildren('button') button: QueryList<ElementRef>;

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  constructor(
    private dmbs: DecoupledModalBridgeService,
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    protected commandService: CommandService,
    @SkipSelf()
    protected cdRefParent: ChangeDetectorRef
  ) {
    super(formManagerService, cdRef, cdRefParent);
  }

  /**
   * Acceso a la configuración que viene de backend del componente
   */
  protected get buttonElement(): FormElementButton {
    return this.config.FormElement as FormElementButton;
  }

  ngAfterViewInit(): void {

    // Añadir los atributos data-
    if (this.button.length > 0 && this.config.dataAttributes && Object.keys(this.config.dataAttributes).length > 0) {
      const buttonItem: ElementRef = this.button.first;
      Object.keys(this.config.dataAttributes).map(attr => {
        buttonItem.nativeElement.setAttribute(`data-${attr}`, this.config.dataAttributes[attr]);
      });
    }

    // Funcionalidad DisableIfDirty
    if (this.buttonElement.DisableIfDirty) {
      const control: AbstractControl = this.formManagerService.getFormComponent(this.buttonElement.DisableIfDirtyTarget);
      this.disableIfDirty(control);
      control.statusChanges
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(() => {
          this.disableIfDirty(control);
        });
    }

    // Funcionalidad DisbaleIfInvalid
    if (this.buttonElement.DisableIfInvalid) {
      const control: AbstractControl = this.formManagerService.getFormComponent(this.buttonElement.DisableIfInvalidTarget);
      this.disableIfInvalid(control);
      control.statusChanges
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(() => {
          this.disableIfInvalid(control);
        });
    }
  }

  /**
   * Custom override para isDisabled para el caso de botones
   */
  public get isDisabled(): boolean {

    if (super.getIsDisabled()) {
      return true;
    }

    if (this.disableIfPristineForm === true) {
      return true;
    }

    if (this.disableIfInvalidForm === true) {
      return true;
    }

    return false;
  }

  getButtonClasses(): string[] {
    const classes: string[] = [...asIterable(this.config.buttonClasses)];
    // En lugar de deshabilitar realmente el componente, le ponemos la clase disabled,
    // ya que sino el tooltip no funciona
    // @see https://github.com/valor-software/ngx-bootstrap/issues/5074

    if (this.isDisabled) {
      classes.push('--disabled');
    }

    return classes;
  }

  get toolTip(): string {
    if (this.disableIfPristineForm === true && this.buttonElement.DisableIfDirtyTooltipMessage) {
      return this.buttonElement.DisableIfDirtyTooltipMessage;
    }
    if (this.disableIfInvalidForm === true && this.buttonElement.DisableIfInvalidTooltipMessage) {
      return this.buttonElement.DisableIfInvalidTooltipMessage;
    }
    return this.config.toolTip;
  }

  /**
   * Click para acciones diferentes a cancel
   */
  clicked(): void {
    this.buttonClicked();
  }

  protected buttonClicked(): void {

    // Fakeamos el disabled, porque sino los tooltips no se muestran en componentes deshabilitados (limitación de la librería de terceros que usamos)
    if (this.isDisabled) {
      return;
    }

    // Si tenemos una batería extendida de acciones, la ejecutamos y obviamos el flujo habitual
    // del click
    if (this.buttonElement.ActionsExtended) {
      this.commandService.executeCommandChain(asIterableObject(this.buttonElement.ActionsExtended))
        .then();
      return;
    }

    if (isNullOrUndefined(this.config.ConfirmChangeMessage)) {
      this.doClick();
      return;
    }

    const modalRef: ModalReference<unknown> = this.dmbs.showConfirm(
      {
        messages: this.config.ConfirmChangeMessage.Messages,
        message1: this.config.ConfirmChangeMessage.Message,
        NoLabel: this.config.ConfirmChangeMessage.NoLabel,
        YesLabel: this.config.ConfirmChangeMessage.YesLabel,
        notVisibleNo: this.config.ConfirmChangeMessage.HideNoButton
      },
      {
        Title: this.config.ConfirmChangeMessage.Title,
        ModalType: this.config.ConfirmChangeMessage.ModalType,
        ModalSize: this.config.ConfirmChangeMessage.ModalSize
      }
    );

    modalRef.close
      .pipe(
        takeUntil(this.componentDestroyed$),
        take(1),
        filter((data) => !!data)
      )
      .subscribe(() => {
        this.doClick();
      });
  }

  /**
   * Aplicación efectiva de un click
   */
  protected doClick(): void {

    const eventData: ButtonClickedEventData = new ButtonClickedEventData();
    eventData.Action = this.config.action;
    eventData.Emitter = this.config.ClientPath;
    eventData.Metadata = this.config.metadata;
    eventData.RequireClientValidations = this.buttonElement.RequireClientValidations;

    this.formManagerService.buttonClicked.emit(eventData);
  }

  /**
   * Calcula el valor de disableIfPristineForm al haber cambios en el estado del target
   * @param control
   */
  disableIfDirty(control: AbstractControl): void {
    if (control.pristine === false && this.disableIfPristineForm === false) {
      this.disableIfPristineForm = true;
      this.forceDetectChanges();
      this.cdRef.detectChanges();
    }
  }

  /**
   * Calcula el valor de disableIfInvalidForm al haber cambios en el estado del target
   * @param control
   */
  disableIfInvalid(control: AbstractControl): void {
    if (control.valid !== !this.disableIfInvalidForm) {
      this.disableIfInvalidForm = !control.valid;
      this.forceDetectChanges();
      this.cdRef.detectChanges();
    }
  }
}
