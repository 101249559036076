<input
    bsDatepicker
    appNgxDatepickerChangedetectionFixerDirective
    (initialized)="handleInitialized()"
    [bsConfig]="bsConfig"
    [(bsValue)]="dateInput"
    [id]="uniqueComponentId('input-')"
    (blur)="propagateTouch()"
    [isDisabled]="config.editable===false"
    [disabled]="config.editable===false"
    (onShown)="propagateTouch()"
    [placeholder]="bsConfig.dateInputFormat"
    class="t-date-input"
    [autocomplete]="'off-' + this.uniqueElementId"
    #inputElement>
