/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { MenuQueryResponse } from '../models/ETG_SABENTISpro_Application_Core_models';
import { NavigationTreeResponse } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class MenuService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Get a menu definition.
  *
  * @param {string} name The name of the menu
  * @param {string} path The path. Leave empty to retrieve the full menu tree.
  * @param {{ [id: string]: string }} args Any arguments for the sepcific path we are retrieving.
  * @param {string} beginAtPath Start tree instrospection at the given backend path
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {MenuQueryResponse}
  */
  getMenu(name: string = null, path: string = null, args: { [id: string]: string } = null, beginAtPath: string = null, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<MenuQueryResponse>>
  {
    const params: any = {'name' : name, 'path' : path, 'args' : JSON.stringify(args), 'beginAtPath' : beginAtPath};
    return this.generalService.get(`core-menu/menu`, params, clientOpts) as Observable<WebServiceResponseTyped<MenuQueryResponse>>;
  }

  /**
  *
  * Get a full controller map of the navigation system, aids the frontend implementation into knowing the finall hierarchy of the menu tree so it can better optimize partial menu calls.
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {NavigationTreeResponse}
  */
  getNavigationtree(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<NavigationTreeResponse>>
  {
    return this.generalService.get(`core-menu/navigationtree`, null, clientOpts) as Observable<WebServiceResponseTyped<NavigationTreeResponse>>;
  }
}
