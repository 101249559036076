import { ChangeDetectorRef, Component, ElementRef, HostListener, TemplateRef, ViewChild } from '@angular/core';
import {
  DtoFrontendModalType,
  IVboOperation
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { ListComponent2Service } from '../../../list.service';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';
import { CORE_QUEUE } from '../../../../../core/models/ETG_SABENTISpro_Models_models';

@Component({
  selector: 'app-view-configuration',
  templateUrl: './view-configuration.component.html'
})
export class ViewConfigurationComponent {

  @ViewChild('cMenu', {static: true}) cMenu: ElementRef;

  isOpenClass: string;

  @ViewChild('columnsModal', {static: true}) columnsModal: TemplateRef<any>;

  /**
   * Configuration modal reference.
   */
  modalRef: ModalReference<unknown>;

  /**
   * Instance of class
   */
  constructor(
    private cdRef: ChangeDetectorRef,
    private listComponentService: ListComponent2Service,
    private dmbs: DecoupledModalBridgeService,
    private translatorService: TranslatorService
  ) {
  }

  /**
   * open dropdown
   * @param {Event} $event
   */
  clickedInside($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.isOpenClass = 'is-open';
    this.cdRef.detectChanges();
  }

  /**
   * close dropdown
   *
   * ACHSPRIME-2168 Revisado funciona OK
   *
   * @param $event
   */
  @HostListener('document:click', ['$event'])
  clickOutside($event: any): void {
    this.isOpenClass = '';
    this.cdRef.detectChanges();
  }

  public dispatchEventOnTaskCompleted(operationResult: { operation: IVboOperation, result: { id: string, message: any[], responseData: any } | CORE_QUEUE }): void {
    this.listComponentService.emitOnVboOperationCompleted(operationResult);
  }

  /**
   * Shows the column configuration modal.
   */
  showColumnConfiguration(): void {
    this.modalRef = this.dmbs.showTemplate(this.columnsModal, {
      Title: this.translatorService.get('Añadir / quitar columnas'),
      ModalType: DtoFrontendModalType.Modal,
    });
  }

}
