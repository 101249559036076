import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigurationService } from '../../app.configuration.service';
import { MappingDefinitionNonGeneric } from '../../core/models/ETG_SABENTISpro_Application_Core_models';


@Injectable()
export class AppMappingService {

  constructor(
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService) {
  }

  /**
   * Generate new template
   * @param {T} value: value of the new context
   */
  ab2str(buf: any): any {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  GenerateTemplate(mappingConfiguration: MappingDefinitionNonGeneric): void {
    let params: HttpParams = new HttpParams();
    params = params.append('mappingDefinition', JSON.stringify(mappingConfiguration));

    this.http.get(`${this.appConfigurationService.getGeneralConfig().domain}core-mapping/generate-template`, {
      params,
      responseType: 'blob',
      observe: 'response'
    })
      .subscribe((res: any) => {
        this.GenerateTemplateCompleted(res);
      });
  }

  GenerateTemplateByMappingDefinition(id: string): void {
    let params: HttpParams = new HttpParams();
    params = params.append('mappingDefinitionId', JSON.stringify(id));

    this.http.get(`${this.appConfigurationService.getGeneralConfig().domain}core-mapping/generate-template-guid`, {
      params,
      responseType: 'blob',
      observe: 'response'
    })
      .subscribe((res: any) => {
        this.GenerateTemplateCompleted(res);
      });
  }

  private GenerateTemplateCompleted(res: any): void {
    const fileURL: string = URL.createObjectURL(res.body);
    // TODO: cambiar por inyeccion de Window
    window.open(fileURL, 'blank');
  }
}
