import { FormElementAction } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';

/**
 * Evento que se lanza al presionar un botón de formulario
 */
export class ButtonClickedEventData {
  Metadata: any[];
  Action: FormElementAction;
  Emitter: string;
  RequireClientValidations: boolean;
}
