<div (click)="onContainerClicked($event)" class="modal fade" tabindex="-1"
    [ngClass]="{'in': visibleAnimate}" [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}">
    <div class="c-overlay__content  c-popup">
        <div class="list-modal-header c-popup__title--2">
            <ng-content select=".list-modal-header"></ng-content>
        </div>
        <div class="c-popup-config u-mt--medium">
            <ng-content select=".c-popup-config .u-mt--medium"></ng-content>
        </div>
        <div class="list-modal-footer c-btns-group">
            <ng-content select=".list-modal-footer"></ng-content>
        </div>
    </div>
</div>
