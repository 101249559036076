import { Component, forwardRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import {
  FormElementViewsEmbed,
  ViewsPluginRequest,
  ViewUserConfiguration,
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import {
  getInSafe,
  isNullOrUndefined,
  isString,
  JsonClone,
  jsonEqual,
  JsonPathEvaluate
} from '../../../../../shared/utils/typescript.utils';
import {
  ViewsuserconfigchangedAction,
  ViewsuserconfigchangedEventdata
} from '../../../../list_v2/viewsuserconfigchanged.eventdata';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { ViewsFormComponent } from '../../../shared/view-form/views-form.component';
import { VboOperations, VboToggleEvent } from '../../../../list_v2/events/vboitemtoogle.eventdata';

/**
 * Component to embed a list in a form as input. This is the inner-control component
 * attached by the formControlName directive.
 */
@Component({
  selector: 'app-views-embed-inner',
  templateUrl: './views-embed-inner.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViewsEmbedInnerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ViewsEmbedInnerComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ViewsEmbedInnerComponent extends FrontendFormElementInput implements Validator, OnInit, OnDestroy {

  /**
   * The List2Component reference.
   */
  @ViewChild('listField', {static: true}) listField: ViewsFormComponent;

  /**
   * Internal value form the field.
   *
   * Must be an instance of ViewUserConfiguration.
   */
  protected valueValue: ViewUserConfiguration;

  /**
   * Llamamos a este subject cuando queremos recargar el listado
   */
  protected reloadListSubject = new Subject<void>();

  /**
   * This flag keeps a boolean indicating if the view for the current field
   * has been initialized (SI SE HAN ENVIADO VALORES EN EL WRITE VALUES)
   */
  protected viewHasAvailableLoadingArguments = false;

  /**
   * If the view has already been initialized (loaded) at least once
   *
   * @protected
   */
  protected viewInitialized = false;

  /**
   * Si tengo o no opciones disponibles
   */
  protected hasOptionsValue = true;

  /**
   *
   * @protected
   */
  protected visibleState = false;

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are
   * initialized.
   */
  ngOnInit(): void {

    this.visibleState = this.config.visible;

    this.formManagerService
        .elementConfigChanged
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.listField.componentDestroyed$),
            filter((clientPath: string) => clientPath === this.config.ClientPath)
        )
        .subscribe(this.OnComponentConfigChanged.bind(this));

    // Al cambiar la userconfiguration, actualizamos el value del componente
    this.listField
        .listComponentService
        .userConfigurationChanged
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.listField.componentDestroyed$)
        )
        .subscribe(this.userConfigurationChangedEventHandler.bind(this));

    this.listField
        .listComponentService
        .onSingleItemOperation
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.listField.componentDestroyed$)
        )
        .subscribe((i) => this.formManagerService
            .formEventComplex
            .emit({
              id: null,
              emiter: this.config.ClientId + '::onSingleItemOperation',
              metadata: i
            }));

    // Para evitar que llamadas sucesivas a WriteValue saturen la carga del listado,
    // hacemos un debounce de 200 milisegundos
    this.reloadListSubject
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.listField.componentDestroyed$),
            debounceTime(300)
        )
        .subscribe(this.reloadListHandler.bind(this));

    // Para dar soporte a HasOptions() debemos escuchar una vez al menos la carga inicial
    this.listField
        .listComponentService
        .viewDataLoaded
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.listField.componentDestroyed$),
        ).subscribe((i) => {

      // Por motivos de rendimiento, el cálculo de EmptyResultSet no se hace en cada request (ya que requiere hacer un count adicional
      // en cada petición...). Lo que hacemos es conservar el valor inicial del this.hasOptionsValue (que suele ser el más fiable). En backend
      // hay unos criterios específicos para lanzar el conteo del EmptyResultSet:
      //   bool calculateEmptyResultSet = this.Configuration.OnEmptyResultSetRender != null
      //   || this.Configuration.OnEmptyResultSetCommandsOnLoad?.Count > 0;
      // Lo que deberíamos hacer es añadir alguna manera "manual" de solicitarle al listado que haga este cálculo, y explotarla
      // aquí.

      // Si viene explícitamente a TRUE es que no hay resultados...
      if (i.ResultCount.EmptyResultSet) {
        this.hasOptionsValue = false;
      } else if (isNullOrUndefined(this.hasOptionsValue)) {
        this.hasOptionsValue = i.Results.length > 0;
      }

      // Se usa este valor oculto (this.config.ClientId + '_hasOptions') para que el backend pueda hacer una correcta evaluación
      // de la API de estados.
      this.groupValue.value[this.config.ClientId + '_hasOptions'] = this.hasOptionsValue;
      this.formManagerService.formStateApiManager.triggerElementChanged(this.config.ClientPath, this.value, this.value);
      this.formManagerService.getFormComponent(this.config.ClientPath).updateValueAndValidity();

      const userConfiguration: ViewUserConfiguration
          = JsonClone(this.listField.listComponentService.getUserConfiguration());
      if (this.valueValue != null) {
        this.valueValue = userConfiguration;
      }
      this.cdRef.detectChanges();
    });
  }

  /**
   * Solo queremos cargar el listado si el componente de formulario
   * está visible. Para ello nos colgamos del OnComponentConfigChanged.
   */
  OnComponentConfigChanged(): void {
    if (this.visibleState !== this.config.visible) {
      this.visibleState = this.config.visible;
      if (this.visibleState === true && this.viewHasAvailableLoadingArguments && !this.viewInitialized) {
        this.reloadListSubject.next();
      } else if (this.visibleState === true && (this.config.FormElement as FormElementViewsEmbed).DoNotRefreshOnVisibilityChange !== true && this.viewHasAvailableLoadingArguments) {
        this.reloadListSubject.next();
      }
    }
  }

  /**
   * This method is triggered on every change of the UserConfiguration done on
   * the embedded view.
   *
   * @param {ViewsuserconfigchangedEventdata} userConfEvent
   */
  userConfigurationChangedEventHandler(userConfEvent: ViewsuserconfigchangedEventdata): void {

    const userConfiguration: ViewUserConfiguration
        = JsonClone(getInSafe(userConfEvent, u => u.userConfiguration, false));

    // Guardamos la user configuration completa como valor del campo
    // para que en los rebuild podamos mantener el estado del listado
    if (JSON.stringify(this.valueValue) === JSON.stringify(userConfiguration)) {
      return;
    }

    // This "assign" must be done throught a clone. If we pass directly the
    // userConfiguration then a "reference" is passed to the this.value property.
    this.value = userConfiguration;

    this.propagateChange(this.valueValue as any);
    this.propagateTouch();

    // TODO No se porque necesito este detect changes aquí!!!
    this.formManagerService.formChangeDetector.detectChanges();
  }

  /**
   * Handler for a value changed event
   */
  reloadListHandler(): void {
    if (this.visibleState === false) {
      return;
    }
    this.hasOptionsValue = null;
    this.viewInitialized = true;
    const eventData: ViewsuserconfigchangedEventdata = new ViewsuserconfigchangedEventdata(this.value);

    if (this.valueValue === null) {
      eventData.refreshAction = ViewsuserconfigchangedAction.DropCurrentUserAndLoad;
    }
    this.listField.disableIntersectionObserverApi = this.config.DisableIntersectionObserverApi;
    this.listField.loadListFromPluginRequest(this.pluginRequest, true, eventData);
  }

  /**
   * Returns the view ID
   * @returns {string}
   */
  get pluginRequest(): ViewsPluginRequest {
    const config: FormElementViewsEmbed = this.config.FormElement as FormElementViewsEmbed;
    const pluginRequest: ViewsPluginRequest = new ViewsPluginRequest();
    pluginRequest.Id = config.ViewsId;

    pluginRequest.Arguments = config.ViewsArguments || {} as any;
    if (!isNullOrUndefined(config.ViewsArguments)) {
      pluginRequest.Arguments = JsonClone(config.ViewsArguments);
    }

    const formValues: object = this.formManagerService.getFormRawValues();

    // Permitimos añadir valores de argumentos como expresion JPath para
    // poder acceder a los valores de formulario en tiempo real
    if (pluginRequest) {
      for (const key of Object.keys(pluginRequest.Arguments)) {
        if (pluginRequest.Arguments.hasOwnProperty(key)) {
          const value: any = pluginRequest.Arguments[key];
          if (isString(value) && (value as string)[0] === '$') {
            const queryResult: string[] = JsonPathEvaluate(value as string, formValues);
            if (queryResult && queryResult.length) {
              pluginRequest.Arguments[key] = queryResult[0];
            }
          }
        }
      }
    }

    // Lo pasamos todo por si las moscas
    pluginRequest.Arguments['formValues'] = formValues;
    pluginRequest.Parameters = config.ViewsParameters;
    return pluginRequest;
  }

  /** @inheritdoc */
  writeValue(obj: any): void {

    // Cuidado porque siempre viene un NULL y después el VALUES como dios manda
    const bothEmpty: boolean = isNullOrUndefined(this.value) && isNullOrUndefined(obj);
    if (this.viewHasAvailableLoadingArguments && (bothEmpty || jsonEqual(this.value, obj))) {
      return;
    }

    if (this.viewHasAvailableLoadingArguments && obj) {
      this.listField.reloadList();
    }

    // Si ponen un null como valor (por ejemplo lo hace la API de estados) debería borrar los contenidos de la selección...
    // ya que este campo es especial.
    // En realidad necesitamos una abstracción alrededor del proceso de "ClearValue" y que cada componente haga lo que tiene que hacer.
    if (isNullOrUndefined(obj) && this.viewHasAvailableLoadingArguments && this.listField.listComponentService.vboUserConfiguration) {
      const event: VboToggleEvent = new VboToggleEvent();
      event.operation = VboOperations.CLEAR;
      this.listField.listComponentService.vboToggleItemHandler(event);
    } else {
      this.viewHasAvailableLoadingArguments = true;
      this.valueValue = obj as ViewUserConfiguration;
      this.cdRef.detectChanges();
    }
    this.reloadListSubject.next();
  }

  /** @inheritdoc */
  emptyValue(value: any): boolean {
    const userConfig: ViewUserConfiguration = value as ViewUserConfiguration;
    return !this.listField.listComponentService.hasAnyVboSelectedFromConfig(userConfig);
  }

  /** @inheritdoc */
  hasOptions(): boolean {
    return this.hasOptionsValue;
  }

  set value(value: ViewUserConfiguration) {
    this.valueValue = value;
    this.propagateChange(this.valueValue, true);
  }

  get value(): ViewUserConfiguration {
    return this.valueValue;
  }
}
