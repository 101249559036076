import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * ACHSPRIME-2168 Revisado funciona OK
 */
@Directive({
  selector: '[appFileSelect]'
})
export class FileSelectDirective {
  @Input() acceptExtensions: string;
  @Output() fileOutput: EventEmitter<FileList> = new EventEmitter<FileList>();

  protected element: ElementRef;

  constructor(private elementRef: ElementRef) {
    this.element = elementRef;
  }

  /**
   * ACHSPRIME-2168 Revisado funciona OK
   */
  @HostListener('change')
  public onChange(): any {
    const files: any = this.element.nativeElement.files;
    this.fileOutput.emit(files);
    this.element.nativeElement.checkedValue = '';
    this.element.nativeElement.value = '';
  }
}
