/**
 * Translation model
 */
export class Translation {
  key: string;
  literalTranslated: string;
  targetLanguage: string;

  constructor(
    key: string,
    targetLanguage: string,
    literalTranslated?: string,
  ) {
    this.targetLanguage = targetLanguage;

    if (literalTranslated) {
      this.literalTranslated = literalTranslated;
    }

    this.key = key;
  }
}
