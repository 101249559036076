<ng-template #loading>
  Cargando
</ng-template>
<div *ngIf="viewMap; else loading">
  <google-map
      height="450px"
      width="100%"
      [center]="initialCenter">
    <map-marker
        #markerComponent="mapMarker"
        *ngFor="let marker of markers"
        [position]="marker.position"
        (mapClick)="openInfoWindow(markerComponent)">
      <map-info-window>{{marker.title}}</map-info-window>
    </map-marker>
  </google-map>

</div>

