import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UtilsString } from '../utils-string';

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(html: string): SafeHtml {
    if (UtilsString.IsNullOrWhitespace(html)) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
