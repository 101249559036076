import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicRadarChartComponent } from './components/basic-radar-chart.component';

@NgModule({
    declarations: [
        BasicRadarChartComponent
    ],
    imports: [
        CommonModule
    ]
})
export class RadarChartModule { }
