/**
 * En GA4 la longitud máxima para un evento es de 40 carácteres, por lo que hay
 * que compactar al máximo los ID's de listado y formulario, sino luego en el
 * panel del GA4 no se distingue nada.
 *
 * Este método está implementado en base a analizar ejemplos de id's y realmente
 * no responde a nada formal, por esto está como está...
 * @param elementId
 */
export function compactElementId(elementId: string, maxLength: number): string {
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  let currentLength: number = elementId.length + 1;
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/^plugin-/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/-plugin$/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/^chart-/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/-chart$/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/^form-/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/-form$/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/^view-/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/-view$/, '');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/-view-/, '-');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/-form-/, '-');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos palabras comunes...
  elementId = elementId.replace(/-plugin-/, '-');
  if (elementId.length <= maxLength) {
    return elementId.substring(0, maxLength);
  }
  // Reemplazamos dash
  while (currentLength > elementId.length) {
    currentLength = elementId.length;
    elementId = elementId.replace(/-/, '');
    if (elementId.length <= maxLength) {
      return elementId.substring(0, maxLength);
    }
  }
  currentLength = elementId.length + 1;
  // Reemplazamos underscore
  while (currentLength > elementId.length) {
    currentLength = elementId.length;
    elementId = elementId.replace(/_/, '');
    if (elementId.length <= maxLength) {
      return elementId.substring(0, maxLength);
    }
  }
  return elementId.substring(0, maxLength);
}
