<app-comment [value]="'TO TRANSLATE THIS CHART, USE THE FOLLOWING CONTEXT: be:chart:' + chartId"></app-comment>
<fuse-widget
    appObserveElement
    #intersection="intersection"
    [threshold]="0"
    (isIntersecting)="isIntersecting($event)"
    class="widget auto-width"
    fxLayout="column"
    fxFlex="100"
    *ngIf="!hidden"
    #widget>
  <!-- Front -->
  <div class="fuse-widget-front" fxLayout="column" *ngIf="!hasError">
    <div
        class="w-100-p p-16 h-52 border-bottom widget--title"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngIf="initialized">
      <div style="display: inline-grid">
        <span class="text-truncate">{{chartInfo?.title}}</span>
        <span class="h5 secondary-text text-truncate">{{chartInfo?.description}}</span>
      </div>
      <div
          style="min-width: 70px"
          fxLayout="row"
          fxLayoutAlign="space-between end"
          *ngIf="initialized">
        <button mat-icon-button
                [title]="'Recargar' | t"
                aria-label="Recargar"
                (click)="reload()">
          <mat-icon>refresh</mat-icon>
        </button>
        <!--<button mat-icon-button
                *ngIf="currentExecution?.UserConfiguration?.FiltersFormState !== null && currentExecution?.UserConfiguration?.FiltersFormState != undefined"
                class="fuse-widget-flip-button"
                aria-label="more"
                (click)="toggle()">
          <mat-icon>more_vert</mat-icon>
        </button>-->
        <button *ngIf="chartInfo?.chartIsExportableAsImage"
                [id]="'download_button_' + chartId"
                class="download_button"
                mat-icon-button
                [title]="'Descargar imagen' | t"
                aria-label="Descargar imagen"
                (click)="downloadImage()">
          <mat-icon>cloud_download</mat-icon>
        </button>
        <button *ngIf="chartInfo?.chartIsExportableAsExcel"
                [id]="'download_button_excel_' + chartId"
                mat-icon-button
                [title]="'Exportar a hoja de cálculo' | t"
                aria-label="Exportar a hoja de cálculo"
                (click)="downloadExcel()">
          <mat-icon>table_chart</mat-icon>
        </button>
        <button *ngIf="chartInfo?.chartIsExportableAsHtmlTable"
                [id]="'download_button_html_table_' + chartId"
                mat-icon-button
                [title]="'Ver gráfica en formato tabla' | t"
                aria-label="Ver gráfica en formato tabla"
                (click)="exportHtmlTable()">
          <mat-icon>table_view</mat-icon>
        </button>
        <button
            id="filter_button"
            *ngIf="chartInfo?.hasFilters"
            [title]="'Filtros' | t"
            mat-icon-button
            fuseWidgetToggle
            (click)="manageFiltersForm()">
          <mat-icon>search</mat-icon>
        </button>
      </div>

    </div>

    <div class="w-100-p"
         fxFlex="1 0 auto"
         fxLayout="column"
    >
      <div class="w-100-p">
        <div class="widget--progress-container py-20 px-60"
             *ngIf="initialized === false">
          <app-chart-loading-placeholder></app-chart-loading-placeholder>
        </div>

        <div *ngIf="!isEmpty">
          <div
              style="min-width: 60px"
              fxLayout="column"
              fxLayoutAlign="space-between start">
            <app-chart-mode-selector></app-chart-mode-selector>
          </div>
          <div *ngIf="!initialized || chartInfo?.hasFilters" [@slideInOutWithFade]="showFilters">
            <app-chart-form [@slideInOutWithFadeDisplay]="showFilters"></app-chart-form>
          </div>
          <div *ngIf="!initialized || chartInfo?.chartIsExportableAsHtmlTable" [@slideInOutWithFade]="showHtmlTable">
            <div style="margin: 15px;" [innerHTML]="htmlTable | safeHtml"></div>
          </div>
          <div style="width:100%" fxLayoutAlign="center center">
            <ng-container style="width:100%" appFuseChart>
            </ng-container>
          </div>
        </div>
        <div *ngIf="isEmpty" fxLayout="column" fxLayoutAlign="center center"
             class="widget--isEmpty">
          <h3 class="secondary-text">{{ 'No hay datos para mostrar' | t }}</h3>
        </div>
      </div>
    </div>

    <div class="bottom-actions-content w-100-p px-8 py-12" fxLayout="row" fxLayoutAlign="start center"
         *ngIf="initialized && checkExistsActions('bottom')">
      <ng-container>
        <ng-container *ngFor="let item of getActions('bottom'); index as i">
          <button
              mat-button
              color="accent"
              (click)="executeAction(item)">
            {{item.Title}}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="fuse-widget-front"  fxLayout="column" *ngIf="hasError">
    <div class="warning_chart-icon">
      <img src="/assets/layout-theming/prime-fuse-layout/images/icons/warning_sign.svg" width="50px" height="50px"/>
      <span>La gráfica no está disponible en estos momentos.</span>
    </div>
  </div>
  <!-- Back -->
  <ng-container>
    <div class="fuse-widget-back">
      <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
              aria-label="Flip widget" (click)="toggle()">
        <mat-icon class="s-16">close</mat-icon>
      </button>
      <div
          class="w-100-p p-16 h-52 border-bottom widget--title"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngIf="initialized">
        <div fxLayout="column" class="text-truncate">
          <span class="text-truncate">{{ 'Filtros' | t }}</span>
          <span class="h5 secondary-text text-truncate">{{ 'Filtre los datos' | t }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</fuse-widget>
