/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormPluginRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormSubmitData } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementInputAsyncValueCallbackRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementInputAsyncValueCallbackResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementAutocompleteRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementAutocompleteCallbackResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementAutocompleteNewCallbackResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementSelectDynamicRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementListSelectorResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementValidationResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementFormatItemsRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementFormatItemsRequestResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementRunCustomCallbackHandlerRequest } from '../models/ETG_SABENTISpro_Application_Core_models';
import { FormElementRunCustomCallbackRequestResult } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class FormService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Get the complete form configuration
  *
  * @param {FormPluginRequest} pluginRequest
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string} Formstate with the requested form
  */
  postConfiguration(pluginRequest: FormPluginRequest, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    const params: any = {'pluginRequest' : JSON.stringify(pluginRequest), 'formId' : formId};
    return this.generalService.post(`core-form/configuration/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }

  /**
  *
  * Callback de valor asíncrono de campo
  *
  * @param {string} signedFormState
  * @param {FormSubmitData} formData
  * @param {FormElementInputAsyncValueCallbackRequest} callbackRequest
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementInputAsyncValueCallbackResult}
  */
  postElementasyncvaluecallback(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementInputAsyncValueCallbackRequest, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementInputAsyncValueCallbackResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest), 'formId' : formId};
    return this.generalService.post(`core-form/element-async-value-callback/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<FormElementInputAsyncValueCallbackResult>>;
  }

  /**
  *
  * Callback for autocomplete fields
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {FormElementAutocompleteRequest} callbackRequest The request
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementAutocompleteCallbackResult} List with autocomplete options
  */
  postFieldautocompletecallback(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementAutocompleteRequest, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementAutocompleteCallbackResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest), 'formId' : formId};
    return this.generalService.post(`core-form/field-autocomplete-callback/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<FormElementAutocompleteCallbackResult>>;
  }

  /**
  * @param {string} signedFormState
  * @param {FormSubmitData} formData
  * @param {FormElementAutocompleteRequest} callbackRequest
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementAutocompleteNewCallbackResult}
  */
  postFieldautocompletecallbacknew(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementAutocompleteRequest, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementAutocompleteNewCallbackResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest), 'formId' : formId};
    return this.generalService.post(`core-form/field-autocomplete-callbacknew/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<FormElementAutocompleteNewCallbackResult>>;
  }

  /**
  * @param {string} signedFormState
  * @param {FormSubmitData} formData
  * @param {FormElementSelectDynamicRequest} callbackRequest
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementListSelectorResult}
  */
  postFieldselectdynamyccallbacknew(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementSelectDynamicRequest, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementListSelectorResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest), 'formId' : formId};
    return this.generalService.post(`core-form/field-selectdynamyc-callbacknew/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<FormElementListSelectorResult>>;
  }

  /**
  *
  * Run validation handlers for a field
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {string} fieldSelector Field selector
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementValidationResult} Valid FormElement object
  */
  postFieldvalidate(signedFormState: string, formData: FormSubmitData, fieldSelector: string, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementValidationResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'fieldSelector' : fieldSelector, 'formId' : formId};
    return this.generalService.post(`core-form/field-validate/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<FormElementValidationResult>>;
  }

  /**
  *
  * Callback for autocomplete fields
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {FormElementFormatItemsRequest} callbackRequest The request
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementFormatItemsRequestResult} List with autocomplete options
  */
  postFormformatitems(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementFormatItemsRequest, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementFormatItemsRequestResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest), 'formId' : formId};
    return this.generalService.post(`core-form/form-format-items/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<FormElementFormatItemsRequestResult>>;
  }

  /**
  *
  * Callback for autocomplete fields
  *
  * @param {string} signedFormState The form state. For security reasons this is stored in the client
  * @param {FormSubmitData} formData User input
  * @param {FormElementRunCustomCallbackHandlerRequest} callbackRequest The request
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {FormElementRunCustomCallbackRequestResult} List with autocomplete options
  */
  postFormruncustomcallback(signedFormState: string, formData: FormSubmitData, callbackRequest: FormElementRunCustomCallbackHandlerRequest, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<FormElementRunCustomCallbackRequestResult>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'callbackRequest' : JSON.stringify(callbackRequest), 'formId' : formId};
    return this.generalService.post(`core-form/form-run-custom-callback/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<FormElementRunCustomCallbackRequestResult>>;
  }

  /**
  *
  * Submit a form
  *
  * @param {string} signedFormState
  * @param {FormSubmitData} formData All user input
  * @param {string} formId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {string} FormState object
  */
  postSubmit(signedFormState: string, formData: FormSubmitData, formId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<string>>
  {
    const params: any = {'signedFormState' : signedFormState, 'formData' : JSON.stringify(formData), 'formId' : formId};
    return this.generalService.post(`core-form/submit/${formId}`, params, clientOpts) as Observable<WebServiceResponseTyped<string>>;
  }
}
