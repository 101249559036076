import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslatorService } from '../services/rest-translator.service';
import { DestroyableObjectTrait } from '../../../shared/utils/destroyableobject.trait';

@Pipe({name: 't', pure: false})
export class TranslatorServicePipe extends DestroyableObjectTrait implements PipeTransform, OnDestroy {

  constructor(private translationService: TranslatorService) {
    super();
  }

  /**
   * Transform que realiza traducción y reemplazo de parametros en pipe.
   *
   * <h3>{{ 'El se llama %nombre% y tiene @edad años.' | t:{'%nombre%':'Juan','@edad':15} }}</h3>
   *
   * @param literal
   * @param params
   * @returns {string}
   */
  transform(literal: string, params: { [id: string]: any } = {}, context: any = null): string {
    return this.translationService.get(literal, params, context);
  }
}
