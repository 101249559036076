import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';

import { AuthService } from '../../core/authentication/auth.service';
import { WebServiceResponseTyped } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { IPersonContext } from '../context/interfaces/person-context.interface';
import { ClientCache } from '../../core/clientcache/ClientCacheClass';
import { SessionService } from '../../core/services/ETG_SABENTISpro_Application_Core_session.service';

/**
 * Permite consultar en frontend los permisos asignados a la persona con la que el usuario
 * está actualmente autenticado. Notar que los permisos de frontend no tienen implicaciones
 * de seguridad alguna, y que únicamnete son para pequeños ajustes de visibilidad/flujos de frontend
 * ya que la aplicación efectiva de permisos se realiza siempre en backend.
 */
function getPermissionsEncrypt(permissionsArray: string[]): string[] {
  return permissionsArray.map(p => Md5.hashStr(p).toString().toUpperCase());
}

export enum GenericPermissionLogicEnum {
  AND = 'and',
  OR = 'or'
}

@Injectable({
  providedIn: 'root',
})
export class GenericPermissionService extends DestroyableObjectTrait implements OnDestroy {

  /**
   * Emits an event when the array of user permissions has been updated (or even cleared if the user has logged out)
   */
  private permissionsUpdatedSubject: Observable<void>;

  /**
   * Array of current user permissions.
   */
  private permissions: BehaviorSubject<string[]>;

  /**
   * Class constructor.
   */
  constructor(
    private sessionService: SessionService,
    private authService: AuthService,
    private clientCache: ClientCache) {

    super();

    // Refrescar los permisos si hay un cambio en la persona seleccioanda
    this.authService
      .$selectedPerson
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((person: IPersonContext) => {
        // Si quitamos la empresa (por ejemplo al cerrar sesión)
        // quitar todos los permisos
        if (!person || !person.id) {
          this.permissions.next([]);
          return;
        }
        this.getAllPermissionsByPersonId(person.id);
      });

    // Inicializar sin permisos
    this.permissions = new BehaviorSubject<string[]>([]);

    this.permissionsUpdatedSubject = this.permissions
      .pipe(
        distinctUntilChanged(),
        map(() => null)
      );
  }

  /**
   * Returns an observable that emits when the array of user permissions has been updated.
   */
  permissionsUpdated(): Observable<void> {
    return this.permissionsUpdatedSubject;
  }

  /**
   * Valid if the person have the permissions or permission.
   */
  validPermission(permissions: string[], logic: GenericPermissionLogicEnum = GenericPermissionLogicEnum.AND): boolean {
    const listPermissions: string[] = this.permissions.getValue();
    if (logic === GenericPermissionLogicEnum.OR) {
      return getPermissionsEncrypt(permissions).some(p => listPermissions.includes(p));
    }
    return getPermissionsEncrypt(permissions).every(p => listPermissions.includes(p));
  }

  /**
   * Como validPermission pero se puede usar directamente como un observable
   *
   * @param permissions
   * @param logic
   */
  validPermissionObservable(permissions: string[], logic: GenericPermissionLogicEnum = GenericPermissionLogicEnum.AND): Observable<boolean> {
    return this.permissions
      .pipe(
        distinctUntilChanged(),
        map((listPermissions) => {
          if (logic === GenericPermissionLogicEnum.OR) {
            return getPermissionsEncrypt(permissions).some(p => listPermissions.includes(p));
          }
          return getPermissionsEncrypt(permissions).every(p => listPermissions.includes(p));
        })
      );
  }

  /**
   * Get the all permissions for a person and store them in the service
   */
  private getAllPermissionsByPersonId(personId: string): void {
    const cacheKey: string = 'generic-permission-service::getAllPermissionsByPersonId::' + personId;
    const storedPermissions: string[] = this.clientCache.getItem(cacheKey, null);
    if (storedPermissions != null) {
      console.debug('generic-permissions.service.ts: Permission matrix acquired from local storage.');
      this.permissions = new BehaviorSubject<string[]>([]);
      this.permissions.next(storedPermissions);
      return;
    }

    this.sessionService
      .getPermissionsbypersonid(personId, {showSpinner: false})
      .pipe(
        take(1)
      )
      .subscribe((response: WebServiceResponseTyped<string[]>) => {
        console.debug('generic-permissions.service.ts: Permission matrix acquired from server.');
        const permissions: string[] = response.result;
        // Para que se tomen en cuenta cambios de permisos de backend (Agregar o quitar grupos a la persona, o modificar
        // la configuración de permisos de un grupo) hay que hacer refresh del navegador ya que
        // esta cache está a nivel de browser
        this.clientCache.setItem(cacheKey, permissions, 'browser');
        this.permissions.next(permissions);
      });
  }
}
