import { Directive, EventEmitter, Injector, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormManagerComponent } from '../../../../../../../shared/form/form-manager/form-manager.component';
import { CustomComponentFactoryService } from '../../../../../../../shared/custom-component-factory/custom-component-factory.service';
import { takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../../../../../../../shared/utils/destroyableobject.trait';
import { FormSubmitData, IFormState } from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { Formstatecontainer } from '../../../../../../../shared/form/formstatecontainer.class';

@Directive({
  selector: '[appFormChartGenerator]'
})
export class FormChartGeneratorDirective extends DestroyableObjectTrait implements OnInit {

  /**
   * Component to render.
   */
  form: FormManagerComponent;

  @Input() formState: Formstatecontainer;

  @Input() formPlugin: string;

  @Input() params: {};

  @Output() saveSucceed: EventEmitter<{ id: string, message: any[], responseData: any }> = new EventEmitter<{ id: string, message: any[], responseData: any }>();

  @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(protected container: ViewContainerRef,
              protected customComponentFactory: CustomComponentFactoryService,
              protected injector: Injector) {
    super();
  }

  ngOnInit(): void {
    if (this.formState) {
      this.form = this.customComponentFactory.createComponent('Form', this.container, this.injector, {formState: this.formState}).instance as FormManagerComponent;
    } else if (this.formPlugin) {
      this.form = this.customComponentFactory.createComponent('Form', this.container, this.injector, {
        formPlugin: this.formPlugin,
        params: this.params
      }).instance as FormManagerComponent;
    }

    if (this.form) {
      this.form.saveSucceed.pipe(takeUntil(this.componentDestroyed$))
        .subscribe((x: any) => this.saveSucceed.emit(x));
      this.form.canceled.pipe(takeUntil(this.componentDestroyed$))
        .subscribe((x: any) => this.canceled.emit(x));
    }
  }

  getFormSubmitDataForSubmit(): FormSubmitData {
    return this.form.createFormSubmitDataFromViews(this.formState.formState);
  }

  resetForm(): void {
    this.form.resetForm();
  }
}
