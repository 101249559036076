import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';

/**
 * Injection the help service.
 */
@Injectable()
export class HelpService {
  private modalHelper: Observable<boolean>;
  private modalTrigger: Observer<boolean>;

  /**
   * The constructor.
   */
  constructor() {
    this.modalHelper = Observable.create(obs => {
      this.modalTrigger = obs;
    });
  }

  /**
   * Call modalTrigger to open help form.
   */
  public openModal(): void {
    this.modalTrigger.next(true);
  }

  /**
   * Call modalTrigger to close help form.
   */
  public closeModal(): void {
    this.modalTrigger.next(false);
  }

  /**
   * Return the value about modalHelper observer.
   */
  public getObservable(): Observable<boolean> {
    return this.modalHelper;
  }
}
