import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  PredefinedCorrectiveActionDetailsComponent,
} from './predefined-corrective-action-details/predefined-corrective-action-details.component';
import { PredefinedCorrectiveActionComponent } from './predefined-corrective-action.component';
import { PredefinedCorrectiveActionDeleteComponent } from './predefined-corrective-action-delete/predefined-corrective-action-delete.component';

const routes: Routes = [
  {
    path: 'predefined',
    data: { breadcrumb: 'parametrics.predefined' },
    children: [
      { path: '', component: PredefinedCorrectiveActionComponent, pathMatch: 'full', data: { mapping: 'parametrics.predefined' } },
      { path: 'add', component: PredefinedCorrectiveActionDetailsComponent, data: { mapping: 'parametrics.predefined.add' } },
      {
        path: ':predefined', children: [
          { path: 'details', component: PredefinedCorrectiveActionDetailsComponent, data: { mapping: 'parametrics.predefined.details' } },
          {
            path: 'details',
            children: [
              { path: 'delete', component: PredefinedCorrectiveActionDeleteComponent, data: { mapping: 'parametrics.predefined.details.delete'}},
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PredefinedRoutingModule { }
