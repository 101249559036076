<div class="timeline-wrapper">
  <div class="timeline-item">
    <div class="animated-background">
      <div class="image">
        <mat-icon>query_stats</mat-icon>
      </div>

      <div class="background-masker content-top"></div>
      <div class="background-masker content-second-line"></div>
      <div class="background-masker content-third-line"></div>
    </div>
  </div>
</div>
