<div [formGroup]="group">
  <app-formelementinputlabel [config]="config" [group]="group"></app-formelementinputlabel>
  <app-formelementinputdescription [config]="config" [group]="group"></app-formelementinputdescription>
  <app-formelementinput-validationmessages [config]="config" [group]="group"></app-formelementinput-validationmessages>
  <!--Usamos un fieldset para poder habilitar/deshabiltiar el listado-->
  <fieldset [disabled]="!config.editable">
    <app-views-embed-inner [formControlName]="config?.name" [config]="config" [group]="group" #formInput>
    </app-views-embed-inner>
  </fieldset>
</div>
