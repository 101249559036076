import { Component, OnInit } from '@angular/core';
import { FrontendFormElementAware } from '../formelement.class';

@Component({
  selector: 'app-formelementinputdescription',
  templateUrl: './formelementinputdescription.component.html'
})
export class FormelementinputdescriptionComponent extends FrontendFormElementAware implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }
}
