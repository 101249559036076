import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { isEmpty, isNullOrUndefined, UtilsTypescript } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { getRemainingChars, handleMaxLengthInput, lengthValidationClasses } from '../../shared-functions';
import { MaxLengthValidationInterface } from '../../shared-interfaces';
import {
  FormElementInput,
  FormElementType,
  FormValidationTypeEnum
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';


/**
 * Input generico para añadir tipos básicos de input.
 * @see https://developer.mozilla.org/es/docs/Web/HTML/Elemento/input
 *
 * Actualmente soporta (components.constant.ts):
 * - FormElementType.TextInput
 * - FormElementType.Email
 * - FormElementType.Password
 */
@Component({
  selector: 'app-forminput',
  templateUrl: './input.component.html',
  // Add custom accesors for validation and form-value-access.
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent
  extends FrontendFormElementInput
  implements AfterViewInit, Validator, MaxLengthValidationInterface {

  @ViewChild('inputElement') input: ElementRef;

  /**
   * Internal value form the field.
   */
  protected value: string;

  /**
   * Confirm match with another field
   */
  protected confirmField: string;

  /**
   * The title of the first input element to compare
   */
  protected baseElementTitle: string;

  /**
   * The title of the other input element to compare with
   */
  protected compareElementTitle: string;

  /**
   * Timer to delay enter key event
   */
  protected timer: any;

  /**
   * Class constructor,
   * @param {FormManagerService} formManagerService
   * @param {ChangeDetectorRef} cdRef
   */
  constructor(
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    protected localeService: TranslatorService
  ) {
    super(formManagerService, cdRef, localeService);
  }

  /**
   * Lifecycle hook that is called after a component's view has been fully
   * initialized.
   */
  ngAfterViewInit(): void {

    super.ngAfterViewInit();

    const element: FormElementInput = Object.assign(new FormElementInput(), this.config.FormElement);

    if (!isNullOrUndefined(element.ConfirmField)) {

      this.compareElementTitle = element.Title;
      this.confirmField = element.ConfirmField;
      this.baseElementTitle = this.formManagerService.getFormComponentInputInstance(this.confirmField).config.label;
      const confirmValueComponent: AbstractControl = this.formManagerService.getFormComponent(this.confirmField);

      confirmValueComponent
        .valueChanges
        .pipe(
          takeUntil(this.componentDestroyed$)
        )
        .subscribe((value: string): void => {
          this.group.get(this.config.name)
            .updateValueAndValidity({emitEvent: true, onlySelf: false});
        });
    }
  }

  /**
   *
   */
  blur(): void {
    this.propagateTouch();
  }

  /**
   * Getter for the component value.
   *
   * @returns {string}
   */
  get inputValue(): string {
    return this.value;
  }

  /**
   * Setter for the component value.
   */
  set inputValue(value: string) {
    this.value = this.normalizeValue(value);
    this.propagateChange(this.value, true);
  }

  /**
   * Writes a new value to the element.
   *
   * This method will be called by the forms API to write to the
   * view when programmatic (model -> view) changes are requested.
   */
  writeValue(obj: string): void {
    this.value = this.normalizeValue(obj);
    this.cdRef.detectChanges();
  }

  /**
   * Returns a boolean indicating if the user input must be "denied"
   * once the input length count equals the max length.
   */
  handleMaxLengthInput(): boolean {
    if (handleMaxLengthInput(this.config, this.value)) {
      return true;
    }

    return false;
  }

  /**
   * `Keydown` event handler.
   * @param {KeyboardEvent} event
   */
  onKeydown(e: KeyboardEvent): void {
    // This method handlers the `ENTER` key press for submissions.
    this.enterKeySubmitHandler(e);
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    if (this.input && this.input.nativeElement) {
      this.input.nativeElement.focus();
      return true;
    }
    return false;
  }

  /**
   * This method handlers the `ENTER` key press for submissions with 200 ms of delay.
   * @param {KeyboardEvent} event
   */
  protected enterKeySubmitHandler(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      return;
    }

    this.timer = setTimeout(() => {
      this.doSubmit()
    }, 200);
  }

  /**
   * This method handlers the `ENTER` key press for submissions.
   * @param {KeyboardEvent} event
   */
  protected doSubmit(): void {
    // Disable state propagation logic when the event was triggered on
    // an empty field.
    const waitForStatePropagation: boolean
      = !(isNullOrUndefined(this.value) || isEmpty(this.value));

    this.formManagerService.implicitSubmit(waitForStatePropagation);
    clearTimeout(this.timer);
  }

  /**
   * Ensure that whitespaces are trated as null values
   *
   * @param value
   */
  protected normalizeValue(value: string): string {
    if (UtilsTypescript.isNullOrWhitespace(value)) {
      return null;
    }
    return value;
  }

  /**
   * Devuelve el tipo específico de input, ya que se reutiliza el mismo
   * componente para varios tipos de entrada
   *
   * @returns {string}
   */
  getInputType(): string {
    switch (this.config.type) {
      case FormElementType.Email:
        return 'email';
      case FormElementType.Password:
        return 'password';
      case FormElementType.TextInput:
        return 'text';
    }
  }

  /**
   * Method that performs synchronous validation against the provided control.
   *
   * @param control The control to validate against.
   *
   * @returns {ValidationErrors} A map of validation errors if validation fails,
   * otherwise null.
   *
   * @see https://angular.io/api/forms/Validator#validate
   */
  doValidate(c: AbstractControl): ValidationErrors {

    const errors: ValidationErrors = super.doValidate(c);

    // Validación de campo de confirmación
    if (!isNullOrUndefined(this.confirmField)) {
      if (c.touched === true) {
        const value: string = this.formManagerService
          .getFormComponentValue(this.confirmField);

        if (!this.equalValues(value, this.inputValue)) {
          if (!isNullOrUndefined(this.baseElementTitle) && !isNullOrUndefined(this.compareElementTitle)) {
            errors[FormValidationTypeEnum.ConfirmField] = 'Los campos ' + this.baseElementTitle + ' y ' + this.compareElementTitle + ' no coinciden';
          } else {
            errors[FormValidationTypeEnum.ConfirmField] = 'Los campos no coinciden';
          }
        }
      }
    }

    return errors;
  }

  /**
   * @inheritdoc
   */
  lengthValidationClasses(): Record<string, boolean> {
    return lengthValidationClasses(this.value, this);
  }


  /**
   * @inheritdoc
   */
  getRemainingChars(): string {
    return getRemainingChars(this.value, this);
  }
}
