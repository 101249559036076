import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FuseSharedModule } from '../../../../../../../../../@fuse/shared.module';
import { CustomDispersionChartComponent } from './components-custom/custom-dispersion-chart.component';

@NgModule({
    declarations: [
        CustomDispersionChartComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule
    ]
})
export class CustomDispersionChartModule { }
