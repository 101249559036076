<div id="flex-row-1" class="flex-col-sm-12" style="padding-left: 20px">
    <h2>{{ 'Método INSHT' | t }}</h2>
  <div class="text-container">
    <label class="label-fine"> {{ 'Método del Instituto Nacional de Seguridad e Higiene en el Trabajo de España.' | t }} </label>
  </div>
  <div class="text-container">
    <label class="label-fine"> {{ 'Parte de una clasificación de las actividades laborales, desarrollando a posteriori toda la información necesaria
      relacionada con cada actividad. Partiendo de esta base, se procede despúes a analizar las variables, identificando
      los peligros, estimando los riesgos y finalmente valorándolos, para determinar si son o no son tolerables.' | t }}
    </label>
  </div>
  <div class="text-container">
    <label class="label-fine"> {{ 'Los dos factores que considera son la Probabilidad y la Consecuencia, la cual genera un Nivel de Riesgo.'| t }} </label>
  </div>
</div>
<div id="wrapper">
  <div id="first">
    <table id="riskTable" class="tableN">
      <tbody>
        <tr>
          <th class="widthColumnName"> {{ 'NIVEL DE RIESGO'| t }} </th>
          <th class="widthColumnDes"> {{ 'SIGNIFICADO'| t }} </th>
        </tr>
        <tr>
          <td>
            <div class="methodologyinsht methodologyinsht_0--legend"> {{ 'Intolerable - IN'| t }} </div>
          </td>
          <td>
            <p>{{ 'No debe comenzar ni continuar el trabajo hasta que se reduzca el riesgo. Si no es posible reducir el riesgo,
              incluso con recursos ilimitados, debe prohibirse el trabajo.'| t }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <div class="methodologyinsht methodologyinsht_1--legend"> {{ 'Importante - IM'| t }} </div>
          </td>
          <td>
            <p> {{ 'No debe comenzar ni continuar el trabajo hasta que se reduzca el riesgo. Puede que se precisen recursos considerables
              para controlar el riesgo. Cuando el riesgo corresponda a un trabajo que se está realizando, debe remediarse
              el problema en un tiempo inferior al de los riesgos moderados.'| t }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <div class="methodologyinsht methodologyinsht_2--legend">{{ 'Moderado - MO'| t }}</div>
          </td>
          <td>
            <p>{{ 'Se deben hacer esfuerzos para reducir el riesgo, determinando las inversiones precisas. Las medidas para
              reducir el riesgo deben implantarse en un periodo determinado.'| t }} </p>
            <p>{{ 'Cuando el riesgo moderado esta asociado con consecuencias extremadamente dañinas, se precisar una accion
              posterior para establecer, con más precisión, la probabildiad de daño como base para determinar la necesidad
              de mejora de las medidas de control.'| t }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <div class="methodologyinsht methodologyinsht_3--legend">{{ 'Tolerable - TO'| t }}</div>
          </td>
          <td>
            <p> {{ 'No se necesita mejorar la acción preventiva. Sin embargo, se deben considerar soluciones más rentables o
              mejoras que no supongan una carga económica importante.'| t }} </p>
            <p> {{ 'Se requieren comprobaciones periódicas para asegurar que se mantiene la eficacia de las medidas de control.'|
              t }}</p>
          </td>
        </tr>
        <tr>
          <td>
            <div class="methodologyinsht methodologyinsht_4--legend"> {{ 'Trivial - TR'| t }} </div>
          </td>
          <td>
            <p>{{ 'No se requiere acción especifica.'| t }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div id="second"></div>

  <div id="third">
    <table id="ActionTable" class="tableN">
      <tbody>
        <tr>
          <th class="widthColumnName"> {{ 'NIVEL DE RIESGO'| t }} </th>
          <th class="widthColumnDes"> {{ 'FECHA PLANIFICADA DE LA ACCIÓN CORRECTORA'| t }} </th>
        </tr>
        <tr>
          <td> {{ 'Riesgo Trivial'| t }} </td>
          <td>
            <p>{{ 'Planificación de la acción correctora antes de un año.'| t }}</p>
          </td>
        </tr>
        <tr>
          <td>{{ 'Riesgo Tolerable'| t }} </td>
          <td>
            <p> {{ 'Planificación de la acción correctora antes de un año.'| t }}</p>
          </td>
        </tr>
        <tr>
          <td> {{ 'Moderado'| t }} </td>
          <td>
            <p> {{ 'Planificación de la acción correctora antes de tres meses.'| t }} </p>
          </td>
        </tr>
        <tr>
          <td> {{ 'Riesgo importante'| t }} </td>
          <td>
            <p> {{ 'Planificación de la accion correctora antes de un mes.'| t }} </p>
          </td>
        </tr>
        <tr>
          <td> {{ 'Riesgo Intolerable'| t }} </td>
          <td>
            <p>{{ 'Planificación de la accion correctora antes de un mes.'| t }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
