import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Guid } from 'guid-typescript';
import { takeUntil } from 'rxjs/operators';
import { IViewModeField, IViewModeUserConfigurationColumn, ViewResultRow, ViewsFieldVboSelectionMode, ViewsVboSelectedItem } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DestroyableObjectTrait } from '../../../../utils/destroyableobject.trait';
import { UtilsTypescript} from '../../../../utils/typescript.utils';
import { VboOperations } from '../../../events/vboitemtoogle.eventdata';
import { ListComponent2Service } from '../../../list.service';

@Component({
  selector: 'app-view-grid-cell-bulk-operations',
  templateUrl: './grid-cell-bulk-operations.component.html'
})
export class ViewGridCellBulkOperationsComponent extends DestroyableObjectTrait implements OnInit, OnDestroy {

  /**
   * The field include info
   */
  @Input() fieldInclude: IViewModeUserConfigurationColumn;

  /**
   * The full row data. We use this so that we
   * can read dependencies between fields.
   */
  @Input() rowData: ViewResultRow;

  /**
   * If the VBO is checked for this cell.
   * @type {boolean}
   */
  vboCheckedValue: boolean;

  /**
   *
   */
  ViewsFieldVboSelectionMode = ViewsFieldVboSelectionMode;

  /**
   * Id unico del componente
   */
  uniqueComponentId: string;

  /**
   * La columna del view mode
   */
  get viewModeColumn(): IViewModeField {
    return this.listComponentService.getViewModeColumnFromViewModeUserColumn(this.fieldInclude);
  }

  /**
   * Obtiene el tipo de selección que usa el listado
   */
  get itemSelectionMode(): ViewsFieldVboSelectionMode {
    return this.listComponentService.vboItemSelectionMode(this.viewModeColumn.Field);
  }

  /**
   * Returns a boolean indicating if the vbo checkbox is checked.
   *
   * @returns {boolean}
   */
  get vboChecked(): boolean {
    return this.listComponentService.isVboAllSelected() || this.vboCheckedValue === true;
  }

  /**
   * Sets the boolean indicating if the vbo checkbox is checked.
   */
  set vboChecked(value: boolean) {
    this.vboCheckedValue = value;
  }

  /**
   * Indicates if VBO checkbox is disabled for current cell.
   *
   * @returns {boolean}
   */
  get vboDisabled(): boolean {
    return this.listComponentService.isVboAllSelected() || this.getRowVboSelectedItem().Disabled === true;
  }

  /**
   * Get tooltiptext for the checkbox
   */
  get toolTipText(): string {
    if (this.getRowVboSelectedItem().Disabled === true) {
      return 'La edición de este elemento está deshabilitada';
    }
    return null;
  }

  /**
   * vboDisabled, currently is only kept to handle errors.
   */
  set vboDisabled(value: boolean) {
    // Do nothing.
  }

  /**
   * Get an instasnce of GridCellComponent
   * @param {ListComponent2Service} listComponentService View orchestrator.
   */
  constructor(
    private listComponentService: ListComponent2Service,
    private cdr: ChangeDetectorRef) {
    super();
    // @ts-ignore
    this.uniqueComponentId = Guid.create();
  }

  /**
   * Subscribes to vboItemToggleHandler
   */
  ngOnInit(): void {

    // Escuchar a cambios externos de estado de la selección
    this.listComponentService
      .onVboItemToggle
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(event => {

        switch (event.operation) {
          case VboOperations.SELECT:
            if (event.row.Id === this.getRowVboSelectedItem().Id) {
              this.vboCheckedValue = true;
            }
            break;
          case VboOperations.UNSELECT:
            if (event.row.Id === this.getRowVboSelectedItem().Id) {
              this.vboCheckedValue = false;
            }
            break;
          case VboOperations.SELECT_ALL:
            this.vboCheckedValue = true;
            break;
          case VboOperations.UNSELECT_ALL:
            this.updateValueFromServiceState();
            break;
        }

        this.cdr.detectChanges();
      });

    // On component initialization ask if checkbox is checked.
    this.vboCheckedValue = this.listComponentService.isVboSelected(this.getRowVboSelectedItem());
  }

  /**
   * Click callback para el botón del modo de visualización SingleButtonb
   */
  vboCheckHandler(triggerUpdate: boolean = true): void {
    const vboItem: ViewsVboSelectedItem = this.getRowVboSelectedItem();

    // Este if es solo un mecanismo de seguridad
    if (vboItem.Selected === true && vboItem.Disabled === true) {
      this.vboChecked = true;
      return;
    }

    this.vboChecked = !this.vboChecked;

    if (triggerUpdate) {
      this.listComponentService.vboToggleItemHandler({
        operation: this.vboChecked ? VboOperations.SELECT : VboOperations.UNSELECT,
        row: vboItem,
        fieldName: this.viewModeColumn.Field
      });
    }
  }

  itemSelected(): void {
    this.listComponentService.vboToggleItemHandler({
      operation: VboOperations.SELECT,
      row: this.getRowVboSelectedItem(),
      fieldName: this.viewModeColumn.Field
    });
  }

  /**
   * Updates the component status from the service.
   */
  private updateValueFromServiceState(): void {
    this.vboChecked = this.listComponentService.vboUserConfiguration.SelectedItems
      .hasOwnProperty(this.getRowVboSelectedItem().Id);
  }

  /**
   * Returns a ViewsVboSelectedItem for the current row.
   */
  getRowVboSelectedItem(): ViewsVboSelectedItem {
    const item: ViewsVboSelectedItem = this.rowData.Metadata[`vbo_${this.viewModeColumn.Field}`] as ViewsVboSelectedItem;
    return UtilsTypescript.jsonClone(item);
  }
}
