import { EventEmitter } from '@angular/core';
import { isUndefined } from '../../../shared/utils/typescript.utils';

export class Locker {

  private promise: Promise<Locker> | null = null;
  private emitter: EventEmitter<any>;

  constructor(private bin: any, private id: any) {
    this.emitter = new EventEmitter();
  }

  public lock(): Promise<Locker> {
    const lock: this = this;

    if (!lock.isFree()) {
      throw new Error('Error al lockear ' + lock.bin + ':' + lock.id + ', cuando el recurso ya estaba bloqueado.');
    }

    lock._lock();
    return this.promise;
  }

  public wait(): Promise<Locker> {
    const lock: this = this;

    if (!lock.promise) {
      throw new Error('Error al obtener promesa de ' + lock.bin + ':' + lock.id + ', cuando el recurso no estaba bloqueado.');
    }

    return this.promise;
  }

  private _lock(): void {
    const lock: this = this;
    this.promise = new Promise(function (resolve: any, reject: any): void {
      lock.emitter.subscribe((o) => {
        if (lock.isFree()) {
          resolve();
        }
      })
    });
  }

  private _unlock(): void {
    this.promise = null;
    this.emitter.emit(new Date().getTime());
  }

  isFree(): boolean {
    return (this.promise === null || isUndefined(this.promise));
  }

  release(): void {
    this._unlock();
  }
}
