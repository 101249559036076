import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FuseSharedModule } from '../../../../../../../../../@fuse/shared.module';
import { InversalLineChartComponent } from './components/inversal-line-chart.component';
import { LineChartModule } from '@swimlane/ngx-charts';
import { UtilsModule } from '../../../../../../../../utils/utils.module';

@NgModule({
    declarations: [InversalLineChartComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        LineChartModule,
        UtilsModule
    ]
})
export class FuseInversalLineChartModule {
}
