import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { IFormElementOption } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { UtilsTypescript } from '../../../../utils/typescript.utils';

@Component({
  selector: 'app-input-check-multiple',
  templateUrl: './input-check-multiple.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputCheckMultipleComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputCheckMultipleComponent), multi: true}
  ]
})
export class InputCheckMultipleComponent extends FrontendFormElementInput {

  value: string[] = [];

  /**
   * Comprueba si el elemento está seleccionado
   *
   * @param {FormElementOption} option
   * @returns {boolean}
   */
  isChecked(option: IFormElementOption): boolean {
    if (isNullOrUndefined(this.value)) {
      return false;
    }
    return this.value.some(o => o === option.Key);
  }

  writeValue(value: string[]): void {
    if (isNullOrUndefined(value)) {
      this.value = new Array<string>();
      return;
    }
    // Hacemos una copia para NO tener referencia al objeto original
    this.value = UtilsTypescript.jsonClone(value);
  }

  /**
   * Genera un identificador único a nivel documento que se pone en cada checkbox individual
   *
   * @param {FormElementOption} option
   * @returns {string}
   */
  getOptionId(option: IFormElementOption): string {
    return this.uniqueElementId + '_' + option.Key;
  }

  /**
   * Captura del evento de cambio de estado (checked) en cada checkbox individual
   *
   * @param e
   */
  checkHandler(e: any): void {
    if (e.target.checked) {
      this.value.push(e.target.value);
    } else {
      this.value.splice(this.value.findIndex(x => x === e.target.value), 1);
    }
    this.propagateChange(UtilsTypescript.jsonClone(this.value));
  }
}
