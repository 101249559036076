import { ChangeDetectorRef, Component, OnInit, SkipSelf } from '@angular/core';
import { InstallPromptService } from '../installprompt.service';
import { Observable } from 'rxjs';
import { DynamicComponent } from '../../custom-component-factory/type-manager.decorator';
import { FrontendFormElementWrapper } from '../../form/form-components/formelementwrapper.class';
import { FormManagerService } from '../../form/form-manager/form-manager.service';
import MouseEvent = google.maps.Data.MouseEvent;
import { FrontendFormElementInput } from '../../form/form-components/formelementinput.class';

@DynamicComponent('app-install-prompt')
@Component({
  selector: 'app-install-prompt',
  templateUrl: './install-prompt.component.html',
  styleUrls: ['./install-prompt.component.scss']
})
export class InstallPromptComponent extends FrontendFormElementWrapper implements OnInit {


  constructor(
      private installPromptService: InstallPromptService,
      formManagerService: FormManagerService,
      cdRef: ChangeDetectorRef,
      @SkipSelf() cdRefParent: ChangeDetectorRef
  ) {
    super(formManagerService, cdRef, cdRefParent);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public installPromptIsEnabled(): Observable<boolean> {
    return this.installPromptService.isEnabled();
  }

  installApp(event: MouseEvent): void {
    this.installPromptService.showInstallPrompt();
  }

  /**
   * Method formElementInstance
   */
  formElementInstance(): FrontendFormElementInput {
    throw new Error('Method not implemented.');
  }
}
