import { Directive, EventEmitter, Injector, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { FormManagerComponent } from '../../form/form-manager/form-manager.component';
import { FormSubmitData, IFormState } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import { takeUntil } from 'rxjs/operators';
import { CustomComponentFactoryService } from '../../custom-component-factory/custom-component-factory.service';
import { ValuechangedEventdata } from '../../form/valuechanged.eventdata';
import { Formstatecontainer } from '../../form/formstatecontainer.class';

@Directive({
  selector: '[appFormViewGenerator]'
})
export class FormViewGeneratorDirective extends DestroyableObjectTrait implements OnInit {

  /**
   * Component to render.
   */
  form: FormManagerComponent;

  private _formState: Formstatecontainer;

  @Input() formPlugin: string;

  @Input() params: {};

  @Output() saveSucceed: EventEmitter<{ id: string, message: any[], responseData: any }> = new EventEmitter<{ id: string, message: any[], responseData: any }>();

  @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() changedValue: EventEmitter<ValuechangedEventdata> = new EventEmitter<ValuechangedEventdata>();

  constructor(protected container: ViewContainerRef,
              protected customComponentFactory: CustomComponentFactoryService,
              protected injector: Injector) {
    super();
  }

  ngOnInit(): void {
    if (this._formState) {
      this.form = this.customComponentFactory.createComponent('Form', this.container, this.injector, {formState: this._formState}).instance as FormManagerComponent;
    } else if (this.formPlugin) {
      this.form = this.customComponentFactory.createComponent('Form', this.container, this.injector, {
        formPlugin: this.formPlugin,
        params: this.params
      }).instance as FormManagerComponent;
    }

    if (this.form) {
      this.form.saveSucceed.pipe(takeUntil(this.componentDestroyed$))
        .subscribe((x: any) => this.saveSucceed.emit(x));

      this.form.canceled.pipe(takeUntil(this.componentDestroyed$))
        .subscribe((x: any) => this.canceled.emit(x));

      this.form.changedValue.pipe(takeUntil(this.componentDestroyed$))
        .subscribe((x: any) => this.changedValue.emit(x));
    }
  }

  get formState(): Formstatecontainer {
    return this._formState;
  }

  @Input()
  set formState(value: Formstatecontainer) {
    this._formState = value;
    if (this.form) {
      this.form.formState = value;
      this.form.resetForm();
    }
  }

  getFormSubmitDataForSubmit(): FormSubmitData {
    return this.form.createFormSubmitDataFromViews(this._formState.formState);
  }
}
