<div style="display: flex; flex-direction: column">
  <!-- Carga de archivo -->
  <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap: 6px; justify-content: flex-end">
    <div class="_18u012x7" *ngIf="mode === 'LIST'">
      <app-file-dropable *ngIf="canUploadFiles()" [uniqueId]="uniqueComponentId('fileinput-')"
                         (onFilesLoaded)="uploadFiles($event)" (onOverError)="onOverError($event)"
                         [multiple]="isMultiple()" [buttonLabel]="this.formElement.ButtonLabel"
                         [validExtensions]="this.config.validExtensions" [enableDragAndDrop]="false"
                         [validSize]="this.config.validSize" [maxFiles]="this.config.maxFiles"
                         [currentFiles]="files?.length" [disabled]="isDisabled">
      </app-file-dropable>
    </div>
  </div>
  <ng-container *ngIf="mode === 'PRETTY';then results else list"></ng-container>
  <!-- Resultado -->
  <ng-container *ngTemplateOutlet="validations"></ng-container>
</div>
<ng-template #results>
  <ul style="display: flex; flex-direction: row; align-items: center; flex-wrap: wrap; padding-inline-start: 0px; text-align: center">
    <ng-container *ngIf="files?.length > 0">
      <li class="media-filmstrip-list-item" *ngFor="let file of files">
        <div id="newFileExperienceWrapper" class="new-file-experience-wrapper new-file-experience-wrapper-regular">
          <div role="presentation">
            <div class="media-file-card-view css-1yn77bd" [attr.data-fileid]="file?.UploadedFile?.id"
                 [attr.data-status]="file?.getStatus()" [attr.data-media-name]="file?.name"
                 [attr.data-media-filename]="file?.name">
              <img draggable="false" alt="" [src]="getSrc(file?.UploadedFile)"
                   style="position: absolute; left: 50%; top: 40%; object-fit: contain; transform: translate(-50%, -50%); max-width: 100%;">
              <div class="media-card-blanket"></div>
              <div id="titleBoxWrapper" class="title-box-wrapper">
                <div id="titleBoxHeader" class="title-box-header">
                  <span>{{(!file?.UploadedFile) ? file?.name : file.UploadedFile.name}}</span>
                </div>
                <div id="titleBoxFooter" class="title-box-footer">
                  <span *ngIf="file?.UploadedFile">
                    {{ getSize(file?.UploadedFile) }}
                  </span>
                  <span
                      *ngIf="!file.UploadedFile && [UploadStatus.STARTED].includes(file.getStatus())">
                    {{ file.progress.toFixed(0) + ' %' }}
                  </span>
                </div>
              </div>
            </div>
            <div id="actionsBarWrapper" class="media-card-actions-bar">
                  <span>
                     <div class="media-card-actions-container">
                        <div id="cardActionButtonDownload"
                             class="action-card-buttton"
                             [ngClass]="file?.UploadedFile ? 'uploaded' : 'not-uploaded'"
                             *ngIf="file?.UploadedFile">
                           <a target="_blank" href="{{ getLink(file?.UploadedFile) }}">
                          <ng-container
                              *ngTemplateOutlet="cardActionButton, context: {'icon': svgDownload, 'text': 'Descargar'}"></ng-container>
                           </a>
                        </div>
                        <div id="cardActionButtonView" class="action-card-buttton"
                             (click)="openImageViewer(file?.UploadedFile)"
                             *ngIf="file?.UploadedFile && isImage(file?.UploadedFile)">
                          <ng-container
                              *ngTemplateOutlet="cardActionButton, context: {'icon': svgViewer, 'text': 'Ver en el visor'}"></ng-container>
                     </div>
                     <div
                         *ngIf="!isDisabled && [UploadStatus.COMPLETED, UploadStatus.CANCELED, UploadStatus.ERROR, UploadStatus.WAITING].includes(file.getStatus())"
                         id="cardActionButtonDelete" class="action-card-buttton"
                         (click)="removeFile(file)">
                           <ng-container
                               *ngTemplateOutlet="cardActionButton, context: {'icon': svgDelete, 'text': 'Eliminar'}"></ng-container>
                     </div>
                     <div
                         *ngIf="!file?.UploadedFile && [UploadStatus.STARTED, UploadStatus.PAUSED].includes(file.getStatus())"
                         id="cardActionButtonStop" class="action-card-buttton"
                         (click)="cancel(file)">
                           <ng-container
                               *ngTemplateOutlet="cardActionButton, context: {'icon': svgStop, 'text': 'Detener carga del archivo'}"></ng-container>
                   </div>
               </div>
               </span>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
    <li class="media-filmstrip-list-item" *ngIf="canUploadFiles()">
      <div id="addFileExperienceWrapper" class="new-file-experience-wrapper new-file-experience-wrapper-dropable">
        <div role="presentation">
          <app-file-dropable *ngIf="canUploadFiles()" [uniqueId]="uniqueComponentId('fileinput-')"
                             (onFilesLoaded)="uploadFiles($event)" (onOverError)="onOverError($event)"
                             [multiple]="isMultiple()" [validExtensions]="this.config.validExtensions"
                             [buttonLabel]="this.formElement.ButtonLabel"
                             [enableDragAndDrop]="this.formElement.EnableDragAndDrop"
                             [validSize]="this.config.validSize" [maxFiles]="this.config.maxFiles"
                             [currentFiles]="files?.length" [disabled]="isDisabled">
          </app-file-dropable>
        </div>
      </div>
    </li>
  </ul>
</ng-template>
<ng-template #list>
  <div class="file-list">
    <table class="css-fkqt3q">
      <thead class="css-1g54gxt">
      <tr>
        <th class="css-15pk9f6" style="width: 188px;">
          <button type="button"><span class="_o5721q9c">Nombre</span></button>
        </th>
        <th aria-sort="descending" class="css-o6d9qo">
          <button type="button">Tamaño</button>
        </th>
        <th class="css-1xabuij" style="text-align: end"><span>Acciones</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <ng-container class="table" *ngIf="files?.length > 0">
        <tr tabindex="0" class=" css-unmje5" *ngFor="let file of files">
          <td style="text-align: start">
            <div [attr.aria-label]="file?.name">
                           <span aria-hidden="true" class="_o5721q9c">
                              <div style="display: flex; gap: 6px; align-items: center">
                                 <span class="css-15xpiq3">
                                   <div
                                       *ngIf="!file.UploadedFile && [UploadStatus.STARTED].includes(file.getStatus())">
                                     <ng-container *ngTemplateOutlet="loadingAnimationSmall"></ng-container>
                                   </div>
                                   <div *ngIf="file.UploadedFile">
                                     <ng-container *ngTemplateOutlet="svgFile"></ng-container>
                                     </div>
                                 </span>
                                  <div role="presentation"> <span
                                      *ngIf="!file.UploadedFile && [UploadStatus.STARTED].includes(file.getStatus())">({{file.progress.toFixed(0)}}
                                    %)</span>
                                    <span *ngIf="!file.UploadedFile && [UploadStatus.STARTED].includes(file.getStatus())"> - </span>
                                    <span>{{(!file?.UploadedFile) ? file?.name : file.UploadedFile.name}}</span>
                                  </div>
                                 </div>
                           </span>
            </div>
          </td>
          <td>{{getSize(file?.UploadedFile)}}</td>
          <td style="display: flex; justify-content: end">
            <button aria-busy="false" class="css-1tg4l9b upload-file"
                    *ngIf="!file?.UploadedFile && [UploadStatus.PAUSED, UploadStatus.WAITING].includes(file.getStatus())"
                    tabindex="0" (click)="file?.upload()"
                    type="button">
              <ng-container
                  *ngTemplateOutlet="listActionButton, context: {'icon': svgPlay, 'text': 'Reiniciar'}"></ng-container>
            </button>
            <button aria-busy="false" class="css-1tg4l9b cancel-upload-file"
                    *ngIf="!file?.UploadedFile && [UploadStatus.STARTED, UploadStatus.PAUSED].includes(file.getStatus())"
                    tabindex="0" (click)="cancel(file)"
                    type="button">
              <ng-container
                  *ngTemplateOutlet="listActionButton, context: {'icon': svgStop, 'text': 'Descargar'}"></ng-container>
            </button>

            <a target="_blank" href="{{ getLink(file?.UploadedFile) }}" *ngIf="file.UploadedFile">
              <button aria-busy="false" class="css-1i4s96o download-file" tabindex="0"
                      type="button">
                <ng-container
                    *ngTemplateOutlet="listActionButton, context: {'icon': svgDownload, 'text': 'Descargar'}"></ng-container>
              </button>
            </a>
            <button aria-busy="false" class="css-1tg4l9b open-viewer-form"
                    tabindex="0"
                    *ngIf="file?.UploadedFile && isImage(file?.UploadedFile)"
                    (click)="openImageViewer(file?.UploadedFile)"
                    type="button">
              <ng-container
                  *ngTemplateOutlet="listActionButton, context: {'icon': svgViewer, 'text': 'Ir al visor'}"></ng-container>
            </button>
            <button
                *ngIf="!isDisabled && [UploadStatus.COMPLETED, UploadStatus.CANCELED, UploadStatus.ERROR, UploadStatus.WAITING].includes(file.getStatus())"
                (click)="removeFile(file)" aria-busy="false" class="css-1tg4l9b delete-file"
                tabindex="0"
                type="button">
              <ng-container
                  *ngTemplateOutlet="listActionButton, context: {'icon': svgDelete, 'text': 'Eliminar'}"></ng-container>
            </button>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</ng-template>
<ng-template #validations>
  <div class="control-validations control-validations-docs" [ngClass]="{ 'over-drop-error': overError }">
    <small *ngIf="config.validSize">
      {{ ('El tamaño máximo por archivo es de @filesize' | t: {'@filesize': formatFileSize(config?.validSize)})  }}
    </small>
    <br>
    <small *ngIf="config.validExtensions?.length > 0">
      {{('Las extensiones válidas de archivo son: @extensions ' | t :{'@extensions': validExtensions()}) }}.
    </small>
    <br *ngIf="config.validExtensions?.length > 0">
    <small *ngIf="config.maxFiles > 1">
      {{ 'Puedes agregar hasta @filecount archivo/s más.' | t :{'@filecount': (config.maxFiles - files.length)} }}
    </small>
  </div>
</ng-template>


<ng-template #listActionButton let-icon="icon" let-text="text">
  <span class="css-bwxjrz">
     <span role="img" class="media-action-button">
       <div [title]="text">
        <ng-container *ngTemplateOutlet="icon"></ng-container>
       </div>
     </span>
  </span>
</ng-template>

<ng-template #cardActionButton let-icon="icon" let-text="text">
  <div role="presentation" [title]="text">
    <div aria-busy="false" class="css-1ayenjz"
         disabled=""
         tabindex="-1" type="button">
          <span class="css-bwxjrz">
             <span role="img" class="media-action-button">
               <ng-container *ngTemplateOutlet="icon"></ng-container>
             </span>
          </span>
    </div>
  </div>
</ng-template>

<ng-template #svgDownload>
  <svg
      width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <g fill="currentColor"
       fill-rule="evenodd">
      <path
          d="M10.687 17.292a.983.983 0 00-1.397 0 1 1 0 000 1.407l1.964 1.98a1.082 1.082 0 001.538 0l1.919-1.933a1 1 0 000-1.408.983.983 0 00-1.398 0l-1.29 1.3-1.336-1.346z"
          fill-rule="nonzero"></path>
      <path
          d="M13.001 19.993L13 10.006C13 9.451 12.552 9 12 9s-1 .45-1 1.007l.001 9.987c0 .555.448 1.006 1 1.006s1-.45 1-1.007z"
          fill-rule="nonzero"></path>
      <path
          d="M7.938 5.48a4.8 4.8 0 00-.777-.062C4.356 5.418 2 7.62 2 10.498 2 13.409 4.385 16 7.1 16h2.881v-1.993H7.1c-1.657 0-3.115-1.663-3.115-3.508 0-1.778 1.469-3.087 3.104-3.087h.012c.389 0 .686.05.97.15l.17.063c.605.248.875-.246.875-.246l.15-.267c.73-1.347 2.201-2.096 3.716-2.12a4.14 4.14 0 014.069 3.645l.046.34s.071.525.665.525c.013 0 .012.005.023.005h.254c1.136 0 1.976.959 1.976 2.158 0 1.207-.987 2.342-2.07 2.342h-3.964V16h3.964C20.105 16 22 13.955 22 11.665c0-2-1.312-3.663-3.138-4.074-.707-2.707-3.053-4.552-5.886-4.591-1.975.02-3.901.9-5.038 2.48z"></path>
    </g>
  </svg>
</ng-template>

<ng-template #svgStop>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
       fill="#42526E" stroke="none">
      <path d="M50 120 l0 -70 70 0 70 0 0 70 0 70 -70 0 -70 0 0 -70z"/>
    </g>
  </svg>
</ng-template>

<ng-template #svgViewer>
  <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <g fill="currentColor" fill-rule="evenodd">
      <path
          d="M12 18c-4.536 0-7.999-4.26-7.999-6 0-2.001 3.459-6 8-6 4.376 0 7.998 3.973 7.998 6 0 1.74-3.462 6-7.998 6m0-14C6.48 4 2 8.841 2 12c0 3.086 4.576 8 10 8 5.423 0 10-4.914 10-8 0-3.159-4.48-8-10-8"></path>
      <path
          d="M11.977 13.984c-1.103 0-2-.897-2-2s.897-2 2-2c1.104 0 2 .897 2 2s-.896 2-2 2m0-6c-2.206 0-4 1.794-4 4s1.794 4 4 4c2.207 0 4-1.794 4-4s-1.793-4-4-4"></path>
    </g>
  </svg>
</ng-template>

<ng-template #svgPlay>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="24.000000pt" height="24.000000pt" viewBox="0 0 24.000000 24.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
       fill="#42526E" stroke="none">
      <path d="M85 211 c-64 -26 -81 -110 -32 -158 36 -36 95 -38 130 -4 28 25 40
                                    58 26 67 -5 3 -9 -2 -9 -11 0 -27 -46 -65 -80 -65 -19 0 -40 9 -55 25 -66 65
                                    14 168 96 124 16 -8 18 -13 8 -25 -9 -12 -6 -14 20 -14 28 0 31 3 31 31 0 25
                                    -3 29 -14 20 -10 -8 -20 -8 -42 4 -32 16 -49 18 -79 6z"/>
    </g>
  </svg>
</ng-template>

<ng-template #svgDelete>
  <svg
      width="24" height="24" viewBox="0 0 24 24" role="presentation">
    <path
        d="M5 5a1 1 0 00-1 1v1h16V6a1 1 0 00-1-1H5zm11.15 15H7.845a1 1 0 01-.986-.835L5 8h14l-1.864 11.166a.999.999 0 01-.986.834M9 4.5a.5.5 0 01.491-.5h5.018a.5.5 0 01.491.5V5H9v-.5z"
        fill="currentColor" fill-rule="evenodd"></path>
  </svg>
</ng-template>

<ng-template #svgFile>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="24.000000pt" height="24.000000pt"
       viewBox="0 0 24.000000 24.000000"
       preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
       fill="#42526E" stroke="none">
      <path d="M44 207 c-3 -8 -4 -52 -2 -98 l3 -84 75 0 75 0 3 64 c3 60 1 66 -29
                                                97 -27 28 -40 34 -76 34 -27 0 -46 -5 -49 -13z m86 -32 c0 -20 5 -25 25 -25
                                                24 0 25 -3 25 -55 l0 -55 -60 0 -60 0 0 80 0 80 35 0 c31 0 35 -3 35 -25z"/>
    </g>
  </svg>
</ng-template>

<ng-template #loadingAnimationSmall>
  <img
      src="/assets/icons/icons8-dots-loading.gif" width="24" height="24"
      alt="Archivo"/>
</ng-template>
