export class ValuechangedEventdata {
  /**
   * The name of the control
   */
  name: string;

  /**
   * The control's path
   */
  path: string;

  /**
   * The previous value of the control
   */
  prevValue: any;

  /**
   * The new value of the control
   */
  newValue: any;
}
