import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ViewOperationsComponent } from './components/view-operations.component';
import { UtilsModule } from '../../utils/utils.module';
import { ViewOperationFormModalComponent } from './components/view-operation-form-modal.component';
import { BatchModule } from '../../batch/batch.module';
import { ViewsSharedModule } from '../shared/views-shared.module';
import { MatButtonModule } from '@angular/material/button';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RestTranslatorModule } from '../../../core/translator/rest-translator.module';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    ModalModule.forRoot(),
    FormsModule,
    BatchModule,
    TooltipModule,
    ViewsSharedModule,
    MatButtonModule,
    RestTranslatorModule
  ],
  declarations: [
    ViewOperationsComponent,
    ViewOperationFormModalComponent
  ],
  exports: [
    ViewOperationsComponent,
    ViewOperationFormModalComponent,
  ]
})
export class ViewOperationsModule {
}
