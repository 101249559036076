import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { KeyValuePair } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable()
/**
 * Common Data Service: Service to manage volatile information
 */
export class CommonDataService {

  /**
   * Data
   */
  private data: KeyValuePair<string, any>[];

  /**
   * Ctor
   */
  constructor() {
    this.data = [];
  }

  /**
   * Add new value to data
   * @param {string} key
   * @param value
   */
  add(key: string, value: any): void {
    this.data.push(({Key: key, Value: value} as KeyValuePair<string, any>))
  }

  /**
   * Get a value from key
   * @param {string} key
   * @param {boolean} destroy
   * @returns {any}
   */
  get(key: string, destroy: boolean = true): any {
    if (isNullOrUndefined(this.data)) {
      return null;
    }
    const item: KeyValuePair<string, any> = this.data.find((i) => i.Key === key);
    if (destroy) {
      this.remove(key);
    }
    if (isNullOrUndefined(item)) {
      return null;
    }
    return item.Value;
  }

  /**
   * Remove a value from key
   * @param {string} key
   */
  remove(key: string): void {
    this.data.forEach((item, index) => {
      if (item.Key === key) {
        this.data.splice(index, 1);
      }
    });
  }
}
