import { KeyValueDiffers, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'keyeddictionary'})
export class KeyedDictionaryPipe implements PipeTransform {

  constructor(private readonly differs: KeyValueDiffers) {
  }

  transform<K, V>(
    input: null | { [key: string]: V, [key: number]: V } | Map<K, V>): V {
    throw new Error('No supported yet.');
  }
}
