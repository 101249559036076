import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PagerComponent } from './pager/pager.component';
import { RestTranslatorModule } from '../../../core/translator/rest-translator.module';
import { PagerLoaderDirective } from './pager-loader.directive';
import { LoadMorePagerComponent } from './load-more-pager/load-more-pager.component';
import { MaterialPagerComponent } from './material-pager/material-pager.component';
import { PaginatorI18n } from './material-pager/mat-paginator-intl';
import { LazyPagerComponent } from './lazy-pager/lazy-pager.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { TranslatorService } from '../../../core/translator/services/rest-translator.service';

@NgModule({
  imports: [
    CommonModule,
    RestTranslatorModule,
    FormsModule,
    MatPaginatorModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  declarations: [
    PagerComponent,
    PagerLoaderDirective,
    LoadMorePagerComponent,
    MaterialPagerComponent,
    LazyPagerComponent,
  ],
  exports: [
    PagerComponent,
    PagerLoaderDirective,
    LoadMorePagerComponent,
    MaterialPagerComponent,
    LazyPagerComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [TranslatorService],
      useFactory: (translateService: TranslatorService) => new PaginatorI18n(translateService)
    }
  ]
})
export class PagerModule {

}
