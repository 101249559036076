/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { LocationValidation } from '../models/ETG_SABENTISpro_Application_Modules_models';

@Injectable({ providedIn: 'root' })
export class SabentisCompanyService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Validates that the company has a coherent structura and returns a companyvalidation item
  *
  * @param {string} id
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {LocationValidation}
  */
  getValidatecompany(id: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<LocationValidation>>
  {
    const params: any = {'id' : id};
    return this.generalService.get(`sabentis-company/validatecompany`, params, clientOpts) as Observable<WebServiceResponseTyped<LocationValidation>>;
  }
}
