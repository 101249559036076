

export interface ITypedObject {
  readonly $type: string;
}

/**
 */
export interface IJsonExtendEntity extends ITypedObject {
  jsonExtend: string;
  JsonExtend: Object;
}


/**
 */
export class ERGO_ASSESSMENT implements IJsonExtendEntity {
  public static readonly $type: string = 'ETG.SABENTISpro.Ergonomia.Ergo.Model.Entities.ERGO_ASSESSMENT';
  readonly $type: string = ERGO_ASSESSMENT.$type;
  fk_ergotutor_study?: string;
  ERGOTUTOR_STUDY: ERGOTUTOR_STUDY;
  id: string;
  fk_sabentis_company: string;
  type: number;
  code: string;
  name: string;
  description: string;
  conclusions: string;
  initialdate: Date;
  enddate: Date;
  status?: number;
  changedAt: number;
  createdAt: Date;
  fk_core_person?: string;
  idExternal: string;
  result?: number;
  jsonExtend: string;
  JsonExtend: Object;
  fullVideoEmbedUrl: string;
  SABENTIS_COMPANY: any;
  CORE_PERSON: any;
  ERGO_ASSESSMENT_CORRECTIVEACTIONs: ERGO_ASSESSMENT_CORRECTIVEACTION[];
  ERGO_ASSESSMENT_JOBs: ERGO_ASSESSMENT_JOB[];
}


/**
 */
export class ERGO_ASSESSMENT_CORRECTIVEACTION implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Ergonomia.Ergo.Model.Entities.ERGO_ASSESSMENT_CORRECTIVEACTION';
  readonly $type: string = ERGO_ASSESSMENT_CORRECTIVEACTION.$type;
  id: string;
  fk_ergo_assessment: string;
  fk_quala_correctiveaction: string;
  QUALA_CORRECTIVEACTION: any;
  ERGO_ASSESSMENT: ERGO_ASSESSMENT;
}


/**
 */
export class ERGOTUTOR_STUDY implements IJsonExtendEntity {
  public static readonly $type: string = 'ETG.SABENTISpro.Ergonomia.ErgoTutor.Model.Entities.ERGOTUTOR_STUDY';
  readonly $type: string = ERGOTUTOR_STUDY.$type;
  id: string;
  fk_sabentis_company: string;
  code: string;
  name: string;
  description: string;
  initialdate: Date;
  enddate: Date;
  status: number;
  changedAt: number;
  createdAt: Date;
  fk_core_person?: string;
  idExternal: string;
  requirements: string;
  answers: string;
  methods: string;
  fk_core_basicparametric_sistemaproduccion?: string;
  fk_core_basicparametric_organizaciontrabajo?: string;
  jsonExtend: string;
  JsonExtend: Object;
  SABENTIS_COMPANY: any;
  CORE_PERSON: any;
  CORE_BASICPARAMETRIC_SISTEMAPRODUCCION: any;
  CORE_BASICPARAMETRIC_ORGANIZACIONTRABAJO: any;
  ERGOTUTOR_STUDY_JOBs: ERGOTUTOR_STUDY_JOB[];
  ERGO_ASSESMENTSs: ERGO_ASSESSMENT[];
}


/**
 */
export class ERGOTUTOR_STUDY_JOB implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Ergonomia.ErgoTutor.Model.Entities.ERGOTUTOR_STUDY_JOB';
  readonly $type: string = ERGOTUTOR_STUDY_JOB.$type;
  id: string;
  fk_ergotutor_study: string;
  fk_sabentis_job: string;
  SABENTIS_JOB: any;
  ErgotutorStudy: ERGOTUTOR_STUDY;
}


/**
 */
export class ERGOTUTOR_STUDY_CORRECTIVEACTION implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Ergonomia.ErgoTutor.Model.Entities.ERGOTUTOR_STUDY_CORRECTIVEACTION';
  readonly $type: string = ERGOTUTOR_STUDY_CORRECTIVEACTION.$type;
  id: string;
  fk_ergotutor_study: string;
  fk_quala_correctiveaction: string;
  QUALA_CORRECTIVEACTION: any;
  ERGOTUTOR_STUDY: ERGOTUTOR_STUDY;
}


/**
 */
export class ERGO_ASSESSMENT_JOB implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Ergonomia.ErgoTutor.Model.Entities.ERGO_ASSESSMENT_JOB';
  readonly $type: string = ERGO_ASSESSMENT_JOB.$type;
  id: string;
  fk_ergo_assessment: string;
  fk_sabentis_job: string;
  SABENTIS_JOB: any;
  ERGO_ASSESSMENT: ERGO_ASSESSMENT;
}


/**
 */
export interface IExtendEntity extends ITypedObject {
  extend: number[];
  Extend: { [id: string]: any };
}


/**
 */
export interface ISoftDeleted extends ITypedObject {
  deletedAt?: number;
}


/**
 */
export class QUALA_STUDY implements IExtendEntity, ISoftDeleted, IJsonExtendEntity {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_STUDY';
  readonly $type: string = QUALA_STUDY.$type;
  colmena_responsable: string;
  peru_ejecutado?: boolean;
  ferreycorp_fk_core_person_revisor?: string;
  FERREYCORP_CORE_PERSON_REVISOR: any;
  ferreycorp_fk_core_person_aprobador?: string;
  FERREYCORP_CORE_PERSON_APROBADOR: any;
  ferreycorp_status?: number;
  ferreycorp_observations: string;
  ferreycorp_revisorNotificado: boolean;
  ferreycorp_aprobadorNotificado: boolean;
  ferreycorp_elaboradorNotificado: boolean;
  fk_core_person_coresponsable?: string;
  CORE_PERSON_CORESPONSABLE: any;
  ier_locations: number[];
  si_questionnaireCompleted: boolean;
  id: string;
  fk_sabentis_company: string;
  fk_sabentis_location_scope: string;
  quala: number;
  code: string;
  name: string;
  description: string;
  initialdate?: number;
  approvalDate: Date;
  enddate?: number;
  plannedStartDate: Date;
  plannedEndDate: Date;
  status: number;
  changedAt: number;
  deletedAt?: number;
  type: number;
  periodicity?: number;
  fk_core_person?: string;
  fk_quala_studytype?: string;
  startdate?: number;
  expirationdate?: number;
  copied: boolean;
  idExternal: string;
  extend: number[];
  Extend: { [id: string]: any };
  jsonExtend: string;
  JsonExtend: Object;
  conditions: number[];
  QUALA_IDENTIFIEDPROBLEMs: any[];
  QUALA_ANSWERs: any[];
  QUALA_STUDY_QUESTIONNAIREs: any[];
  QUALA_IDENTIFIEDNONCOMPLIANCEs: any[];
  QUALA_CORRECTIVEACTIONs: any[];
  QUALA_EVALUATIONTARGETs: any[];
  SABENTIS_COMPANY: any;
  SABENTIS_LOCATION_SCOPE: any;
  CORE_PERSON: any;
  QUALA_STUDYTYPE: any;
  SABENTIS_DIVISIONENTIDADs: any[];
}


/**
 */
export class QUALA_QUESTIONNAIRE implements ISoftDeleted, IExtendEntity {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_QUESTIONNAIRE';
  readonly $type: string = QUALA_QUESTIONNAIRE.$type;
  achs_relatedgoal: string;
  id: string;
  quala: number;
  fk_quala_questionnairetype?: string;
  fk_quala_questionnairecategory?: string;
  code: string;
  name: string;
  description: string;
  configowner: string;
  changedAt: number;
  deletedAt?: number;
  fk_sabentis_scope?: string;
  extend: number[];
  deshabilitado: boolean;
  Extend: { [id: string]: any };
  QUALA_QUESTIONs: any[];
  QUALA_STUDY_QUESTIONNAIREs: any[];
  QUALA_QUESTIONNAIRE_PROBLEMs: any[];
  QUALA_QUESTIONNAIRETYPE: any;
  QUALA_QUESTIONNAIRECATEGORY: any;
  SABENTIS_SCOPE: any;
}


/**
 */
export class QUALA_PROBLEMCATEGORY implements ISoftDeleted {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_PROBLEMCATEGORY';
  readonly $type: string = QUALA_PROBLEMCATEGORY.$type;
  id: string;
  quala: number;
  code: string;
  name: string;
  description: string;
  changedAt: number;
  deletedAt?: number;
  fk_quala_problemcategory?: string;
  level?: number;
  deshabilitado: boolean;
  fk_sabentis_company?: string;
  QUALA_PROBLEMTYPEs: QUALA_PROBLEMTYPE[];
  QUALA_PROBLEMs: any[];
  QUALA_PROBLEMCATEGORY_fk_quala_problemcategory: QUALA_PROBLEMCATEGORY;
  SABENTIS_COMPANY: any;
}


/**
 */
export class QUALA_PROBLEMTYPE implements ISoftDeleted {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Quala.QUALA_PROBLEMTYPE';
  readonly $type: string = QUALA_PROBLEMTYPE.$type;
  id: string;
  fk_quala_problemcategory?: string;
  quala: number;
  code: string;
  name: string;
  description: string;
  changedAt: number;
  deletedAt?: number;
  QUALA_PROBLEMs: any[];
  QUALA_PROBLEMCATEGORY: QUALA_PROBLEMCATEGORY;
}


/**
 */
export class OPT_NOCONFORMIDAD implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Modules.OPT.OPT_NOCONFORMIDAD';
  readonly $type: string = OPT_NOCONFORMIDAD.$type;
  ferreycorp_code: string;
  id: string;
  fk_opt_observacion: string;
  fk_core_basicparametric_type: string;
  fk_core_basicparametric_causa: string;
  description: string;
  status: number;
  changedAt: number;
  OPT_OBSERVACION: OPT_OBSERVACION;
  CORE_BASICPARAMETRIC_TYPE: any;
  CORE_BASICPARAMETRIC_CAUSA: any;
  OPT_NOCONFORMIDAD_CORRECTIVEACTIONs: OPT_NOCONFORMIDAD_CORRECTIVEACTION[];
}


/**
 */
export class OPT_OBSERVACION implements IJsonExtendEntity {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Modules.OPT.OPT_OBSERVACION';
  public static readonly EntityApiEntityKey: string = 'OPT_OBSERVACION_ENTITY';
  readonly $type: string = OPT_OBSERVACION.$type;
  ferreycorp_codigozona: string;
  ferreycorp_otrosdescripcion: string;
  id: string;
  fk_sabentis_company: string;
  type: number;
  code: string;
  description: string;
  initialdate: Date;
  enddate: Date;
  date: Date;
  status: number;
  fk_sabentis_location: string;
  numemployees?: number;
  changedAt: number;
  jsonExtend: string;
  JsonExtend: Object;
  SABENTIS_COMPANY: any;
  SABENTIS_LOCATION: any;
  SABENTIS_DIVISIONENTIDADs: any[];
  OPT_OBSERVACION_PERSONs: any[];
}


/**
 */
export class OPT_NOCONFORMIDAD_CORRECTIVEACTION implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Modules.OPT.OPT_NOCONFORMIDAD_CORRECTIVEACTION';
  readonly $type: string = OPT_NOCONFORMIDAD_CORRECTIVEACTION.$type;
  id: string;
  fk_opt_noconformidad: string;
  fk_quala_correctiveaction: string;
  QUALA_CORRECTIVEACTION: any;
  OPT_NOCONFORMIDAD: OPT_NOCONFORMIDAD;
}


/**
 */
export class ABSENTEEISM_INCIDENT_CORRECTIVEACTION implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Modules.Absenteeism.ABSENTEEISM_INCIDENT_CORRECTIVEACTION';
  readonly $type: string = ABSENTEEISM_INCIDENT_CORRECTIVEACTION.$type;
  id: string;
  fk_absenteeism_incident: string;
  fk_quala_correctiveaction: string;
  changedAt: number;
  ABSENTEEISM_INCIDENT: any;
  QUALA_CORRECTIVEACTION: any;
}


/**
 */
export class CORE_GROUP implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Core.CORE_GROUP';
  readonly $type: string = CORE_GROUP.$type;
  id: string;
  code: string;
  name: string;
  description: string;
  hierarchy: number;
  supergroup: boolean;
  grouptype: number;
  profileType?: number;
  changedAt: number;
  configowner: string;
  Mandatory2FA: boolean;
  CORE_GROUPPERMISSIONs: any[];
  CORE_PERSON_GROUPs: any[];
}


/**
 */
export class CORE_QUEUE implements ITypedObject {
  public static readonly $type: string = 'ETG.SABENTISpro.Models.Core.CORE_QUEUE';
  readonly $type: string = CORE_QUEUE.$type;
  id: string;
  name: string;
  title: string;
  expire?: number;
  created: number;
  status: string;
  configuration: string;
  sandbox: string;
  owner: string;
  plugin: string;
  startedAt: number;
  batchTime: number;
  batchCount: number;
  log: string;
  lastRun?: number;
  lastTaken?: number;
  key: string;
  applicationLock: string;
  reuse?: number;
  cancelable: boolean;
  timeOfLastStatusQuery?: number;
  runningRetryCount: number;
}


/**
 */
export enum AccesoTypeEnum {
  Acceso = 0,
  SalidaExterior = 1
}


/**
 */
export enum TraningStatusEnum {
  Pending = 0,
  Evaluated = 1
}


/**
 */
export enum AchsGESTareaFuenteExposicionOperacionEnum {
  Directa = 0,
  Indirecta = 1,
  Ambas = 2
}


/**
 */
export enum AchsGESEstadoEnum {
  EnCreacion = 0,
  PorEvaluar = 1,
  Evaluado = 2,
  EnProceso = 3
}


/**
 */
export enum CourseStatusEnum {
  EnMatriculacion = 0,
  EnProceso = 1,
  Finalizado = 2
}


/**
 */
export enum AchsE11TraningStatusEnum {
  PendingRegistration = 0,
  Finalized = 1,
  Cerrado = 2
}


/**
 */
export enum CauseTypeEnum {
  Basica = 0,
  Immediata = 1
}


/**
 */
export enum CoreBatchReuseModeEnum {
  NoReuse = 0,
  ReuseByInstanceAndUser = 1,
  ReuseByInstance = 2
}

