<mwl-calendar-day-view
  [viewDate]="viewDate"
  [locale]="lang"
  [events]="events"
  [refresh]="refresh"
  [dayStartHour]="dayStartOn?.getHours()"
  [dayStartMinute]="dayStartOn?.getMinutes()"
  [dayEndHour]="dayEndOn?.getHours()"
  [dayEndMinute]="dayEndOn?.getMinutes()"
  (eventClicked)="onEventClicked($event)"
  (eventTimesChanged)="onEventTimesChanged($event)">
</mwl-calendar-day-view>
