<ng-container *ngIf="!systemInfo else component">
  <ng-container *ngTemplateOutlet="loading"></ng-container>
</ng-container>

<ng-template #component>
  <ng-container *ngTemplateOutlet="button">
  </ng-container>

  <div ngClass="c-simplemessage-footer-floattext">
    <ng-container
        *ngTemplateOutlet="messages, context: {'icon': 'fa-check-circle', 'messages': filterMessages((systemInfo)?.Messages, CoreRenderTemplateSimpleMessageTypeSuccess)}"></ng-container>

    <ng-container
        *ngTemplateOutlet="messages, context: {'icon': 'fa-times-circle', 'messages': filterMessages((systemInfo)?.Messages, CoreRenderTemplateSimpleMessageTypeError)}"></ng-container>

    <ng-container
        *ngTemplateOutlet="messages, context: {'icon': 'fa-exclamation-circle', 'messages': filterMessages((systemInfo)?.Messages, CoreRenderTemplateSimpleMessageTypeWarning)}"></ng-container>

    <ng-container
        *ngTemplateOutlet="messages, context: {'icon': 'fa-info-circle', 'messages': filterMessages((systemInfo)?.Messages, CoreRenderTemplateSimpleMessageTypeInfo)}"></ng-container>
  </div>
</ng-template>


<ng-template #loading>
  <div ngClass="c-simplemesage-footer-container" fxLayout="row"
       fxLayoutAlign="center start">
    <i class="fa fa-info-circle"></i>
    <div>
      {{ 'Obteniendo información...' | t }}
    </div>
  </div>
</ng-template>


<ng-template #button>
  <button *ngIf="(systemInfo)?.EnableRefreshButton" mat-button class="c-btn-link refresh-button"
          (click)="onRefreshButtonClicked($event)">
    <i class="fas fa-sync-alt"></i>
  </button>
</ng-template>

<ng-template #messages let-messages="messages" let-icon="icon">
  <div *ngIf="messages?.length > 0" ngClass="c-simplemesage-footer-container" fxLayout="row"
       fxLayoutAlign="center start">
    <i [ngClass]="'fa ' + icon"></i>
    <div *ngFor="let message of messages let i = index" fxLayout="row" fxLayoutAlign="center start">
      <div *ngIf="i !== 0">&nbsp;-&nbsp;</div>
      {{ message.Message }}
    </div>
  </div>
</ng-template>
