/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { GeocodeResult } from '../models/ETG_SABENTISpro_Application_Core_models';
import { ReverseGeocodeResult } from '../models/ETG_SABENTISpro_Application_Core_models';

@Injectable({ providedIn: 'root' })
export class GeocodingService
{
  constructor(public generalService: CommunicationService) {}

  /**
  * @param {string} address
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {GeocodeResult[]}
  */
  getGeocode(address: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<GeocodeResult[]>>
  {
    const params: any = {'address' : address};
    return this.generalService.get(`core-geocoding/geocode`, params, clientOpts) as Observable<WebServiceResponseTyped<GeocodeResult[]>>;
  }

  /**
  * @param {number} latitude
  * @param {number} longitude
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {ReverseGeocodeResult[]}
  */
  getReveregeocode(latitude: number, longitude: number, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<ReverseGeocodeResult[]>>
  {
    const params: any = {'latitude' : JSON.stringify(latitude), 'longitude' : JSON.stringify(longitude)};
    return this.generalService.get(`core-geocoding/revere-geocode`, params, clientOpts) as Observable<WebServiceResponseTyped<ReverseGeocodeResult[]>>;
  }
}
