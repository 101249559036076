<div [formGroup]="group">
  <app-formelementinputlabel [config]="config" [group]="group"></app-formelementinputlabel>

  <app-select [config]="config"
              [group]="group"
              [formControlName]="config?.name" #formInput>
  </app-select>

  <app-formelementinputdescription [config]="config" [group]="group"></app-formelementinputdescription>
  <app-formelementinput-validationmessages [config]="config" [group]="group"></app-formelementinput-validationmessages>
</div>
