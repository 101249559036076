<ul class="dropdown-menu" [ngClass]="getDirection()" aria-labelledby="dropdownMenu1" [ngStyle]="{ 'display': open ? 'block' : 'none' }" #list>
  <ng-container *ngFor="let child of children">
    <li *ngIf="canShowItem(child)" [ngClass]="{ 'dropdown-submenu' : child.children }" (mouseover)="child.isOpen = true;" (mouseout)="child.isOpen = false;">
      <a [appSabentisRouterLink]="child" (click)="itemClicked(child)">
        <span *ngIf="hasMenuChildren(child) && isLeft()"><i class="fa fa-angle-left"></i></span>
        <span>{{ child.title }}</span>
        <span *ngIf="hasMenuChildren(child) && !isLeft()"><i class="fa fa-angle-right" *ngIf="child.children"></i></span>
      </a>
      <app-dropdown-menu-child *ngIf="hasMenuChildren(child)" [children]="child.children" [open]="child.isOpen" [direction]="direction" (childEvents)="propagateEvent($event)"></app-dropdown-menu-child>
    </li>
  </ng-container>
</ul>
