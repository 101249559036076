<div class="l-company-selector">
  <div class="c-company-selector">
    <app-selector
        plugin="core-list-context-company"
        selector="name"
        selectorSecundary="cif"
        [selectionLabel]="'Empresa' | t"
        [placeholder]="'Añadir Empresa' | t"
        [selectedItem]="objCompany"
        (itemSelected)="selectedCompany($event)"
        [canUnselect]="canUnselectCompany"></app-selector>
  </div>

  <i class="fa fa-arrows-alt-h fa-lg" *ngIf="isCenterVisible()"></i>

  <div class="c-company-selector" *ngIf="isCenterVisible()">
    <app-selector
        plugin="core-list-context-productiveunit"
        selector="selectedCenterLabel"
        selectorSecundary="selectedCenterSecondaryLabel"
        [selectionLabel]="'Centro' | t"
        [placeholder]="'Añadir Centro de Trabajo' | t"
        [additionalArguments]="{companyId: objCompany?.id}"
        [selectedItem]="objCenter"
        (itemSelected)="selectedCenter($event)"
        [canUnselect]="canUnselectCenter"></app-selector>
  </div>
</div>
