import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MappingListComponent } from './mapping-list/mapping-list.component';
import { MappingComponent } from './mapping.component';
import { MappingDetailsComponent } from './mapping-details/mapping-details.component';
import { SFormModule } from '../form/form.module';
import { List2Module } from '../list_v2/list.module';
import { MappingExecutionComponent } from './mapping-execution/mapping-execution.component';
import { MappingDeleteComponent } from './mapping-delete/mapping-delete.component';
import { MappingExecutionDetailsComponent } from './mapping-execution-details/mapping-execution-details.component';
import { MappingReportGenerationComponent } from './mapping-report-generation/mapping-report-generation.component';
import { BatchModule } from '../batch/batch.module';
import { MappingExecutionHistoryComponent } from './mapping-execution-history/mapping-execution-history.component';
import { MappingFlowModalComponent } from './mapping-flow-modal/mapping-flow-modal.component';
import { SpreadSheetService } from '../../core/services/ETG_SABENTISpro_Application_Core_spreadsheet.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SFormModule,
    List2Module,
    BatchModule
  ],
  declarations: [
    MappingComponent,
    MappingListComponent,
    MappingDetailsComponent,
    MappingDeleteComponent,
    MappingExecutionComponent,
    MappingExecutionDetailsComponent,
    MappingReportGenerationComponent,
    MappingExecutionHistoryComponent,
    MappingFlowModalComponent
  ],
  providers: [SpreadSheetService],
  exports: [MappingReportGenerationComponent, MappingFlowModalComponent]
})

export class MappingModule {
}
